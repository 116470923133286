import {
  add,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  getDay,
  isEqual,
  isSameDay,
  isSameMonth,
  isToday,
  parse,
  startOfToday,
  startOfWeek,
} from 'date-fns'
import React, { useContext, useEffect, useState } from 'react'
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi'
import * as Apiurl from '../Apisurl'
import Context from '../Context'
import './Calender.css'

const Calender = () => {
  const { selectValue } = useContext(Context)
  let today = startOfToday()
  let [events, setEvents] = useState()
  let [selectedDay, setSelectedDay] = useState(today)
  let [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'))
  let firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date())
  let [view, setView] = useState('day')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getEvents = async () => {
      setLoading(true)
      try {
        const response = await fetch(`${Apiurl.Fetchurl + 'reports_attendance?user_id=' + selectValue.user_id}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + ' ' + sessionStorage.getItem('session'),
          },
        })
        if (response.ok) {
          const data = await response.json()
          setEvents(data.attend)
          setLoading(false)
        } else {
          throw new Error('Something Went Wrong......')
        }
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }
    getEvents()
  }, [selectValue.user_id])

  let days = eachDayOfInterval({
    start: startOfWeek(firstDayCurrentMonth),
    end: endOfWeek(endOfMonth(firstDayCurrentMonth)),
  })

  // let months = eachMonthOfInterval({
  //   start: startOfYear(today),
  //   end: endOfYear(today),
  // })

  const changeView = () => {
    if (view === 'day') {
      setView('month')
    } else if (view === 'month') {
      setView('year')
    } else {
      setView('day')
    }
  }

  const jumpToToday = () => {
    setSelectedDay(today)
    setCurrentMonth(format(today, 'MMM-yyyy'))
  }

  const previousMonth = () => {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 })
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
  }

  const nextMonth = () => {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 })
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
  }

  return (
    !loading && (
      <div className='dashboard-cards p-3 m-0'>
        <div className='d-flex align-items-center'>
          <button type='button' onClick={changeView} className='change-view'>
            {format(firstDayCurrentMonth, 'MMMM yyyy')}
          </button>
          <button type='button' onClick={previousMonth} className='change-month'>
            <span className='sr-only'>Previous month</span>
            <HiChevronLeft style={{ width: '1.25rem', height: '1.25rem' }} aria-hidden='true' />
          </button>
          <button type='button' onClick={jumpToToday} className='change-month'>
            <span style={{ fontSize: '14px' }}>Today</span>
          </button>
          <button onClick={nextMonth} type='button' className='change-month'>
            <span className='sr-only'>Next month</span>
            <HiChevronRight style={{ width: '1.25rem', height: '1.25rem' }} aria-hidden='true' />
          </button>
        </div>

        <div className='days mt-2'>
          {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((d, i) => (
            <div key={i} className='days-title mx-auto'>
              {d}
            </div>
          ))}

          {days.map((day, dayIdx) => {
            let isEvent = events?.some((event) => {
              let temp = event.split('-')
              let formatedDate = new Date(temp[2], temp[1] - 1, temp[0])
              return isSameDay(formatedDate, day)
            })

            return (
              <div key={day.toString()} className={` py-1_5`} style={{ gridColumnStart: dayIdx === 0 && colStartClasses[getDay(day)] }}>
                <button
                  type='button'
                  onClick={() => setSelectedDay(day)}
                  className={`
                day mx-auto
                ${isEvent && 'bg-success text-white border border-2 border-success'}
                ${isEqual(day, selectedDay) && 'text-white'}
                ${!isEqual(day, selectedDay) && isToday(day) && 'text-danger'}
                ${!isEqual(day, selectedDay) && !isToday(day) && isSameMonth(day, firstDayCurrentMonth) && 'text-black'}
                ${!isEqual(day, selectedDay) && !isToday(day) && !isSameMonth(day, firstDayCurrentMonth) && 'text-secondary'}
                ${isEqual(day, selectedDay) && isToday(day) && 'bg-danger'}
                ${isEqual(day, selectedDay) && !isToday(day) && 'bg-dark'}                
                ${(isEqual(day, selectedDay) || isToday(day)) && 'fw-bold'}
                
                `}
                >
                  <time dateTime={format(day, 'yyyy-MM-dd')}>{format(day, 'd')}</time>
                </button>
              </div>
            )
          })}
        </div>

        {/* <div className='event-container'>
          {events.some((event) => {
            let temp = event.split('-')
            let formatedDate = new Date(temp[2], temp[1] - 1, temp[0])

            return isSameDay(formatedDate, day)
          }) && <div className='event'></div>}
        </div> */}

        {/* <div className='months mt-2'>
        {months.map((month, monthIdx) => (
          <div key={month.toString()} className='py-2'>
            <button type='button' onClick={() => setCurrentMonth(month)} className='month mx-auto'>
              <time dateTime={format(month, 'yyyy-MM-dd')}>{format(month, 'MMM')}</time>
            </button>
          </div>
        ))}
      </div> */}
      </div>
    )
  )
}

export default Calender

let colStartClasses = ['0', '2', '3', '4', '5', '6', '7']
