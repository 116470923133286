import React, { useState } from 'react'
import { Container, Row, Col, Button, Form, Modal } from 'react-bootstrap'
import styles from './GameComponent.module.css'
import EquationInput from './EquationInput'
import EquationList from './EquationList'
import GameOverModal from './GameOverModal'

const GameComponent = () => {
  const s = '4812'
  const validEquations = ['4*12=48', '4+8=12', '4*1*2=8', '8/2=4', '4/2=2'] // Example valid equations
  const [userEquations, setUserEquations] = useState([])
  const [currentInput, setCurrentInput] = useState('')
  const [score, setScore] = useState(0)
  const [remainingEquations, setRemainingEquations] = useState(validEquations.length)
  const [showModal, setShowModal] = useState(false)

  const handleInputChange = (e) => {
    setCurrentInput(e.target.value)
  }

  const checkEquation = () => {
    if (validEquations.includes(currentInput)) {
      if (!userEquations.includes(currentInput)) {
        setUserEquations([...userEquations, currentInput])
        setScore(score + 1)
        setRemainingEquations(remainingEquations - 1)
      }
    }
    setCurrentInput('')
  }

  const handleSkip = () => {
    setShowModal(true)
  }

  const handleModalClose = () => {
    setShowModal(false)
  }

  return (
    <Container className={styles.gameContainer}>
      <Row className='justify-content-center'>
        <Col xs={12} md={8} className='text-center'>
          <h2>Find All Valid Equations</h2>
          <h4>String: {s}</h4>
          <EquationInput value={currentInput} onChange={handleInputChange} onSubmit={checkEquation} />
          <EquationList equations={userEquations} />
          <div className='mt-3'>
            <h5>Score: {score}</h5>
            <h5>Remaining: {remainingEquations}</h5>
          </div>
          <Button variant='danger' onClick={handleSkip} className='mt-3'>
            Skip
          </Button>
        </Col>
      </Row>
      <GameOverModal
        show={showModal}
        onHide={handleModalClose}
        validEquations={validEquations}
        userEquations={userEquations}
        score={score}
      />
    </Container>
  )
}

export default GameComponent
