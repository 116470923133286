import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Spinner } from 'react-bootstrap'
import CustomDropzone from '../CustomDropzone'
import '../steps.css'
import * as API from '../../../Apisurl'

const StepOne = ({ formRef, validated, data, setData }) => {
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState()
  const [mods, setMods] = useState([])

  useEffect(() => {
    const getModules = async () => {
      try {
        const response = await fetch(`${API.Fetchurl}main_module_keys`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            module: 1,
            language: localStorage.getItem('getlang'),
          }),
        })
        const info = await response.json()
        setMods(info)
        !data &&
          setData({
            module: Object.keys(info)[0],
            text: info['Module 1'].text,
            image: info['Module 1'].image,
          })
      } catch (error) {
        console.log(error)
      }
    }
    getModules()
  }, [data, setData])

  useEffect(() => {
    // Revoke the data uris to avoid memory leaks
    return () => URL.revokeObjectURL(file)
  }, [file])

  const handleModuleChange = (e) => {
    setFile()
    const { value } = e.target
    setData({ module: value, text: mods[value].text, image: mods[value].image })
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setData((old) => ({ ...old, [name]: value }))
  }

  const upload = async () => {
    if (!file) return
    try {
      setLoading(true)

      const formData = new FormData()
      formData.append('user_id', sessionStorage.getItem('session'))
      formData.append('folder_type', 'module')
      formData.append('file', file)

      const response = await fetch(`${API.Fetchurl}file_upload_1`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('session'),
        },
      })

      const data = await response.json()
      let imageURL = data.file_path[0]
      setData((old) => ({ ...old, image: imageURL }))
      setFile()

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const selectOptions = Object.keys(mods)
  selectOptions.sort((a, b) => {
    return a.split(' ')[1] - b.split(' ')[1]
  })

  return (
    <Row>
      <Col>
        <Form ref={formRef} noValidate validated={validated}>
          <Form.Group className='mb-3' controlId='module-title'>
            <Form.Label>Module</Form.Label>
            <Form.Select required value={data?.module} onChange={handleModuleChange} name='module'>
              {selectOptions?.map((module, i) => {
                return (
                  <option key={i} value={module}>
                    {module}
                  </option>
                )
              })}
            </Form.Select>
          </Form.Group>

          <Form.Group className='mb-3' controlId='description'>
            <Form.Label>About</Form.Label>
            <Form.Control required type='text' name='text' value={data?.text} onChange={handleChange} maxLength='30' />
            <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
          </Form.Group>

          <div className='d-flex gap-1 mb-4'>
            <CustomDropzone setData={setFile} isMultiple={false} />

            <button
              onClick={upload}
              disabled={!file || loading}
              type='button'
              className='d-flex justify-content-center align-items-center py-1 px-3 border-0 rounded-3 text-white'
              style={{ backgroundColor: 'var(--green-color)' }}
            >
              {loading ? <Spinner animation='border' /> : 'Upload'}
            </button>
          </div>
        </Form>
      </Col>
      <Col>
        <div className='d-flex flex-column gap-2 align-items-center'>
          <h4>Preview</h4>
          <div className='preview-module-card'>
            <div className='text-center'>
              {!file ? <p>uploaded</p> : <p>preview only, click upload to save</p>}
              <img
                src={file ? URL.createObjectURL(file) : data?.image}
                alt='module-img'
                onLoad={() => URL.revokeObjectURL(file)}
              />
            </div>
            <div className='preview-info'>
              <h4 style={{ fontSize: '20px' }}>{data?.module}</h4>
              <p>{data?.text}</p>
            </div>
            <button class='common-btn'>Start</button>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default StepOne
