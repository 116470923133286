import React, { useContext, useState } from 'react'
import ReportTable from '../../brain/ReportTable'
import { Container } from 'react-bootstrap'
import Header from '../../brain/Header'
import Activity from '../../dashboard/Activity'
import BrainAnim from '../../brain/BrainAnim'
import PayButton from '../../pay/PayButton'
import Context from '../../Context'

const BrainReports = () => {
  const { userServices } = useContext(Context)
  const [coordinates, setCoordinates] = useState()
  const [insights, setInsights] = useState()

  const [condition, setCondition] = useState('reading')
  const [headset, setHeadset] = useState(sessionStorage.getItem('headset_name'))
  const [date, setDate] = useState()
  const [filePath, setFilePath] = useState('')
  const [speed, setSpeed] = useState(2)

  const isAccessable = userServices.includes(15)

  return (
    <div className='content flex-grow-1'>
      <Container fluid>
        <div className='dashboard-heading'>
          <div class='content-container'>
            <h2>Brain Report</h2>
            <p>Brain reports based on student data.</p>
          </div>
          <Activity />
        </div>
        <Header
          via={1}
          viaBolean={false}
          filePath={filePath}
          date={date}
          condition={condition}
          coordinates={coordinates}
        />
        {coordinates ? (
          <BrainAnim
            headset={headset}
            speed={speed}
            insights={insights}
            coordinates={coordinates}
            setCoordinates={setCoordinates}
          />
        ) : (
          <div className='position-relative user-select-none report-tab'>
            {!isAccessable && (
              <div
                className='d-flex align-items-center justify-content-center'
                style={{
                  position: 'absolute',
                  zIndex: 1,
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  background: 'rgba(255,255,255,0.6)',
                }}
              >
                <PayButton service={15} month={1} />
              </div>
            )}

            <div style={{ filter: !isAccessable && 'grayscale(100%)' }}>
              <ReportTable
                coordinates={coordinates}
                setCoordinates={setCoordinates}
                setInsights={setInsights}
                setCondition={setCondition}
                setHeadset={setHeadset}
                setDate={setDate}
              />
            </div>
          </div>
        )}
      </Container>
    </div>
  )
}

export default BrainReports
