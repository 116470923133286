import React, { useState } from 'react'
import Header from '../profile/Header'
import Activity from '../dashboard/Activity'
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

const QRCResult = () => {
  const location = useLocation()

  let result = location?.state?.data

  let main = result.master.top2

  let colors = { Rationals: '#1b75bc', Idealists: '#00a14b', Artisans: '#eb1b23', Guardians: '#f3c910' }

  return (
    <div className='content flex-grow-1'>
      <Container fluid className='report-tab'>
        <div className='dashboard-heading'>
          <div class='content-container'>
            <h2>Keirsey Test Report</h2>
            <p>The answer to your questions</p>
          </div>
          <Activity />
        </div>

        {result.res.toLowerCase().includes('TryFourTemperTest'.toLowerCase()) ? (
          <Row className='mx-auto w-75 dashboard-cards p-4 text-black'>Please take another test for better results</Row>
        ) : (
          <Tabs defaultActiveKey='1' id='1' className='mt-3'>
            {main.map((m, i) => {
              let sub = result.res.split('&')[1].split('or')

              let description = m.desc.split('\n').filter((str) => str !== '')
              let core_values = {
                pride: m.pride,
                tend_to_be: m.tendtobe,
                they_are: m.theyare,
                they_make: m.theyare,
              }

              let sub_main = []
              sub.forEach((s) => {
                let k = s.trim()
                if (m[k]) sub_main.push(m[k])
              })

              return (
                <Tab eventKey={i + 1} title={m.value}>
                  <Container>
                    <Row className='dashboard-cards p-4 text-black'>
                      <Col md={12}>
                        <h1 className='fst-italic text-center'>
                          {m.value}
                          <span className='fs-3'>{` make up ${m.percentage}% of the entire population`}</span>
                        </h1>
                        <Row className='d-flex flex-wrap align-items-center justify-content-around'>
                          {Object.entries(core_values).map(([title, info]) => {
                            return (
                              <Col md={6} xl={4} xxl={3} className='position-relative' key={title}>
                                <svg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'>
                                  <path
                                    fill={colors[m.value]}
                                    d='M50.1,-58.9C66.3,-46.1,81.7,-31.6,85.1,-14.7C88.4,2.2,79.6,21.5,67.2,34.9C54.8,48.2,38.8,55.5,22.4,61.4C6,67.2,-10.8,71.6,-27.8,68.7C-44.8,65.8,-61.9,55.7,-71.4,40.6C-80.9,25.4,-82.8,5.2,-78.9,-13.4C-75,-32.1,-65.3,-49.2,-51.2,-62.3C-37.1,-75.5,-18.5,-84.6,-0.8,-83.7C16.9,-82.7,33.9,-71.7,50.1,-58.9Z'
                                    transform='translate(100 100)'
                                  />
                                </svg>
                                <div className='position-absolute top-50 start-50 translate-middle'>
                                  <h4 className='text-capitalize mb-1 text-white'>{title.split('_').join(' ')}</h4>
                                  <p className='m-0 p-0 fw-bold' style={{ fontSize: '12px' }}>
                                    {info}
                                  </p>
                                </div>
                              </Col>
                            )
                          })}
                        </Row>
                        <Container>
                          {description.map((str, i) => (
                            <p
                              className={`${i === 0 || i === 2 ? 'text-center fst-italic' : ''}`}
                              style={{
                                color: i === 0 || i === 2 ? colors[m.value] : 'black',
                              }}
                            >
                              {str}
                            </p>
                          ))}
                          <div className='border-top border-bottom px-2 py-4'>
                            <p className='text-center fst-italic'>
                              Although all {m.value} share core characteristics, not all of them are the same. There are
                              4 kinds of {m.value}.
                            </p>
                            <div className='d-flex align-items-center justify-content-center gap-4'>
                              {Object.values(m).map((type, i) => {
                                let highlight = sub_main.map((obj) => obj.value)
                                let isActive = highlight.includes(type.value)
                                return i < 4 ? (
                                  <div
                                    className={`${
                                      isActive ? 'text-white order-1' : 'text-black order-2'
                                    }  rounded-pill px-3 py-2 `}
                                    style={{
                                      backgroundColor: isActive ? colors[m.value] : '',
                                      border: `2px solid ${colors[m.value]}`,
                                    }}
                                  >
                                    {type.value}
                                  </div>
                                ) : null
                              })}
                            </div>
                          </div>

                          {sub_main.map((s_m, index) => {
                            return (
                              <Row key={index} className='align-items-center'>
                                <Col
                                  md={6}
                                  lg={5}
                                  xl={4}
                                  className={`${index % 2 !== 0 ? 'order-2' : 'order-1'} position-relative`}
                                >
                                  <svg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                      fill={colors[m.value]}
                                      d='M50.1,-58.9C66.3,-46.1,81.7,-31.6,85.1,-14.7C88.4,2.2,79.6,21.5,67.2,34.9C54.8,48.2,38.8,55.5,22.4,61.4C6,67.2,-10.8,71.6,-27.8,68.7C-44.8,65.8,-61.9,55.7,-71.4,40.6C-80.9,25.4,-82.8,5.2,-78.9,-13.4C-75,-32.1,-65.3,-49.2,-51.2,-62.3C-37.1,-75.5,-18.5,-84.6,-0.8,-83.7C16.9,-82.7,33.9,-71.7,50.1,-58.9Z'
                                      transform='translate(100 100)'
                                    />
                                  </svg>
                                  <h4 className='m-0 position-absolute top-50 start-50 translate-middle'>
                                    <span className='text-white'>You are a</span> {s_m.value}
                                  </h4>
                                </Col>
                                <Col md={6} lg={7} xl={8} className={`${index % 2 !== 0 ? 'order-1' : 'order-2'}`}>
                                  <p className='m-0 fst-italic'>{s_m.desc}</p>
                                </Col>
                              </Row>
                            )
                          })}
                        </Container>
                      </Col>
                    </Row>
                  </Container>
                </Tab>
              )
            })}
          </Tabs>
        )}
      </Container>
    </div>
  )
}

export default QRCResult
