import react from 'react'
import { Row, Container, Col } from 'react-bootstrap'
import backbtn from '../../../assets/images/backbtn.svg'
import Focus from '../../../includes/Focus'
import Progress from '../Testprogrsscom'
import refreshbtn from '../../../assets/images/refresh.svg'
import Draggable, { DraggableCore } from 'react-draggable' // Both at the same time
import { useState, useRef, useEffect, useContext } from 'react'
import StartModal from '../../SelectgameStart'
import { NavLink, useLocation, useParams, useNavigate } from 'react-router-dom'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import * as Currdate from '../../../CurrDate'
import * as Apiurl from '../../../Apisurl'
import { SocketConfig } from '../../../SocketConfig'

import { useSpeechSynthesis } from 'react-speech-kit'
import leftarrow from '../../../assets/images/left.svg'
import rightarrow from '../../../assets/images/right.svg'
import Form from 'react-bootstrap/Form'
import Speech from '../../Speech'

const Typing = (props) => {
  let headingname = useParams()
  const { speak } = useSpeechSynthesis()

  // let date = (UserJson().date = GetCurrentDate());
  let DraggableCore = Draggable.DraggableCore
  const [score, setScore] = useState(0)
  const location = useLocation()
  const [isActive, setIsActive] = useState(false)
  let initialArray = []
  const [parsedata, setparsedata] = useState(false)
  const [error, setError] = useState()
  const [mistakes, setMistakes] = useState(0)
  const [userAnswer, setUserAnswer] = useState('')
  const resetButton = useRef(null)
  const [smShow, setsmShow] = useState(false)
  const [img1, setimg1] = useState()
  const [per, setper] = useState()
  const [img2, setimg2] = useState()
  let inputRef = useRef()

  let vocationalData = props.vocationalData
  //   console.log(vocationalData);
  let datalength = props.datalength
  let date = Currdate.GetCurrentDate()

  props.time(date)
  let datalengthi = datalength
  const [callday, setcallday] = useState(1)
  let navigate = useNavigate()
  function editDistance(s1, s2) {
    s1 = s1.toLowerCase()
    s2 = s2.toLowerCase()

    var costs = new Array()
    for (var i = 0; i <= s1.length; i++) {
      var lastValue = i
      for (var j = 0; j <= s2.length; j++) {
        if (i == 0) costs[j] = j
        else {
          if (j > 0) {
            var newValue = costs[j - 1]
            if (s1.charAt(i - 1) != s2.charAt(j - 1)) newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1
            costs[j - 1] = lastValue
            lastValue = newValue
          }
        }
      }
      if (i > 0) costs[s2.length] = lastValue
    }
    return costs[s2.length]
  }
  function handleSubmit(e, via) {
    Speech.Speech(inputRef.current.value)
    // document.querySelectorAll(".css-1peva9n-Box").map()
    let similartxt = similarity(datalength[props.page].ans, inputRef.current.value)
    var perc = Math.round(similartxt * 10000) / 100
    props.setPage((prev) => {
      props.setTheArray((oldArray) => {
        const update = [
          ...oldArray,
          {
            via: 'correct',
            percentage: perc,
            inputvalue: inputRef.current.value,
          },
        ]
        inputRef.current.value = ''

        localStorage.setItem('useranswer', JSON.stringify(update))

        return update
      })

      if (props.page === datalengthi.length - 1) {
        props.setTheArray([])
        // socketemitdata();
        props.submitResult(date)

        // navigate('vocationresult', {
        //   state: { ...location.state, date: date },
        // })
      } else {
        props.setScore((prev) => prev + 1)

        return prev + 1
      }
    })
  }
  function similarity(s1, s2) {
    var longer = s1
    var shorter = s2
    if (s1.length < s2.length) {
      longer = s2
      shorter = s1
    }
    var longerLength = longer.length
    if (longerLength === 0) {
      return 1.0
    }
    return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)
  }
  //edit distance string check
  function editDistance(s1, s2) {
    s1 = s1.toLowerCase()
    s2 = s2.toLowerCase()

    var costs = new Array()
    for (var i = 0; i <= s1.length; i++) {
      var lastValue = i
      for (var j = 0; j <= s2.length; j++) {
        if (i == 0) costs[j] = j
        else {
          if (j > 0) {
            var newValue = costs[j - 1]
            if (s1.charAt(i - 1) != s2.charAt(j - 1)) newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1
            costs[j - 1] = lastValue
            lastValue = newValue
          }
        }
      }
      if (i > 0) costs[s2.length] = lastValue
    }
    return costs[s2.length]
  }
  const preventCopyPaste = (e) => {
    e.preventDefault()
    setError('Copying and pasting is not allowed!')
  }
  const errorRemover = () => {
    let similartxt = similarity(datalength[props.page].ans, inputRef.current.value)
    var perc = Math.round(similartxt * 10000) / 100
    setper(perc)
    // console.log();
    setError('')
  }
  return (
    <>
      <Container fluid>
        <h2>Type the following text</h2>
        <Row className='colors-main-container typing-game  objgame '>
          <Col md={6}>
            <div className='preview-txt-container'>{datalength[props.page]?.ans}</div>
          </Col>
          <Col md={6}>
            <div className='txt-container'>
              <Form.Control
                as='textarea'
                rows={3}
                autoComplete='nope'
                ref={inputRef}
                onInput={errorRemover}
                onCopy={(e) => preventCopyPaste(e)}
                onPaste={(e) => preventCopyPaste(e)}
                onCut={(e) => preventCopyPaste(e)}
                placeholder='Type here'
              />
              <span className='matching-per'>{per}%</span>
            </div>
          </Col>
          <p className='mt-2' style={{ color: 'red', textAlign: 'right' }}>
            {error}
          </p>
        </Row>

        <div className='text-right' style={{ textAlign: 'right' }}>
          <button className='common-btn px-3 py-2' onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </Container>
      <StartModal
        calldelay={setcallday}
        socket={SocketConfig}
        type={['action', 'focus']}
        date={date}
        letquesLength={datalength.length}
        calldaynum={callday}
      />
    </>
  )
}
export default Typing
