import { MDBIcon } from "mdb-react-ui-kit";
import { Row, Col, Table } from "react-bootstrap";

import UserJson from "../../../Userjson";
import { useOutletContext } from "react-router-dom";
import react, { useRef } from "react";
import Pagination from "../../Pagination";
import { useEffect, useState, useContext } from "react";
import * as Apiurl from "../../../Apisurl";
import Context from "../../../Context";
import InputRange from "react-input-range";

const TabularResult = (props) => {
  return (
    <Row>
      <>
        <Col md={6} className="excelgame-container">
          <h4>Correct Answer Table</h4>
          {props.ques.map((items, index) => {
            return (
              <>
                <div className="tabl-container">
                  <span>{index + 1}</span>
                  <Table
                    striped
                    bordered
                    hover
                    className="tbody-container1 w-100"
                  >
                    <thead>
                      <th>Item</th>
                      <th>quantity</th>
                    </thead>
                    <tbody className="tbody-container">
                      {items.ans["quantity"].map((item, index) => {
                        return (
                          <tr>
                            <td>
                              <p>{items.ans["item"][index]}</p>
                            </td>
                            <td>
                              <p>{item}</p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </>
            );
          })}
        </Col>

        <Col md={6} className="excelgame-container">
          <h4>Answer Table</h4>
          {props.answ.map((item, i) => {
            return (
              <Table striped bordered hover className="tbody-container1">
                <thead>
                  <th>Item</th>
                  <th>quantity</th>
                </thead>
                <tbody className="tbody-container">
                  {item.useranswers.map((o, index) => {
                    console.log(o[1], "zxcxzczc");
                    return (
                      <>
                        <tr>
                          <td>
                            <p>{o[0].item}</p>
                          </td>
                          <td>
                            <p>{o[1].quantity}</p>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            );
          })}
        </Col>
      </>
      );
    </Row>
  );
};

export default TabularResult;
