import React, { useCallback, useMemo } from 'react'
import { useDropzone } from 'react-dropzone'

const baseStyle = {
  minHeight: '100px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  border: '2px dashed #54baaf',
  backgroundColor: '#54baaf',
  borderRadius: 8,
  outline: 'none',
  transition: 'background-color .24s ease-in-out',
}

const focusedStyle = {
  backgroundColor: '#54baaf',
}

const acceptStyle = {
  backgroundColor: '#54baaf',
}

const rejectStyle = {
  backgroundColor: '#ff1744',
}

const CustomVideoDropzone = ({ setData, isMultiple }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (isMultiple) {
        if (acceptedFiles?.length) {
          setData(acceptedFiles)
        }
      } else {
        if (acceptedFiles?.length) {
          setData(acceptedFiles[0])
        }
      }
    },
    [setData, isMultiple]
  )

  const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      'video/*': ['.mp4', '.MP4'],
    },
    multiple: isMultiple,
    onDrop,
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  )

  return (
    <>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />

        {!acceptedFiles.length > 0 ? (
          <p style={{ margin: 0, fontSize: '16px' }} className='text-white'>
            {isDragAccept
              ? 'Drop Files Here'
              : isDragReject
              ? 'Only .JPEG | .JPG | .PNG Allowed'
              : "Drag 'n' drop , or click to select files"}
          </p>
        ) : (
          <p style={{ margin: 0, fontSize: '16px' }} className='text-white'>
            {acceptedFiles.length} Files Added
          </p>
        )}
      </div>
    </>
  )
}

export default CustomVideoDropzone
