import react from 'react'
import { Row, Container, Col } from 'react-bootstrap'
import { useEffect, useCallback } from 'react'
import { useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import Focus from '../../../includes/Focus'
import Progress from '../Testprogrsscom'
import refreshbtn from '../../../assets/images/refresh.svg'
import socketio from 'socket.io-client'
import StartModal from '../../SelectgameStart'
import * as Apiurl from '../../../Apisurl'
import Userjson from '../../../Userjson'

import { useDrag, DndProvider, DragPreviewImage } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import * as Currdate from '../../../CurrDate'
import headersReq from '../../../HeadersJson'

import Match from './Match'

const EnglishGame = () => {
  let headerjson = headersReq()
  const [callday, setcallday] = useState(1)
  const [mathsdata, setmathsdata] = useState([])
  let [constantTime, setconstantTime] = useState('')
  const [data1, setdata1] = useState([])
  const [connect, setconnected] = useState(false)
  const [messageHistory, setMessageHistory] = useState([])
  const [score, setScore] = useState(0)
  const location = useLocation()

  const [showError, setShowError] = useState()
  const [timer, setTimer] = useState(30)
  const [page, setPage] = useState(0)
  const [smShow, setsmShow] = useState(false)
  const [level, setlevel] = useState('')
  const [activeleftAnswer, setleftactiveAnswer] = useState('')
  const [activerightAnswer, setrightactiveAnswer] = useState(false)
  let leveldata = location.state.link
  const [englishData, setEnglishData] = useState([])
  let cat = location.state.category
  // let navigate=useNavigate();

  let initialArray = []

  const [action, setactiondata] = useState([])
  const [theArray, setTheArray] = useState(initialArray)
  const [inputvalue, setinputvalue] = useState('')
  let userj = Userjson()
  const submitResult = () => {
    let answer = JSON.parse(localStorage.getItem('useranswer'))
    let date = Currdate.GetCurrentDate()

    let getScore = answer.filter((o, i) => {
      return o.via == 'correct'
    })

    let alldata = {
      ...userj,
      result: JSON.stringify({
        ques:
          englishData != undefined
            ? englishData[location.state.link].map((data) => {
                let modifiedData = { ...data }

                if (modifiedData['image'] !== undefined) {
                  modifiedData['image'] = modifiedData['image'].replace(
                    'https://ui-nema-database.s3.ap-south-1.amazonaws.com/',
                    ''
                  )
                }
                if (modifiedData['images'] !== undefined) {
                  modifiedData['images'] = modifiedData['images'].replace(
                    'https://ui-nema-database.s3.ap-south-1.amazonaws.com/',
                    ''
                  )
                }
                if (modifiedData['image_1'] !== undefined) {
                  modifiedData['image_1'] = modifiedData['image_1'].replace(
                    'https://ui-nema-database.s3.ap-south-1.amazonaws.com/',
                    ''
                  )
                }
                if (modifiedData['image_2'] !== undefined) {
                  modifiedData['image_2'] = modifiedData['image_2'].replace(
                    'https://ui-nema-database.s3.ap-south-1.amazonaws.com/',
                    ''
                  )
                }

                return modifiedData
              })
            : '',
        score: getScore.length,
        total: englishData[location.state.link].length,
        ans: answer.map((data) => {
          let modifiedData = { ...data }

          if (modifiedData['answer'] !== undefined) {
            modifiedData['answer'] = modifiedData['answer'].replace(
              'https://ui-nema-database.s3.ap-south-1.amazonaws.com/',
              ''
            )
          }
          if (modifiedData['answer1'] !== undefined) {
            modifiedData['answer1'] = modifiedData['answer1'].replace(
              'https://ui-nema-database.s3.ap-south-1.amazonaws.com/',
              ''
            )
          }

          return modifiedData
        }),
      }),
      date: date,
    }
    const esc = encodeURIComponent

    const query = Object.keys(alldata)
      .map((k) => `${esc(k)}=${esc(alldata[k])}`)
      .join('&')

    async function levelgame() {
      const response = await fetch(`${Apiurl.Fetchurl + 'manual_report_fc?' + query + ''}`, {
        method: 'GET',
        headers: headerjson,
      })
      const childdata = await response.json()
      return childdata
    }

    levelgame()
      .then((data) => {
        navigate(`matchresult?id=${data.serial_num}`, { state: { ...location.state, date: date } })
        // setmathsdata(data)
      })
      .catch((error) => {
        alert(error)
      })
  }

  useEffect(() => {
    async function levelgame() {
      const response = await fetch(`${Apiurl.Fetchurl + 'level_match'}`, {
        method: 'POST',
        body: JSON.stringify({
          category: cat.toLowerCase(),
        }),
        headers: headerjson,
      })
      const childdata = await response.json()
      return childdata
    }
    levelgame()
      .then((data) => {
        setEnglishData(data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  // console.log(aanjsonData);
  let json = englishData.length != 0 ? englishData[leveldata] : []
  let length1 = englishData[location.state.link]
  let downloadTimer
  let navigate = useNavigate()
  let catarr = ['adjectives', '2_phase_mcq', 'sentences_mcq']

  if (englishData.length != 0) {
    const renderEnglishcat = () => {
      let games
      switch (cat) {
        case 'matching':
        case 'food_we_eat':
        case 'sense_organs':
          games = (
            <Match
              englishData={json}
              datalength={[location.state.link]}
              setPage={setPage}
              submitResult={() => submitResult()}
              setTheArray={setTheArray}
              page={page}
              time={setconstantTime}
              setScore={setScore}
              setdata1={setdata1}
              data1={data1}
            />
          )

          break
      }
      return games
    }

    return (
      <>
        <div className='d-flex align-item-center justify-content-between my-4' style={{ position: 'relative' }}>
          {/* <Focus /> */}
        </div>
        {renderEnglishcat()}

        <div className='activity-listing'>
          <ul>
            <li className='active'></li>
            <li className='active'>
              Score:
              <b>{catarr.includes(cat) ? length1.length / 2 : length1.length}</b>/<span>{score}</span>
            </li>
          </ul>

          <ul>
            <li>
              {/* <Progress
                json={englishData[leveldata]}
                currindex={page}
                getarray={theArray}
              /> */}
            </li>
            {/* <li className="refresh-btn">
              <NavLink to="/school/game/level/colorresult">
                <button>
                  <img src={refreshbtn} alt="refresh" />
                  Start Another Level
                </button>
              </NavLink>
              <button>
                <img src={refreshbtn} alt="refresh" />
                Start Over
              </button>
            </li> */}
          </ul>
        </div>
      </>
    )
  }
}
export default EnglishGame
