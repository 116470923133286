import React from 'react'
import { ListGroup } from 'react-bootstrap'
import styles from './EquationList.module.css'

const EquationList = ({ equations }) => {
  return (
    <ListGroup>
      {equations.map((eq, index) => (
        <ListGroup.Item key={index} className={styles.listItem}>
          {eq}
        </ListGroup.Item>
      ))}
    </ListGroup>
  )
}

export default EquationList
