import { useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import Style from '../cssmodules/button.module.css'
import Bloom from './Chart1'
import EmotionalAnalysis from './EmotionalModal'
import Pm from './Pm'
import QAcharts from './QAcharts'
import ShowResult from './Showresult'

const DashboardCardStructure = () => {
  const [lgShow, setLgShow] = useState(false)
  const [via, setvia] = useState(false)

  return (
    <>
      <ShowResult />

      <Row>
        <Col>
          <div className='dashboard-cards'>
            <div class='performance-content d-flex justify-content-between'>
              <h4 style={{ fontWeight: '600' }}>Your Nema Report</h4>
              <p
                className='read-mr-btn'
                style={{ fontSize: '12px', fontWeight: '600', cursor: 'pointer' }}
                onClick={() => setvia(true)}
              >
                Read More
              </p>
            </div>
            <Bloom />
          </div>

          <div className='dashboard-cards stat-container'>
            {sessionStorage.getItem('headsetVia') == 2 ? (
              <div class='performance-heading'>
                <div class='performance-content'>
                  <h4>Performance Metrics</h4>
                </div>
                <button className={Style.commonbtn + ' ' + 'w-50'} onClick={() => setLgShow(true)} type='button'>
                  Emotional Analysis
                </button>
              </div>
            ) : (
              <img
                src={require('../assets/images/blooimg.jpeg')}
                style={{
                  height: '300px',
                  objectFit: 'contain',
                  borderRadius: '30px',
                  background: '#ecfaff',
                  padding: '8px',
                }}
              />
            )}
            <Pm />
          </div>
        </Col>

        <Col>
          <div className='dashboard-cards stat-container'>
            <QAcharts />
          </div>
        </Col>

        <EmotionalAnalysis via={lgShow} setvia={setLgShow} />

        <Modal size='lg' show={via} onHide={() => setvia(false)}>
          <Modal.Body>
            <Row>
              <Col md={12}>
                <img src={require('../assets/images/blooimg.jpeg')} style={{ width: '100%' }} />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </Row>
    </>
  )
}
export default DashboardCardStructure
