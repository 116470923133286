import React, { useEffect, useState } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import '../steps.css'
import CustomVideoDropzone from '../CustomVideoDropzone'
import CustomDropzone from '../CustomDropzone'
import * as API from '../../../Apisurl'

const StepFourB = ({ data, setData }) => {
  const [step, setStep] = useState(1)

  const levelComponents = [
    <Card key={1} step={step} title={data[0].name} data={data} setData={setData} />,
    <Card key={2} step={step} title={data[1].name} data={data} setData={setData} />,
    <Card key={3} step={step} title={data[2].name} data={data} setData={setData} />,
  ]

  return (
    <>
      <div>{levelComponents[step - 1]}</div>
      <div className='d-flex justify-content-start gap-2'>
        <button
          onClick={() => setStep((s) => s - 1)}
          type='button'
          className='d-flex justify-content-center align-items-center py-1 px-3 rounded-pill border border-2 text-secondary'
          style={{ backgroundColor: 'transparent' }}
          disabled={step === 1}
        >
          Back
        </button>
        <button
          onClick={() => setStep((s) => s + 1)}
          type='submit'
          className={`d-flex justify-content-center align-items-center py-1 px-3 rounded-pill text-white`}
          style={{ backgroundColor: 'var(--green-color)', border: 'none' }}
          disabled={step === 3}
        >
          Next
        </button>
      </div>
    </>
  )
}

export default StepFourB

export const Card = ({ title, step, data, setData }) => {
  const [loadingImage, setLoadingImage] = useState(false)
  const [loadingVideo, setLoadingVideo] = useState(false)
  const [image, setImage] = useState()
  const [video, setVideo] = useState()

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(image)
      URL.revokeObjectURL(video)
    }
  }, [image, video])

  const upload = async (e) => {
    if (!image) return

    try {
      setLoadingImage(true)

      const formData = new FormData()
      formData.append('user_id', sessionStorage.getItem('session'))
      formData.append('folder_type', 'education')
      formData.append('file', image)

      const response = await fetch(`${API.Fetchurl}file_upload_1`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('session'),
        },
      })

      const info = await response.json()
      let imageURL = info.file_path[0]

      let updated = data.map((d) => (d.name === title ? { ...d, image: imageURL } : d))
      setData(updated)
      setImage()

      setLoadingImage(false)
    } catch (error) {
      setLoadingImage(false)
      console.log(error)
    }
  }

  const uploadVideo = async (e) => {
    if (!video) return

    try {
      setLoadingVideo(true)

      const formData = new FormData()
      formData.append('user_id', sessionStorage.getItem('session'))
      formData.append('folder_type', 'education')
      formData.append('file', video)

      const response = await fetch(`${API.Fetchurl}file_upload_1`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('session'),
        },
      })

      const info = await response.json()
      let videoURL = info.file_path[0]

      let updated = data.map((d) => (d.name === title ? { ...d, video: videoURL } : d))
      setData(updated)
      setVideo()

      setLoadingVideo(false)
    } catch (error) {
      setLoadingVideo(false)
      console.log(error)
    }
  }

  return (
    <Row>
      <Col>
        <h4 className='mb-4 text-capitalize' style={{ fontSize: '24px' }}>
          {title.slice(0, 5) + ' ' + title.charAt(5)}
        </h4>
        <div>
          <h4 className='mb-2'>Thumbnail</h4>
          <div className='d-flex gap-1 mb-4'>
            <CustomDropzone setData={setImage} isMultiple={false} />
            <button
              onClick={upload}
              type='button'
              disabled={!image || loadingImage}
              style={{ backgroundColor: 'var(--green-color)' }}
              className='d-flex justify-content-center align-items-center py-1 px-3 border-0 rounded-3 text-white'
            >
              {loadingImage ? <Spinner animation='border' /> : 'Upload'}
            </button>
          </div>
          <h4 className='mb-2'>Video</h4>
          <div className='d-flex gap-1 mb-4'>
            <CustomVideoDropzone setData={setVideo} isMultiple={false} />
            <button
              onClick={uploadVideo}
              type='button'
              disabled={!video || loadingVideo}
              style={{ backgroundColor: 'var(--green-color)' }}
              className='d-flex justify-content-center align-items-center py-1 px-3 border-0 rounded-3 text-white'
            >
              {loadingVideo ? <Spinner animation='border' /> : 'Upload'}
            </button>
          </div>
        </div>
      </Col>
      <Col>
        <div>
          <h4 className='mb-4 text-center'>Preview</h4>
          <div className='d-flex gap-4'>
            {image || data[step - 1].image ? (
              <div className='text-center'>
                <img
                  style={{ objectFit: 'cover', height: '250px', width: '250px', borderRadius: '15px' }}
                  src={image ? URL.createObjectURL(image) : data[step - 1].image}
                  onLoad={() => URL.revokeObjectURL(image)}
                  alt='level'
                />
                {!image ? <p>Uploaded</p> : <p>Preview only, Click Upload to Save</p>}
              </div>
            ) : (
              <div
                className='d-flex text-center align-items-center'
                style={{ height: '250px', width: '250px', borderRadius: '15px', backgroundColor: 'var(--grey-color)' }}
              >
                Image After Uploading Will Appear Here
              </div>
            )}

            {video || data[step - 1].video ? (
              <div className='text-center'>
                <video
                  style={{ objectFit: 'cover', height: '250px', width: '250px', borderRadius: '15px' }}
                  src={video ? URL.createObjectURL(video) : data[step - 1].video}
                  onLoad={() => URL.revokeObjectURL(video)}
                  controls
                />
                {!video ? <p>Uploaded</p> : <p>Preview only, Click Upload to Save</p>}
              </div>
            ) : (
              <div
                className='d-flex text-center align-items-center'
                style={{ height: '250px', width: '250px', borderRadius: '15px', backgroundColor: 'var(--grey-color)' }}
              >
                Video After Uploading Will Appear Here
              </div>
            )}
          </div>
        </div>
      </Col>
    </Row>
  )
}
