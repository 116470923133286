import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import * as API from '../Apisurl'
import Context from '../Context'
import Activity from '../dashboard/Activity'
import Header from '../profile/Header'
import weekplan from '../assets/images/weekplan.svg'
import PayButton from '../pay/PayButton'

const WeekPlan = () => {
  const { selectValue, userServices } = useContext(Context)
  const navigate = useNavigate()

  const [info, setInfo] = useState(null)
  const [progress, setProgress] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [weeks, setWeeks] = useState([])

  useEffect(() => {
    const getWeekPlan = async () => {
      if (!selectValue.user_id) return

      setLoading(true)
      setError(null)
      try {
        const res = await fetch(`${API.Fetchurl}roles_user?user_id=${selectValue.user_id}`)

        if (!res.ok) throw new Error('Something went wrong')

        const data = await res.json()
        setInfo(data)
        setWeeks({
          week1: data.about.week1,
          week2: data.about.week2,
          week3: data.about.week3,
          week4: data.about.week4,
        })
        setProgress(data.progress)
      } catch (err) {
        console.log(err)
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    getWeekPlan()
  }, [selectValue.user_id])

  const isAccessable = userServices.includes(16)

  return (
    <div className='content flex-grow-1'>
      <Container fluid>
        <div className='dashboard-heading'>
          <div class='content-container'>
            <h2>Weekly Plan</h2>
            <p>A comprehensive 4 week plan to improve your abilities</p>
          </div>
          <Activity />
        </div>
        <Header />

        {loading ? (
          <div>Loading....</div>
        ) : error ? (
          <div>{error}</div>
        ) : (
          <Row className='my-3'>
            <Col xl={4} className='mb-4'>
              <div className='h-100  dashboard-cards p-3 ' style={{ borderRadius: '14px' }}>
                <div
                  className='bg-success bg-opacity-10 h-100 d-flex flex-column align-items-center justify-content-between gap-2 p-4'
                  style={{ borderRadius: '14px' }}
                >
                  <h1 className='m-0 text-center text-uppercase fw-bolder'>{info.character}</h1>
                  <img src={info.about.image} alt='character' className='rounded-circle border' width={'100%'} />

                  <div>
                    <h2 className='fw-bold text-black text-uppercase'>{info.about.desc[0]}</h2>
                    <p className='fw-bold fs-6 m-0' style={{ textAlign: 'justify' }}>
                      {info.about.desc[1]}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={8} className='position-relative'>
              {!isAccessable && (
                <div
                  className='h-100 w-100 d-flex justify-content-center align-items-center'
                  style={{ position: 'absolute', zIndex: '1', background: 'rgba(255,255,255,0.8)' }}
                >
                  <PayButton service={16} month={1} />
                </div>
              )}
              <Row className='h-100' style={{ filter: !isAccessable && 'grayscale(100%)' }}>
                {Object.entries(weeks).map(([week, data], i) => {
                  let completed = progress[week]
                  return (
                    <Col xl={6} className='mb-4'>
                      <div className='h-100 d-flex flex-column dashboard-cards p-3'>
                        <div className='bg-success bg-opacity-10 flex-grow-1 mb-3 overflow-hidden' style={{ height: '200px', borderRadius: '12px' }}>
                          <img src={weekplan} alt='cover-art' />
                        </div>
                        <h6 className='m-0 text-uppercase text-black opacity-100'>{week.replace('week', 'Week ')}</h6>
                        <div className='rounded-pill my-3 bg-dark bg-opacity-10' style={{ height: '8px' }}>
                          <div className='h-100 rounded-pill' style={{ width: `${completed}%`, background: 'var(--green-color)' }}></div>
                        </div>
                        <div className='d-flex align-items-center justify-content-between'>
                          <p className='m-0 text-secondary'>
                            Completed: <span className='text-black fw-bold'>{completed}%</span>
                          </p>

                          <button
                            onClick={() => navigate(week, { state: { data } })}
                            className='py-1 px-3 border-0'
                            style={{
                              background: completed ? 'var(--green-color)' : 'var(--green-highlight-color)',
                              color: completed ? 'white' : 'var(--green-color)',
                              fontWeight: !completed ? 'bold' : 'normal',
                              borderRadius: '8px',
                            }}
                          >
                            <p className='m-0 text-center'>{completed ? 'Resume' : 'Start'}</p>
                          </button>
                        </div>
                      </div>
                    </Col>
                  )
                })}
              </Row>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  )
}

export default WeekPlan
