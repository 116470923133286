import { useContext } from 'react'
import { Container, Tab, Tabs } from 'react-bootstrap'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as Apiurl from '../Apisurl'
import Context from '../Context'
import headersReq from '../HeadersJson'
import Activity from '../dashboard/Activity'
import Header from '../profile/Header'
import Test1 from './Test1'
import Test2 from './Test2'
import Test3 from './Test3'
import { test1, test2 } from './data'

const Questions = () => {
  const reqJson = headersReq()
  const { selectValue } = useContext(Context)
  const navigate = useNavigate()

  const searchParams = useSearchParams()[0]

  const isLogical = searchParams.get('test') === 'logical'

  const submit = async (res) => {
    try {
      const response = await fetch(Apiurl.Fetchurl + 'questionaire', {
        method: 'POST',
        headers: reqJson,
        body: JSON.stringify({ user_id: selectValue.user_id, ...res }),
      })
      if (response.ok) {
        const data = await response.json()
        navigate('result', { state: { data: data } })
      } else {
        const error = await response.json()
        throw new Error(error?.error || 'Something Went Wrong....')
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  const submitLogical = async (res) => {
    try {
      const response = await fetch(Apiurl.Fetchurl + 'questionaire_logical', {
        method: 'POST',
        headers: reqJson,
        body: JSON.stringify({ ...res, user_id: selectValue.user_id, username: selectValue.username }),
      })
      if (response.ok) {
        await response.json()
        navigate('resultlogical', { state: { data: res } })
      } else {
        const error = await response.json()
        throw new Error(error?.error || 'Something Went Wrong....')
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  const options = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I']

  return (
    <div className='content flex-grow-1'>
      <Container fluid className='report-tab'>
        <div className='dashboard-heading'>
          <div class='content-container'>
            <h2>Questionarie</h2>
            <p>Answer few questions and find the surprise</p>
          </div>
          <Activity />
        </div>
        <Header />
        {!isLogical ? (
          <Tabs defaultActiveKey='test-1' id='questionaire' className='mt-3'>
            <Tab eventKey='test-1' title='Test 1'>
              <Test1 data={test1} submit={submit} options={options} limit={5} />
            </Tab>
            <Tab eventKey='test-2' title='Test 2'>
              <Test2 data={test2} submit={submit} options={options} limit={4} />
            </Tab>
          </Tabs>
        ) : (
          <Tabs defaultActiveKey='test-3' id='questionaire' className='mt-3'>
            <Tab eventKey='test-3' title='Logical'>
              <Test3 submit={submitLogical} options={options} />
            </Tab>
          </Tabs>
        )}
      </Container>
    </div>
  )
}

export default Questions
