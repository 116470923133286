import { useEffect, useState } from 'react'
import Pagination from 'react-bootstrap/Pagination'
import './CustomPagination.css'

const CustomPagination = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage)
  const [paginationItems, setPaginationItems] = useState([])

  useEffect(() => {
    const calculatePagination = () => {
      const pages = []
      const maxPagesToShow = Math.floor(window.innerWidth / 100) // Adjust this value as needed for the container width

      let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2))
      let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1)

      if (startPage > 1) {
        pages.push(<Pagination.First key='first' onClick={() => onPageChange(1)} />)
        pages.push(<Pagination.Prev key='prev' onClick={() => onPageChange(currentPage - 1)} />)
      }

      if (startPage > 2) {
        pages.push(<Pagination.Ellipsis key='start-ellipsis' />)
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(
          <Pagination.Item key={i} active={i === currentPage} onClick={() => onPageChange(i)}>
            {i}
          </Pagination.Item>
        )
      }

      if (endPage < totalPages - 1) {
        pages.push(<Pagination.Ellipsis key='end-ellipsis' />)
      }

      if (endPage < totalPages) {
        pages.push(<Pagination.Next key='next' onClick={() => onPageChange(currentPage + 1)} />)
        pages.push(<Pagination.Last key='last' onClick={() => onPageChange(totalPages)} />)
      }

      setPaginationItems(pages)
    }

    calculatePagination()
  }, [currentPage, onPageChange, totalItems])

  return <Pagination className='custom-pagination justify-content-center m-0'>{paginationItems}</Pagination>
}

export default CustomPagination
