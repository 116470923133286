import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import * as API from '../Apisurl'
import Context from '../Context'
import Rating from '../profile/Rating'

const Insights = ({ filter }) => {
  const { selectValue } = useContext(Context)

  const [loading, setLoading] = useState(true)

  const [productivity, setProductivity] = useState()
  const [productivityG, setProductivityG] = useState()
  const [ranks, setRanks] = useState({
    prod_prec: [],
    attention: [],
    focus: [],
    stress: [],
  })
  const [ranksG, setRanksG] = useState({
    prod_prec: [],
    attention: [],
    focus: [],
    stress: [],
  })

  const getinfo = async () => {
    setLoading(true)
    try {
      const response = await fetch(`${API.Fetchurl}user_productivity`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('session')}`,
        },
        body: JSON.stringify({
          user_id: selectValue.user_id,
          isGroup: false,
          ...filter,
        }),
      })
      if (!response.ok) throw new Error('Something Went Wrong...')

      const data = await response.json()

      let p = [],
        a = [],
        f = [],
        s = []

      let pG = [],
        aG = [],
        fG = [],
        sG = []

      Object.entries(data.user_insight).forEach(([day, stats]) => {
        p.push([day, stats.prod_prec])
        a.push([day, stats.attention])
        f.push([day, stats.focus])
        s.push([day, stats.stress])
      })

      Object.entries(data.global_insight).forEach(([day, stats]) => {
        pG.push([day, stats.prod_prec])
        aG.push([day, stats.attention])
        fG.push([day, stats.focus])
        sG.push([day, stats.stress])
      })

      p.sort((a, b) => b[1] - a[1])
      a.sort((a, b) => b[1] - a[1])
      f.sort((a, b) => b[1] - a[1])
      s.sort((a, b) => a[1] - b[1])

      pG.sort((a, b) => b[1] - a[1])
      aG.sort((a, b) => b[1] - a[1])
      fG.sort((a, b) => b[1] - a[1])
      sG.sort((a, b) => a[1] - b[1])

      setRanks((old) => ({ ...old, prod_prec: p, attention: a, focus: f, stress: s }))
      setRanksG((old) => ({ ...old, prod_prec: pG, attention: aG, focus: fG, stress: sG }))

      setProductivity(data.user_insight)
      setProductivityG(data.global_insight)

      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getinfo()
  }, [selectValue.user_id, filter.category, filter.speciality, filter.gender, filter.minAge, filter.maxAge])

  return (
    !loading && (
      <div className='text-black'>
        <h4
          className='dashboard-cards text-center'
          style={{
            color: 'var(--green-color)',
            backgroundColor: 'var(--green-highlight-color)',
          }}
        >
          Day Wise Insight
        </h4>

        <Row>
          <h4 className='mt-4'>
            <span className='text-capitalize'>{selectValue.username}</span>'s Productivity Trend
          </h4>
          {ranks.prod_prec.length && (
            <p className='m-0 fs-5' style={{ textAlign: 'justify' }}>
              {ranks.prod_prec[1][1] === 0
                ? 'You were most productive in ' + ranks.prod_prec[0][0]
                : 'You were most productive in ' +
                  ranks.prod_prec[0][0] +
                  ', and least productive in ' +
                  ranks.prod_prec[3][0]}
              .
            </p>
          )}
          {productivity &&
            Object.entries(productivity).map(([day, info], i) => {
              return (
                <InfoCard key={i} day={day} star={info.prod_star} i={i}>
                  {Object.entries(info).map(([d, v], idx) => {
                    return d !== 'prod_star' && <InfoBar key={idx} ranks={ranks} d={d} v={v} />
                  })}
                </InfoCard>
              )
            })}
        </Row>

        <Row>
          <div className='page-break' />
          <h4 className='mt-4'>Global Productivity Trends</h4>
          {ranksG.prod_prec.length && ranksG.attention.length && ranksG.focus.length && ranksG.stress.length && (
            <p className='m-0 fs-5' style={{ textAlign: 'justify' }}>
              Most users were highly productive in {ranksG.prod_prec[0][0]}, with higher attention in{' '}
              {ranksG.attention[0][0]}, higher focus in {ranksG.focus[0][0]} and reported low stress in{' '}
              {ranksG.stress[0][0]}
            </p>
          )}
          {productivityG &&
            Object.entries(productivityG).map(([day, info], i) => {
              return (
                <InfoCard key={i} day={day} star={info.prod_star} i={i}>
                  {Object.entries(info).map(([d, v], idx) => {
                    return d !== 'prod_star' && <InfoBar key={idx} ranks={ranksG} d={d} v={v} />
                  })}
                </InfoCard>
              )
            })}
        </Row>
      </div>
    )
  )
}

export default Insights

export const InfoBar = ({ ranks, d, v }) => {
  const [bg, setBg] = useState('silver')

  useEffect(() => {
    const getBgColor = () => {
      if (v === 0) {
        return setBg('gray')
      }

      let data = ranks[d],
        idx = null

      for (let i = 0; i < data.length; i++) {
        if (data[i][1] === v) {
          idx = i
          break
        }
      }

      setBg(colors[idx])
    }

    getBgColor()
  }, [v])

  return (
    <div className={`d-flex justify-content-between align-items-center ${d === 'prod_prec' ? 'order-1' : 'order-2'}`}>
      <span className='text-capitalize'>{d === 'prod_prec' ? 'productivity' : d}</span>
      <div
        style={{
          marginInline: '10px',
          borderRadius: '2px',
          height: '2px',
          width: '100%',
          backgroundColor: 'silver',
        }}
      ></div>
      <span className={`rounded-pill px-3 py-1`} style={{ backgroundColor: bg, color: 'white' }}>
        {v === 0 ? 'NA' : v}
      </span>
    </div>
  )
}

export const InfoCard = ({ day, star, i, children }) => {
  return (
    <Col md={6} xxl={3} key={i} style={{ order: i === 0 ? 2 : i === 1 ? 3 : i === 2 ? 1 : 4 }}>
      <div className='dashboard-cards p-4'>
        <div className='d-flex justify-content-between align-items-center mb-4'>
          <h5 className='text-capitalize m-0'>{day}</h5>

          <Rating value={star} />
        </div>
        <div className='d-flex flex-column gap-2'>{children}</div>
      </div>
    </Col>
  )
}

export const colors = ['green', 'orange', 'coral', 'red']
