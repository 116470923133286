import react from 'react'
import { Modal, Row, Col, Spinner } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Formstyle from '../teacher/cssModule/Formstyle.module.css'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import userImg from '../assets/images/user5.svg'
import { useEffect, useRef, useState } from 'react'
import * as Apiurl from '../Apisurl'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import swal from 'sweetalert'
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import headersReq from '../HeadersJson'
import { differenceInYears, parse } from 'date-fns'

const FormModal = (props) => {
  let reqJson = headersReq()
  const role = localStorage.getItem('role')

  const [hrList, setHrList] = useState()
  const [companyList, setCompanyList] = useState()
  const [validated, setValidated] = useState(false)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [apiVia, setapivia] = useState(false)
  const [err, seterr] = useState(false)
  let formElement = useRef()

  let location = useLocation()
  let pageid = location.state != null ? location.state.pageid : ''

  useEffect(() => {
    let prefilldata = props.uservalues

    props.setInputValue({
      username: prefilldata.username,
      class: prefilldata.class,
      school_name: prefilldata.school_name,
      special_need: prefilldata.special_need,
      parent_name: prefilldata.parent_name,
      person_type: 'child',
      dob: prefilldata.dob,
      gender: prefilldata.gender,
    })
  }, [])

  useEffect(() => {
    const getHRList = async () => {
      try {
        const response = await fetch(`${Apiurl.Fetchurl}hr_list?company_name=${props.uservalues.school_name.trim()}&person_type=${role}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        const data = await response.json()
        setHrList(data.hr_data)
      } catch (error) {
        console.log(error)
      }
    }

    role === 'admin' && props.uservalues.school_name.trim().length === 0 && setHrList([])

    role === 'admin' && props.uservalues.school_name.trim().length > 0 && getHRList()
  }, [props.uservalues.school_name, role])

  useEffect(() => {
    const getCompanyList = async () => {
      try {
        const response = await fetch(`${Apiurl.Fetchurl}company_list?person_type=${role}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        const data = await response.json()
        setCompanyList(data.company_list)
      } catch (error) {
        console.log(error)
      }
    }

    role === 'admin' && getCompanyList()
  }, [role])

  const childValidate = (event) => {
    event.preventDefault()
    if (formElement.current.checkValidity() === false) {
      event.stopPropagation()
    } else {
      if (sessionStorage.getItem('session') === '123456') {
        let date = parse(props.uservalues.dob.split('-').join('/'), 'dd/MM/yyyy', new Date())
        let age = differenceInYears(new Date(), date)

        localStorage.setItem(
          'childData',
          JSON.stringify([
            {
              ...props.uservalues,
              age: age,
              user_uid: 1,
            },
          ])
        )

        props.populateChild()
        return props.close(false)
      }

      if (parseInt(props.editvia) == 1) {
        addchild1()
      } else {
        let max_students = sessionStorage.getItem('max_students')
        if (props.totalChildren >= max_students)
          return setError(`You have exceeded the maximum children you can add, Please contact admin for futher help`)
        addchild()
      }
    }
    setValidated(true)
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    props.setInputValue({
      ...props.uservalues,
      [name]: value,
    })
  }
  let uservaluesdata = { person_uid: window.sessionStorage.getItem('session') }
  let data = {
    ...props.uservalues,
  }
  let allvalues = JSON.stringify({
    ...uservaluesdata,
    ...data,
  })

  let allvalues1 = JSON.stringify({
    ...uservaluesdata,
    ...data,
    userid: props.userid,
  })
  let apiname = parseInt(pageid) == 1 || parseInt(pageid) == 3 ? 'add_child' : 'signup'

  const addchild = () => {
    async function signup() {
      const response = await fetch(`${Apiurl.Fetchurl + apiname}`, {
        method: 'POST',
        headers: reqJson,
        body: allvalues,
      })

      const Admin = await response.json()
      return Admin
    }
    setLoading(true)
    signup()
      .then((data) => {
        setLoading(false)
        props.close(false)

        seterr(data.Status)
        props.populateChild()
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
  }

  const addchild1 = () => {
    async function signup() {
      const response = await fetch(`${Apiurl.Fetchurl + 'edit_child'}`, {
        method: 'POST',
        headers: reqJson,
        body: allvalues1,
      })
      const Admin = await response.json()
      return Admin
    }
    setLoading(true)
    signup()
      .then((data) => {
        setLoading(false)
        swal({
          title: 'Good job!',
          text: 'User Edited Successfully',
          icon: 'success',
        }).then((value) => {
          props.setshow(false)
          props.populateChild()
        })
        seterr(data.Status)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
  }

  const getUploadParams = ({ meta, file }) => {
    console.log(meta)

    const body = new FormData()
    body.append('type', 'module')
    body.append('userid', sessionStorage.getItem('session'))
    body.append('file', file)

    return {
      body,
      url: `${Apiurl.Fetchurl + 'file_upload_1'}`,
      method: 'POST',
    }
  }
  return (
    <>
      <Modal show={props.sendModalVia} onHide={props.close} className='FormModal'>
        <Modal.Body>
          <Form className={Formstyle.formContainer} noValidate validated={validated} ref={formElement}>
            {error && <p className='text-danger'>{error}</p>}
            <label>Upload Image</label>
            <Dropzone
              getUploadParams={getUploadParams}
              // onChangeStatus={handleChangeStatus}
              // value={uservalues.userImage}
              name='userImage'
              maxFiles={1}
              // onChangeStatus={() => getValue("module", 1)}
              // onChange={handleChange}
              // value={uservalues.file}
              accept='image/*,audio/*,video/*'
            />
            <div className={Formstyle.profileContainer}>
              {/* <figure>
                <img src={userImg} alt="userimage" />
              </figure> */}
              <FloatingLabel controlId='floatingInput' label='Name' className={Formstyle.labelinput}>
                <Form.Control
                  required
                  type='text'
                  placeholder='age'
                  maxLength='50'
                  name='username'
                  onChange={handleChange}
                  value={props.uservalues.username}
                />
                <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
              </FloatingLabel>
            </div>
            {/* <FloatingLabel controlId='floatingInput' label='Age' className='mb-3'>
              <Form.Control
                required
                type='number'
                placeholder='Class'
                maxLength='30'
                onChange={handleChange}
                name='age'
                value={props.uservalues.age}
              />
              <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
            </FloatingLabel> */}
            <Row>
              <Col md={5}>
                <Form.Group className='mb-3'>
                  <Form.Label htmlFor='gender'>Gender</Form.Label>
                  <br />
                  <Form.Check
                    required
                    type='radio'
                    label='Male'
                    name='gender'
                    value='male'
                    checked={props.uservalues.gender === 'male'}
                    onChange={handleChange}
                    inline
                  />
                  <Form.Check
                    required
                    type='radio'
                    label='Female'
                    name='gender'
                    value='female'
                    checked={props.uservalues.gender === 'female'}
                    onChange={handleChange}
                    inline
                  />
                  <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={7}>
                <FloatingLabel controlId='dob' label='Date of Birth' className='mb-3'>
                  <Form.Control
                    required
                    type='date'
                    name='dob'
                    value={props.uservalues.dob
                      ?.split('-')
                      .reverse()
                      .join('-')}
                    onChange={(e) =>
                      props.setInputValue({
                        ...props.uservalues,
                        dob: e.target.value
                          ?.split('-')
                          .reverse()
                          .join('-'),
                      })
                    }
                  />
                  <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                {role === 'admin' ? (
                  <FloatingLabel controlId='Company' label='Company'>
                    <Form.Select required value={props.uservalues.school_name} onChange={handleChange} name='school_name'>
                      <option value=''>select company</option>
                      {companyList?.map((name, i) => (
                        <option key={i} value={name}>
                          {name}
                        </option>
                      ))}
                    </Form.Select>

                    <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
                  </FloatingLabel>
                ) : (
                  <FloatingLabel controlId='School' label='School'>
                    <Form.Control
                      required
                      type='text'
                      placeholder='School'
                      name='school_name'
                      onChange={handleChange}
                      maxLength='50'
                      value={props.uservalues.school_name}
                    />
                    <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
                  </FloatingLabel>
                )}
              </Col>
              <Col md={6}>
                {role === 'admin' ? (
                  <FloatingLabel controlId='HR' label='HR'>
                    <Form.Select required value={props.uservalues.class} onChange={handleChange} name='class'>
                      <option value=''>select hr</option>
                      {hrList?.map((name, i) => (
                        <option key={i} value={name}>
                          {name}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
                  </FloatingLabel>
                ) : (
                  <FloatingLabel controlId='Class' label='Class'>
                    <Form.Control
                      required
                      type='text'
                      placeholder='Class'
                      name='class'
                      onChange={handleChange}
                      maxLength='50'
                      value={props.uservalues.class}
                    />
                    <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
                  </FloatingLabel>
                )}
              </Col>
            </Row>
            {role !== 'admin' ? (
              <FloatingLabel controlId='Parent name' label='Parent name'>
                <Form.Control
                  required
                  type='text'
                  placeholder='parent name'
                  name='parent_name'
                  onChange={handleChange}
                  maxLength='50'
                  value={props.uservalues.parent_name}
                />
              </FloatingLabel>
            ) : null}

            {role === 'admin' ? (
              <FloatingLabel controlId='floatingSelect' label='Department'>
                <Form.Control required type='text' placeholder='' value={props.uservalues.special_need} onChange={handleChange} name='special_need' />
                <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
              </FloatingLabel>
            ) : (
              <FloatingLabel controlId='floatingSelect' label='Speciality'>
                <Form.Select required value={props.uservalues.special_need} onChange={handleChange} name='special_need'>
                  <option value=''>Select Special Need</option>
                  <option value='Autism'>Autism</option>
                  <option value='ADHD'>ADHD</option>
                  <option value='DownSyndrome'>Down Syndrome</option>
                  <option value='Intellectualdisability'>Intellectual disability</option>
                  <option value='other'>Other</option>
                </Form.Select>
                <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
              </FloatingLabel>
            )}

            <Button variant='primary' className='common-btn full-btn' type='button' disabled={loading} onClick={childValidate}>
              {!loading ? (
                'Submit'
              ) : (
                <div className='d-flex align-items-center justify-content-center gap-2'>
                  <Spinner animation='border' /> {props.editvia === 1 ? 'Updating' : 'Adding'}
                </div>
              )}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default FormModal
