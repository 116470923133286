/* eslint-disable no-undef */
import React, { useContext, useState } from 'react'
import { Container } from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import 'react-data-table-component-extensions/dist/index.css'
import * as Apiurl from '../Apisurl'
import Context from '../Context'
import headersReq from '../HeadersJson'
import Acitivity from '../dashboard/Activity'
import BrainAnim from './BrainAnim'
import Cummulative from './Cummulative'
import Header from './Header'
import NewReport from './NewReport'
import ReportTable from './ReportTable'

const Brain = (props) => {
  const selectData = useContext(Context)
  const role = localStorage.getItem('role')

  const [coordinates, setCoordinates] = useState()
  const [insights, setInsights] = useState()

  const [condition, setCondition] = useState('reading')
  const [headset, setHeadset] = useState(sessionStorage.getItem('headset_name'))

  const [counter, setCounter] = useState(9)
  const [mins, setMins] = useState(0)
  const [secs, setSecs] = useState(30)
  const [speed, setSpeed] = useState(2)

  const [date, setDate] = useState()
  const [filePath, setFilePath] = useState('')

  const populateBrainData = async (res) => {
    const reqJson = headersReq()
    let user_id = selectData.selectValue.user_id

    try {
      const response = await fetch(
        `${Apiurl.Fetchurl}brain_scan?thresshold=${counter / 10}&user_id=${user_id}&file_path=${res ||
          filePath}&minutes=${mins}&seconds=${secs}&condition=${condition}&headset_name=${headset}`,
        {
          method: 'GET',
          headers: reqJson,
        }
      )
      const brainData = await response.json()

      let obj = Object.entries(brainData)
      if (props.via == false) {
        props.setBrainData(obj)
      }
      setCoordinates(obj[0][1][0].eeg_position)
      setInsights(brainData.insights.insights)
    } catch (error) {
      console.log('populate brain data....', error)
    }
  }

  function runSocket() {
    let userjson = {
      result: 'begin',
      user_id: sessionStorage.getItem('session'),
      buffer: 5,
      ques_length: 10,
      type: 'brain_scan',
    }

    SocketConfig.emit('brain_scan', userjson)

    SocketConfig.on('brain_scan', (data) => {
      console.log('Datta ', data)
      let obj = Object.entries(data)

      setCoordinates(obj[0][1][0].eeg_position)
    })
  }

  const increment = (type) => {
    switch (type) {
      case 'counter':
        counter < 10 && setCounter((count) => count + 1)
        break
      case 'mins':
        mins < 60 && setMins((min) => parseInt(min) + 1)
        break
      case 'secs':
        secs < 60 && setSecs((sec) => parseInt(sec) + 1)
        break
      case 'speed':
        speed < 5 && setSpeed((s) => s + 1)
        break
      default:
        break
    }
  }

  const decrement = (type) => {
    switch (type) {
      case 'counter':
        counter > 1 && setCounter((count) => count - 1)
        break
      case 'mins':
        mins > 0 && setMins((min) => parseInt(min) - 1)
        break
      case 'secs':
        secs > 0 && setSecs((sec) => parseInt(sec) - 1)
        break
      case 'speed':
        speed > 1 && setSpeed((s) => s - 1)
        break
      default:
        break
    }
  }

  return props.via == false ? (
    <div className=' brain-container'>
      {coordinates ? (
        <BrainAnim headset={headset} speed={speed} coordinates={coordinates} insights={insights} setCoordinates={setCoordinates} via={props.via} />
      ) : (
        <NewReport
          increment={increment}
          decrement={decrement}
          condition={condition}
          counter={counter}
          mins={mins}
          secs={secs}
          speed={speed}
          headset={headset}
          setCondition={setCondition}
          setMins={setMins}
          setSecs={setSecs}
          setHeadset={setHeadset}
          setFilePath={setFilePath}
          populateBrainData={populateBrainData}
          via={props.via}
        />
      )}
    </div>
  ) : (
    <div className='content flex-grow-1'>
      <Container fluid>
        <div className='dashboard-heading'>
          <div class='content-container'>
            <h2>Brain Scan</h2>
            <p>Brain scans and reports based on student data.</p>
          </div>
          <Acitivity />
        </div>
        <Header via={1} viaBolean={false} filePath={filePath} date={date} condition={condition} coordinates={coordinates} />
        {coordinates ? (
          <BrainAnim headset={headset} speed={speed} insights={insights} coordinates={coordinates} setCoordinates={setCoordinates} />
        ) : (
          <div className='report-tab'>
            <Tabs defaultActiveKey='Reports' id='brain-scan'>
              {role === 'admin' && (
                <Tab eventKey='New' title='Brain Scan'>
                  <NewReport
                    increment={increment}
                    decrement={decrement}
                    condition={condition}
                    counter={counter}
                    mins={mins}
                    secs={secs}
                    speed={speed}
                    headset={headset}
                    setCondition={setCondition}
                    setSecs={setSecs}
                    setMins={setMins}
                    setHeadset={setHeadset}
                    setFilePath={setFilePath}
                    populateBrainData={populateBrainData}
                    via={props.via}
                  />
                </Tab>
              )}
              <Tab eventKey='Reports' title='Reports'>
                <ReportTable
                  coordinates={coordinates}
                  setCoordinates={setCoordinates}
                  setInsights={setInsights}
                  setCondition={setCondition}
                  setHeadset={setHeadset}
                  setDate={setDate}
                />
              </Tab>
            </Tabs>
          </div>
        )}
      </Container>
    </div>
  )
}

export default Brain
