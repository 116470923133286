import React, { useEffect, useState } from 'react'
import HeaderPdf from './pdfparts/header'
import { Col, Row } from 'react-bootstrap'

const Recommendations = ({ profileData }) => {
  const [activeTab, setActiveTab] = useState(0)

  const progressData = [
    {
      title: 'Reasoning',
      percentage: profileData.charts.Brain_Strength.thinking[0],
      optimal: profileData.charts.Brain_Strength.thinking[2],
    },
    {
      title: 'Memory',
      percentage: profileData.charts.Brain_Strength.memory[0],
      optimal: profileData.charts.Brain_Strength.memory[2],
    },
    {
      title: 'Association',
      percentage: profileData.charts.Brain_Strength.association[0],
      optimal: profileData.charts.Brain_Strength.association[2],
    },
    {
      title: 'Visualization',
      percentage: profileData.charts.Brain_Strength.vision[0],
      optimal: profileData.charts.Brain_Strength.vision[2],
    },
  ]

  const data = {
    association: [
      {
        name: 'Word Association',
        objective: 'Enhance vocabulary and cognitive association by linking related words.',
        materials: 'None',
        instructions: [
          'Pick a category (e.g., fruits, animals).',
          'Start with a word and take turns saying related words.',
          'Continue until no more related words can be found.',
        ],
        outcome: 'Improves verbal fluency and association skills.',
        time: '10-15',
        zone: 'green',
      },
      {
        name: 'Mind Mapping',
        objective: 'Enhance idea generation and organization by creating visual maps.',
        materials: 'Paper, Pens',
        instructions: [
          'Write the central theme in the middle of a paper.',
          'Draw branches from the central theme with related ideas.',
          'Expand on each idea with further associations.',
        ],
        outcome: 'Develops organizational and conceptual thinking skills.',
        time: '15-20',
        zone: 'yellow',
      },
      {
        name: 'Concept Connections',
        objective: 'Develop complex thinking by linking diverse concepts.',
        materials: 'None',
        instructions: [
          'Choose two unrelated concepts.',
          'Discuss how they can be linked.',
          'Create a story or explanation connecting the concepts.',
        ],
        outcome: 'Encourages creative thinking and complex problem-solving.',
        time: '20-25',
        zone: 'red',
      },
    ],
    visualization: [
      {
        name: 'Picture Puzzle',
        objective: 'Enhance spatial awareness by assembling puzzle pieces.',
        materials: 'Picture puzzle',
        instructions: ['Choose a picture puzzle.', 'Mix up the pieces.', 'Arrange the pieces to complete the picture.'],
        outcome: 'Improves visual and spatial reasoning.',
        time: '15-20',
        zone: 'red',
      },
      {
        name: 'Visual Memory Game',
        objective: 'Improve visual memory by recalling object positions.',
        materials: 'Various small objects',
        instructions: [
          'Arrange objects on a table.',
          'Allow the participant to observe the objects for a few seconds.',
          'Remove the objects and ask the participant to recall their positions.',
        ],
        outcome: 'Enhances memory retention and recall.',
        time: '10-15',
        zone: 'yellow',
      },
      {
        name: 'Shape Sorting',
        objective: 'Develop shape recognition and sorting skills.',
        materials: 'Various shapes (e.g., blocks, cutouts)',
        instructions: [
          'Select a variety of shapes.',
          'Sort the shapes based on their visual characteristics.',
          'Discuss the reasoning behind the sorting.',
        ],
        outcome: 'Builds foundational skills in geometry and categorization.',
        time: '10-15',
        zone: 'green',
      },
    ],
    reasoning: [
      {
        name: 'Logic Puzzles',
        objective: 'Enhance logical reasoning and problem-solving abilities.',
        materials: 'Logic puzzles (e.g., Sudoku, riddles)',
        instructions: [
          'Choose a logic puzzle.',
          'Analyze the clues and deduce the solution.',
          'Verify the solution to ensure accuracy.',
        ],
        outcome: 'Develops critical thinking and deductive reasoning.',
        time: '20-30',
        zone: 'green',
      },
      {
        name: 'Pattern Recognition',
        objective: 'Improve reasoning by identifying and predicting patterns.',
        materials: 'Pattern cards',
        instructions: [
          'Observe a sequence of patterns.',
          'Identify the rule governing the pattern.',
          'Predict the next element in the sequence.',
        ],
        outcome: 'Enhances pattern recognition and logical reasoning.',
        time: '15-20',
        zone: 'red',
      },
      {
        name: 'Pattern Detective',
        objective: 'Enhance reasoning by identifying and completing patterns.',
        materials: 'Pattern cards (shapes, colors, numbers), Blank cards or paper',
        instructions: [
          'Children identify the rule of the pattern on the cards and fill in the missing part.',
          'For older children, patterns can be more complex, involving numbers or mixed shapes.',
        ],
        outcome: 'Develops logical thinking and the ability to recognize and predict patterns.',
        time: '15-20',
        zone: 'yellow',
      },
      {
        name: 'Deduction Game',
        objective: 'Improve reasoning and deduction skills.',
        materials: 'Clue cards',
        instructions: [
          'Present a scenario with clues.',
          'Discuss possible solutions based on the clues.',
          'Deduce the correct answer.',
        ],
        outcome: 'Builds deductive reasoning and critical thinking.',
        time: '20-25',
        zone: 'yellow',
      },
    ],
    memory: [
      {
        name: 'Memory Match',
        objective: 'Improve memory retention through matching exercises.',
        materials: 'Cards with matching pairs',
        instructions: [
          'Lay out all cards face down.',
          'Flip two cards at a time to find matching pairs.',
          'Continue until all pairs are matched.',
        ],
        outcome: 'Enhances short-term memory and recall.',
        time: '10-15',
        zone: 'green',
      },
      {
        name: 'Story Recall',
        objective: 'Develop memory and comprehension by recalling story details.',
        materials: 'Short story',
        instructions: [
          'Read a short story to the participants.',
          'Ask them to recall as many details as possible.',
          'Discuss the recalled details and their accuracy.',
        ],
        outcome: 'Improves memory retention and comprehension.',
        time: '10-15',
        zone: 'yellow',
      },
      {
        name: 'Number Recall',
        objective: 'Enhance memory by recalling sequences of numbers.',
        materials: 'List of numbers',
        instructions: [
          'Memorize a sequence of numbers.',
          'Recite the sequence from memory.',
          'Increase the length of the sequence as skill improves.',
        ],
        outcome: 'Strengthens memory and concentration.',
        time: '10-15',
        zone: 'red',
      },
    ],
  }

  const getZone = (val, optimal) => {
    if (val >= optimal) return 'green'
    else if (val >= 40) return 'yellow'
    else return 'red'
  }

  const recommendations = progressData
    .sort((a, b) => b.percentage - a.percentage)
    .slice(0, 2)
    .reduce((acc, item) => {
      const zone = getZone(item.percentage, item.optimal)
      const activity = data[item.title.toLowerCase()].filter((d) => d.zone === zone)
      acc[item.title.toLowerCase()] = activity

      return acc
    }, {})

  console.log(recommendations)

  return (
    <div className='main-container page9-container'>
      <HeaderPdf />
      <div className='pdf-content-container'>
        <div className='heading-seprator'>
          <h1>
            <span>ACTIVITIES RECOMENDATION</span>
          </h1>
          <div className='report-content'>
            <p>
              Based on your report, we’ve picked some activities that can help you think faster, remember better, and
              stay focused. These activities are designed to improve specific areas of your brain therefore improving
              your overall cognitive ability.
            </p>

            <div className='listing-container'>
              <div className='d-flex align-items-center gap-2 border-bottom d-print-none'>
                {Object.keys(recommendations).map((category, i) => (
                  <button
                    key={i}
                    onClick={() => setActiveTab(i)}
                    className='px-4 py-1 text-capitalize'
                    style={{
                      border: '0px',
                      borderTopRightRadius: '12px',
                      borderTopLeftRadius: '12px',
                      color: activeTab === i ? 'white' : 'black',
                      background: activeTab === i ? 'var(--button-color)' : '#e6e6e6',
                    }}
                  >
                    {category}
                  </button>
                ))}
              </div>
              <Row className='d-print-none'>
                {Object.values(recommendations)[activeTab].map((activity, i) => (
                  <Col className='p-1' xs={12}>
                    <div key={i} className='shadow-sm h-100 overflow-hidden' style={{ borderRadius: '12px' }}>
                      <div className='d-flex align-items-center justify-content-between border-bottom fw-bold  px-2 py-1 mb-0'>
                        <span>
                          {activity.zone === 'green' ? '🟢' : activity.zone === 'yellow' ? '🟡' : '🔴'} {activity.name}
                        </span>
                        <span style={{ fontSize: '12px' }}>⏱️{activity.time}m</span>
                      </div>
                      <ul className='p-3'>
                        <li className='mb-3'>
                          🎯
                          <div className='list-item ms-2'>
                            <h3 className='mb-0'>Objective</h3> <p>{activity.objective}</p>
                          </div>
                        </li>
                        <li className='mb-3'>
                          📦
                          <div className='list-item ms-2'>
                            <h3 className='mb-0'>Materials</h3> <p>{activity.materials}</p>
                          </div>
                        </li>
                        <li className='mb-3'>
                          📝
                          <div className='list-item ms-2'>
                            <h3 className='mb-0'>Instructions</h3>

                            {activity.instructions.map((step, index) => (
                              <p key={index}>
                                {index + 1}.{step}
                              </p>
                            ))}
                          </div>
                        </li>
                        <li className='mb-3'>
                          🎉
                          <div className='list-item ms-2'>
                            <h3 className='mb-0'>Outcome</h3> <p>{activity.outcome}</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Col>
                ))}
              </Row>
              <Row className='d-none d-print'>
                <div className='d-flex align-items-center gap-2 border-bottom'>
                  {Object.keys(recommendations).map((category, i) => (
                    <button
                      key={i}
                      className='px-4 py-1 text-capitalize'
                      style={{
                        border: '0px',
                        borderTopRightRadius: '12px',
                        borderTopLeftRadius: '12px',
                        color: 0 === i ? 'white' : 'black',
                        background: 0 === i ? 'var(--button-color)' : '#e6e6e6',
                      }}
                    >
                      {category}
                    </button>
                  ))}
                </div>
                {Object.values(recommendations)[0].map((activity, i) => (
                  <Col className='p-1' xs={12}>
                    <div key={i} className='shadow-sm h-100 overflow-hidden' style={{ borderRadius: '12px' }}>
                      <div className='d-flex align-items-center justify-content-between border-bottom fw-bold  px-2 py-1 mb-0'>
                        <span>
                          {activity.zone === 'green' ? '🟢' : activity.zone === 'yellow' ? '🟡' : '🔴'} {activity.name}
                        </span>
                        <span style={{ fontSize: '12px' }}>⏱️{activity.time}m</span>
                      </div>
                      <ul className='p-3'>
                        <li className='mb-3'>
                          🎯
                          <div className='list-item ms-2'>
                            <h3 className='mb-0'>Objective</h3> <p>{activity.objective}</p>
                          </div>
                        </li>
                        <li className='mb-3'>
                          📦
                          <div className='list-item ms-2'>
                            <h3 className='mb-0'>Materials</h3> <p>{activity.materials}</p>
                          </div>
                        </li>
                        <li className='mb-3'>
                          📝
                          <div className='list-item ms-2'>
                            <h3 className='mb-0'>Instructions</h3>

                            {activity.instructions.map((step, index) => (
                              <p key={index}>
                                {index + 1}.{step}
                              </p>
                            ))}
                          </div>
                        </li>
                        <li className='mb-3'>
                          🎉
                          <div className='list-item ms-2'>
                            <h3 className='mb-0'>Outcome</h3> <p>{activity.outcome}</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Col>
                ))}
              </Row>
              <Row className='d-none d-print page-break'>
                <div className='d-flex align-items-center gap-2 border-bottom'>
                  {Object.keys(recommendations).map((category, i) => (
                    <button
                      key={i}
                      className='px-4 py-1 text-capitalize'
                      style={{
                        border: '0px',
                        borderTopRightRadius: '12px',
                        borderTopLeftRadius: '12px',
                        color: 1 === i ? 'white' : 'black',
                        background: 1 === i ? 'var(--button-color)' : '#e6e6e6',
                      }}
                    >
                      {category}
                    </button>
                  ))}
                </div>
                {Object.values(recommendations)[1].map((activity, i) => (
                  <Col className='p-1' xs={12}>
                    <div key={i} className='shadow-sm h-100 overflow-hidden' style={{ borderRadius: '12px' }}>
                      <div className='d-flex align-items-center justify-content-between border-bottom fw-bold  px-2 py-1 mb-0'>
                        <span>
                          {activity.zone === 'green' ? '🟢' : activity.zone === 'yellow' ? '🟡' : '🔴'} {activity.name}
                        </span>
                        <span style={{ fontSize: '12px' }}>⏱️{activity.time}m</span>
                      </div>
                      <ul className='p-3'>
                        <li className='mb-3'>
                          🎯
                          <div className='list-item ms-2'>
                            <h3 className='mb-0'>Objective</h3> <p>{activity.objective}</p>
                          </div>
                        </li>
                        <li className='mb-3'>
                          📦
                          <div className='list-item ms-2'>
                            <h3 className='mb-0'>Materials</h3> <p>{activity.materials}</p>
                          </div>
                        </li>
                        <li className='mb-3'>
                          📝
                          <div className='list-item ms-2'>
                            <h3 className='mb-0'>Instructions</h3>

                            {activity.instructions.map((step, index) => (
                              <p key={index}>
                                {index + 1}.{step}
                              </p>
                            ))}
                          </div>
                        </li>
                        <li className='mb-3'>
                          🎉
                          <div className='list-item ms-2'>
                            <h3 className='mb-0'>Outcome</h3> <p>{activity.outcome}</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </div>
        {/* <footer>
          <h6>09</h6>
        </footer> */}
      </div>
    </div>
  )
}

export default Recommendations
