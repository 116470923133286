import react from 'react'
import { useState, useEffect } from 'react'

const Selectedlang = () => {
  const googleTranslateElementInit = () => {
    console.log('in')
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        autoDisplay: false,
      },
      'google_translate_element'
    )
  }
  useEffect(() => {
    setTimeout(() => {
      var addScript = document.createElement('script')
      addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit')
      addScript.async = true
      document.body.appendChild(addScript)
      window.googleTranslateElementInit = googleTranslateElementInit
      var selEl = document.querySelector('.goog-te-combo')
      // alert("in")
      console.log(selEl)
    }, 1000)
  }, [])
  return <div id='google_translate_element'></div>
}

export default Selectedlang
