import { MDBIcon } from 'mdb-react-ui-kit'
import { Col } from 'react-bootstrap'
import Card from 'react-bootstrap/Card'
import childrencss from './cssModule/children.module.css'
import user4 from '../assets/images/user4.png'

const ChildInfoCard = (props) => {
  let activeclass = props.index === props.isActive ? 'childActive' : ''
  return (
    <Col md={4}>
      <Card className={childrencss.cardContainer + ' ' + activeclass} onClick={() => props.setActive(props.index)}>
        <div className={'d-flex align-items-center'}>
          <figure className='edit-box' key={props.index}>
            <Card.Img
              variant='top'
              src={props.childdata.image === 'image' ? user4 : props.childdata.image}
              className='rounded-circle border'
            />
            <figcaption>
              <MDBIcon fas icon='pen' />
            </figcaption>
          </figure>
          <Card.Body className='p-0 mx-2'>
            <div className='d-flex align-items-baseline justify-content-between'>
              <h4>{props.childdata.username}</h4>
              <div>
                <MDBIcon
                  fas
                  icon='trash'
                  className='me-4'
                  style={{ fontSize: '12px', color: '#c36b6b' }}
                  onClick={() => props.deleteChild(props.childdata.user_uid)}
                />
                <MDBIcon
                  fas
                  icon='pen'
                  style={{ fontSize: '12px', color: '#6BC3BA' }}
                  onClick={() => props.editChild(1)}
                />
              </div>
            </div>
            <p>{props.childdata.school_name}</p>
            <span>
              {props.childdata.class} | {props.childdata.age} years old
            </span>
          </Card.Body>
        </div>
      </Card>
    </Col>
  )
}
export default ChildInfoCard
