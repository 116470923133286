import { useContext, useRef, useState } from 'react'
import Table from 'react-bootstrap/Table'
import Context from '../../../Context'
import UserJson from '../../../Userjson'

const Colortable = () => {
  const props1 = useContext(Context)
  let tableref = useRef()

  let category = UserJson().category

  let renderFirstRow = (o) => {
    let renderFirstRow

    const renderOperator = () => {
      let operator = ''

      if (category.includes('addition')) {
        operator = '+'
      } else if (category.includes('multiply')) {
        operator = 'x'
      } else if (category.includes('subtraction')) {
        operator = '-'
      } else if (category.includes('division')) {
        operator = '/'
      } else {
        switch (category) {
          case 'multiple':
            operator = 'x'
            break
          case 'greater_lesser':
            operator = levelText(UserJson().level)
            break
          default:
            operator = '.'
            break
        }
      }
      return operator
    }

    const levelText = (res) => {
      console.log(res)
      let populateLeveltxt = ''
      switch (res) {
        case 'level1':
          populateLeveltxt = '>'
          break
        case 'level2':
          populateLeveltxt = '<'
          break
        case 'level3':
          populateLeveltxt = '='
          break
      }
      return populateLeveltxt
    }

    switch (category) {
      case 'subtraction_objects':
        renderFirstRow = (
          <>
            <div className='objectscal'>
              <figure>
                <img src={o.image_1} alt='' />
                <figcaption>{o.occ_img1}</figcaption>
              </figure>
              <span style={{ color: '#7ac9c0', fontSize: '45px' }}> - </span>
              <figure>
                <img src={o.image_1} alt='' />
                <figcaption>{o.occ_img2}</figcaption>
              </figure>
            </div>
          </>
        )
        break
      case 'addition_objects':
        renderFirstRow = (
          <>
            <div className='objectscal'>
              <figure>
                <img src={o.image_1} alt='' />
                <figcaption>{o.occ_img1}</figcaption>
              </figure>
              <span style={{ color: '#7ac9c0', fontSize: '45px' }}>+</span>
              <figure>
                <img src={o.image_1} alt='' />
                <figcaption>{o.occ_img2}</figcaption>
              </figure>
            </div>
          </>
        )
        break
      case 'meaningful_counting':
        renderFirstRow = (
          <>
            {console.log(o)}
            <h4 style={{ fontWeight: '600', fontSize: '15px' }}>{o.ques}</h4>
          </>
        )
        break
      default:
        renderFirstRow = (
          <>
            <h4 style={{ fontWeight: '600', fontSize: '15px' }}>{o.no1}</h4>
            <h4 style={{ fontWeight: '600', fontSize: '15px' }}>
              {renderOperator()}
              {o.no2}
            </h4>
          </>
        )
    }
    return renderFirstRow
  }

  let socketVia = parseInt(sessionStorage.getItem('headsetVia'))
  if (socketVia == 1) {
  }
  console.log(props1[2])
  let overview = props1[2].Overview
  console.log(overview)
  if (overview != undefined && overview.length != 0) {
    return (
      <>
        <Table className='result-table' ref={tableref}>
          <thead>
            <tr>
              <th></th>
              {overview.ques.map((o, i) => {
                // console.log(overview.ques);
                return <th>Q{i + 1}</th>
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Asked</td>

              {overview.ques.map((o, i) => {
                return <td>{renderFirstRow(o)}</td>
              })}
            </tr>
            <tr>
              <td>Your Answer</td>
              {overview.ans.map((o, i) => {
                console.log(overview)
                console.log(o.via)
                return (
                  <td>
                    <div className='asked-color-box'>
                      <p style={o.via == 'correct' ? { color: '#77C63A' } : { color: 'red' }}>
                        {UserJson().category == 'meaningful_counting' ? o.answer.join(',') : o.answer}
                      </p>
                    </div>
                  </td>
                )
              })}
            </tr>
            <tr>
              <td>Correct Answer</td>
              {overview.ques.map((o, i) => {
                console.log(o)
                return (
                  <td>
                    <div className='asked-color-box'>
                      <p>
                        {category == 'meaningful_counting'
                          ? o.ans1 + ',' + o.ans2
                          : category == 'greater_lesser'
                          ? o.ans1
                          : o.ans}
                      </p>
                    </div>
                  </td>
                )
              })}
            </tr>
          </tbody>
        </Table>
      </>
    )
  }
}

export default Colortable
