import { useContext, useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import * as Apiurl from '../Apisurl'
import Context from '../Context'
import logo from '../assets/images/nema.svg'
import user3 from '../assets/images/user4.png'
import MainData from '../school/Maindata'
import headersReq from '../HeadersJson'
import { useLocation } from 'react-router-dom'

const Header = ({ via }) => {
  const reqJson = headersReq()
  const location = useLocation()
  const { selectValue, setSelectedValue1, setmoduleCardjson } = useContext(Context)
  const [datai, setDatai] = useState()
  const [logged, setlogged] = useState(1)

  let isGroup = localStorage.getItem('isGroup')
  useEffect(() => {
    async function getGroup() {
      const response = await fetch(
        Apiurl.Fetchurl + `make_group?teacher_user_id=${window.sessionStorage.getItem('session')}`,
        {
          method: 'GET',
          headers: reqJson,
        }
      )
      const groupdata = await response.json()
      return groupdata
    }

    async function populateChild() {
      const response = await fetch(`${Apiurl.Fetchurl + 'child'}`, {
        method: 'POST',
        body: JSON.stringify({ person_uid: window.sessionStorage.getItem('session') }),
        headers: reqJson,
      })
      const childdata = await response.json()
      return childdata
    }

    if (isGroup === 'true') {
      getGroup()
        .then((data) => {
          setDatai(data.group_distribution)
          let childindex = localStorage.getItem('ischildActive') == null ? 0 : localStorage.getItem('ischildActive')
          let sendModulleData = {
            user_id: data.group_distribution[childindex].group_user_id,
            username: data.group_distribution[childindex].group_name,
            school: data.group_distribution[childindex].school_name,
            class: data.group_distribution[childindex].student_class,
            speciality: data.group_distribution[childindex].speciality,
            dob: data.group_distribution[childindex].dob,
            age: 'NA',
          }

          setSelectedValue1(sendModulleData)
          localStorage.setItem('sendModulleData', JSON.stringify(sendModulleData))
        })
        .catch((error) => {
          console.log('error from grouplist', error)
        })
    } else {
      populateChild()
        .then((data) => {
          setDatai(data.Status.children.registred_child)
          let childindex = localStorage.getItem('ischildActive') == null ? 0 : localStorage.getItem('ischildActive')
          let sendModulleData = {
            user_id: data.Status.children.registred_child[childindex].user_uid,
            username: data.Status.children.registred_child[childindex].username,
            image: data.Status.children.registred_child[childindex].image,
            school: data.Status.children.registred_child[childindex].school_name,
            dob: data.Status.children.registred_child[childindex].dob,
            gender: data.Status.children.registred_child[childindex].gender,
            age: data.Status.children.registred_child[childindex].age,
            class: data.Status.children.registred_child[childindex].class,
            speciality: data.Status.children.registred_child[childindex].special_need,
          }

          setSelectedValue1(sendModulleData)
          localStorage.setItem('sendModulleData', JSON.stringify(sendModulleData))
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [])

  useEffect(() => {
    let api = localStorage.getItem('api_name')
    async function phaseselect() {
      if (!selectValue?.user_id) return

      const response = await fetch(`${Apiurl.Fetchurl + (api || 'main_module_ass')}`, {
        method: 'POST',
        headers: reqJson,
        body: JSON.stringify({
          module: 1,
          user_id: selectValue.user_id,
          language: localStorage.getItem('getlang') || 'en',
        }),
      })
      const data = await response.json()
      localStorage.setItem('modulejson', JSON.stringify(data))
      setmoduleCardjson(data)
    }

    phaseselect()
  }, [selectValue.user_id])

  const onSelect = (...res) => {
    let json = {
      username: res[0].username,
      user_id: res[0].id,
      school: res[0].school,
      age: res[0].age,
      class: res[0].class,
      speciality: res[0].speciality,
      image: res[0].image,
      modulename1: 'Assessment',
    }

    localStorage.setItem('ischildActive', res[0].index)

    setSelectedValue1(json)
    localStorage.setItem('sendModulleData', JSON.stringify(json))
  }

  let colourStyles = {
    control: (base, state) => ({
      ...base,
      boxShadow: 'none',
      border: 'none',
      background: 'transparent',
    }),
    menu: (base, state) => ({
      ...base,
      zIndex: 9999,
    }),
  }

  let options1
  if (datai != undefined) {
    options1 = datai.map((o, i) => {
      if (isGroup === 'true') {
        return {
          value: o.group_user_id,
          label: o.group_name,
          username: o.group_name,
          module: 'child',
          id: o.group_user_id,
          index: i,
          school: o.school_name,
          age: o.age,
          class: o.student_class,
          parent: o.parent_name,
          speciality: o.speciality,
        }
      } else {
        return {
          value: o.user_uid,
          label: o.username,
          username: o.username,
          module: 'child',
          id: o.user_uid,
          index: i,
          school: o.school_name,
          age: o.age,
          class: o.class,
          parent: o.parent_name,
          speciality: o.special_need,
          image: o.image,
        }
      }
    })
  }

  return (
    <>
      <div className='studentprofile std-dropdownContainer'>
        <div>
          {datai && (
            <div className='dropdown-container mt-2'>
              <figure className='dropdown-image'>
                <img
                  src={selectValue.image === 'image' ? user3 : selectValue.image}
                  alt='user4'
                  className='rounded-circle'
                />

                <div style={{ minWidth: '150px' }}>
                  <Select
                    options={options1}
                    defaultValue={options1[localStorage.getItem('ischildActive') || 0]}
                    styles={colourStyles}
                    onChange={onSelect}
                  />
                </div>
              </figure>
            </div>
          )}
        </div>
        <div className='weekly-container'>
          <div className='hours-container' style={via != false ? { display: 'block' } : { display: 'none' }}>
            <h6>
              Hours Logged - <span>{logged} /20</span>
            </h6>
          </div>
        </div>
      </div>
    </>
  )
}
export default Header
