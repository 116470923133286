import React, { useRef } from 'react'
import { Col, Row } from 'react-bootstrap'
import { memo, useState } from 'react'

import Draggable, { DraggableCore } from 'react-draggable' // Both at the same time
import Focus from '../../../includes/Focus'
import { useDrag, DndProvider, DragPreviewImage, useDrop } from 'react-dnd'

import { DragDropContainer, DropTarget } from 'react-drag-drop-container'
import { useSpeechSynthesis } from 'react-speech-kit'

import { useNavigate, useLocation } from 'react-router-dom'
import StartModal from '../../SelectgameStart'

import refreshbtn from '../../../assets/images/refresh.svg'
import * as Currdate from '../../../CurrDate'
import * as Apiurl from '../../../Apisurl'
// import { ItemTypes } from "./Constants";
import { SocketConfig } from '../../../SocketConfig'

// CommonJS

const Wordgame4 = (props) => {
  const { speak } = useSpeechSynthesis()
  const [InputValue, setInputValue] = useState()
  const [draggedId, setDraggedId] = useState('')
  const [listItem, setList] = useState('')
  const [droppedVal, setdroppedVal] = useState([])
  const [submitVia, setSubmitvia] = useState(false)
  const [callday, setcallday] = useState(1)
  let datalength = props.datalength

  const location = useLocation()
  let navigate = useNavigate()
  let date = Currdate.GetCurrentDate()
  props.time(date)

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'colors',
    drop: (item) => {
      // alert("in");
      console.log(item)
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }))
  let numerArr = []
  if (props.datalength != undefined) {
    let splitData =
      props.datalength != undefined ? (props.englishData != undefined ? props.englishData.ques.split(',') : '') : ''
    console.log(splitData)
    let inputids = []
    const dropAnswer = () => {
      let checkInputEmptyVal = filterId().map((o, i) => {
        let checkvalue = ''
        if (o != null) {
          checkvalue = document.getElementById(o).value
          if (checkvalue != '') {
            setSubmitvia(true)
          }
        }
      })
    }

    let filterId = () => {
      let getIds = splitData.map((o, i) => {
        let id = o == '_' ? i : null
        return id
      })
      console.log(getIds)
      return getIds
    }
    function refresh() {
      setdroppedVal([])
      setSubmitvia(false)
      filterId().map((o, i) => {
        if (o != null) {
          document.getElementById(o).value = ''
          document.getElementById(o).style = 'background-color:transparent'
        }
      })

      document.querySelectorAll('.score-item1 .ddcontainersource').forEach((obj, index) => {
        obj.style = 'visibility:visible'
      })
    }
    function subMitScore() {
      refresh()
      let arrSequence = []
      document.querySelectorAll('.scoreIndex').forEach((obj, index) => {
        arrSequence.push(obj.getAttribute('data-number'))
      })

      if (arrSequence.join('') == props.englishData.ans) {
        props.setPage((prev) => {
          props.setTheArray((oldArray) => {
            const update = [
              ...oldArray,
              {
                via: 'correct',
                answer: arrSequence,
              },
            ]
            localStorage.setItem('useranswer', JSON.stringify(update))
            return update
          })
          if (prev === props.datalength.length - 1) {
            props.setTheArray([])
            SocketConfig.emit('common', { result: 'disconnect' })
            props.submitResult()
            // setTimeout(() => {
            //   navigate('enresult', { state: { ...location.state, date: date } })
            // }, 1000)
          } else {
            props.setScore((prev) => prev + 1)
            return prev + 1
          }
        })
      } else {
        props.setPage((prev) => {
          props.setTheArray((oldArray) => {
            const update = [
              ...oldArray,
              {
                via: 'incorrect',
                answer: arrSequence,
              },
            ]
            localStorage.setItem('useranswer', JSON.stringify(update))

            return update
          })
          if (prev === props.datalength.length - 1) {
            props.setTheArray([])
            SocketConfig.emit('common', { result: 'disconnect' })
            props.submitResult()
            // setTimeout(() => {
            //   navigate('enresult', { state: { ...location.state, date: date } })
            // }, 1000)
          } else {
            return prev + 1
          }
        })
      }
    }

    if (props.englishData != undefined) {
      let data22 = listItem.length != 0 ? listItem : props.englishData.mcq
      return (
        <>
          <Row className='color-main-container meaning-counting ml-auto mr-auto'>
            <p>Your Problem</p>
            <button onClick={refresh} className='refresh-btn'>
              <img src={refreshbtn} alt='refresh' />
              Refresh
            </button>
            <img src={props.englishData.image} className='englishDataName' />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {splitData.map((o, i) => {
                {
                  return (
                    <>
                      {o == '_' ? (
                        <>
                          <div className='score-item'>
                            <DropTarget
                              targetKey='foo'
                              dropData={o}
                              onHit={function(e) {
                                // console.log(e.path[0].getAttribute("id"));
                                setDraggedId(e.target.getAttribute('id'))

                                let inputVal = e.target.setAttribute('data-number', e.sourceElem.textContent)
                                console.log(e.target + 'waawe')
                                e.target.value = e.sourceElem.textContent
                                setdroppedVal([...droppedVal, inputVal])
                                e.target.value != ''
                                  ? (e.target.style = 'background-color:#96d4ce;color:#fff')
                                  : (e.target.style = 'background-color:transparent')
                                // console.log(droppedVal);

                                console.log(inputids)
                                e.sourceElem.style = 'visibility:hidden'

                                // alert("You put the orange in the box!");
                              }}
                            >
                              <input
                                type='text'
                                id={i}
                                ref={drop}
                                data-number={o}
                                className='scoreIndex'
                                readOnly={true}
                              />
                            </DropTarget>
                          </div>
                        </>
                      ) : (
                        <h3 data-number={o} className='scoreIndex'>
                          {o}
                        </h3>
                      )}
                    </>
                  )
                }
              })}
            </div>
            <div className='mt-5 option-container'>
              <h6>Drag your Answer to input box:-</h6>
              <Row>
                {data22.map((o, i) => {
                  return (
                    <Col md={4} className='d-flex score-item score-item1 mt-2'>
                      <DragDropContainer
                        targetKey='foo'
                        dropData={o}
                        disappearDraggedElement={true}
                        onDragEnd={dropAnswer}
                      >
                        <h3>{o}</h3>
                      </DragDropContainer>
                    </Col>
                  )
                })}
              </Row>
              <button
                className='common-btn'
                style={submitVia == true ? { display: 'block' } : { display: 'none' }}
                onClick={subMitScore}
              >
                Submit
              </button>
            </div>
          </Row>
          <StartModal
            calldelay={setcallday}
            socket={SocketConfig}
            type={['focus']}
            date={date}
            letquesLength={datalength.length}
            calldaynum={callday}
          />
        </>
      )
    }
  }
}

export default Wordgame4
