import react from 'react'
import FormHeader from '../includes/FormHeader'
import { Container, Row, Col } from 'react-bootstrap'
import LoginContent from '../login/LoginContent'
import Roleitem from './RoleItem'
import RoleItemCorporate from './RoleItemCorporate'
import { useLocation } from 'react-router-dom'
import RoleItemInstitution from './RoleItemInstitution'

const Role = () => {
  const location = useLocation()
  let type = location.pathname.split('/').at(-1)

  let roles = {
    school: ['teacher', 'parent'],
    corporate: ['employee', 'hr'],
    college: ['student', 'college'],
  }

  return (
    <>
      <FormHeader />
      <main>
        <section class='login-form-section'>
          <Container>
            <Row>
              <Col md={6} className='my-auto heading-container'>
                <LoginContent />
              </Col>
              <Col md={6} className='o-1'>
                {type === 'school' ? (
                  <Roleitem />
                ) : type === 'corporate' ? (
                  <RoleItemCorporate />
                ) : type === 'institution' ? (
                  <RoleItemInstitution />
                ) : null}
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  )
}

export default Role
