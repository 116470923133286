import react from 'react'
import { useState, useEffect } from 'react'
import FormHeader from '../includes/FormHeader'
import { Col, Row, Container } from 'react-bootstrap'
import Button from 'react-bootstrap/button'
import RoleformHeader from '../teacher/RoleFormHeading.jsx'
import childrenCss from './cssModule/children.module.css'

import { MDBIcon } from 'mdb-react-ui-kit'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import Children from './Children'
import * as Apisurl from '../Apisurl'
import swal from 'sweetalert'
import Groupcard from './Groupcard'
import FormModalGroup from './FormModalGroup'
import headersReq from '../HeadersJson'

const SelectGroup = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const reqJson = headersReq()

  const [open, setOpen] = useState(false)
  const [children, setChildren] = useState([])
  const [groupData, setGroupData] = useState({})
  const [groupList, setGroupList] = useState([])
  const [active, setActive] = useState(0)

  const pageid = location?.state?.pageid
  let isEdit = false

  const getChildrenData = async () => {
    try {
      const response = await fetch(`${Apisurl.Fetchurl + 'child'}`, {
        method: 'POST',
        headers: reqJson,
        body: JSON.stringify({ person_uid: sessionStorage.getItem('session') }),
      })
      const data = await response.json()

      data.Status.children.registred_child === 0
        ? navigate('/add/children', { state: { pageid: 1 } })
        : setChildren(data.Status.children.registred_child)
    } catch (error) {
      console.log('error from populate child', error)
    }
  }

  const getGroupData = async () => {
    try {
      const response = await fetch(
        Apisurl.Fetchurl + `make_group?teacher_user_id=${window.sessionStorage.getItem('session')}`,
        { headers: reqJson }
      )
      const data = await response.json()
      setGroupList(data.group_distribution)
    } catch (error) {
      console.log('error from grouplist', error)
    }
  }

  useEffect(() => {
    getChildrenData()
    getGroupData()
  }, [])

  const handleOpen = (type, id) => {
    if (type === 'edit') {
      isEdit = true
      const grp = groupList.find((grp) => grp.group_user_id === id)
      setGroupData(grp)
      setOpen(true)
    } else {
      isEdit = false
      setGroupData({})
      setOpen(true)
    }
  }

  const handleClose = () => {
    setGroupData({})
    setOpen(false)
  }

  const deleteGroup = (id) => {
    async function deleteGrp() {
      const response = await fetch(`${Apisurl.Fetchurl}delete_group`, {
        method: 'POST',
        headers: reqJson,
        body: JSON.stringify({
          group_user_id: id,
          teacher_user_id: window.sessionStorage.getItem('session'),
        }),
      })
      const groupdata = await response.json()
      return groupdata
    }
    deleteGrp()
      .then((data) => {
        getGroupData()
      })
      .catch((error) => {
        console.log('error from grouplist', error)
      })
  }

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        autoDisplay: false,
      },
      'google_translate_element'
    )
  }

  useEffect(() => {
    var addScript = document.createElement('script')
    addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit')
    document.body.appendChild(addScript)
    window.googleTranslateElementInit = googleTranslateElementInit
  }, [])

  return (
    <>
      <FormHeader />
      <div id='google_translate_element'></div>
      <Container>
        <RoleformHeader role='Add Group' />
        {groupList === undefined || groupList.length === 0 ? (
          <Children handleShow={handleOpen} />
        ) : (
          <>
            <Row className='my-3'>
              {groupList.map((grp, i) => {
                return (
                  <Groupcard
                    index={i}
                    key={grp.group_user_id}
                    group={grp}
                    delete={() => deleteGroup(grp.group_user_id)}
                    edit={() => handleOpen('edit', grp.group_user_id)}
                    setActive={setActive}
                    active={active}
                  />
                )
              })}
            </Row>

            <button className={childrenCss.addBtn} onClick={() => handleOpen('add')}>
              Create Group
            </button>

            <Col md={4} className={childrenCss.btnContainer}>
              <div className={childrenCss.redirectoption}>
                <NavLink
                  onClick={() => localStorage.setItem('ischildActive', active)}
                  to={pageid == 3 ? '/profile' : '/'}
                >
                  <Button variant='primary' className='common-btn full-btn  ' type='button'>
                    Submit
                  </Button>
                </NavLink>
                <p className='active' style={{ marginLeft: 20 }}>
                  Skip
                </p>
              </div>

              <p>
                <MDBIcon fas icon='exclamation-circle' />
                Don’t worry, you can always make group later.
              </p>
            </Col>
          </>
        )}
      </Container>
      {open && (
        <FormModalGroup
          isEdit={isEdit}
          getGroupData={getGroupData}
          groupData={groupData}
          setGroupData={setGroupData}
          open={open}
          handleClose={handleClose}
          children={children}
        />
      )}
    </>
  )
}

export default SelectGroup
