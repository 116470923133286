import react, { useRef, useState } from 'react'
import {
  DropdownButton,
  Dropdown,
  Item,
  Popover,
  Overlay,
  ButtonToolbar,
  OverlayTrigger,
  Button,
} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import userImage from '../assets/images/user3.svg'
import notification from '../assets/images/notification.svg'
import downarrow from '../assets/images/downarrow.svg'
import * as Apiurl from '../Apisurl'
import headersReq from '../HeadersJson'
import Calender from '../includes/Calender'
import { RxCalendar } from 'react-icons/rx'

const Activity = () => {
  let navigate = useNavigate()
  let reqJson = headersReq()
  const [show, setShow] = useState(false)
  const [target, setTarget] = useState()
  const ref = useRef()

  const logoutbtn = () => {
    if (sessionStorage.getItem('session') === '123456') {
      sessionStorage.clear()
      localStorage.clear()
      return navigate('/welcome')
    }

    async function logout() {
      const response = await fetch(`${Apiurl.Fetchurl + 'logout?user_id=' + sessionStorage.getItem('session') + ''}`, {
        method: 'GET',
        headers: reqJson,
      })
      const Admin = await response.json()
      return Admin
    }
    logout()
      .then((data) => {
        sessionStorage.clear()
        localStorage.clear()

        navigate('/loginmain')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleClick = (e) => {
    setShow((p) => !p)
    setTarget(e.target)
  }

  return (
    <div className='activity-container' style={{ minWidth: '100px' }}>
      {sessionStorage.getItem('session') === '123456' ? null : (
        <div ref={ref}>
          <button type='button' className='profile-item p-2 border-0 bg-white text-black' onClick={handleClick}>
            <RxCalendar style={{ fontSize: '24px' }} />
          </button>

          <Overlay show={show} target={target} placement='bottom-end' container={ref}>
            <Popover id='calender' style={{ width: '350px', maxWidth: '400px', border: 0 }}>
              <Calender />
            </Popover>
          </Overlay>
        </div>
      )}

      <div className='profile-container profile-item' style={{ minWidth: '125px' }}>
        <div className='nav-item dropdown' style={{ width: '100%' }}>
          <Dropdown>
            <Dropdown.Toggle style={{ width: '100%' }}>
              <figure className='profile-image' style={{ width: '100%' }}>
                <img src={userImage} alt='user4' />
                <div className='d-flex justify-content-between' style={{ width: '100%' }}>
                  <figcaption>{window.sessionStorage.getItem('sessionname')}</figcaption>
                  <img src={downarrow} className='downarrow' alt='downarrow' />
                </div>
              </figure>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={logoutbtn}>Logout </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  )
}

export default Activity
