import { Col } from 'react-bootstrap'
import Card from 'react-bootstrap/Card'
import { MDBIcon } from 'mdb-react-ui-kit'
import childrencss from './cssModule/children.module.css'

const Groupcard = (props) => {
  let activeclass = props.index === props.active ? 'childActive' : ''

  return (
    <>
      <Col md={4}>
        <Card
          className={childrencss.cardContainer + ' ' + activeclass}
          onClick={() => {
            props.setActive(props.index)
            localStorage.setItem('ischildActive', props.index)
          }}
        >
          <div className={'d-flex align-items-center'}>
            <figure className='edit-box'>
              <Card.Img variant='top' src={require('../assets/images/user4.png')} />
              <figcaption>
                <MDBIcon fas icon='pen' />
              </figcaption>
            </figure>
            <Card.Body className='p-0 mx-2'>
              <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                <h4 className='m-0'>{props.group.group_name}</h4>
                <div>
                  <MDBIcon
                    fas
                    icon='trash'
                    style={{ fontSize: '12px', color: '#c36b6b', marginRight: '20px' }}
                    onClick={props.delete}
                  />
                  <MDBIcon fas icon='pen' style={{ fontSize: '12px', color: '#6BC3BA' }} onClick={props.edit} />
                </div>
              </div>
              <p className='m-0'>{props.group.school_name}</p>
              <span>{props.group.student_class}</span>
              <div className='d-flex align-items-center gap-2 mt-1'>
                {props.group.members.slice(0, 3).map((member) => {
                  return (
                    <span
                      className='text-black px-3 py-1 border border-black rounded-pill bg-white'
                      key={member.child_userid}
                    >
                      {member.childname}
                    </span>
                  )
                })}
                {props.group.members.length > 3 && (
                  <span className='text-black px-3 py-1 border border-black rounded-pill bg-white'>
                    +{props.group.members.length - 3}
                  </span>
                )}
              </div>
            </Card.Body>
          </div>
        </Card>
      </Col>
    </>
  )
}
export default Groupcard
