import { Container } from 'react-bootstrap'
import Activity from './Activity'
import DashboardCardStructure from './DashboardCardStructure'

const MainContainer = () => {
  return (
    <div className='content dashboard-container flex-grow-1'>
      <Container fluid>
        <div className='dashboard-heading'>
          <div class='content-container'>
            <h2>Hi there</h2>
            <p>Here’s a quick overview for your recent assignments</p>
          </div>
          <Activity />
        </div>
        <DashboardCardStructure />
      </Container>
    </div>
  )
}

export default MainContainer
