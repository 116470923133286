import React from "react";

export default function SelectCategoryallot() {
  return (
    <>
      <div className="category-allot">
        <span>
          Select Games to allot. Click on the game to select the state
        </span>
        <div className="category-multiselect">
          <div className="category-school">
            <img src="../assets/images/education.svg" alt="education" />
            <p>Education</p>
            <img src="../assets/images/games.svg" alt="education" />
            <p>Games</p>
            <img src="../assets/images/communication.svg" alt="education" />
            <p>Communication</p>
          </div>
          <div className="category-selection"></div>
        </div>
      </div>
    </>
  );
}
