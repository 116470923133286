import React, { useContext, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import DataTableExtensions from 'react-data-table-component-extensions'
import * as Apiurl from '../Apisurl'
import Activity from '../dashboard/Activity'
import Context from '../Context'
import DownloadPdf from '../assets/images/downloadpf.svg'
import Showresult from '../dashboard/Showresult'
import PdfHeader from '../profile/components/pdfparts/header'
import { useLocation } from 'react-router-dom'

function ReportTable(props) {
  return (
    <div className='pdf-page-container content flex-grow-1 allreport'>
      <Container fluid className='option-main-container report-tab'>
        <PdfHeader />
        <div className='dashboard-heading'>
          <div className='content-container'>
            <h2>Reports</h2>
            <p>Check out your cumulative and test-wise reports here</p>
          </div>
          <Activity />
          <div className='module-filter'>
            <Showresult />
            <AllReportsTable userId={props.userId} />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default ReportTable

export const AllReportsTable = (props) => {
  const [reportData, setReportData] = useState()
  const [tableData, settableData] = useState(0)
  const [sortByDate, setSortByDate] = useState(1)
  const selectData = useContext(Context)

  const location = useLocation()

  const url = location.pathname.split('/').at(-1)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(Apiurl.Fetchurl + 'reports_table_alluser', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem('session')}`,
          },
          body: JSON.stringify({
            user_id: props.userId || 'all',
            isGroup: localStorage.getItem('isGroup'),
            modulename: selectData.selectedModule,
          }),
        })
        const data = await response.json()
        setReportData(JSON.parse(data))
        console.log(reportData)
      } catch (error) {
        console.error('Error fetching report data:', error)
      }
    }

    fetchData()
  }, [selectData.selectedModule, props.userId])

  const customStyles = {
    rows: {
      style: {
        minHeight: '80px',
      },
    },
    cells: {
      style: {
        marginLeft: '35px',
        fontSize: '14px',
        padding: '0px',
        color: '#414141',
      },
    },
    headRow: {
      style: {
        borderRadius: '2px',
        background: 'radial-gradient(98.07% 73.14% at 3.51% 19.05%, #97d5cd 8.51%, #54baaf 100%)', // Gradient background for header row
      },
    },
  }

  const columns = [
    { name: 'Name', selector: 'username' },
    { name: 'Module', selector: 'modulename' },
    { name: 'Category', selector: 'category' },
    { name: 'Score', selector: 'score' },
    { name: 'Total', selector: 'total' },
  ]

  useEffect(() => {
    const formattedData =
      reportData &&
      Object.keys(reportData.userid).map((index) => ({
        username: reportData.username[index],
        modulename: reportData.modulename[index],
        category: reportData.category[index],
        score: reportData.score[index],
        total: reportData.total[index],
      }))

    settableData({
      columns,
      data: formattedData,
    })
  }, [reportData])

  const sortDate = (res) => {
    setSortByDate(res)
  }

  const handlePrint = () => {
    window.print()
  }

  const getPercentage = () => {
    let percentage = 0
    let sum = 0
    tableData?.data?.forEach((d) => {
      let res = (d.score / d.total) * 100
      sum += res
    })

    percentage = sum / tableData?.data?.length

    return Math.round(percentage) || 0
  }

  const gladSeperation = () => {
    if (selectData?.selectedModule?.toLowerCase().includes('glad')) {
      let per = getPercentage()
      if (per < 40) {
        return 'and is currently at frustration level'
      } else if (per >= 40 && per <= 70) {
        return 'and is currently at instructional level'
      } else {
        return 'and is currently at independent level'
      }
    }

    return ''
  }

  return (
    <div className='main-spacing-container'>
      <div className='icons-container'>
        <div className='heading-seprator'>
          <h1>
            <span>Result Report</span>
          </h1>
          <ul className='page1-icon-container'>
            <li>
              <svg width='33' height='33' viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M16.61 31.5212C24.9605 31.5212 31.73 24.7518 31.73 16.4012C31.73 8.0507 24.9605 1.28125 16.61 1.28125C8.25944 1.28125 1.48999 8.0507 1.48999 16.4012C1.48999 24.7518 8.25944 31.5212 16.61 31.5212Z'
                  fill='url(#paint0_linear_826_58)'
                />
                <path
                  d='M16.88 7.40057L17.58 7.54059C18.3883 7.76202 19.1164 8.21023 19.6781 8.83221C20.2398 9.4542 20.6118 10.224 20.75 11.0506C20.8461 11.6461 20.8164 12.2551 20.6629 12.8384C20.5093 13.4217 20.2353 13.9664 19.8585 14.4374C19.4817 14.9084 19.0104 15.2953 18.4751 15.5731C17.9397 15.851 17.3521 16.0136 16.75 16.0506C15.6634 16.107 14.5952 15.7531 13.7571 15.0591C12.9191 14.3651 12.3723 13.3816 12.2251 12.3035C12.0779 11.2254 12.3411 10.1313 12.9624 9.2381C13.5838 8.34485 14.518 7.71756 15.58 7.48059L16.08 7.39062L16.88 7.40057Z'
                  fill='white'
                />
                <path
                  d='M16.57 25.4011H12.3C11.5898 25.4281 10.8893 25.2285 10.3 24.8312C9.96677 24.6076 9.68841 24.3115 9.4858 23.9652C9.28318 23.6188 9.16154 23.2312 9.13001 22.8312C8.99006 21.3567 9.19902 19.8699 9.74001 18.4911C10.0284 17.6459 10.615 16.9348 11.39 16.4911C11.8925 16.2182 12.4585 16.0837 13.03 16.1011C13.2215 16.1184 13.4064 16.18 13.57 16.2811C13.84 16.4311 14.09 16.6111 14.35 16.7711C15.0145 17.2247 15.8004 17.4673 16.605 17.4673C17.4096 17.4673 18.1955 17.2247 18.86 16.7711C19.0709 16.6461 19.2746 16.5092 19.47 16.3611C19.6423 16.2436 19.8363 16.1619 20.0407 16.1206C20.2451 16.0794 20.4557 16.0796 20.66 16.1211C21.2027 16.193 21.7194 16.3976 22.1641 16.7168C22.6089 17.036 22.9681 17.46 23.21 17.9511C23.6801 18.8582 23.9592 19.852 24.03 20.8711C24.0833 21.3894 24.1066 21.9102 24.1 22.4311C24.117 23.1724 23.8464 23.8915 23.3449 24.4377C22.8434 24.9839 22.15 25.3148 21.41 25.3611C21.2168 25.3711 21.0232 25.3711 20.83 25.3611L16.57 25.4011Z'
                  fill='white'
                />
                <path
                  d='M16.61 31.5212C24.9605 31.5212 31.73 24.7518 31.73 16.4012C31.73 8.0507 24.9605 1.28125 16.61 1.28125C8.25944 1.28125 1.48999 8.0507 1.48999 16.4012C1.48999 24.7518 8.25944 31.5212 16.61 31.5212Z'
                  stroke='url(#paint1_linear_826_58)'
                  stroke-width='2'
                  stroke-miterlimit='10'
                />
                <defs>
                  <linearGradient
                    id='paint0_linear_826_58'
                    x1='1.48999'
                    y1='16.4012'
                    x2='31.73'
                    y2='16.4012'
                    gradientUnits='userSpaceOnUse'
                  >
                    <stop stop-color='#95CBCE' />
                    <stop offset='1' stop-color='#A1DBDC' />
                  </linearGradient>
                  <linearGradient
                    id='paint1_linear_826_58'
                    x1='0.489991'
                    y1='16.4012'
                    x2='32.73'
                    y2='16.4012'
                    gradientUnits='userSpaceOnUse'
                  >
                    <stop stop-color='#A1DBDC' />
                    <stop offset='1' stop-color='#95CBCE' />
                  </linearGradient>
                </defs>
              </svg>

              <div className='icons-txt'>
                <p>Name</p>
                <h3 style={{ fontFamily: 'sorabold' }}>{sessionStorage.getItem('sessionname')}</h3>
              </div>
            </li>

            <li>
              <svg width='33' height='33' viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M16.63 31.4705C24.9806 31.4705 31.75 24.701 31.75 16.3505C31.75 7.99992 24.9806 1.23047 16.63 1.23047C8.27946 1.23047 1.51001 7.99992 1.51001 16.3505C1.51001 24.701 8.27946 31.4705 16.63 31.4705Z'
                  fill='url(#paint0_linear_826_53)'
                />
                <path
                  d='M13.6 8.64844C13.79 8.64844 13.98 8.71843 14.16 8.76843C14.8102 8.94032 15.3963 9.2974 15.8473 9.79626C16.2982 10.2951 16.5945 10.9143 16.7 11.5784C16.7747 12.0553 16.7491 12.5425 16.6248 13.0089C16.5005 13.4753 16.2802 13.9107 15.9781 14.2871C15.676 14.6636 15.2987 14.9728 14.8703 15.1952C14.4419 15.4175 13.9718 15.5481 13.49 15.5784C12.6124 15.6386 11.7447 15.3633 11.0624 14.808C10.3801 14.2528 9.93415 13.4592 9.81473 12.5876C9.69531 11.7161 9.91133 10.8318 10.4191 10.1135C10.9269 9.39516 11.6886 8.89649 12.55 8.71838L12.95 8.64844H13.6Z'
                  fill='white'
                />
                <path
                  d='M15.72 22.7716V19.1116C15.8169 18.699 16.0504 18.3313 16.3826 18.068C16.7148 17.8048 17.1261 17.6616 17.5499 17.6616H18.12C18.1145 17.3121 18.2007 16.9673 18.37 16.6616C18.162 16.3743 17.8979 16.1322 17.5937 15.95C17.2894 15.7678 16.9514 15.6493 16.6 15.6016C16.4363 15.5693 16.2678 15.5704 16.1046 15.6047C15.9413 15.6391 15.7867 15.706 15.65 15.8016C15.4944 15.9197 15.3307 16.0266 15.16 16.1216C14.6286 16.4814 14.0016 16.6738 13.3599 16.6738C12.7182 16.6738 12.0913 16.4814 11.56 16.1216C11.35 15.9916 11.14 15.8415 10.93 15.7215C10.7992 15.6427 10.6521 15.5948 10.5 15.5816C10.0437 15.5686 9.59201 15.6755 9.18996 15.8916C8.56842 16.24 8.09811 16.8065 7.86995 17.4816C7.43378 18.583 7.26583 19.7724 7.37996 20.9516C7.40465 21.2711 7.50108 21.5808 7.6621 21.8578C7.82312 22.1349 8.04458 22.372 8.30996 22.5516C8.7865 22.875 9.35455 23.0363 9.92995 23.0116H15.74C15.7257 22.9324 15.719 22.8521 15.72 22.7716Z'
                  fill='white'
                />
                <path
                  d='M16.28 19.2109L16.52 19.3309C17.92 20.0576 19.3166 20.7909 20.71 21.5309C20.8285 21.5994 20.9631 21.6354 21.1 21.6354C21.2369 21.6354 21.3714 21.5994 21.49 21.5309L25.69 19.3309L25.91 19.2309V22.5609C25.9249 22.7617 25.8964 22.9634 25.8263 23.1522C25.7562 23.3409 25.6462 23.5123 25.5038 23.6547C25.3614 23.7971 25.19 23.9071 25.0012 23.9772C24.8125 24.0473 24.6108 24.0759 24.41 24.0609H17.79C17.5888 24.0757 17.3867 24.0471 17.1975 23.9771C17.0083 23.907 16.8363 23.7972 16.6932 23.655C16.55 23.5128 16.4391 23.3416 16.3678 23.1528C16.2965 22.9641 16.2666 22.7622 16.28 22.5609V19.2209V19.2109Z'
                  fill='white'
                />
                <path
                  d='M16.6899 18.6608C17.2599 18.0608 17.9899 18.2808 18.6899 18.2208V17.6608C18.6858 17.4719 18.7201 17.2841 18.7905 17.1088C18.8609 16.9334 18.9661 16.7741 19.0997 16.6405C19.2333 16.5069 19.3926 16.4017 19.5679 16.3313C19.7432 16.2609 19.931 16.2267 20.1199 16.2308H22.0699C22.2593 16.2267 22.4476 16.2609 22.6234 16.3312C22.7993 16.4015 22.9592 16.5065 23.0936 16.64C23.228 16.7734 23.3341 16.9327 23.4057 17.1081C23.4772 17.2834 23.5127 17.4714 23.5099 17.6608V18.2308H23.7999C24.1199 18.2308 24.4299 18.2308 24.7399 18.2308C25.0384 18.2679 25.3126 18.4139 25.5099 18.6408L24.8199 19.0008L21.2599 20.8608C21.2098 20.8898 21.1529 20.9051 21.0949 20.9051C21.037 20.9051 20.9801 20.8898 20.9299 20.8608C19.5799 20.1408 18.2199 19.4408 16.8599 18.7208C16.8009 18.7082 16.7438 18.688 16.6899 18.6608ZM22.2999 18.2208V17.6608C22.3047 17.6304 22.3025 17.5993 22.2933 17.5698C22.2841 17.5404 22.2682 17.5136 22.2469 17.4913C22.2256 17.469 22.1995 17.452 22.1705 17.4415C22.1415 17.431 22.1105 17.4273 22.0799 17.4308H20.0799C20.0525 17.4279 20.0247 17.4309 19.9986 17.4398C19.9725 17.4486 19.9486 17.4631 19.9286 17.4821C19.9086 17.5012 19.893 17.5243 19.8829 17.55C19.8727 17.5757 19.8683 17.6033 19.8699 17.6308C19.8699 17.8208 19.8699 18.0208 19.8699 18.2208H22.2999Z'
                  fill='white'
                />
                <path
                  d='M16.63 31.4705C24.9806 31.4705 31.75 24.701 31.75 16.3505C31.75 7.99992 24.9806 1.23047 16.63 1.23047C8.27946 1.23047 1.51001 7.99992 1.51001 16.3505C1.51001 24.701 8.27946 31.4705 16.63 31.4705Z'
                  stroke='url(#paint1_linear_826_53)'
                  stroke-width='2'
                  stroke-miterlimit='10'
                />
                <defs>
                  <linearGradient
                    id='paint0_linear_826_53'
                    x1='1.51001'
                    y1='16.3505'
                    x2='31.75'
                    y2='16.3505'
                    gradientUnits='userSpaceOnUse'
                  >
                    <stop stop-color='#95CBCE' />
                    <stop offset='1' stop-color='#A1DBDC' />
                  </linearGradient>
                  <linearGradient
                    id='paint1_linear_826_53'
                    x1='0.51001'
                    y1='16.3505'
                    x2='32.75'
                    y2='16.3505'
                    gradientUnits='userSpaceOnUse'
                  >
                    <stop stop-color='#A1DBDC' />
                    <stop offset='1' stop-color='#95CBCE' />
                  </linearGradient>
                </defs>
              </svg>

              <div className='icons-txt'>
                <p>Teacher</p>
                <h3 style={{ fontFamily: 'sorabold' }}>{localStorage.getItem('role')}</h3>
              </div>
            </li>

            <li>
              <svg width='33' height='33' viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M16.63 31.4705C24.9806 31.4705 31.75 24.701 31.75 16.3505C31.75 7.99992 24.9806 1.23047 16.63 1.23047C8.27946 1.23047 1.51001 7.99992 1.51001 16.3505C1.51001 24.701 8.27946 31.4705 16.63 31.4705Z'
                  fill='url(#paint0_linear_826_53)'
                />
                <path
                  d='M13.6 8.64844C13.79 8.64844 13.98 8.71843 14.16 8.76843C14.8102 8.94032 15.3963 9.2974 15.8473 9.79626C16.2982 10.2951 16.5945 10.9143 16.7 11.5784C16.7747 12.0553 16.7491 12.5425 16.6248 13.0089C16.5005 13.4753 16.2802 13.9107 15.9781 14.2871C15.676 14.6636 15.2987 14.9728 14.8703 15.1952C14.4419 15.4175 13.9718 15.5481 13.49 15.5784C12.6124 15.6386 11.7447 15.3633 11.0624 14.808C10.3801 14.2528 9.93415 13.4592 9.81473 12.5876C9.69531 11.7161 9.91133 10.8318 10.4191 10.1135C10.9269 9.39516 11.6886 8.89649 12.55 8.71838L12.95 8.64844H13.6Z'
                  fill='white'
                />
                <path
                  d='M15.72 22.7716V19.1116C15.8169 18.699 16.0504 18.3313 16.3826 18.068C16.7148 17.8048 17.1261 17.6616 17.5499 17.6616H18.12C18.1145 17.3121 18.2007 16.9673 18.37 16.6616C18.162 16.3743 17.8979 16.1322 17.5937 15.95C17.2894 15.7678 16.9514 15.6493 16.6 15.6016C16.4363 15.5693 16.2678 15.5704 16.1046 15.6047C15.9413 15.6391 15.7867 15.706 15.65 15.8016C15.4944 15.9197 15.3307 16.0266 15.16 16.1216C14.6286 16.4814 14.0016 16.6738 13.3599 16.6738C12.7182 16.6738 12.0913 16.4814 11.56 16.1216C11.35 15.9916 11.14 15.8415 10.93 15.7215C10.7992 15.6427 10.6521 15.5948 10.5 15.5816C10.0437 15.5686 9.59201 15.6755 9.18996 15.8916C8.56842 16.24 8.09811 16.8065 7.86995 17.4816C7.43378 18.583 7.26583 19.7724 7.37996 20.9516C7.40465 21.2711 7.50108 21.5808 7.6621 21.8578C7.82312 22.1349 8.04458 22.372 8.30996 22.5516C8.7865 22.875 9.35455 23.0363 9.92995 23.0116H15.74C15.7257 22.9324 15.719 22.8521 15.72 22.7716Z'
                  fill='white'
                />
                <path
                  d='M16.28 19.2109L16.52 19.3309C17.92 20.0576 19.3166 20.7909 20.71 21.5309C20.8285 21.5994 20.9631 21.6354 21.1 21.6354C21.2369 21.6354 21.3714 21.5994 21.49 21.5309L25.69 19.3309L25.91 19.2309V22.5609C25.9249 22.7617 25.8964 22.9634 25.8263 23.1522C25.7562 23.3409 25.6462 23.5123 25.5038 23.6547C25.3614 23.7971 25.19 23.9071 25.0012 23.9772C24.8125 24.0473 24.6108 24.0759 24.41 24.0609H17.79C17.5888 24.0757 17.3867 24.0471 17.1975 23.9771C17.0083 23.907 16.8363 23.7972 16.6932 23.655C16.55 23.5128 16.4391 23.3416 16.3678 23.1528C16.2965 22.9641 16.2666 22.7622 16.28 22.5609V19.2209V19.2109Z'
                  fill='white'
                />
                <path
                  d='M16.6899 18.6608C17.2599 18.0608 17.9899 18.2808 18.6899 18.2208V17.6608C18.6858 17.4719 18.7201 17.2841 18.7905 17.1088C18.8609 16.9334 18.9661 16.7741 19.0997 16.6405C19.2333 16.5069 19.3926 16.4017 19.5679 16.3313C19.7432 16.2609 19.931 16.2267 20.1199 16.2308H22.0699C22.2593 16.2267 22.4476 16.2609 22.6234 16.3312C22.7993 16.4015 22.9592 16.5065 23.0936 16.64C23.228 16.7734 23.3341 16.9327 23.4057 17.1081C23.4772 17.2834 23.5127 17.4714 23.5099 17.6608V18.2308H23.7999C24.1199 18.2308 24.4299 18.2308 24.7399 18.2308C25.0384 18.2679 25.3126 18.4139 25.5099 18.6408L24.8199 19.0008L21.2599 20.8608C21.2098 20.8898 21.1529 20.9051 21.0949 20.9051C21.037 20.9051 20.9801 20.8898 20.9299 20.8608C19.5799 20.1408 18.2199 19.4408 16.8599 18.7208C16.8009 18.7082 16.7438 18.688 16.6899 18.6608ZM22.2999 18.2208V17.6608C22.3047 17.6304 22.3025 17.5993 22.2933 17.5698C22.2841 17.5404 22.2682 17.5136 22.2469 17.4913C22.2256 17.469 22.1995 17.452 22.1705 17.4415C22.1415 17.431 22.1105 17.4273 22.0799 17.4308H20.0799C20.0525 17.4279 20.0247 17.4309 19.9986 17.4398C19.9725 17.4486 19.9486 17.4631 19.9286 17.4821C19.9086 17.5012 19.893 17.5243 19.8829 17.55C19.8727 17.5757 19.8683 17.6033 19.8699 17.6308C19.8699 17.8208 19.8699 18.0208 19.8699 18.2208H22.2999Z'
                  fill='white'
                />
                <path
                  d='M16.63 31.4705C24.9806 31.4705 31.75 24.701 31.75 16.3505C31.75 7.99992 24.9806 1.23047 16.63 1.23047C8.27946 1.23047 1.51001 7.99992 1.51001 16.3505C1.51001 24.701 8.27946 31.4705 16.63 31.4705Z'
                  stroke='url(#paint1_linear_826_53)'
                  stroke-width='2'
                  stroke-miterlimit='10'
                />
                <defs>
                  <linearGradient
                    id='paint0_linear_826_53'
                    x1='1.51001'
                    y1='16.3505'
                    x2='31.75'
                    y2='16.3505'
                    gradientUnits='userSpaceOnUse'
                  >
                    <stop stop-color='#95CBCE' />
                    <stop offset='1' stop-color='#A1DBDC' />
                  </linearGradient>
                  <linearGradient
                    id='paint1_linear_826_53'
                    x1='0.51001'
                    y1='16.3505'
                    x2='32.75'
                    y2='16.3505'
                    gradientUnits='userSpaceOnUse'
                  >
                    <stop stop-color='#A1DBDC' />
                    <stop offset='1' stop-color='#95CBCE' />
                  </linearGradient>
                </defs>
              </svg>

              <div className='icons-txt'>
                <p>No. of students</p>
                <h3 style={{ fontFamily: 'sorabold' }}>
                  {reportData && Array.from(new Set(Object.values(reportData.userid))).length}
                </h3>
              </div>
            </li>
          </ul>
        </div>
      </div>

      {url !== 'all' && (
        <h4 className='text-black'>
          {selectData?.selectValue?.username} scored {getPercentage()}% {gladSeperation()}
        </h4>
      )}

      {/* <button onClick={handlePrint}>Download</button> */}
      <div className='row'>
        <div className='col-md-8'>
          <div className='report-datatable'>
            <DataTableExtensions {...tableData}>
              <DataTable
                pagination={5}
                defaultSortField='id'
                customStyles={customStyles}
                defaultSortAsc={false}
                highlightOnHover
                columns={columns}
                export={true}
              />
            </DataTableExtensions>
          </div>
        </div>
        <div className='col-md-4 m-auto h-100'>
          <div className='downloadbtn-container h-100'>
            <div className='week-report' style={{ position: 'static', top: '64px' }}>
              <h3>Filter :</h3>
              <button
                type='button'
                className={sortByDate == 1 ? 'btn btn-all  ' : 'btn-grey btn'}
                onClick={() => sortDate(1, 'All')}
              >
                All
              </button>
              <button
                type='button'
                className={sortByDate == 2 ? 'btn btn-all ' : 'btn btn-grey'}
                onClick={() => sortDate(2, 'This Week')}
              >
                This Week
              </button>
              <button
                type='button'
                className={sortByDate == 3 ? 'btn btn-all ' : 'btn btn-grey'}
                onClick={() => sortDate(3, 'This Month')}
              >
                This Month
              </button>
            </div>
            <img src={DownloadPdf} alt='DownloadPdf' />
            <button className='common-btn' onClick={handlePrint}>
              Download Report Here
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
