import ChartDataLabels from 'chartjs-plugin-datalabels'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { Bar } from 'react-chartjs-2'
import * as Apiurl from '../Apisurl'
import Context from '../Context'
import { NavLink } from 'react-router-dom'

const Aggregate = () => {
  const { selectValue } = useContext(Context)
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)

  let role = localStorage.getItem('role')

  useEffect(() => {
    const getCharts = async () => {
      try {
        setLoading(true)
        const response = await fetch(`${Apiurl.Fetchurl + 'brain_scan_aggregate'}`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + sessionStorage.getItem('session'),
          },
          body: JSON.stringify({ user_id: selectValue.user_id }),
        })

        const { aggregate } = await response.json()

        setData(aggregate)
        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }

    getCharts()
  }, [selectValue.user_id])

  let top3 = data && Object.entries(data).sort((a, b) => b[1] - a[1])

  return (
    <div>
      <p className='fs-5' style={{ textAlign: 'justify' }}>
        These subject wise findings show the performance based on the unique learning pattern of a{' '}
        {role === 'parent' || role === 'teacher' ? 'child' : 'individual'}.
      </p>
      <p className='fs-5' style={{ textAlign: 'justify' }}>
        You would have noticed that every {role === 'parent' || role === 'teacher' ? 'child' : 'individual'} has a
        favourite subject, it may be academics or arts or anything else. According to the experts, curiosity makes our
        brain more receptive to learning, and that’s when we enjoy the sensation of learning. The same stands true for{' '}
        {role === 'parent' || role === 'teacher' ? 'children' : 'individuals'}.
      </p>

      <p className='fs-5' style={{ textAlign: 'justify' }}>
        {!loading &&
          top3 &&
          `As shown below, ${top3[0][0]} is higher than ${top3[1][0]}. Likewise ${top3[1][0]} is higher than ${top3[2][0]}.`}
      </p>
      <Row className='justify-content-center'>
        <Col lg={6}>
          <div className='dashboard-cards stat-container'>
            <div class='performance-heading'>
              <div class='performance-content'>
                <h4 className='text-black'>{selectValue?.username}</h4>
              </div>
            </div>
            {!loading && (
              <Bar
                plugins={[ChartDataLabels]}
                data={{
                  labels: Object.keys(data).map((k) => k.charAt(0).toUpperCase() + k.slice(1)),
                  datasets: [
                    {
                      data: Object.values(data),
                      backgroundColor: bgColors,
                      barPercentage: 0.5,
                      barThickness: 40,
                      maxBarThickness: 50,
                      borderRadius: 8,
                      minBarLength: 2,
                    },
                  ],
                }}
                options={options}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Aggregate

export const bgColors = ['#c8d1fc', '#b3ddf6', '#95d8a5', '#c5e49e', '#fedf6b', '#eea08c', '#B7A1C7']

export const options = {
  layout: {
    padding: {
      top: 30,
    },
  },
  plugins: {
    tooltip: {
      enabled: false,
    },
    legend: {
      display: false,
    },
    datalabels: {
      labels: {
        value: {
          anchor: 'end',
          align: 'top',
          display: true,
          color: 'black',
          font: {
            weight: 'bold',
            size: 16,
          },
          offset: 0,
          formatter: (value, ctx) => {
            return value
          },
        },
      },
    },
  },

  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      display: false,
      min: 0,
      max: 100,
    },
  },
}
