import React, { useContext, useRef, useState } from 'react'
import { Button, Card, Col } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Currdate from '../../CurrDate'
import { SocketConfig } from '../../SocketConfig'
import headersReq from '../../HeadersJson'

import * as Apiurl from '../../Apisurl'
import Context from '../../Context'

const Education = ({ images, title, setNewvia, video, level }) => {
  const { selectValue } = useContext(Context)
  const reqJson = headersReq()
  let navigate = useNavigate()
  const [datain, setdatain] = useState()
  const [leveldata, setlevel] = useState()
  const vidRef = useRef(null)
  let locationdata = useLocation()
  let date = Currdate.GetCurrentDate()
  let parseData = locationdata.state
  let sendallData = {
    username: selectValue.username,
    userid: selectValue.user_id,
    category: parseData.category,
    modulename1: parseData.modulename1,
    level: leveldata,
    modulename: 'education',
  }

  async function saveReport() {
    let alldata = {
      ...sendallData,
      modulename1: parseData.modulename1
        .toLowerCase()
        .split(' ')
        .join(''),
      date: date,
      result: JSON.stringify({
        ques: [],
        total: '1',
        score: '1',
        ans: [],
      }),
    }

    const esc = encodeURIComponent

    const query = Object.keys(alldata)
      .map((k) => `${esc(k)}=${esc(alldata[k])}`)
      .join('&')

    const response = await fetch(`${Apiurl.Fetchurl + 'manual_report_fc?' + query + ''}`, {
      method: 'GET',
      headers: reqJson,
    })

    const data = await response.json()
    return data
  }

  const handlePlay = (e) => {
    e.preventDefault()
    let levelattr = e.target.getAttribute('levels')
    setlevel(levelattr)
    let educationdata = {
      result: 'connect',
      l_video: [vidRef.current.duration],
      level: levelattr,
    }
    let datei = {
      date: date,
    }
    let mergeobj = {
      ...sendallData,
      ...datei,
      ...educationdata,
    }

    setdatain(mergeobj)

    SocketConfig.emit('educate', mergeobj)

    const elem = vidRef.current

    vidRef.current.style = 'display:block'
    if (elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen()
      }
      vidRef.current.play()
    }
  }

  const handleVideoEnded = (e) => {
    e.preventDefault()
    SocketConfig.emit('educate', { result: 'submit' })
    saveReport()
      .then((data) => {
        navigate('ereport', { state: datain })
      })
      .catch((error) => {
        console.log(error)
      })
  }
  return (
    <Col md={4}>
      <Card>
        <figure>
          <Card.Img variant='top' src={images} />
          {setNewvia == false ? (
            <span className='attempttxt new bg-success'>New</span>
          ) : (
            <span className='attempttxt bg-primary'>Attempted</span>
          )}
        </figure>
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <div className='avacado' id='level_vid'>
            <video width='0' height='0' ref={vidRef} onEnded={handleVideoEnded} style={{ display: 'none' }} src={video}>
              <source src={video} />
            </video>
          </div>
          <div className='btn-container'>
            <Button className='full-btn common-btn' levels={level} onClick={handlePlay}>
              Start
            </Button>
          </div>
        </Card.Body>
      </Card>
    </Col>
  )
}
export default Education
