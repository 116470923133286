import React, { useContext, useEffect, useState } from 'react'
import './Puzzle15.css'
import { Modal, ModalBody } from 'react-bootstrap'
import * as API from '../../Apisurl'
import Context from '../../Context'

const shuffle = (array) => {
  return [...array].sort(() => Math.random() - 0.5)
}

const squares = Array.from({ length: 16 }, (_, i) => i)

function Puzzle15() {
  const [randomSquares, setRandomSquares] = useState([])
  const [moves, setMoves] = useState(0)
  const [show, setShow] = useState(false)

  const { selectValue } = useContext(Context)

  const reset = () => {
    setMoves(0)
    setRandomSquares(() => shuffle(squares))
  }

  useEffect(() => {
    reset()
  }, [])

  const saveReport = async (res) => {
    try {
      await fetch(`${API.Fetchurl}games_data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('session')}`,
        },
        body: JSON.stringify(res),
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (randomSquares.length && randomSquares.every((num, index) => num === 0 || num === index + 1)) {
      setShow(true)
      saveReport({
        user_id: selectValue.user_id,
        username: selectValue.username,
        bet: 0,
        time: '',
        category: '15 puzzle',
        score: moves,
        bet_array: [],
      })
    }
  }, [randomSquares, moves, selectValue.user_id, selectValue.username])

  const moveSquare = (val) => {
    let zeroIndex = randomSquares.indexOf(0)
    let valIndex = randomSquares.indexOf(val)

    if (
      valIndex + 4 === zeroIndex ||
      valIndex - 4 === zeroIndex ||
      (valIndex + 1 === zeroIndex && zeroIndex % 4 !== 0) ||
      (valIndex - 1 === zeroIndex && (zeroIndex + 1) % 4 !== 0)
    ) {
      swap(valIndex, zeroIndex)
      setMoves((m) => m + 1)
    }
  }

  const swap = (valIndex, zeroIndex) => {
    let temArray = [...randomSquares]
    temArray[zeroIndex] = randomSquares[valIndex]
    temArray[valIndex] = 0
    setRandomSquares(() => [...temArray])
  }

  return (
    <div className='h-100vh w-100 d-flex justify-content-center align-items-center'>
      <div className='p-4 shadow rounded-3'>
        <div>
          <h4 className='text-end'>Moves : {moves}</h4>
        </div>
        {
          <div className='Container'>
            {randomSquares.map((value, i) => {
              let color
              if (value === 0) color = 'EmptySquare'
              else if (value === i + 1) color = 'CorrectSquare'
              else color = 'FillSquare'

              return (
                <div key={i} className={color} onClick={() => moveSquare(value)}>
                  {value === 0 ? '' : value}
                </div>
              )
            })}
          </div>
        }
        <button onClick={reset} className='w-100 p-3 border-0 rounded mt-2 bg-warning text-black'>
          New Game
        </button>
      </div>

      <Modal show={show} onHide={setShow} centered backdrop='static'>
        <ModalBody>
          <div>
            <h1 className='fst-italic text-center mb-4'>Good Job</h1>
            <p className='text-center m-0'>
              It Took you <span className='fw-bold'>{moves} Moves</span>
            </p>
            <button
              type='button'
              className='w-100 p-3 border-0 rounded mt-2 bg-warning text-black'
              onClick={() => {
                reset()
                setShow(false)
              }}
            >
              Play Again
            </button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default Puzzle15
