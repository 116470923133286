import Table from 'react-bootstrap/Table'
import { MDBIcon } from 'mdb-react-ui-kit'
import ReactPaginate from 'react-paginate'
import UserJson from '../../../Userjson'
import { useOutletContext } from 'react-router-dom'
import react, { useRef } from 'react'
import Pagination from '../../Pagination'
import { useEffect, useState, useContext } from 'react'
import * as Apiurl from '../../../Apisurl'
import Context from '../../../Context'

const Colortable = () => {
  const [overview1, setOverview] = useState()
  const props1 = useContext(Context)
  console.log(props1)
  //let overview = JSON.parse(localStorage.getItem("overview"));
  let tableref = useRef()
  let user = UserJson()
  let tlengthdata = tableref.current
  console.log(tableref.current)
  console.log(tlengthdata)
  console.log(props1)
  // for (let i = 1; i < tlengthdata.rows.length; i++) {
  //   // GET THE CELLS COLLECTION OF THE CURRENT ROW.
  //   var objCells = tlengthdata.rows.item(i).cells;

  //   // LOOP THROUGH EACH CELL OF THE CURENT ROW TO READ CELL VALUES.
  //   for (var j = 0; j < objCells.length; j++) {
  //     console.log(objCells.item(j));
  //   }
  // }
  let category = UserJson().category

  console.log(props1[2])
  let overview = props1[2].Overview
  console.log(overview)
  if (overview != undefined && overview.length != 0) {
    return (
      <>
        <Table className='result-table' ref={tableref}>
          <thead>
            <tr>
              <th></th>
              {overview.ques.map((o, i) => {
                // console.log(overview.ques);
                return <th>Q{i + 1}</th>
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Asked</td>

              {overview.ques.map((o, i) => {
                return (
                  <td>
                    <div className='asked-color-box d-flex '>
                      <img src={o.mcq[0]} height='80' />
                      <img src={o.mcq[1]} style={{ marginLeft: '10px ' }} height='80' />
                    </div>
                  </td>
                )
              })}
            </tr>
            <tr>
              <td>Your Answer</td>
              {overview.ans.map((o, i) => {
                return (
                  <td>
                    <div
                      className='asked-color-box colorvia'
                      style={o.via == 'correct' ? { border: '2px solid green' } : { border: '2px solid red' }}
                    >
                      {o.via == 'correct' ? <i class='fas fa-check'></i> : <i class='fas fa-times'></i>}
                      <img src={o.answer} height='80' />
                    </div>
                  </td>
                )
              })}
            </tr>
            <tr>
              <td>Correct Answer</td>
              {overview.ques.map((o, i) => {
                return (
                  <td>
                    <div className='asked-color-box'>
                      <img src={o.ans} height='80' />
                    </div>
                  </td>
                )
              })}
            </tr>
          </tbody>
        </Table>
      </>
    )
  }
}

export default Colortable
