import React, { useContext, useState, useEffect } from "react";
import { Col, Row, ProgressBar } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import Slider from "react-custom-slider";
import Context from "../Context";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Pm from "../dashboard/Pm";

const Test = (props) => {
  const selectData = useContext(Context);
  // console.log(props.brainData[0][1][0].teachwith, "adeadasd");
  const [brainStrength, setBrainStrength] = useState({
    association: 14.3,
    memory: 14.3,
    thinking: 14,
    vision: 14,
  });
  const [Producitvity, setProductivityScale] = useState();
  // setBrainStrength;

  const [teachWith, setTeachWith] = useState({
    audio: 0,
    interactive: 20,
    photos: 0,
    videos: 0,
  });

  // setCoordinates(props.brainDat[0][1][0].eeg_position);
  const [emotData, setEmotData] = useState([10, 30, 45, 60]);
  const [pmData, setPmData] = useState([20, 40, 60, 30]);
  const [gradient, setGradient] = useState(1);
  useEffect(() => {
    let initArray = props.brainData[0][1][0];
    let emotArr = initArray.EmotionalAnalysis;
    let pmArr = initArray.PerformanceMetric;
    setBrainStrength(initArray.brain_strength);
    setTeachWith(initArray.teachwith);
    setProductivityScale(initArray["Producitvity Scale"]);
    setEmotData([
      emotArr.Contempt,
      emotArr.Happy,
      emotArr.Relaxed,
      emotArr.Status,
      emotArr.Tired,
    ]);
    setPmData([
      pmArr.Engagement,
      pmArr.Excitement,
      pmArr.Focus,
      pmArr.Interest,
      pmArr.Relaxation,
      pmArr.Status,
      pmArr.Stress,
    ]);
    console.log(emotArr, pmArr);
  }, []);
  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between">
        <h2 className="text-center">Cummulative Brain Scan Report</h2>
        <h5 style={{ color: "#000" }}>Productivity : {Producitvity}</h5>
      </div>
      <div style={{ maxWidth: "1100px", margin: "auto", marginBottom: "15px" }}>
        <Row>
          <Col lg={6}>
            <div className="dashboard-cards  stat-container">
              <div class="performance-heading">
                <div class="performance-content">
                  <h4>Teach With</h4>
                </div>
              </div>

              <div className="teach-with">
                <div className="label-box">
                  <div className="label">
                    <span style={{ backgroundColor: "#c1f0dc" }}></span>
                    <p>Photos:</p>
                  </div>
                  <div className="label">
                    <span style={{ backgroundColor: "#c1f0dc" }}></span>
                    <span style={{ backgroundColor: "#fbcacb" }}></span>
                    <p>Videos:</p>
                  </div>
                  <div className="label">
                    <span style={{ backgroundColor: "#fbcacb" }}></span>
                    <p>Audio:</p>
                  </div>
                  <div className="label">
                    <span style={{ backgroundColor: "#fff3ce" }}></span>
                    <p>Interactive:</p>
                  </div>
                </div>
                <div className="slider-box">
                  <div className="slider">
                    {/* <ProgressBar
                    style={{
                      height: '15px',
                      width: '80%',
                      borderRadius: '10px',
                    }}
                    striped
                    variant='primary'
                    min={0}
                    max={100}
                    now={teachWith.photos}
                  /> */}

                    <div>
                      <Slider
                        value={teachWith.photos}
                        handlerShape="rounded"
                        fillColor="#6bc3ba"
                        showValue={false}
                        showMarkers={false}
                        trackLength={250}
                        trackShape="rounded"
                        trackThickness={10}
                        max={100}
                        disabled={true}
                        disabledColor="#dff5f3"
                      />
                    </div>
                    <span>{teachWith.photos}%</span>
                  </div>
                  <div className="slider">
                    {/* <ProgressBar
                    style={{
                      height: '15px',
                      width: '80%',
                      borderRadius: '10px',
                    }}
                    striped
                    variant='primary'
                    min={0}
                    max={100}
                    now={teachWith.videos}
                  /> */}
                    <div>
                      <Slider
                        value={teachWith.videos}
                        handlerShape="rounded"
                        fillColor="#6bc3ba"
                        showValue={false}
                        showMarkers={false}
                        trackLength={250}
                        trackShape="rounded"
                        trackThickness={10}
                        max={100}
                        disabled={true}
                        disabledColor="#dff5f3"
                      />
                    </div>
                    <span>{teachWith.videos}%</span>
                  </div>
                  <div className="slider">
                    {/* <ProgressBar
                    style={{
                      height: '15px',
                      width: '80%',
                      borderRadius: '10px',
                    }}
                    striped
                    variant='primary'
                    min={0}
                    max={100}
                    now={teachWith.audio}
                  /> */}
                    <div>
                      <Slider
                        value={teachWith.audio}
                        handlerShape="rounded"
                        fillColor="#6bc3ba"
                        showValue={false}
                        showMarkers={false}
                        trackLength={250}
                        trackShape="rounded"
                        trackThickness={10}
                        max={100}
                        disabled={true}
                        disabledColor="#dff5f3"
                      />
                    </div>
                    <span>{teachWith.audio}%</span>
                  </div>
                  <div className="slider">
                    {/* <ProgressBar
                    style={{
                      height: '15px',
                      width: '80%',
                      borderRadius: '10px',
                    }}
                    striped
                    variant='primary'
                    min={0}
                    max={100}
                    now={teachWith.interactive}
                  /> */}
                    <div>
                      <Slider
                        value={teachWith.interactive}
                        handlerShape="rounded"
                        fillColor="#6bc3ba"
                        showValue={false}
                        showMarkers={false}
                        trackLength={250}
                        trackShape="rounded"
                        trackThickness={10}
                        max={100}
                        disabled={true}
                        disabledColor="#dff5f3"
                      />
                    </div>
                    <span>{teachWith.interactive}%</span>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col lg={6}>
            <div className="dashboard-cards  stat-container">
              <div class="performance-heading">
                <div class="performance-content">
                  <h4>Brain Strength</h4>
                </div>
              </div>
              <div className="brain-strength">
                <div className="label-box">
                  <div className="label">
                    <span style={{ backgroundColor: "#dfdbe4" }}></span>
                    <p>Memory:</p>
                  </div>
                  <div className="label">
                    <span style={{ backgroundColor: "#dfdbe4" }}></span>
                    <p>Thinking:</p>
                  </div>
                  <div className="label">
                    <span style={{ backgroundColor: "#c1f0dc" }}></span>
                    <p>Vision:</p>
                  </div>
                  <div className="label">
                    <span style={{ backgroundColor: "#fff3ce" }}></span>
                    <p>Association:</p>
                  </div>
                </div>
                <div className="slider-box">
                  <div className="slider">
                    {/* <ProgressBar
                    style={{
                      height: '15px',
                      width: '80%',
                      borderRadius: '10px',
                    }}
                    striped
                    variant='primary'
                    min={0}
                    max={100}
                    now={brainStrength.memory}
                  /> */}
                    <div>
                      <Slider
                        value={brainStrength.memory}
                        handlerShape="rounded"
                        fillColor="#6bc3ba"
                        showValue={false}
                        showMarkers={false}
                        trackLength={250}
                        trackShape="rounded"
                        trackThickness={10}
                        max={100}
                        disabled={true}
                        disabledColor="#dff5f3"
                      />
                    </div>
                    <span>{brainStrength.memory}%</span>
                  </div>
                  <div className="slider">
                    {/* <ProgressBar
                    style={{
                      height: '15px',
                      width: '80%',
                      borderRadius: '10px',
                    }}
                    striped
                    variant='primary'
                    min={0}
                    max={100}
                    now={brainStrength.thinking}
                  /> */}
                    <div>
                      <Slider
                        value={brainStrength.thinking}
                        handlerShape="rounded"
                        fillColor="#6bc3ba"
                        showValue={false}
                        showMarkers={false}
                        trackLength={250}
                        trackShape="rounded"
                        trackThickness={10}
                        max={100}
                        disabled={true}
                        disabledColor="#dff5f3"
                      />
                    </div>
                    <span>{brainStrength.thinking}%</span>
                  </div>
                  <div className="slider">
                    {/* <ProgressBar
                    style={{
                      height: '15px',
                      width: '80%',
                      borderRadius: '10px',
                    }}
                    striped
                    variant='primary'
                    min={0}
                    max={100}
                    now={brainStrength.vision}
                  /> */}
                    <div>
                      <Slider
                        value={brainStrength.vision}
                        handlerShape="rounded"
                        fillColor="#6bc3ba"
                        showValue={false}
                        showMarkers={false}
                        trackLength={250}
                        trackShape="rounded"
                        trackThickness={10}
                        max={100}
                        disabled={true}
                        disabledColor="#dff5f3"
                      />
                    </div>
                    <span>{brainStrength.vision}%</span>
                  </div>
                  <div className="slider">
                    {/* <ProgressBar
                    style={{
                      height: '15px',
                      width: '80%',
                      borderRadius: '10px',
                    }}
                    striped
                    variant='primary'
                    min={0}
                    max={100}
                    now={brainStrength.association}
                  /> */}
                    <div>
                      <Slider
                        value={brainStrength.association}
                        handlerShape="rounded"
                        fillColor="#6bc3ba"
                        showValue={false}
                        showMarkers={false}
                        trackLength={250}
                        trackShape="rounded"
                        trackThickness={10}
                        max={100}
                        disabled={true}
                        disabledColor="#dff5f3"
                      />
                    </div>
                    <span>{brainStrength.association}%</span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div style={{ maxWidth: "1100px", margin: "auto" }}>
        <Row>
          <Col lg={6}>
            <div className="dashboard-cards  stat-container">
              <div class="performance-heading">
                <div class="performance-content">
                  <h4>Performance Metrics</h4>
                </div>
              </div>

              <Bar
                plugins={[ChartDataLabels]}
                style={{ marginTop: "30px" }}
                data={{
                  labels: ["Attention", "Focus", "Interest", "Stress"],

                  datasets: [
                    {
                      barPercentage: 1,
                      barThickness: 80,
                      maxBarThickness: 60,
                      borderRadius: 8,

                      maintainAspectRatio: false,
                      data: selectData.pm || pmData,
                      backgroundColor: (context) => {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, 200);
                        gradient.addColorStop(0, "#eaf6f6");
                        gradient.addColorStop(1, "#e0f3f1");
                        return gradient;
                      },
                    },
                  ],
                }}
                options={{
                  plugins: {
                    tooltip: {
                      enabled: false,
                    },
                    legend: {
                      display: false,
                      labels: {
                        color: "rgb(255, 99, 132)",
                      },
                      backgroundColor: gradient,

                      title: {
                        display: false,
                        text: "Custom Chart Title",
                      },
                    },
                    datalabels: {
                      labels: {
                        value: {
                          formatter: (value, ctx) => {
                            return value;
                          },
                          color: "#3c726e",
                          display: true,
                          font: {
                            weight: "bold",
                            size: "20px",
                          },
                          anchor: "center",
                          offset: 0,
                        },
                      },
                    },
                  },

                  scales: {
                    x: {
                      grid: {
                        display: false,
                      },
                    },
                    y: {
                      type: "linear",
                      display: false,
                      position: "left",
                      grid: {
                        display: false,
                      },
                    },
                  },
                }}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="dashboard-cards  stat-container">
              <div class="performance-heading">
                <div class="performance-content">
                  <h4>Emotional Analysis</h4>
                </div>
              </div>
              <Bar
                style={{ marginTop: "30px" }}
                data={{
                  labels: ["Contempt", "Happy", "Relaxed", "Tired"],
                  datasets: [
                    {
                      axis: "y",
                      barPercentage: 0.5,
                      barThickness: 40,
                      maxBarThickness: 50,
                      borderRadius: 8,
                      minBarLength: 2,
                      data: selectData.emotData || emotData,
                      backgroundColor: (context) => {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, 200);
                        gradient.addColorStop(0, "#eaf6f6");
                        gradient.addColorStop(1, "#e0f3f1");
                        return gradient;
                      },
                    },
                  ],
                }}
                plugins={[ChartDataLabels]}
                options={{
                  indexAxis: "y",

                  plugins: {
                    tooltip: {
                      enabled: false,
                    },
                    legend: {
                      display: false,
                      labels: {
                        color: "rgb(255, 99, 132)",
                        position: "right",
                      },

                      title: {
                        display: false,
                        text: "Custom Chart Title",
                      },
                    },
                    datalabels: {
                      labels: {
                        value: {
                          formatter: (value, ctx) => {
                            return parseInt(value);
                          },
                          color: "#3c726e",
                          display: true,
                          font: {
                            weight: "bold",
                            size: "20px",
                          },
                          anchor: "center",
                          offset: 0,
                        },
                      },
                    },
                  },
                  scales: {
                    x: {
                      display: false,
                      grid: {
                        display: false,
                      },
                    },
                    y: {
                      display: true,
                      position: "left",
                      grid: {
                        display: false,
                      },
                    },
                  },
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Test;
