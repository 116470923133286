import React from 'react'
import { useState, useEffect } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import Backbtn from '../BackComp'
import Pagination from '../Pagination'
import PM from '../../dashboard/Pm'
import ReportAnalysis from '../../dashboard/ReporModal'
import * as Apiurl from '../../Apisurl'
import { Bar, getDatasetAtEvent } from 'react-chartjs-2'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import * as Currdate from '../../CurrDate'
import PmReport from './PmReport'
import EmotReport from './EmotReport'
import userJson from '../../Userjson'
import headersReq from '../../HeadersJson'
const ReportLayout = (props) => {
  const reqJson = headersReq()
  const [lgShow, setLgShow] = useState(false)
  const [emotDatad, setemotData] = useState([])
  const [error1, seterror1] = useState()
  const [performanceMatrix, setperformManceMatricsarr] = useState()
  let headingname = useParams()
  let modulename = useLocation().pathname.split('/')[2]
  let currdate = localStorage.getItem('edudate')

  let userData
  if (userJson() != {}) {
    userData = JSON.stringify(userJson())
  }
  console.log(userData)
  let userData1 = userJson()
  useEffect(() => {
    async function emotdata() {
      const response = await fetch(`${Apiurl.Fetchurl + 'emot_report'}`, {
        method: 'POST',
        headers: reqJson,
        body: userData,
      })
      const data = await response.json()
      return data
    }
    emotdata()
      .then((data) => {
        let datad = JSON.parse(data.replace(/'/g, '"'))
        let getData = datad.result.EmotionalAnalysis
        console.log(getData)
        if (getData.Status == 400) {
          seterror1(getData.text)
        } else {
          setemotData([getData.Contempt, getData.Happy, getData.Relaxed, getData.Tired])
        }

        console.log(emotDatad)
      })
      .catch((error) => {
        console.log('error')
      })
  }, [])

  useEffect(() => {
    async function pmData() {
      const response = await fetch(`${Apiurl.Fetchurl + 'pm_report'}`, {
        method: 'POST',
        headers: reqJson,
        body: userData,
      })
      const data = await response.json()
      return data
    }
    pmData()
      .then((data) => {
        let datad = JSON.parse(data.replace(/'/g, '"'))
        let getData = datad.result.PerformanceMetric
        if (getData.Status == 400) {
          localStorage.setItem('category', getData.text)
          console.log(getData.text)
          // setError(getData.text);
        } else {
          localStorage.removeItem('category')
          setperformManceMatricsarr([getData.Engagement, getData.Focus, getData.Interest, getData.Stress])
        }
      })
      .catch((error) => {
        console.log('error')
      })
  }, [])

  return (
    <>
      <Backbtn title={headingname.category} />
      <ul className='userInfo-container'>
        <li>Username:-{userData1.username}</li>
        <li>Module:-{userData1.modulename1}</li>
        <li>Category:-{userData1.modulename}</li>
        <li>Level:-{userData1.level}</li>
      </ul>
      <Container>
        <Row>
          <p class='error' style={error1 != '' ? { display: 'none' } : { display: 'block' }}>
            {error1}
          </p>
          <ul></ul>
          <Col md={5}>
            <ul></ul>
            <div className='dashboard-cards  stat-container'>
              <div class='performance-  heading'>
                <div class='performance-content'>
                  <h4>Performance Metrics</h4>
                </div>
              </div>
              <PmReport />
            </div>
          </Col>
          <Col md={1}></Col>
          <Col md={6}>
            <div className='dashboard-cards'>
              <h4>Detailed Emotional Analysis</h4>
              <p>Appropriate Name for Graph</p>
              <div className='Headset-msg'>
                <p>{localStorage.getItem('category')}</p>
              </div>
              <EmotReport />
            </div>
          </Col>
        </Row>
        <ReportAnalysis via={lgShow} setvia={setLgShow} />
      </Container>
    </>
  )
}

export default ReportLayout
