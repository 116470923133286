import react from "react";
import { Modal } from "react-bootstrap";
import ChartistGraph from "react-chartist";
import { useState } from "react";
import { useEffect, forwardRef, useImperativeHandle } from "react";
import * as Apiurl from "../Apisurl";
import { Row, Col } from "react-bootstrap";
import Chart from "chart.js/auto";
import { Bar, getDatasetAtEvent } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
const ReportModal = forwardRef((props, ref) => {
  return (
    <>
      <Modal
        className="report-modal"
        size="md"
        show={props.via}
        onHide={() => props.setvia(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Body className="p-0">
          <Row className="reportContainer">
            <Col md={6} className="p-4">
              <div class="card box-item">
                <div className="card-header">
                  <p>Alert</p>
                  <h4>86</h4>
                </div>
                <div className="absolutediv">
                  <h2>Guava</h2>
                  <h3>Banana</h3>
                  <h1>Apple</h1>
                  <h4>Avocado</h4>

                  <h5>Quinco</h5>
                  <h6>Acerola</h6>
                </div>
              </div>
            </Col>
            <Col md={6} className="p-4">
              <div class="card box-item">
                <div className="card-header">
                  <p>Happy</p>
                  <h4>86</h4>
                </div>
                <div className="absolutediv">
                  <h2>Guava</h2>
                  <h3>Banana</h3>
                  <h1>Apple</h1>
                  <h4>Avocado</h4>

                  <h5>Quinco</h5>
                  <h6>Acerola</h6>
                </div>
              </div>
            </Col>
            <Col md={6} className="p-4">
              <div class="card box-item">
                <div className="card-header">
                  <p>Bored</p>
                  <h4>86</h4>
                </div>
                <div className="absolutediv">
                  <h2>Guava</h2>
                  <h3>Banana</h3>
                  <h1>Apple</h1>
                  <h4>Avocado</h4>

                  <h5>Quinco</h5>
                  <h6>Acerola</h6>
                </div>
              </div>
            </Col>
            <Col md={6} className="p-4">
              <div class="card box-item">
                <div className="card-header">
                  <p>Relaxed</p>
                  <h4>86</h4>
                </div>
                <div className="absolutediv">
                  <h2>Guava</h2>
                  <h3>Banana</h3>

                  <h1>Apple</h1>
                  <h4>Avocado</h4>

                  <h5>Quinco</h5>
                  <h6>Acerola</h6>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
});

export default ReportModal;
