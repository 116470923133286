import { useEffect, useRef, useState } from 'react'
import Table from 'react-bootstrap/Table'
import { useLocation, useSearchParams } from 'react-router-dom'
import * as Apiurl from '../../Apisurl'
import headersReq from '../../HeadersJson'
import UserJson from '../../Userjson'

const Etable = () => {
  let loc = useLocation()
  const reqJson = headersReq()
  const [eddata, seteddata] = useState([])
  const [errors, seterror] = useState('')
  let tabeRef = useRef()

  let userData = UserJson()

  const searchParams = useSearchParams()[0]
  const id = searchParams.get('id')

  let value = {
    score: localStorage.getItem('score'),
    ans_text: localStorage.getItem('ans_text'),
    image: loc.state.image,
  }

  let result = JSON.stringify({
    ...userData,
    ...value,
  })

  useEffect(() => {
    async function userData1() {
      const response = await fetch(`${Apiurl.Fetchurl + 'manual_report_fc_mcq?serial_num=' + id}`, {
        headers: reqJson,
      })
      const responsedata = await response.json()
      return responsedata
    }
    // async function userData1() {
    //   const response = await fetch(`${Apiurl.Fetchurl + 'manual_report_fc'}`, {
    //     method: 'POST',
    //     headers: reqJson,
    //     body: JSON.stringify({ serial_num: id }),
    //   })
    //   const responsedata = await response.json()
    //   return responsedata
    // }
    userData1()
      .then((data) => {
        seteddata(data.result)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [id])

  if (eddata != undefined) {
    if (eddata.ans != undefined) {
      return (
        <>
          <Table className='result-table quizes_report_table' ref={tabeRef}>
            <thead>
              <tr>
                <th style={{ width: '60%' }}>Taught</th>
                <th>Score</th>
                <th>Additional Notes</th>
              </tr>
            </thead>
            <tbody>
              {eddata.ans.map((o, i) => {
                return (
                  <tr>
                    <td>
                      {userData.category.toUpperCase() == 'ACTION WORDS' ? (
                        <video width='200px' height='200px' controls>
                          <source src={o.image} type='video/mp4' />
                        </video>
                      ) : (
                        <img src={o.image} />
                      )}
                    </td>
                    <td>
                      <p>{o.score}</p>
                    </td>
                    <td>
                      <p>{o.ans_text}</p>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
          <p style={{ color: 'red' }}>{errors}</p>
        </>
      )
    }
  }
}

export default Etable
