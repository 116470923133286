import React from "react";

import { MDBIcon } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";

const Backbtn = (props) => {
    let navigate = useNavigate();

  return (
    <div
      className="back-container"
      onClick={() => navigate(-1)}
      style={{ "cursor": "pointer" }}
    >
      <MDBIcon fas icon="chevron-left" />
      <img src={require("../assets/images/maths.png")} alt="maths" />
      <h3>{props.title}</h3>
    </div>
  );
};
export default Backbtn;
