import { useLocation } from 'react-router-dom'

const UserJson = (res) => {
  let location = useLocation()
  let sendModulleData1 = JSON.parse(localStorage.getItem('sendModulleData'))

  if (location.state != null) {
    return {
      userid: sendModulleData1.user_id,
      username: location.state.username,
      category: location.state.category2 == undefined ? location.state.category : location.state.category2,
      level: location.state.level == '' ? 'level1' : location.state.level,
      date: location.state.date == undefined ? res : location.state.date,
      modulename: location.state.modulename2,
      modulename1: location.state.modulename3
        ? location.state.modulename3.replace(/\s+/g, '')
        : location.state.modulename3,
    }
  }
}

export default UserJson
