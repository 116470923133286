import react from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import socketio from "socket.io-client";
import * as Apiurl from "../Apisurl";
import { Modal } from "react-bootstrap";
import { SocketConfig } from "../SocketConfig";
const Focus = () => {
  const [focusNum, setfocusNum] = useState([]);
  const [show, setShow] = useState(false);
  const [action, setactiondata] = useState([]);
  const [message, setmessage] = useState("");
  let location = useLocation();

  console.log(location);
  // useEffect(() => {
  //   setInterval(() => {
  //     async function populateAdminView() {
  //       const response = await fetch("https://18.219.37.54/focus", {
  //         method: "POST",
  //         header: {
  //           "Access-Control-Allow-Origin": "*",
  //         },
  //       });
  //       const Admin = await response.json();
  //       return Admin;
  //     }
  //     populateAdminView()
  //       .then((data) => {
  //         console.log(data);
  //         setfocusNum(parseInt(data.focus));
  //         if (
  //           location.pathname == "/nema/build/colorgame" ||
  //           location.pathname == "/nema/build/maths"
  //         ) {
  //           if (data.focus < 35) {
  //             document.getElementsByTagName("body")[0].style =
  //               "background-color:rgb(0 0 0 / 28%)";
  //           }
  //         } else {
  //           document.getElementsByTagName("body")[0].style =
  //             "background-color:#fff";
  //         }
  //       })
  //       .catch((error) => {
  //         console.log("error");
  //       });
  //   }, 3000);
  // }, []);

  useEffect(() => {
    // setSocketconnect(socket);

  SocketConfig.on("focus_data", (data) => {
      console.log("Datta ", data);
      let parseddata = data.result;
      console.log(data.result);
      setfocusNum(data.result.focus);

      if (data.result.focus < 25) {
        setShow(true);
      } else {
        setShow(false);
      }
      if (data.result == "Please Wear the headset correctly") {
        setmessage(data.result);
      }
    });
  }, []);

  const styles = {
    focus: {
      boxShadow: "0px 22px 25.6438px 2px rgba(0, 0, 0, 0.05)",
      backgroundColor: "#fff",
      padding: "8px 10px",
      borderRadius: "8px",
      fontSize: "14px",
      position: "absolute",
      // width: "13%",
      top: "0px",
      right: "22px",
    },
    spantxt: {
      backgroundColor: "#21C167",
      height: "24px",
      width: "24px",
      borderRadius: "50%",
      display: "inline-block",
      textAlign: "center",
      lineHeight: "26px",
      fontSize: "13px",
      color: "#fff",
    },
  };
  if (focusNum != undefined) {
    return (
      <>
        <div style={styles.focus}>
          Focus : <span style={styles.spantxt}>{parseInt(focusNum)} </span>
        </div>
        <div className="overlayitem"></div>
        <>
          <Modal
            show={show}
            backdrop="static"
            className=" ex-modal "
            keyboard={false}
          >
            {/* <div className="new-modal">
          <h3>Select timer to Start</h3>
        </div> */}

            <Modal.Body className="text-center">
              <span>
                <i class="fas fa-exclamation"></i>
              </span>
              <h3>Your Focus is below optimal</h3>
              <p>
                We recommeneded that you takesome rest and come back later to
                reattempt this level.Test taken at low focus may lead to
                inaccurate result
              </p>
            </Modal.Body>
          </Modal>
        </>
      </>
    );
  }
  // else {
  //   return (
  //     <p
  //       style={{
  //         backgroundColor: "#21c16736",
  //         border: "2px solid #21c167",
  //         color: "#21c167",
  //         padding: "6px 11px",
  //         borderRadius:"6px"
  //       }}
  //     >
  //       {message}
  //     </p>
  //   );
  // }
};;
export default Focus;
