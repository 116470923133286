import React from 'react'
import { useState, useEffect } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import SelectModuleCard from '../school/SelectModuleCard'
import Activity from '../dashboard/Activity'
import school2 from '../assets/images/school2.svg'
import arrowright from '../assets/images/arrowright.svg'
import calendar from '../assets/images/calendar.svg'
import leftarrow from '../assets/images/arrowleft.svg'
import assignment from '../assets/images/assignment.svg'
import { Container } from 'react-bootstrap'
import * as Apiurl from '../Apisurl'

const Report = () => {
  const [active, setActive] = useState(0)
  let moduleCardjson = [
    {
      id: 1,
      title: 'School',
      buttontxt: 'View report',
      text: 'View report for task done under school section',
      link: 'schoolreport',
      images: school2,
    },

    {
      id: 2,
      title: "View all report",
      text: "View report for task done under school section",
      buttontxt: "View report",
      link: "all",
      images: assignment,
    },
  ]
  const showhidebtn = (e) => {
    console.log(e.target)
  }
  const getactiveid = (id) => {
    setActive(id)
  }
  return (
    <>
      <div className='content flex-grow-1'>
        <Container fluid className='option-main-container '>
          <div className='dashboard-heading'>
            <div class='content-container'>
              <h2>Reports</h2>
              <p>Check out you cumulative and test-wise reports here</p>
            </div>
            <Activity />
          </div>

          <div className='content-container meet-table-container report-category'>
            <div className='two-partition'>
              <p className='text-secondary'>Please select to proceed</p>
            </div>
            <div className='weekly-container'>
              <figure>
                <img src={calendar} alt='calendar' />
                <figcaption>This Week</figcaption>
                <div className='arrow-container'>
                  <img src={leftarrow} alt='rightarrow' />
                  <img src={arrowright} alt='arrowright' />
                </div>
              </figure>
            </div>
          </div>
          <Row className='option-container justify-content-center'>
            {moduleCardjson.map((item, i) => {
              return (
                <SelectModuleCard
                  images={item.images}
                  title={item.title}
                  buttontxt={item.buttontxt}
                  link={item.link}
                  key={i}
                  text={item.text}
                  btnvia={true}
                  onClick={() => getactiveid(item.id)}
                  isActive={active === item.id}
                />
              )
            })}
          </Row>
        </Container>
      </div>
    </>
  )
}
export default Report
