import React, { useEffect, useState } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { MdClose } from 'react-icons/md'
import CustomDropzone from '../CustomDropzone'
import '../steps.css'
import * as API from '../../../Apisurl'

const StepFourA = ({ data, setData }) => {
  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState([])

  useEffect(() => {
    // Revoke the data uris to avoid memory leaks
    return () => {
      files.map((file) => URL.revokeObjectURL(file.image))
    }
  }, [files])

  const handleChange = (id, name) => {
    setFiles((prevData) => prevData.map((data, idx) => (idx === id ? { ...data, name: name } : data)))
  }

  const handleRemove = (id) => {
    setFiles((prevData) => prevData.filter((file, idx) => idx !== id))
  }

  const upload = async (e) => {
    if (!files?.length) return

    try {
      setLoading(true)

      const formData = new FormData()
      formData.append('user_id', sessionStorage.getItem('session'))
      formData.append('folder_type', 'flashcard')
      files.map((file) => formData.append('file', file.image))

      const response = await fetch(`${API.Fetchurl}file_upload_1`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('session'),
        },
      })

      const data = await response.json()
      let imageURLS = data.file_path

      let updated = files.map((file, i) => [imageURLS[i], file.name])
      setData(updated)
      setFiles([])

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  return (
    <Row>
      <Col>
        <h4 className='mb-3'>Add Photos for Flashcard</h4>
        <div className='d-flex gap-1 mb-4'>
          <CustomDropzone setData={setFiles} isMultiple={true} />
          <button
            onClick={upload}
            disabled={!files.length || loading}
            type='button'
            className='d-flex justify-content-center align-items-center py-1 px-3 border-0 rounded-3 text-white'
            style={{ backgroundColor: 'var(--green-color)' }}
          >
            {loading ? <Spinner animation='border' /> : 'Upload'}
          </button>
        </div>
        <div className='d-flex flex-column gap-2'>
          <div className='d-flex align-items-center justify-content-between'>
            <h4>Preview</h4>
            <button
              onClick={() => setFiles([])}
              type='button'
              className='d-flex justify-content-center align-items-center py-1 px-3 rounded-pill border border-2 text-secondary'
              style={{ backgroundColor: 'white', fontSize: '12px', fontWeight: 'bold' }}
            >
              Remove all
            </button>
          </div>
          {files.length > 0 ? (
            <div className='d-flex flex-wrap gap-3'>
              {files?.map((file, i) => (
                <div key={i} className='preview-flashcard'>
                  <div className='delete-btn shadow' onClick={() => handleRemove(i)}>
                    <MdClose />
                  </div>
                  <img src={URL.createObjectURL(file.image)} alt='flashcard-images' />
                  <input
                    type='text'
                    required
                    name='name'
                    value={file.name}
                    onChange={(e) => handleChange(i, e.target.value)}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className='d-flex flex-wrap gap-3'>
              {data?.map((d, i) => (
                <div key={i} className='preview-flashcard'>
                  <img src={d[0]} alt='flashcard-images' />
                  <input type='text' readOnly name='name' value={d[1]} />
                </div>
              ))}
            </div>
          )}
        </div>
      </Col>
    </Row>
  )
}

export default StepFourA
