import { useState, useRef } from "react";
import StartBtn from "./StartBtn";
import { useContext } from "react";
import Context from "../Context";

const mimeType = 'video/webm; codecs="opus,vp8"';

const ScreenRecorder = (props) => {
  const context = useContext(Context);
  const {
    pause,
    timeLeft,
    restart,
    start,
    setTimeLeft,
    screenURL,
    setScreenURL,
  } = context;
  const [permission, setPermission] = useState(false);
  const [showContent, setShowContent] = useState(true);

  const mediaRecorder = useRef(null);

  const [recordingStatus, setRecordingStatus] = useState("inactive");

  const [stream, setStream] = useState(null);

  const [recordedVideo, setRecordedVideo] = useState(null);

  const [videoChunks, setVideoChunks] = useState([]);

  const liveVideoFeed = useRef(null);

  const getCameraPermission = async () => {
    setRecordedVideo(null);
    setShowContent(false);

    //get video and audio permissions and then stream the result media stream to the videoSrc variable
    if ("MediaRecorder" in window) {
      try {
        const videoStream = await navigator.mediaDevices.getDisplayMedia({
          audio: false,
          video: true,
        });

        setPermission(true);
        setStream(videoStream);
        startRecording(videoStream);

        // alert(timeLeft);
        //set videostream to live feed player
        liveVideoFeed.current.srcObject = videoStream;
      } catch (err) {
        alert(err.message);
      }
    } else {
      alert("The MediaRecorder API is not supported in your browser.");
    }
  };

  const startRecording = async (stream) => {
    props.recordFn();
    start();
    setRecordingStatus("recording");

    const media = new MediaRecorder(stream, { mimeType });

    mediaRecorder.current = media;

    mediaRecorder.current.start();

    let localVideoChunks = [];

    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === "undefined") return;
      if (event.data.size === 0) return;
      localVideoChunks.push(event.data);
    };
    setVideoChunks(localVideoChunks);
  };

  const stopRecording = async () => {
    pause();
    setPermission(false);
    setRecordingStatus("inactive");
    mediaRecorder.current.stop();

    mediaRecorder.current.onstop = () => {
      const videoBlob = new Blob(videoChunks, { type: mimeType });
      const videoUrl = URL.createObjectURL(videoBlob);

      setRecordedVideo(videoUrl);
      setVideoChunks([]);
      props.stopFn();

      setTimeLeft(false);
    };
  };

  return (
    <div>
      <div className="video-player">
        {showContent ? (
          <div className="video-content">
            <h3>Your screen appears here</h3>
            <svg
              height="100"
              width="100"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM15.5963 10.3318C16.8872 11.0694 16.8872 12.9307 15.5963 13.6683L11.154 16.2068C9.9715 16.8825 8.5002 16.0287 8.5002 14.6667L8.5002 9.33339C8.5002 7.97146 9.9715 7.11762 11.154 7.79333L15.5963 10.3318Z"
                  fill="#323232"
                ></path>{" "}
              </g>
            </svg>
          </div>
        ) : (
          ""
        )}
        {!recordedVideo && !screenURL ? (
          <video
            ref={liveVideoFeed}
            autoPlay
            className="live-player w-100"
          ></video>
        ) : null}
        {recordedVideo || screenURL ? (
          <div className="recorded-player">
            <video
              className="recorded  w-100"
              src={screenURL || recordedVideo}
              controls
            ></video>
            <a download href={recordedVideo}>
              Download Recording
            </a>
          </div>
        ) : null}
      </div>
      {/* <button onClick={stopRecording}>stop</button> */}
      <StartBtn
        permission={permission}
        getCameraPermission={getCameraPermission}
        stopBtn={stopRecording}
      />
    </div>
  );
};

export default ScreenRecorder;
