import react from 'react'
import { Row, Container, Col } from 'react-bootstrap'
import backbtn from '../../../assets/images/backbtn.svg'
import { useEffect } from 'react'
import Draggable, { DraggableCore } from 'react-draggable' // Both at the same time
import { useState } from 'react'
import ChartistGraph from 'react-chartist'
import { NavLink } from 'react-router-dom'
import refreshbtn from '../../../assets/images/refresh.svg'
import Focus from '../../../includes/Focus'
import * as Apiurl from '../../../Apisurl'
import StartModal from '../../SelectgameStart'
import Progress from '../Testprogrsscom'
import { Bar, getDatasetAtEvent } from 'react-chartjs-2'
import { Form } from 'react-bootstrap'
import { SocketConfig } from '../../../SocketConfig'

import 'chartjs-plugin-datalabels'

const Action = () => {
  const [left, setleft] = useState(false)
  const [play1, setplay1] = useState(false)
  const [timer, setTimer] = useState()
  const [showtimer1, setshowtimer1] = useState(false)
  const [right, setright] = useState(false)
  const [up, setup] = useState(false)
  const [down, setdown] = useState(false)
  const [teethSmile, setteethSmile] = useState(false)
  const [blinkeye, setblinkeye] = useState(false)
  const [smilestat, setsmilestate] = useState('')
  const [callday, setcallday] = useState(1)
  const [pm_data, setpm_data] = useState([10, 30, 40, 50, 60])
  const [clenchstat, setclenchstat] = useState('')
  const [page, setPage] = useState(0)
  const [score, setScore] = useState(null)
  let initialArray = []
  const [theArray, setTheArray] = useState(initialArray)

  let emojiquestion = {
    level1: [
      {
        emoji: require('../../../assets/images/left.png'),
        figcaption: 'LEFT',
      },
      {
        emoji: require('../../../assets/images/right.png'),
        figcaption: 'RIGHT',
      },
      {
        emoji: require('../../../assets/images/up.png'),
        figcaption: 'UP',
      },
      {
        emoji: '../../../assets/images/down.png',
        figcaption: 'DOWN',
      },
    ],
  }

  const [callDelay1, setCalldelay1] = useState(0)
  const handleChange = (e) => {
    setCalldelay1(e.target.value)
    setplay1(true)
  }
  const startCountDown = (e) => {
    let timeleft = e.target.value
    let downloadTimer = setInterval(function() {
      timeleft--
      setTimer(timeleft)
      if (timeleft <= 0) {
        clearInterval(downloadTimer)
        setTimer(0)
        nextQuestion('')
      }
    }, 1000)
  }

  function nextQuestion(e) {
    let checkanswer = e

    if (emojiquestion.level1[page].figcaption.toUpperCase() == checkanswer) {
      // setTheArray((oldArray) => [...oldArray, "correct"]);
      setScore((prev) => prev + 1)
      setPage((prev) => {
        if (prev === emojiquestion.level1.length - 1) {
          return 0
        } else {
          return prev + 1
        }
      })
    } else {
      // setTheArray((oldArray) => [...oldArray, "incorrect"]);
      setPage((prev) => {
        if (prev === emojiquestion.level1.length - 1) {
          return 0
        } else {
          return prev + 1
        }
      })
    }
  }
  useEffect(() => {
    SocketConfig.on('action_data', (data) => {
      console.log('Datta ', data)
      let parseddata = data.result

      console.log(parseddata)
      nextQuestion(parseddata)
      switch (parseddata.action) {
        case 'LEFT':
          setleft(true)

          break
        case 'RIGHT':
          setright(true)
          break
        case 'UP':
          setup(true)
          break
        case 'DOWN':
          setdown(true)
          break
        // case "teethSmile":setteethSmile(true);
        // break;

        default:
          setleft(false)
          setright(false)
          setup(false)
          setdown(false)
      }
    })
    //
  }, [])

  function nextQuestion(e) {
    let checkanswer = e == false ? '' : e.target.innerText

    if (emojiquestion.level1[page].figcaption.toLowerCase() == checkanswer) {
      // setTheArray((oldArray) => [...oldArray, "correct"]);
      setScore((prev) => prev + 1)
      setPage((prev) => prev + 1)
    } else {
      // setTheArray((oldArray) => [...oldArray, "incorrect"]);
      setPage((prev) => prev + 1)
    }
  }
  return (
    <Container fluid>
      <Row className=' colors-main-container'>
        <Col md={6}>
          <figure>
            <img src={emojiquestion.level1[page].emoji} />
            <figcaption>{emojiquestion.level1[page].figcaption}</figcaption>
          </figure>
        </Col>
        <Col md={6}>
          <div className=''>
            <Focus />
            <Bar
              style={{ marginTop: '20px' }}
              data={{
                labels: ['Attention', 'Focus', 'Interest', 'Relaxation'],

                datasets: [
                  {
                    barPercentage: 1,
                    barThickness: 80,
                    maxBarThickness: 60,
                    borderRadius: 8,

                    maintainAspectRatio: false,
                    data: [20, 30, 40, 100],
                    backgroundColor: (context) => {
                      const ctx = context.chart.ctx
                      const gradient = ctx.createLinearGradient(0, 0, 0, 200)
                      gradient.addColorStop(0, '#eaf6f6')
                      gradient.addColorStop(1, '#e0f3f1')
                      return gradient
                    },
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                    labels: {
                      color: 'rgb(255, 99, 132)',
                    },

                    title: {
                      display: false,
                      text: 'Custom Chart Title',
                    },
                  },
                },

                scales: {
                  x: {
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    type: 'linear',
                    display: false,
                    position: 'left',
                    grid: {
                      display: false,
                    },
                  },
                },
              }}
            />
          </div>
        </Col>
      </Row>

      <Form.Select aria-label='Default select example' onChange={startCountDown}>
        <option value='1'>1 sec</option>
        <option value='2'>2 sec</option>
        <option value='3'>3 sec</option>
        <option value='4'>4 sec</option>

        <option value='5'>5 sec</option>
        <option value='6'>6 sec</option>
        <option value='7'>7 sec</option>
        <option value='8'>8 sec</option>
        <option value='9'>9 sec</option>

        <option value='10'>10 sec</option>
      </Form.Select>
      <p>00:{timer}</p>

      <div className='activity-listing'>
        <ul>
          <li>
            <Progress json={emojiquestion.level1} currindex={page} getarray={theArray} />
          </li>
          <li className='refresh-btn'>
            <NavLink to='/school/game/level/colorresult'>
              <button>
                <img src={refreshbtn} alt='refresh' />
                Start Another Level
              </button>
            </NavLink>
            <button>
              <img src={refreshbtn} alt='refresh' />
              Start Over
            </button>
          </li>
        </ul>
      </div>
      <StartModal calldelay={setcallday} socket={SocketConfig} type={['action', 'focus']} calldaynum={callday} />
    </Container>
  )
}
export default Action
