import React from 'react'
import { Col, Row } from 'react-bootstrap'
import user from '../assets/images/user_icon.svg'
import group from '../assets/images/group_user_icon.svg'
import { useNavigate } from 'react-router-dom'

const SelectionType = () => {
  const navigate = useNavigate()
  const role = localStorage.getItem('role')
  return (
    <>
      <div className='content module-section'>
        <div id='google_translate_element'></div>
        <h2>Please select to proceed</h2>
        <Row className='language-row  justify-content-center'>
          <Col md={4}>
            <div className='Card1'>
              <div className='cardlang'>
                <img src={user} alt='' />
                <div class='card-body'>
                  <h5 class='card-title notranslate'>{role === 'hr' || role === 'employee' ? 'User' : 'Child'}</h5>
                  <button
                    class='common-btn module-edit'
                    value='en'
                    id='english'
                    onClick={() => {
                      localStorage.setItem('isGroup', false)
                      navigate('/children/add', {
                        state: { pageid: role === 'hr' || role === 'employee' || role === 'admin' ? 3 : 1 },
                      })
                    }}
                  >
                    Select
                  </button>
                </div>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className='Card1'>
              <div className='cardlang'>
                <img src={group} alt='' />
                <div class='card-body'>
                  <h5 class='card-title notranslate'>Group</h5>
                  <button
                    class='common-btn module-edit'
                    id='Group'
                    onClick={() => {
                      localStorage.setItem('isGroup', true)
                      navigate('/children/add/group', {
                        state: { pageid: role === 'hr' || role === 'employee' || role === 'admin' ? 3 : 1 },
                      })
                    }}
                  >
                    Select
                  </button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default SelectionType
