import React, { useContext, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import DataTableExtensions from 'react-data-table-component-extensions'
import * as API from '../../Apisurl'
import Context from '../../Context'
import { Container } from 'react-bootstrap'
import Header from '../../profile/Header'
import Activity from '../../dashboard/Activity'

const Questionarie = () => {
  const { selectValue } = useContext(Context)
  const [reports, setReports] = useState()

  useEffect(() => {
    const getReports = async () => {
      try {
        const response = await fetch(`${API.Fetchurl}questionaire?user_id=${selectValue.user_id}`, {
          method: 'GET',
          headers: { Authorization: `Bearer ${sessionStorage.getItem('session')}` },
        })
        const data = await response.json()

        let res = []
        data.ques.forEach((d) => {
          if (d[1] !== 'TEST0') {
            res.push({ user_id: d[0], test: d[1], ans: d[2], result: d[3], date: d[4] })
          }
        })
        setReports(res)
      } catch (error) {
        console.log(error)
      }
    }

    getReports()
  }, [selectValue.user_id])

  const isJson = (res) => {
    try {
      return JSON.parse(res)?.result
    } catch (e) {
      return res
    }
  }

  const tableData = {
    columns: [
      {
        name: 'User',
        selector: (row) => row.user_id,
      },
      {
        name: 'Test',
        selector: (row) => row.test,
        sortable: true,
      },
      {
        name: 'Date',
        selector: (row) => row.date,
        sortable: true,
      },
      {
        name: 'Result',
        selector: (row) => {
          return isJson(row.result)
        },
      },
    ],
    data: reports,
  }

  return (
    <div className='content flex-grow-1'>
      <Container fluid className='report-tab'>
        <div className='dashboard-heading'>
          <div class='content-container'>
            <h2>Questionarie Report</h2>
            <p>The answer to your questions</p>
          </div>
          <Activity />
        </div>
        <Header />

        <DataTableExtensions {...tableData}>
          <DataTable pagination customStyles={customStyles} highlightOnHover />
        </DataTableExtensions>
      </Container>
    </div>
  )
}

export default Questionarie

const customStyles = {
  rows: {
    style: {
      minHeight: '80px',
    },
  },
  cells: {
    style: {
      fontSize: '14px',
      color: '#414141',
    },
  },
}
