import React, { useContext, useEffect, useState } from 'react'
import logo from '../assets/images/logo.svg'
import * as API from '../Apisurl'
import Context from '../Context'
import { Button } from 'react-bootstrap'
import { FaLock } from 'react-icons/fa'
import jwtDecode from 'jwt-decode'
import swal from 'sweetalert'

const PayButton = ({ service, month }) => {
  const { userServices, setUserServices } = useContext(Context)
  const [price, setPrice] = useState(null)

  const user = sessionStorage.getItem('session') && jwtDecode(sessionStorage.getItem('session'))
  const user_id = user.user_id
  const username = sessionStorage.getItem('sessionname')
  const role = localStorage.getItem('role')

  const getServiceNames = async () => {
    try {
      const res = await fetch(API.Fetchurl + 'plan_prices', {
        method: 'POST',
        body: JSON.stringify({ id_plan: [parseInt(service)], duration: month }),
      })
      const data = await res.json()

      const serviceNames = data.output.map((d) => d.service_name).join(', ')
      return serviceNames
    } catch (error) {
      throw new Error(error.message)
    }
  }

  const sendInvoice = async (data) => {
    const item = await getServiceNames()
    const body = {
      name: username,
      email: user.email_id,
      phoneNumber: user.phone_number,
      address: 'online',
      boookingDate: '',
      item: item,
      qty: 1,
      price: price,
      paid_via: data.method.method,
    }

    await fetch(API.Fetchurl + 'invoice_mailers', {
      method: 'POST',
      body: JSON.stringify(body),
    })
  }

  const initiatePayment = async () => {
    try {
      const response = await fetch(API.Fetchurl + 'order', {
        method: 'POST',
        body: JSON.stringify({
          service_id: [service],
          month: month,
          person_type: role,
        }),
      })
      const data = await response.json()
      openPaymentGateway(data.Success)
    } catch (error) {
      console.error(error)
      throw new Error('Error in initiatePayment')
    }
  }

  const openPaymentGateway = (paymentData) => {
    const paymentOptions = {
      key: process.env.REACT_APP_RAZORPAY,
      amount: paymentData.amount,
      currency: paymentData.currency,
      name: 'NEMA AI',
      description: 'Test Transaction',
      image: logo,
      order_id: paymentData.id,

      handler: async (response) => {
        try {
          const verificationResponse = await fetch(API.Fetchurl + 'verify', {
            method: 'POST',
            body: JSON.stringify({
              ...response,
              service_id: [service],
              month: month,
              person_type: role,
            }),
          })

          if (!verificationResponse.ok) throw new Error('Something went wrong')

          const verificationData = await verificationResponse.json()
          sessionStorage.setItem('isPaymentSuccessful', 'true')
          sessionStorage.setItem('responseFromOrder', JSON.stringify(response))
          sessionStorage.setItem('paymentResponse', JSON.stringify(verificationData))

          await verifyPayment({
            ...response,
            service_id: JSON.stringify([service]),
            month: month,
            person_type: role,
            payment_id: verificationData.payment_id,
            method: JSON.stringify(verificationData.method),
            user_id,
          })

          sessionStorage.removeItem('isPaymentSuccessful')
          sessionStorage.removeItem('responseFromOrder')
          sessionStorage.removeItem('paymentResponse')

          let updatedServices = [...userServices, service]

          setUserServices(updatedServices)
          sessionStorage.setItem('services', JSON.stringify(updatedServices))
          await sendInvoice(verificationData)
        } catch (error) {
          console.error(error)
        }
      },
      theme: { color: '#3399cc' },
    }
    const razorpayInstance = new window.Razorpay(paymentOptions)
    razorpayInstance.open()
  }

  const verifyPayment = async (paymentDetails) => {
    try {
      const res = await fetch(API.Fetchurl + 'verify?' + new URLSearchParams(paymentDetails).toString())
      if (!res.ok) throw new Error('Something went wrong')
      return res
    } catch (error) {
      console.error(error)
      throw new Error('Error in verifyPayment')
    }
  }

  const processPay = async () => {
    const isPaymentSuccessful = sessionStorage.getItem('isPaymentSuccessful') === 'true'
    const paymentResponse = JSON.parse(sessionStorage.getItem('paymentResponse'))
    const responseFromOrder = JSON.parse(sessionStorage.getItem('responseFromOrder'))

    if (isPaymentSuccessful) {
      await verifyPayment({
        ...responseFromOrder,
        service_id: JSON.stringify([service]),
        month: month,
        person_type: role,
        payment_id: paymentResponse.payment_id,
        method: JSON.stringify(paymentResponse.method),
        user_id,
      })
      sessionStorage.removeItem('isPaymentSuccessful')
      sessionStorage.removeItem('responseFromOrder')
      sessionStorage.removeItem('paymentResponse')

      let updatedServices = [...userServices, service]

      setUserServices(updatedServices)
      sessionStorage.setItem('services', JSON.stringify(updatedServices))
      await sendInvoice(paymentResponse)
    } else {
      try {
        await initiatePayment()
      } catch (error) {
        console.error(error)
        throw new Error('Error in processSignup')
      }
    }
  }

  useEffect(() => {
    const getServicePrice = async () => {
      try {
        const response = await fetch(API.Fetchurl + 'order_payment', {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('session'),
          },
          body: JSON.stringify({
            service_id: [service],
            month: month.toString(),
          }),
        })

        if (!response.ok) throw new Error('Something Went Wrong...')

        const data = await response.json()
        setPrice(data.price)
      } catch (error) {
        console.log(error)
      }
    }

    getServicePrice()
  }, [month, service])

  return (
    <Button
      variant='primary'
      className='common-btn d-flex align-items-center gap-2 px-4 fs-4 m-0'
      type='button'
      onClick={processPay}
    >
      Pay ₹{price}
      {/* <FaLock className='text-white' /> */}
    </Button>
  )
}

export default PayButton
