const test1 = [
  {
    q_no: 1,
    choices: [
      'I have to have neat, orderly surrounding to work in. (A)',
      'I have to have comfortable surroundings to work in. (B)',
    ],
  },

  {
    q_no: 2,
    choices: [
      'Deadlines and schedules make my work easier to do. (A)',
      'Deadlines and schedules interfere with the way I work. (B)',
    ],
  },

  {
    q_no: 3,
    choices: [
      'I’m good at analyzing all the different parts of a problem. (A)',
      'I’m good at thinking of many different solutions to a problem. (B)',
    ],
  },

  {
    q_no: 4,
    choices: ['I’m proud of the creativity of my work. (A)', 'I’m proud of the thoroughness of my work. (B)'],
  },

  {
    q_no: 5,
    choices: [
      'When I take a “break” I relax and do nothing. (A)',
      'When I take a “break” I find something different to do. (B)',
    ],
  },

  {
    q_no: 6,
    choices: ['I don’t think about the time when I work. (A)', 'I plan out my time when doing work. (B)'],
  },

  {
    q_no: 7,
    choices: ['I will follow proven ways of doing my jobs. (A)', 'I will find my own way of doing new jobs. (B)'],
  },

  {
    q_no: 8,
    choices: [
      'I prefer to finish one job before starting a new one. (A)',
      'I prefer to be working on many jobs at the same time. (B)',
    ],
  },

  {
    q_no: 9,
    choices: ['I can usually analyze what should happen next. (A)', 'I can usually sense what should happen next. (B)'],
  },

  {
    q_no: 10,
    choices: [
      'I do easy things first and save important things for later. (A)',
      'I do important things first and other things later. (B)',
    ],
  },

  {
    q_no: 11,
    choices: ['Other people think I’m unorganized. (A)', 'Other people think I organize things well. (B)'],
  },

  {
    q_no: 12,
    choices: [
      'I arrange objects so they are off- center and angled. (A)',
      'I arrange objects so they are centered and in line. (B)',
    ],
  },

  {
    q_no: 13,
    choices: ['I follow an outline when I write a paper. (A)', 'I don’t use an outline or change it when I write. (B)'],
  },

  {
    q_no: 14,
    choices: [
      'With a difficult decision I follow what I know. (A)',
      'With a difficult decision I follow my feelings. (B)',
    ],
  },

  {
    q_no: 15,
    choices: [
      'I choicestion new ideas more than other people do. (A)',
      'I agree with new ideas before other people do. (B)',
    ],
  },

  {
    q_no: 16,
    choices: ['I change the way I do a job, for variety. (A)', 'When one way works well, I don’t change it. (B)'],
  },

  {
    q_no: 17,
    choices: ['I’m usually late. (A)', 'I’m usually early. (B)'],
  },

  {
    q_no: 18,
    choices: ['Where I put things depends on what I’m doing. (A)', 'I put each thing in a particular place. (B)'],
  },

  {
    q_no: 19,
    choices: ['I’m very consistent. (A)', 'I’m very spontaneous. (B)'],
  },

  {
    q_no: 20,
    choices: [
      'I arrange clothes in my closet by type, length, season, etc. (A)',
      'I don’t put clothes in my closet in any particular order. (B)',
    ],
  },
]

const test2 = [
  {
    q_no: 1,
    choices: ['Major in Logic. (A)', 'Write a letter. (B)', 'Fix things at home. (C)', 'Major in art. (D)'],
  },
  {
    q_no: 2,
    choices: [
      'Be a movie critic. (A)',
      'Learn new words . (B)',
      'Improve your skills in a game. (C)',
      'Create a new toy. (D)',
    ],
  },
  {
    q_no: 3,
    choices: [
      'Improve your strategy in a game. (A)',
      'Remember people’s names . (B)',
      'Engage in sports . (C)',
      'Play an instrument by ear. (D)',
    ],
  },
  {
    q_no: 4,
    choices: [
      'Review a book. (A)',
      'Write for a magazine . (B)',
      'Build new shelves at home. (C)',
      'Draw a landscape or seascape. (D)',
    ],
  },
  {
    q_no: 5,
    choices: [
      'Analyze market trends. (A)',
      'Write a movie script . (B)',
      'Do carpentry work . (C)',
      'Imagine a new play. (D)',
    ],
  },
  {
    q_no: 6,
    choices: [
      'Analyze management practices. (A)',
      'Locate words in a dictionary. (B)',
      'Put jigsaw puzzles together . (C)',
      'Paint in oil. (D)',
    ],
  },

  {
    q_no: 7,
    choices: [
      'Be in charge of computer programming. (A)',
      'Study word origins and meaning. (B)',
      'Putter in the yard. (C)',
      'Invent a new gadget. (D)',
    ],
  },

  {
    q_no: 8,
    choices: [
      'Analyze production costs. (A)',
      'Describe a new product in words. (B)',
      'Sell a new product on the market. (C)',
      'Draw a picture of a new product. (D)',
    ],
  },

  {
    q_no: 9,
    choices: [
      'Explain the logic of a theory. (A)',
      'Be a copy writer for ads. (B)',
      'Work with wood and clay. (C)',
      'Invent a story. (D)',
    ],
  },

  {
    q_no: 10,
    choices: [
      'Be a comparison shopper. (A)',
      'Read about famous men and women. (B)',
      'Run a traffic control tower. (C)',
      'Mold with clay and putty. (D)',
    ],
  },

  {
    q_no: 11,
    choices: [
      'Analyze your budget. (A)',
      'Study literature. (B)',
      'Visualize and re -arrange furniture. (C)',
      'Be an artist. (D)',
    ],
  },
  {
    q_no: 12,
    choices: [
      'Plan a trip and make a budget. (A)',
      'Write a novel. (B)',
      'Build a house or shack. (C)',
      'Make crafts your hobby. (D)',
    ],
  },
]

const test0 = [
  {
    q_no: 1,
    ques: 'At a party do you ',
    choices: ['Interact with many, including strangers (A)', 'Interact with a few people you know well (B)'],
  },
  {
    q_no: 2,
    ques: 'Are you more',
    choices: ['Realistic than speculative (A)', 'Speculative than realistic (B)'],
  },
  {
    q_no: 3,
    ques: 'Is it worse to',
    choices: ['Be someone who likes variety and new ideas (A)', 'Be someone who likes predictable routine (B)'],
  },
  {
    q_no: 4,
    ques: 'Are you more impressed by',
    choices: ['Principles (A)', 'Emotions (B)'],
  },
  {
    q_no: 5,
    ques: 'Are you more drawn towards the',
    choices: ['Convincing (A)', 'Touching (B)'],
  },
  {
    q_no: 6,
    ques: 'Do you prefer to work',
    choices: ['On a schedule with a deadlines (A)', 'Whenever you are inspired to work (B)'],
  },
  {
    q_no: 7,
    ques: 'Do you tend to chose',
    choices: ['Rather carefully (A)', 'Somewhat impulsively (B)'],
  },
  {
    q_no: 8,
    ques: 'At parties do you',
    choices: ['Stay late, with increasing energy (A)', 'Leave early, with decreasing energy (B)'],
  },
  {
    q_no: 9,
    ques: 'Are you more attracted to',
    choices: ['Practical people (A)', 'Imaginative people (B)'],
  },
  {
    q_no: 10,
    ques: 'Are you more interested in',
    choices: ['What is actual (A)', 'What is possible (B)'],
  },
  {
    q_no: 11,
    ques: 'In judging others are you more swayed by',
    choices: ['Laws than circumstances (A)', 'Circumstances than laws (B)'],
  },
  {
    q_no: 12,
    ques: 'In approaching others is your inclination to be somewhat',
    choices: ['Objective (A)', 'Personal (B)'],
  },
  {
    q_no: 13,
    ques: 'Are you more',
    choices: ['Punctual (A)', 'Leisurely (B)'],
  },
  {
    q_no: 14,
    ques: 'Does it bother you having things',
    choices: ['Incomplete (A)', 'Completed (B)'],
  },
  {
    q_no: 15,
    ques: 'In your social groups do you',
    choices: ['Keep abreast of other’s happenings (A)', 'Get behind on the news (B)'],
  },
  {
    q_no: 16,
    ques: 'In doing ordinary things are you more likely to',
    choices: ['Do it the usual way (A)', 'Do it your own way (B)'],
  },
  {
    q_no: 17,
    ques: 'Writers should',
    choices: ['“say what they mean and mean what they say” (A)', 'Express things more by use of analogy (B)'],
  },
  {
    q_no: 18,
    ques: 'Which appeals to you more',
    choices: ['Consistency of thought (A)', 'Harmonious human relationships (B)'],
  },
  {
    q_no: 19,
    ques: 'Are you more comfortable in making',
    choices: ['Logical judgments (A)', 'Value judgments (B)'],
  },
  {
    q_no: 20,
    ques: 'Do you want things',
    choices: ['Settled and decided (A)', 'Unsettled and undecided (B)'],
  },
  {
    q_no: 21,
    ques: 'Would you say you are more',
    choices: ['Serious and determined (A)', 'Easy-going (B)'],
  },
  {
    q_no: 22,
    ques: 'In phoning do you',
    choices: ['Rarely think about what you will say (A)', 'Rehearse what you’ll say (B)'],
  },
  {
    q_no: 23,
    ques: 'Facts',
    choices: ['Are valuable in themselves (A)', 'Are useful because they illustrate principles (B)'],
  },
  {
    q_no: 24,
    ques: 'Are visionaries',
    choices: ['Somewhat annoying (A)', 'Rather fascinating (B)'],
  },
  {
    q_no: 25,
    ques: 'Are you more often',
    choices: ['A cool-headed person (A)', 'A warm-hearted person (B)'],
  },
  {
    q_no: 26,
    ques: 'Is it worse to be',
    choices: ['Unjust (A)', 'Without mercy for others (B)'],
  },
  {
    q_no: 27,
    ques: 'Should one usually let events occur',
    choices: ['By careful selection and choice (A)', 'Randomly and by chance (B)'],
  },
  {
    q_no: 28,
    ques: 'Do you feel better about',
    choices: ['Having purchased something (A)', 'Having the option to buy something (B)'],
  },
  {
    q_no: 29,
    ques: 'In company do you',
    choices: ['Initiate the conversation (A)', 'Wait to be approached (B)'],
  },
  {
    q_no: 30,
    ques: 'Common sense is',
    choices: ['Rarely mistaken (A)', 'Frequently mistaken and unreliable (B)'],
  },
  {
    q_no: 31,
    ques: 'Children often do not',
    choices: ['Make themselves useful enough (A)', 'Exercise their fantasy enough (B)'],
  },
  {
    q_no: 32,
    ques: 'In making decision do you feel more comfortable with',
    choices: ['Standards (A)', 'Feelings (B)'],
  },
  {
    q_no: 33,
    ques: 'Are you more',
    choices: ['Firm than gentles (A)', 'Gentle than firm (B)'],
  },
  {
    q_no: 34,
    ques: 'Which is more admirable:',
    choices: ['The ability to organize and be methodical (A)', 'The ability to adapt and adjust quickly (B)'],
  },
  {
    q_no: 35,
    ques: 'Do you put more value on what is',
    choices: ['Definite (A)', 'Open-ended (B)'],
  },
  {
    q_no: 36,
    ques: 'Does new and non-routine interaction with others',
    choices: ['Stimulate and energize you (A)', 'Leave you feeling tied (B)'],
  },
  {
    q_no: 37,
    ques: 'Are you more frequently',
    choices: ['A practical sort of person (A)', 'A fanciful sort of person (B)'],
  },
  {
    q_no: 38,
    ques: 'Are you more likely to',
    choices: ['See how others are useful (A)', 'See how others see (B)'],
  },
  {
    q_no: 39,
    ques: 'Which is more satisfying:',
    choices: ['To discuss an issue thoroughly (A)', 'To arrive at agreement on an issue (B)'],
  },
  {
    q_no: 40,
    ques: 'Which rules your more:',
    choices: ['Your head (A)', 'Your heart (B)'],
  },
  {
    q_no: 41,
    ques: 'Are you more comfortable with work that is',
    choices: ['Contracted and agreed upon (A)', 'Done on a casual basis (B)'],
  },
  {
    q_no: 42,
    ques: 'Do you tend to look for',
    choices: ['The orderly (A)', 'Whatever turns up (B)'],
  },
  {
    q_no: 43,
    ques: 'Do you prefer',
    choices: ['Many friends with brief contact (A)', 'A few friends with more lengthy contact (B)'],
  },
  {
    q_no: 44,
    ques: 'Which has more value for you?',
    choices: ['Accurate facts (A)', 'Theoretical principles (B)'],
  },
  {
    q_no: 45,
    ques: 'Are you more interested in',
    choices: ['Production and distribution (A)', 'Design and research (B)'],
  },
  {
    q_no: 46,
    ques: 'Which is more of a compliment:',
    choices: ['“that person is very logical” (A)', '“that person is gentle and caring” (B)'],
  },
  {
    q_no: 47,
    ques: 'Which do you value most in yourself?',
    choices: ['Being unwavering (A)', 'Being devoted (B)'],
  },
  {
    q_no: 48,
    ques: 'Do you more often prefer the',
    choices: ['Final and unalterable statement (A)', 'Tentative and preliminary statement (B)'],
  },
  {
    q_no: 49,
    ques: 'Are you more comfortable',
    choices: ['After a decision (A)', 'Before a decision (B)'],
  },
  {
    q_no: 50,
    ques: 'Do you',
    choices: ['Speak easily and at length with strangers (A)', 'Find little to say to strangers (B)'],
  },
  {
    q_no: 51,
    ques: 'Are you more likely to trust your',
    choices: ['Experience (A)', 'Hunch (B)'],
  },
  {
    q_no: 52,
    ques: 'Do you feel',
    choices: ['More practical than creative (A)', 'More creative than practical (B)'],
  },
  {
    q_no: 53,
    ques: 'Which person is more to be complimented: one of',
    choices: ['Clear reason (A)', 'Strong feeling (B)'],
  },
  {
    q_no: 54,
    ques: 'Are you inclined more to be',
    choices: ['Fair minded (A)', 'Sympathetic (B)'],
  },
  {
    q_no: 55,
    ques: 'Is it preferable mostly to',
    choices: ['Make sure things are arranged (A)', 'Just let things happen (B)'],
  },
  {
    q_no: 56,
    ques: 'In relationships should most things be',
    choices: ['Renegotiable (A)', 'Random and circumstantial (B)'],
  },
  {
    q_no: 57,
    ques: 'When the phone rings do you',
    choices: ['Hasten to get it first (A)', 'Hope someone else will answer (B)'],
  },
  {
    q_no: 58,
    ques: 'Do you prize more in yourself',
    choices: ['A strong sense of reality (A)', 'A vivid imagination (B)'],
  },
  {
    q_no: 59,
    ques: 'Are you drawn more to',
    choices: ['Fundamentals (A)', 'Overtones and nuance (B)'],
  },
  {
    q_no: 60,
    ques: 'Which seems the greatest error',
    choices: ['To be too passionate (A)', 'To be too objective (B)'],
  },
  {
    q_no: 61,
    ques: 'Do you see yourself as basically',
    choices: ['Hard-headed (A)', 'Soft-hearted (B)'],
  },
  {
    q_no: 62,
    ques: 'Which situation appeals to you more:',
    choices: ['The structured and scheduled (A)', 'The unstructured and unscheduled (B)'],
  },
  {
    q_no: 63,
    ques: 'Are you a person that is more',
    choices: ['Organized than playful (A)', 'Playful than organized (B)'],
  },
  {
    q_no: 64,
    ques: 'Are you more inclined to be',
    choices: ['Easy to approach (A)', 'Somewhat reserved (B)'],
  },
  {
    q_no: 65,
    ques: 'In writings do you prefer',
    choices: ['The more literal (A)', 'The more figurative (B)'],
  },
  {
    q_no: 66,
    ques: 'Is it harder for you to',
    choices: ['Identify with others (A)', 'Utilize others (B)'],
  },
  {
    q_no: 67,
    ques: 'Which do you wish more for yourself',
    choices: ['Clarity of reason (A)', 'Strength of compassion (B)'],
  },
  {
    q_no: 68,
    ques: 'Which is the greater fault:',
    choices: ['Being indiscriminate (A)', 'Being critical (B)'],
  },
  {
    q_no: 69,
    ques: 'Do you prefer the',
    choices: ['Planned event (A)', 'Unplanned event (B)'],
  },
  {
    q_no: 70,
    ques: 'Do you tend to be more',
    choices: ['Deliberate than spontaneous (A)', 'Spontaneous than deliberate (B)'],
  },
]

const test3 = {
  Number_Series: [
    {
      q_no: 1,
      ques: 'Look at this series: 12, 11, 13, 12, 14, 13, … What number should come next?',
      choices: ['A. 10', 'B. 16', 'C. 13', 'D. 15'],
      answer: ['D'],
    },
    {
      q_no: 2,
      ques: 'Look at this series: 36, 34, 30, 28, 24, … What number should come next?',
      choices: ['A. 22', 'B. 26', 'C. 23', 'D. 20'],
      answer: ['A'],
    },
    {
      q_no: 3,
      ques: 'Look at this series: 7, 10, 8, 11, 9, 12, … What number should come next?',
      choices: ['A. 7', 'B. 12', 'C. 10', 'D. 13'],
      answer: ['C'],
    },
    {
      q_no: 4,
      ques: 'Look at this series: 2, 1, (1/2), (1/4), … What number should come next?',
      choices: ['A. (1/3)', 'B. (1/8)', 'C. (2/8)', 'D. (1/16)'],
      answer: ['B'],
    },
    {
      q_no: 5,
      ques: 'Look at this series: 80, 10, 70, 15, 60, … What number should come next?',
      choices: ['A. 20', 'B. 25', 'C. 30', 'D. 50'],
      answer: ['A'],
    },
  ],
  Verbal_Classification: [
    {
      q_no: 6,
      ques: 'Which word does NOT belong with the others?',
      choices: ['A. index', 'B. glossary', 'C. chapter', 'D. book'],
      answer: ['D'],
    },
    {
      q_no: 7,
      ques: 'Which word is the odd man out?',
      choices: ['A. trivial', 'B. unimportant', 'C. important', 'D. insignificant'],
      answer: ['C'],
    },
    {
      q_no: 8,
      ques: 'Which word does NOT belong with the others?',
      choices: ['A. wing', 'B. fin', 'C. beak', 'D. rudder'],
      answer: ['C'],
    },
  ],
  Analogies: [
    {
      q_no: 9,
      ques: 'Which word is the odd man out?',
      choices: ['A. hate', 'B. fondness', 'C. liking', 'D. attachment'],
      answer: ['A'],
    },
    {
      q_no: 10,
      ques: 'Pick the odd man out?',
      choices: ['A. just', 'B. fair', 'C. equitable', 'D. biased'],
      answer: ['D'],
    },
    {
      q_no: 11,
      ques: 'CUP : LIP :: BIRD : ?',
      choices: ['A. GRASS', 'B. FOREST', 'C. BEAK', 'D. BUSH'],
      answer: ['C'],
    },

    {
      q_no: 12,
      ques: 'Paw : Cat :: Hoof : ?',
      choices: ['A. Lamb', 'B. Horse', 'C. Elephant', 'D. Tiger'],
      answer: ['B'],
    },

    {
      q_no: 13,
      ques: 'Safe : Secure :: Protect :',
      choices: ['A. Lock', 'B. Guard', 'C. Sure', 'D. Conserve'],
      answer: ['B'],
    },
  ],

  Matching_Definitions: [
    {
      q_no: 14,
      ques:
        'An Informal Gathering occurs when a group of people get together in a casual, relaxed manner. Which situation below is the best example of an Informal Gathering?',
      choices: [
        'A. A debating club meets on the first Sunday morning of every month.',
        'B. After finding out about his salary raise, Jay and a few colleagues go out for a quick dinner after work.',
        'C. Meena sends out 10 invitations for a bachelorette party she is giving for her elder sister.',
        'D. Whenever she eats at a Chinese restaurant, Roop seems to run into Dibya.',
      ],
      answer: ['B'],
    },

    {
      q_no: 15,
      ques:
        'A Tiebreaker is an additional contest carried out to establish a winner among tied contestants. Choose one situation from the options below that best represents a Tiebreaker.',
      choices: [
        'A. At halftime, the score is tied at 2-2 in a football match.',
        'B. Serena and Maria have each secured 1 set in the game.',
        'C. The umpire tosses a coin to decide which team will have bat first.',
        'D. RCB and KKR each finished at 140 all out.',
      ],
      answer: ['D'],
    },

    {
      q_no: 16,
      ques:
        'The Sharks and the Bears each finished with 34 points, and they are now battling it out in a five-minute overtime.',
      choices: [
        'A. When he is offered a better paying position, Jacob leaves the restaurant he manages to manage a new restaurant on the other side of town.',
        'B. Catherine is spending her junior year of college studying abroad in France.',
        'C. Malcolm is readjusting to civilian life after two years of overseas military service.',
        'D. After several miserable months, Sharon decides that she can no longer share an apartment with her roommate Hilary.',
      ],
      answer: ['C'],
    },

    {
      q_no: 17,
      ques:
        'Re Entry occurs when a person leaves his or her social system for a period of time and then returns. Which situation below best describes Reentry?',
      choices: [
        'A. When he is offered a better paying position, Javed leaves the hotel he manages to manage another one in a neighboring city.',
        'B. Charan is spending her final year of college studying abroad in China.',
        'C. Manan is readjusting to civilian life after 2 years of overseas merchant navy service.',
        'D. After 5 miserable months, Sneha decides that she can no longer share her room with roommate Hital.',
      ],
      answer: ['C'],
    },

    {
      q_no: 18,
      ques:
        'Posthumous Award occurs when an award is given to someone, after their death. Choose one situation below as the best example of a Posthumous Award.',
      choices: [
        'A. Late yesteryear actress Sridevi was awarded with a Lifetime Achievement Award posthumously in Filmfare 2019.',
        'B. Chitra never thought she’d live to receive a third Booker prize for her novel.',
        'C. Emanuel has been honored with a prestigious literary award for his writing career and his daughter accepted the award on behalf of her deceased father.',
        'D. Meenal’s publisher canceled her book contract after she failed to deliver the manuscript on time.',
      ],
      answer: ['A', 'C'],
    },
  ],
}

export { test1, test2, test0, test3 }
