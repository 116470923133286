import { useContext, useEffect, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import Slider from 'react-slick'
import * as Currdate from '../../CurrDate'
import { SocketConfig } from '../../SocketConfig'
import nextArrow from '../../assets/images/nextarrow.svg'
import prevArrow from '../../assets/images/prevarrow.svg'
import Speech from '../Speech'
import SliderPreview from './SliderPreview'
import Context from '../../Context'

const CommunicationMainComponent = () => {
  const { selectValue } = useContext(Context)
  let data = JSON.parse(localStorage.getItem('modulejson'))
  const [currentSliderElement, setcurrentSliderElement] = useState(null)

  const [prevImg, setPrevImg] = useState('')

  const sliderRef = useRef()
  const currSlide = useRef(null)
  const [hideStartbtn, sethideStartbtnbia] = useState(false)
  const [btnvia, setbtnvia] = useState(false)
  const [stopbtnvia, setstopbtnvia] = useState(false)
  const currSlide1 = useRef(null)
  const [timernum, setTimernum] = useState(6000)
  const [stopvia, setStopvia] = useState(false)
  const [hidebtn, sethidebtn] = useState(false)
  const [udata, setudata] = useState()
  const [visibilityForm, setvisibilityForm] = useState(false)

  let locationdata = useLocation()

  let date = Currdate.GetCurrentDate()
  let parseData = locationdata.state
  let flashcardScoreData = []

  let sendallData = {
    username: selectValue.username,
    userid: selectValue.user_id,
    category: parseData.category2,
    modulename1: parseData.modulename3 == 'undefined' ? null : parseData.modulename3,
    level: 'level1',
    modulename: 'flashcard',
  }

  let inputref = useRef()
  let jsonData
  let subtext = useRef()
  var settings = {
    infinite: false,
    initialslide: 0,
    speed: 500,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
      <figure className='arrow-item prevArrow'>
        <img src={nextArrow} alt='nextArrow' />
      </figure>
    ),
    prevArrow: (
      <figure className='arrow-item prevArrow'>
        <img src={prevArrow} alt='prevarrow' />
      </figure>
    ),

    afterChange: (current) => {
      let slide = document.querySelector(`[data-index="${current}"]`)

      let imageName = slide.children[0].children[0].getAttribute('img-name')
      setPrevImg(slide.children[0].children[0].children[0].getAttribute('src'))
      Speech.Speech(`${selectValue.username} choose ${imageName}`)

      setcurrentSliderElement(document.querySelector(`[data-index="${current}"]`))
    },
  }

  const [timer, setTimer] = useState()

  const startCountDown = (num) => {
    let timeleft = num
    let downloadTimer = setInterval(function() {
      timeleft--
      setTimer(timeleft)
      if (timeleft <= 0) {
        clearInterval(downloadTimer)
        setTimer(0)
      }
    }, 1000)
  }

  const startSocket = () => {
    setbtnvia(true)

    let socketdata = {
      result: 'connect',
      l_video: [timernum / 1000],
    }
    let datei = {
      date: date,
    }

    jsonData = {
      ...sendallData,
      ...datei,
      ...socketdata,
    }
    setudata(jsonData)

    SocketConfig.emit('educate', { ...jsonData, stop: false })
  }

  function getFirstIndex(doneCallback) {
    let firstIndex = document.querySelector("[data-index='0']")
    setcurrentSliderElement(firstIndex)

    doneCallback(true, firstIndex)
  }

  const getResult = (e) => {
    e.preventDefault()
    getFirstIndex((via, firstIndex) => {
      let index = currentSliderElement == null ? firstIndex : currentSliderElement
      let imageName = index.children[0].children[0].getAttribute('img-name')

      setPrevImg(index.children[0].children[0].children[0].getAttribute('src'))

      Speech.Speech(`${selectValue.username} choose ${imageName}`)

      sethidebtn(true)
      sethideStartbtnbia(true)
      setvisibilityForm(true)

      startSocket()
    })
  }

  let quesLength = 0

  let questionArrLength = 0
  const selectTimer = (e) => setTimernum(e.target.value)

  let checkCat = ['ACTION WORDS', 'ADJECTIVES']
  return (
    <Row className='align-items-center'>
      <Col lg={8}>
        <div className='select-student-label-container'>
          <div className='heading-container'>
            <h3>Please select a category to proceed</h3>
            <p>Feel free to scroll through to view all the available options</p>
          </div>
        </div>

        <div className='category-txt mt-64'>
          <label>Selection</label>
        </div>

        <div>
          <Slider {...settings} ref={sliderRef}>
            {data != undefined && data != ''
              ? data[parseData.modulename1].map((o, i) => {
                  if (i != 0) {
                    if (o.module.toUpperCase() == parseData.modulename.toUpperCase()) {
                      return o.categories.map((obj, index) => {
                        if (obj.name.toUpperCase() == parseData.category.toUpperCase()) {
                          let currslider = i == 0 ? currSlide : currSlide1
                          questionArrLength = obj.sub_cat.length
                          return obj.sub_cat.map((key, ind) => {
                            flashcardScoreData.push(parseInt(key[2]))
                            quesLength += key[2]
                            return checkCat.includes(parseData.category2.toUpperCase()) ? (
                              <div
                                img-name={key[1]}
                                ref={currslider}
                                className='d-flex flex-column align-items-center'
                                style={btnvia == false ? { pointerEvents: 'none' } : { pointerEvents: 'initial' }}
                              >
                                <video width='100%' height='100%' src={key[0]} controls>
                                  <source src={key[0]} type='video/mp4' />
                                </video>
                                <button
                                  type='button'
                                  className='btn common-btn px-4 py-3 mt-4'
                                  onClick={() => Speech.Speech(`${key[1]}`)}
                                >
                                  Play Audio
                                </button>
                              </div>
                            ) : (
                              <div
                                img-name={key[1]}
                                ref={currslider}
                                className='d-flex flex-column align-items-center'
                                style={btnvia == false ? { pointerEvents: 'none' } : { pointerEvents: 'initial' }}
                              >
                                <img src={key[0]} height={600} width={'100%'} />
                                <button
                                  type='button'
                                  className='btn common-btn px-4 py-3 mt-4'
                                  onClick={() => Speech.Speech(`${key[1]}`)}
                                >
                                  Play Audio
                                </button>
                              </div>
                            )
                          })
                        }
                      })
                    }
                  }
                })
              : ''}
          </Slider>
        </div>
      </Col>

      <Col className='text-center'>
        <SliderPreview
          flashcardScoreData={flashcardScoreData}
          sliderRef={sliderRef}
          currSlide={currentSliderElement}
          click={startCountDown}
          alldata={jsonData}
          startSocket={startSocket}
          sethideStartbtnbia={sethideStartbtnbia}
          hideStartbtn={hideStartbtn}
          datalength={quesLength}
          selectTimer={selectTimer}
          questionArrLength={questionArrLength}
          subtext={subtext}
          inputref={inputref}
          jsonData={udata}
          image={prevImg}
          sliderLength={data[parseData.modulename1]}
          setStopvia={setStopvia}
          getStart={getResult}
          stopvia={stopvia}
          setstop={setstopbtnvia}
          stopbtn={stopbtnvia}
          setvisibility={setvisibilityForm}
          visibility={visibilityForm}
          sethidebtn={sethidebtn}
          hidebtn={hidebtn}
          data={data}
        />
      </Col>
    </Row>
  )
}
export default CommunicationMainComponent
