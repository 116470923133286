import { useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Draggable from 'react-draggable' // Both at the same time
import { useLocation, useNavigate } from 'react-router-dom'
import * as Apiurl from '../../../Apisurl'
import * as Currdate from '../../../CurrDate'
import headersReq from '../../../HeadersJson'
import UserJson from '../../../Userjson'
import refreshbtn from '../../../assets/images/refresh.svg'
import Progress from '../Testprogrsscom'
import Addobj from './Addobj'
import Calculation from './Calculationgame'
import ComparingGame from './ComparingGame'
import Meaningful_counting from './Meaning_count'

const MathsGame = () => {
  let headerjson = headersReq()
  const location = useLocation()
  const navigate = useNavigate()

  let DraggableCore = Draggable.DraggableCore
  const [score, setScore] = useState(0)
  const [callday, setcallday] = useState(1)
  const [isActive, setIsActive] = useState(false)
  const [mathsdata, setmathsdata] = useState([])
  let [constantTime, setconstantTime] = useState('')
  const [data1, setdata1] = useState([])
  let initialArray = []
  const [theArray, setTheArray] = useState(initialArray)
  const [page, setPage] = useState(0)

  let date = Currdate.GetCurrentDate()
  // socket.disconnect();
  let cat = location.state.category
  let userJson = UserJson()
  let datei = {
    date: date,
  }

  const sendOperator = () => {
    let operator = ''

    if (cat.includes('addition')) {
      operator = '+'
    } else if (cat.includes('multiply')) {
      operator = 'x'
    } else if (cat.includes('subtraction')) {
      operator = '-'
    } else if (cat.includes('division')) {
      operator = '/'
    } else {
      switch (cat) {
        case 'multiple':
          operator = 'x'
          break
        default:
          operator = '.'
          break
      }
    }
    return operator
  }

  useEffect(() => {
    async function levelgame() {
      // console.log(sendOperator());
      const response = await fetch(`${Apiurl.Fetchurl + 'level_maths'}`, {
        method: 'POST',
        body: JSON.stringify({
          category: location.state.category,
        }),
        headers: headerjson,
      })
      const childdata = await response.json()
      return childdata
    }
    levelgame()
      .then((data) => {
        setmathsdata(data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const submitResult = (...datei) => {
    let answer = JSON.parse(localStorage.getItem('useranswer'))
    let getScore = answer.filter((o, i) => {
      return o.via == 'correct'
    })
    let alldata = {
      ...userJson,
      result: JSON.stringify({
        ques: mathsdata != undefined ? mathsdata[location.state.link] : '',
        score: getScore.length,
        total: mathsdata[location.state.link].length,
        ans: answer,
      }),
      date: datei,
    }
    const esc = encodeURIComponent
    const query = Object.keys(alldata)
      .map((k) => `${esc(k)}=${esc(alldata[k])}`)
      .join('&')

    async function levelgame() {
      const response = await fetch(`${Apiurl.Fetchurl + 'manual_report_fc?' + query + ''}`, {
        method: 'GET',
        headers: headerjson,
      })
      const childdata = await response.json()
      return childdata
    }
    levelgame()
      .then((data) => {
        navigate(`mathresult?id=${data.serial_num}`, { state: { ...location.state, date: date } })
      })
      .catch((error) => {
        alert(error)
      })
  }

  const renderComponent = () => {
    let comp
    if (mathsdata != undefined) {
      switch (cat) {
        case 'greater_lesser':
          comp =
            mathsdata[location.state.link] != undefined ? (
              <ComparingGame
                operator={sendOperator()}
                datalength={mathsdata[location.state.link]}
                mathsdata={mathsdata[location.state.link][page]}
                setPage={setPage}
                time={setconstantTime}
                submitResult={submitResult}
                setTheArray={setTheArray}
                level={location.state.link}
                page={page}
                setdata1={setdata1}
                data1={data1}
                setScore={setScore}
              />
            ) : (
              ''
            )
          break
        case 'subtraction_objects':
          comp =
            mathsdata[location.state.link] != undefined ? (
              <Addobj
                operator={sendOperator()}
                datalength={mathsdata[location.state.link]}
                mathsdata={mathsdata[location.state.link][page]}
                answer={mathsdata[location.state.link][page]?.ans}
                setPage={setPage}
                time={setconstantTime}
                submitResult={submitResult}
                setTheArray={setTheArray}
                level={location.state.link}
                page={page}
                setdata1={setdata1}
                data1={data1}
                setScore={setScore}
              />
            ) : (
              ''
            )
          break
        case 'addition_objects':
          comp =
            mathsdata[location.state.link] != undefined ? (
              <Addobj
                operator={sendOperator()}
                datalength={mathsdata[location.state.link]}
                mathsdata={mathsdata[location.state.link][page]}
                setPage={setPage}
                time={setconstantTime}
                submitResult={submitResult}
                setTheArray={setTheArray}
                answer={mathsdata[location.state.link][page]?.ans}
                level={location.state.link}
                page={page}
                setdata1={setdata1}
                data1={data1}
                setScore={setScore}
              />
            ) : (
              ''
            )
          break
        case 'meaningful_counting':
          comp = (
            <DndProvider backend={HTML5Backend}>
              <Meaningful_counting
                mathsdata={mathsdata[location.state.link] != undefined ? mathsdata[location.state.link][page] : ''}
                datalength={mathsdata[location.state.link]}
                setPage={setPage}
                submitResult={submitResult}
                setTheArray={setTheArray}
                page={page}
                time={setconstantTime}
                setScore={setScore}
                setdata1={setdata1}
                data1={data1}
              />
            </DndProvider>
          )
          break
        default:
          comp = (
            <Calculation
              operator={sendOperator()}
              datalength={mathsdata[location.state.link]}
              mathsdata={mathsdata[location.state.link][page]}
              setPage={setPage}
              time={setconstantTime}
              submitResult={submitResult}
              setTheArray={setTheArray}
              level={location.state.link}
              page={page}
              setdata1={setdata1}
              data1={data1}
              setScore={setScore}
            />
          )
      }
    }
    return comp
  }

  if (mathsdata.length != 0) {
    return (
      <>
        <Container fluid>
          <Row className='colors-main-container '>{renderComponent()}</Row>
          <div className='activity-listing'>
            <ul>
              <li>
                <Progress json={mathsdata[location.state.link]} currindex={page} getarray={theArray} />
              </li>
              <li className='refresh-btn'>
                <button>
                  <img src={refreshbtn} alt='refresh' />
                  Start Another Level
                </button>
                {/* <button>
                  <img src={refreshbtn} alt="refresh" />
                  Start Over
                </button> */}
              </li>
            </ul>
          </div>
        </Container>
      </>
    )
  }
}
export default MathsGame
