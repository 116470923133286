import { Col, Row } from 'react-bootstrap'
import { DragDropContainer, DropTarget } from 'react-drag-drop-container'

const Match = (props) => {
  const reset = () => {
    let allElement = document.querySelectorAll('.tickAbsolute')
    allElement.forEach((item, index) => {
      item.classList.remove('wright')
      item.classList.remove('wrong')
    })
    let allElement1 = document.querySelectorAll('.ddcontainersource')
    allElement1.forEach((item, index) => {
      item.classList.remove('none')
    })
  }

  let update

  return (
    <Row className='matching-container'>
      <div className='headingContainer d-flex mb-3 align-items-center justify-content-between'>
        <h4 style={{ textAlign: 'center', fontSize: '30px', margin: 'auto' }}> Match the Following</h4>
        <div class='refresh-btn match' onClick={reset}>
          <button>
            <img src='/static/media/refresh.1d5b1ddfaec71aa08dc120a7e1fd0cc5.svg' alt='refresh' />
            Start Over
          </button>
        </div>
      </div>
      <Col md={4} className='matching-item'>
        {props.englishData.map((obj, index) => {
          let objImage = obj.image1
            .split('/')
            .pop()
            .split('.')[0]

          return (
            <DragDropContainer
              targetKey='foo'
              dropData={obj.option1}
              disappearDraggedElement={true}
              //   onDragEnd={dropAnswer}
            >
              <div className='tickAbsolute' objAnswer={obj.ans} data-id={obj.option1}>
                <div className='tickAbsolute1'></div>
                <img src={obj.image1} alt='' data-image2={obj.image2} data-id={obj.option1} />
              </div>
            </DragDropContainer>
          )
        })}
      </Col>
      <Col md={4}></Col>
      <Col md={4} className='matching-item'>
        {props.englishData.map((obj, index) => {
          let objImage = obj.image2
            .split('/')
            .pop()
            .split('.')[0]
          return (
            <DropTarget
              targetKey='foo'
              dropData={obj.option2}
              onHit={function(e) {
                let thisImageEle = e.target
                let thisParentEle = thisImageEle.parentElement
                let answeredImages = thisImageEle.getAttribute('src')
                let userAnswer1 =
                  e.sourceElem.children[0].getAttribute('data-id') + thisParentEle.getAttribute('data-id')

                if (e.sourceElem.children[0].getAttribute('objAnswer') == userAnswer1) {
                  thisParentEle.classList.add('wright')
                } else {
                  thisParentEle.classList.add('wrong')
                }

                let isCorrectAnswer = e.sourceElem.children[0].getAttribute('objAnswer') == userAnswer1
                e.sourceElem.classList.add('none')
                props.setTheArray((oldArray) => {
                  update = [
                    ...oldArray,
                    {
                      via: isCorrectAnswer ? 'correct' : 'incorrect',
                      answer: answeredImages,
                      answer1: obj.image2,
                      answerKey: userAnswer1,
                    },
                  ]

                  localStorage.setItem('useranswer', JSON.stringify(update))
                  return update
                })
              }}
            >
              <div className='tickAbsolute' objAnswer={obj.ans} data-id={obj.option2}>
                <div className='tickAbsolute1' style={{ display: 'none' }}>
                  ✓
                </div>
                <div className='tickAbsolute2' style={{ display: 'none' }}>
                  ⨯
                </div>

                <img src={obj.image2} alt='' data-id={obj.option2} />
              </div>
            </DropTarget>
          )
        })}
        <button className='common-btn p-2 w-100 mt-2' onClick={props.submitResult}>
          Submit
        </button>
      </Col>
    </Row>
  )
}
export default Match
