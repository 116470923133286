import { useContext, useRef, useState } from 'react'
import Table from 'react-bootstrap/Table'
import Context from '../../../Context'
import UserJson from '../../../Userjson'

const Colortable = () => {
  const [overview1, setOverview] = useState()
  const props1 = useContext(Context)

  let tableref = useRef()
  let user = UserJson()
  let tlengthdata = tableref.current

  let category = UserJson().category

  let overview = props1[2].Overview

  if ((overview != undefined && overview.length != 0) || overview != '') {
    return (
      <>
        <Table className='result-table' ref={tableref}>
          <thead>
            <tr>
              <th></th>
              {overview.ques.map((o, i) => {
                return <th>Q{i + 1}</th>
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Asked</td>

              {overview.ans.map((o, i) => {
                return (
                  <td>
                    <div className='asked-color-box d-flex ' data-img={o.option2 + o.option1}>
                      <img src={`https://ui-nema-database.s3.ap-south-1.amazonaws.com/${o.answer1}`} height='80' />
                      <img
                        src={`https://ui-nema-database.s3.ap-south-1.amazonaws.com/${o.answer1}`}
                        style={{ marginLeft: '10px ' }}
                        height='80'
                      />
                    </div>
                  </td>
                )
              })}
            </tr>
            <tr>
              <td>Your Answer</td>
              {overview.ans.map((o, i) => {
                return (
                  <td>
                    <div className='asked-color-box'>
                      <p
                        style={
                          overview.ans[i].via == 'correct'
                            ? { border: '4px solid #77C63A' }
                            : { border: '4px solid red' }
                        }
                      >
                        <img src={`https://ui-nema-database.s3.ap-south-1.amazonaws.com/${o.answer}`} alt='' />
                      </p>
                    </div>
                  </td>
                )
              })}
            </tr>
          </tbody>
        </Table>
      </>
    )
  }
}

export default Colortable
