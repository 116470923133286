import { Navigate, Outlet } from 'react-router-dom'
import * as NavObj from './includes/NavObj'

function PrivateRouteByrole() {
  if (sessionStorage.getItem('session') === '123456') return <Outlet />
  const redirectRoutes = () => {
    let role = localStorage.getItem('role')
    let routesVia = true
    switch (role) {
      // case 'parent':
      //   if (!NavObj.accessArray.includes(sessionStorage.getItem('session'))) {
      //     routesVia = false
      //   }
      //   break

      // care 'teacher'
      case 'parent':
        routesVia = true
        break

      case 'teacher':
        routesVia = true
        break

        
      case 'admin':
        routesVia = true
        break

      default:
        routesVia = false
        break
    }
    return routesVia
  }
  return redirectRoutes() != true ? <Navigate to='/' replace /> : <Outlet />
}
export default PrivateRouteByrole
