import { useContext } from 'react'
import Table from 'react-bootstrap/Table'
import Context from '../../../Context'
import { useLocation } from 'react-router-dom'

const EvsTable = () => {
  const location = useLocation()
  let cat = location.state.category
  const props1 = useContext(Context)

  let overview = props1[2].Overview

  if (overview != undefined && overview.length != 0) {
    const renderTable = () => {
      let comp
      switch (cat) {
        case 'parts_of_tree':
        case 'body_parts':
        case 'mean_of_transport':
          comp = (
            <Table className='result-table'>
              <thead>
                <tr>
                  <th></th>
                  {overview.ans.map((_, i) => {
                    return <th key={i}>Q{i + 1}</th>
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Asked</td>
                  {overview.ques.map((o, i) => {
                    return (
                      <td key={i}>
                        <div className='asked-color-box'>{o}</div>
                      </td>
                    )
                  })}
                </tr>
                <tr>
                  <td>Your Answer</td>
                  {overview.ans.map((o, i) => {
                    return (
                      <td key={i}>
                        <div className='asked-color-box colorvia'>
                          {o.via == 'correct' ? <i class='fas fa-check'></i> : <i class='fas fa-times'></i>}

                          <p style={o.via == 'correct' ? { color: '#77C63A' } : { color: 'red' }}>{o.answer}</p>
                        </div>
                      </td>
                    )
                  })}
                </tr>
                <tr>
                  <td>Correct Answer</td>
                  {overview.ques.map((o, i) => {
                    return (
                      <td>
                        <div className='asked-color-box'>{o}</div>
                      </td>
                    )
                  })}
                </tr>
              </tbody>
            </Table>
          )
          break
        case 'living':
        case 'non_living':
        case 'kitchen':
        case 'bathroom':
        case 'living_room':
        case 'bedroom':
        case 'summer':
        case 'winter':
        case 'monsoon':
          comp = (
            <Table className='result-table'>
              <thead>
                <tr>
                  <th></th>
                  {overview.ans.map((_, i) => {
                    return <th key={i}>Q{i + 1}</th>
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Asked</td>
                  {overview.ques.map((o, i) => {
                    return (
                      <td key={i}>
                        <div className='asked-color-box'>{o}</div>
                      </td>
                    )
                  })}
                </tr>
                <tr>
                  <td>Your Answer</td>
                  {overview.ans.map((o, i) => {
                    return (
                      <td key={i}>
                        <div className='asked-color-box colorvia'>
                          {o.via == 'correct' ? <i class='fas fa-check'></i> : <i class='fas fa-times'></i>}
                          <img src={o.answer} />
                        </div>
                      </td>
                    )
                  })}
                </tr>
                <tr>
                  <td>Correct Answer</td>
                  {overview.ques.map((o, i) => {
                    return (
                      <td>
                        <div className='asked-color-box'>{o}</div>
                      </td>
                    )
                  })}
                </tr>
              </tbody>
            </Table>
          )
          break

        default:
          comp = (
            <Table className='result-table'>
              <thead>
                <tr>
                  <th></th>
                  {overview.ans.map((o, i) => {
                    return <th key={i}>Q{i + 1}</th>
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Asked</td>
                  {overview.ques.map((o, i) => {
                    return (
                      <td key={i}>
                        <div className='asked-color-box'>
                          <img src={o.images} />
                        </div>
                      </td>
                    )
                  })}
                </tr>
                <tr>
                  <td>Your Answer</td>
                  {overview.ans.map((o, i) => {
                    return (
                      <td key={i}>
                        <div className='asked-color-box' style={o.via == 'correct' ? { color: '#77C63A' } : { color: 'red' }}>
                          {o.answer}
                        </div>
                      </td>
                    )
                  })}
                </tr>
                <tr>
                  <td>Correct Answer</td>
                  {overview.ques.map((o, i) => {
                    return (
                      <td>
                        <div className='asked-color-box'>{o.ans}</div>
                      </td>
                    )
                  })}
                </tr>
              </tbody>
            </Table>
          )
          break
      }
      return comp
    }
    return <>{renderTable()}</>
  }
}

export default EvsTable
