import { Row, Col } from 'react-bootstrap'
import formtextStyle from './cssModule/Roleformheader.module.css'

const RoleHeader = ({ role, step = 1 }) => {
  return (
    <Row>
      <Col md={6} className={formtextStyle.wrapper}>
        <p className={formtextStyle.createtxt}>Create an Account</p>
        <h3 className='text-capitalize'> {role} </h3>
      </Col>


      <Col md={6} className='text-right stepstext m-auto'>
        <h2>
          Step <span className='active'>{step}</span> of 2
        </h2>
      </Col>
    </Row>
  )
}
export default RoleHeader
