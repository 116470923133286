import React, { useRef, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'

const Test1 = ({ data, submit, options, limit }) => {
  const [formData, setFormData] = useState({})
  const [validated, setValidated] = useState(false)
  const [step, setStep] = useState(1)
  const formRef = useRef()

  const last_page = data.length / limit

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((old) => ({ ...old, [name]: value }))
  }

  const nextStep = (e) => {
    e.preventDefault()
    if (formRef.current.checkValidity() === false) {
      e.stopPropagation()
    } else {
      if (step === last_page) return finish()
      setStep((s) => s + 1)
    }
    setValidated(true)
  }

  const finish = () => {
    let A_ques = [4, 5, 6, 10, 11, 12, 16, 17, 18]
    let ans = {
      0: 'Strong left hemisphere orientation.',
      1: 'Strong left hemisphere orientation.',
      2: 'Strong left hemisphere orientation.',
      3: 'Strong left hemisphere orientation.',
      4: 'Strong left hemisphere orientation.',
      5: 'Moderate left hemisphere orientation.',
      6: 'Moderate left hemisphere orientation.',
      7: 'Moderate left hemisphere orientation.',
      8: 'Moderate left hemisphere orientation.',
      9: 'Bilateral hemisphere balance.',
      10: 'Bilateral hemisphere balance.',
      11: 'Bilateral hemisphere balance.',
      12: 'Moderate right hemisphere orientation.',
      13: 'Moderate right hemisphere orientation.',
      14: 'Moderate right hemisphere orientation.',
      15: 'Moderate right hemisphere orientation.',
      16: 'Strong right hemisphere orientation.',
      17: 'Strong right hemisphere orientation.',
      18: 'Strong right hemisphere orientation.',
      19: 'Strong right hemisphere orientation.',
      20: 'Strong right hemisphere orientation.',
    }
    let A = 0
    let B = 0
    Object.entries(formData).forEach(([q_no, value]) => {
      if (A_ques.includes(Number(q_no))) {
        value === 'A' && A++
      } else {
        value === 'B' && B++
      }
    })
    let total = A + B

    let res = {
      test_name: 'TEST 1',
      ans: Object.values(formData),
      result: ans[total],
    }

    submit(res)
  }

  return (
    <Container>
      <Row className='my-3 dashboard-cards p-4'>
        <Col>
          <p className='text-black'>
            <strong>
              Some of the following choices may seem to be either both true or both false. However, please don’t check
              both or leave any blank. Force yourself to choose the one sentence which is most accurate. These questions
              will help you discover your personal thinking style in the way you do your work.
            </strong>
          </p>
          <Form noValidate validated={validated} ref={formRef}>
            <ul className='list-unstyled'>
              {data.slice((step - 1) * limit, (step - 1) * limit + limit).map((d) => {
                return (
                  <li key={d.q_no} className='my-3 mx-2 text-secondary'>
                    <div className='d-flex justify-content-between'>
                      <div className='d-flex gap-4'>
                        {d.q_no}.
                        <div>
                          {d.choices.map((q, i) => {
                            return (
                              <p key={i} className='m-0'>
                                {q}
                              </p>
                            )
                          })}
                        </div>
                      </div>
                      <div>
                        <Form.Group>
                          {d.choices.map((_, idx) => {
                            return (
                              <Form.Check
                                required
                                key={idx}
                                id={`${options[idx]}_${d.q_no}`}
                                type='radio'
                                label={options[idx]}
                                name={d.q_no}
                                value={options[idx]}
                                checked={formData[d.q_no] === options[idx]}
                                onChange={handleChange}
                                inline
                              />
                            )
                          })}
                          <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
            <div className='d-flex justify-content-between'>
              <button
                onClick={() => setStep((s) => s - 1)}
                type='button'
                className='d-flex justify-content-center align-items-center py-1 px-3 rounded-pill border border-2 text-secondary'
                style={{ backgroundColor: 'transparent' }}
                disabled={step === 1}
              >
                Prev
              </button>
              <button
                onClick={nextStep}
                type='submit'
                className={`d-flex justify-content-center align-items-center py-1 px-3 rounded-pill text-white`}
                style={{ backgroundColor: 'var(--green-color)', border: 'none' }}
              >
                {step !== last_page ? 'Next' : 'Finish'}
              </button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default Test1
