import { MDBIcon } from 'mdb-react-ui-kit'
import React, { useEffect, useState } from 'react'
import { Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { BiPlus } from 'react-icons/bi'
import * as API from '../../../Apisurl'
import CustomDropzone from '../CustomDropzone'
import '../steps.css'

const StepThree = ({ isFlashCard, id, setId, data, setData }) => {
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState()
  const [show, setShow] = useState(false)
  const [action, setAction] = useState('')
  const [validated, setValidated] = useState(false)

  const [extra, setExtra] = useState()

  const initialValues = {
    name: '',
    name_title: '',
    image: 'https://ui-nema-database.s3.ap-south-1.amazonaws.com/education/level1/fruitslevel1.jpeg',
  }
  const [cat, setCat] = useState(initialValues)

  useEffect(() => {
    // Revoke the data uris to avoid memory leaks
    return () => URL.revokeObjectURL(file)
  }, [file])

  useEffect(() => {
    let ext = isFlashCard
      ? { sub_cat: [] }
      : {
          levels: [
            {
              name: 'level1',
              name_title: 'level1',
              image: '',
              video: '',
            },
            {
              name: 'level2',
              name_title: 'level2',
              image: '',
              video: '',
            },
            {
              name: 'level3',
              name_title: 'level3',
              image: '',
              video: '',
            },
          ],
        }
    setExtra(ext)
  }, [isFlashCard])

  const handleChange = (e) => {
    const { value } = e.target
    setCat((old) => ({ ...old, name: value, name_title: value }))
  }

  const upload = async () => {
    if (!file) return

    try {
      setLoading(true)

      const formData = new FormData()
      formData.append('user_id', sessionStorage.getItem('session'))
      formData.append('folder_type', 'module_category')
      formData.append('file', file)

      const response = await fetch(`${API.Fetchurl}file_upload_1`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('session'),
        },
      })

      const data = await response.json()
      let imageURL = data.file_path[0]
      id
        ? setData((old) => old.map((d, i) => (i === id ? { ...d, image: imageURL } : d)))
        : setCat((old) => ({ ...old, image: imageURL }))

      setFile()

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const handleShow = (action, id) => {
    if (action === 'edit') {
      setAction('Edit')
      setCat(data[id])
    } else {
      setAction('Add')
      setCat({ ...initialValues, ...extra })
    }
    setShow(true)
  }

  const handleClose = () => {
    setFile()
    setShow(false)
  }

  const remove = (id) => {
    setData((old) => old.filter((d, i) => i !== id))
  }

  const submit = (e) => {
    e.preventDefault()
    if (e.currentTarget.checkValidity() === false) {
      e.stopPropagation()
    } else {
      if (action === 'Edit') {
        setData((old) => old.map((d, i) => (i === id ? cat : d)))
      } else {
        setData((old) => [...old, cat])
        setId(0)
      }
      handleClose()
    }
    setValidated(true)
  }

  return (
    <>
      <Row>
        <h4>Categories</h4>
        <Col md={4}>
          <button
            type='button'
            onClick={() => handleShow('add')}
            className='d-flex justify-content-center align-items-center mt-2 py-1 px-2 rounded w-100 bg-transparent'
            style={{
              height: '77px',
              border: '2px dashed var(--light-gray)',
            }}
          >
            <span className='d-flex align-items-center text-secondary gap-2' style={{ fontWeight: 'bold' }}>
              <BiPlus
                style={{
                  fontSize: '24px',
                }}
              />
              Add New Category
            </span>
          </button>
        </Col>
        {data?.map((d, idx) => {
          return (
            <Col md={4} key={idx} onClick={() => setId(idx)}>
              <div
                className='d-flex justify-content-between align-items-center mt-2 p-2 rounded'
                style={{
                  border: id !== idx ? '2px solid var(--light-gray)' : '2px solid var(--green-border-color)',
                  backgroundColor: id === idx && 'var(--green-highlight-color)',
                }}
              >
                <div className='preview-category-card'>
                  <div className='rounded-circle'>
                    <img src={d.image} alt='category-img' />
                  </div>
                  <h3>{d.name}</h3>
                </div>

                <MDBIcon fas icon='trash' style={{ fontSize: '14px', color: '#c36b6b' }} onClick={() => remove(idx)} />
                <MDBIcon
                  fas
                  icon='pen'
                  style={{ fontSize: '14px', color: '#6BC3BA', marginInline: '10px' }}
                  onClick={() => handleShow('edit', idx)}
                />
              </div>
            </Col>
          )
        })}
      </Row>
      <Modal size='md' show={show} onHide={handleClose} className='FormModal'>
        <Form noValidate validated={validated} onSubmit={submit}>
          <Modal.Header closeButton className='px-4'>
            <h2 className='m-0'>{action} Category</h2>
          </Modal.Header>
          <Modal.Body>
            <div className='d-flex flex-column p-2 gap-2'>
              <div className='border-bottom pb-4'>
                <Form.Group className='mb-3' controlId='category'>
                  <Form.Label>Category</Form.Label>
                  <Form.Control
                    required
                    type='text'
                    name='name'
                    value={cat?.name}
                    onChange={handleChange}
                    maxLength='30'
                  />
                  <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
                </Form.Group>

                <div className='d-flex gap-1'>
                  <CustomDropzone setData={setFile} isMultiple={false} />
                  <button
                    onClick={upload}
                    disabled={!file || loading}
                    type='button'
                    className='d-flex justify-content-center align-items-center py-1 px-3 border-0 rounded-3 text-white'
                    style={{ backgroundColor: 'var(--green-color)' }}
                  >
                    {loading ? <Spinner animation='border' /> : 'Upload'}
                  </button>
                </div>
              </div>
              <div>
                <h6 className='mb-3'>Preview</h6>
                <div className='d-flex align-items-center'>
                  <div>
                    <div className='preview-category-card'>
                      <div className='rounded-circle'>
                        <img
                          src={file ? URL.createObjectURL(file) : cat?.image}
                          alt='category-img'
                          onLoad={() => URL.revokeObjectURL(file)}
                        />
                      </div>
                      <h3>{cat?.name}</h3>
                    </div>
                    {!file ? <p>uploaded</p> : <p>preview only, click upload to save</p>}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type='button'
              className='d-flex justify-content-center align-items-center py-1 px-3 rounded-pill border border-2 text-secondary bg-transparent'
              onClick={() => setShow(false)}
            >
              Close
            </button>
            <button
              type='submit'
              className='d-flex justify-content-center align-items-center py-1 px-3 border-0 rounded-pill text-white'
              style={{ backgroundColor: 'var(--green-color)' }}
            >
              {action}
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default StepThree
