import react, { useEffect } from 'react'
import FormHeader from '../includes/FormHeader'
import { Container, Row, Col } from 'react-bootstrap'
import LoginContent from '../login/LoginContent'
import LoginItem from './LoginItem'
import LoginForm from './LoginForm'

const LoginMain = () => {
  // useEffect(() => {
  //   document.documentElement.style.setProperty('--primary-gradient', 'radial-gradient(98.07% 73.14% at 3.51% 19.05%, #97d5cd 8.51%, #54baaf 100%)')
  //   document.documentElement.style.setProperty('--primary-color', '#6bc3ba')
  //   document.documentElement.style.setProperty('--green-color', '#3c726e')
  //   document.documentElement.style.setProperty('--green-light-color', '#98e8df')
  //   document.documentElement.style.setProperty('--green-border-color', '#00bdb2')
  //   document.documentElement.style.setProperty('--green-highlight-color', 'rgba(107, 195, 186, 0.22)')
  //   document.documentElement.style.setProperty('--secondary-color', 'rgba(3, 2, 2, 0.612)')
  //   document.documentElement.style.setProperty('--grey-color', '#f5f5f5')
  // }, [])
  return (
    <>
      <FormHeader />
      <main>
        <section class='login-form-section '>
          <Container>
            <Row>
              <Col md={6} className='my-auto heading-container'>
                <LoginContent />
              </Col>
              <Col md={6} className='o-1'>
                <LoginForm />
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  )
}

export default LoginMain
