import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import * as API from '../../Apisurl'
import Context from '../../Context'
import HeaderPdf from './pdfparts/header'

const Assessment = ({ brainData }) => {
  const { selectValue } = useContext(Context)

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [info, setInfo] = useState(null)

  useEffect(() => {
    const getWeekPlan = async () => {
      if (!selectValue.user_id) return

      setLoading(true)
      setError(null)
      try {
        const res = await fetch(`${API.Fetchurl}roles_user?user_id=${selectValue.user_id}`)

        if (!res.ok) throw new Error('Something went wrong')

        const data = await res.json()
        setInfo({
          title: data.character,
          desc: data.about.desc,
          image: data.about.image,
        })
      } catch (err) {
        console.log(err)
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    getWeekPlan()
  }, [selectValue.user_id])

  if (loading || !brainData) return <div>Loading...</div>
  if (error) return <div>Error...... {error}</div>

  const getColor = (val, optimal) => {
    if (val >= optimal) return 'green'
    else if (val >= 40) return 'rgb(248, 210, 53)'
    else return 'rgb(238, 67, 54)'
  }

  const brainTableData = {
    memory: [brainData.charts.Brain_Strength.memory[0], brainData.charts.Brain_Strength.memory[2]],
    reasoning: [brainData.charts.Brain_Strength.thinking[0], brainData.charts.Brain_Strength.thinking[2]],
    association: [brainData.charts.Brain_Strength.association[0], brainData.charts.Brain_Strength.association[2]],
    visualization: [brainData.charts.Brain_Strength.vision[0], brainData.charts.Brain_Strength.vision[2]],
  }

  const brainActual = Math.round(
    Object.values(brainTableData).reduce((total, curr) => {
      total += curr[0]
      return total
    }, 0) / 4
  )

  const brainOptimal = Math.round(
    Object.values(brainTableData).reduce((total, curr) => {
      total += curr[1]
      return total
    }, 0) / 4
  )

  const highestCognition = Object.entries(brainTableData).sort((a, b) => b[1][0] - a[1][0])[0][0]

  const cognition = {
    association: ['Prof Octopus', 'Agent Dolphin', 'Captian Whale'],
    reasoning: ['Cool Seal', 'Master Shark', 'Captian Whale'],
    memory: ['Cool Seal', 'Dr Penguin', 'Agent Dolphin'],
    visualization: ['Dr Penguin', 'Prof Octopus', 'Master Shark'],
  }

  return (
    <div className='page-break'>
      <HeaderPdf />

      <div className='pdf-content-container'>
        <div class='heading-seprator'>
          <h1>
            <span>ASSESSMENT</span>
          </h1>
        </div>
        <h4 className='my-4 fw-bolder'>Cognition Profile</h4>
        <div className='d-flex gap-5 '>
          <div>
            <img
              src={info.image}
              alt='personality-character'
              height={150}
              width={150}
              className='rounded-circle'
              style={{ objectFit: 'cover', border: '8px solid var(--primary-color)' }}
            />
          </div>
          <div>
            <h4
              className='px-3 py-1 fw-bolder'
              style={{
                width: 'fit-content',
                borderRadius: '12ch',
                borderBottomLeftRadius: '0px',
                backgroundColor: 'var(--primary-color)',
              }}
            >
              {info.title}
            </h4>
            <p className='fw-bolder fs-5 mb-2'>
              {info.desc[0][0]}
              <span className='text-lowercase'>{info.desc[0].slice(1)}</span>
            </p>
            <p className='fs-6 mb-2' style={{ lineHeight: 'normal' }}>
              {info.desc[1]}
            </p>
            <p className='fs-6 m-0' style={{ fontFamily: 'sorabold', color: 'green' }}>
              Family Cognition: <span className='text-uppercase'>'{highestCognition[0]}'</span> - {highestCognition}
            </p>
            <p>
              You can adapt to various roles that highlight your strengths. Diverse Characters : (
              {cognition[highestCognition].join(', ')})
            </p>
          </div>
        </div>
        <div className='d-flex align-items-center position-relative my-3'>
          <div
            className='rounded-circle position-absolute bg-white'
            style={{ height: '150px', width: '150px', border: '12px solid rgb(65,139,212)', padding: '20px' }}
          >
            <div
              className='fs-3 fw-bold text-white rounded-circle d-flex justify-content-center align-items-center'
              style={{ height: '100%', width: '100%', background: 'rgb(65,139,212)' }}
            >
              {brainActual}%
            </div>
          </div>
          <div
            className='bg-secondary bg-opacity-10 py-2 w-100 ms-5'
            style={{ borderRadius: '12px', paddingLeft: '150px' }}
          >
            <p>
              Character Assigned Based on Strength: <span style={{ fontFamily: 'sorabold' }}>{info.title}</span>
            </p>

            <p className='m-0'>
              {brainActual >= brainOptimal ? 'Good' : brainActual >= 40 ? 'Medium' : 'Weak'} Cognitive Ability:
              <span style={{ fontFamily: 'sorabold' }}> {brainActual}%</span>
            </p>

            <p className='m-0'>
              Optimal Range: <span style={{ fontFamily: 'sorabold' }}> {brainOptimal}% and above</span>
            </p>
          </div>
        </div>
        <h4 className='mb-4 fw-bolder mt-5'>Evaluated Parameters</h4>
        <table
          className='table table-sm table-bordered text-center mb-4'
          style={{ tableLayout: 'fixed', borderRadius: '12px', overflow: 'hidden' }}
        >
          <thead>
            <tr className='bg-secondary bg-opacity-10'>
              <th>Evaluated</th>
              <th>Optimal</th>
              <th>{selectValue.username}</th>
            </tr>
          </thead>
          <tbody className='border-top'>
            <tr>
              <th>Memory</th>
              <td>{brainTableData.memory[1].toFixed(0)}%</td>
              <td>
                <div
                  className='text-center text-white rounded-pill'
                  style={{
                    height: '100%',
                    width: '100%',
                    background: getColor(brainTableData.memory[0], brainTableData.memory[1]),
                  }}
                >
                  {brainTableData.memory[0].toFixed(0)}%
                </div>
              </td>
            </tr>
            <tr>
              <th>Reasoning</th>
              <td>{brainTableData.reasoning[1].toFixed(0)}%</td>
              <td>
                <div
                  className='text-center text-white rounded-pill'
                  style={{
                    height: '100%',
                    width: '100%',
                    background: getColor(brainTableData.reasoning[0], brainTableData.reasoning[1]),
                  }}
                >
                  {brainTableData.reasoning[0].toFixed(0)}%
                </div>
              </td>
            </tr>
            <tr>
              <th>Association</th>
              <td>{brainTableData.association[1].toFixed(0)}%</td>
              <td>
                <div
                  className='text-center text-white rounded-pill'
                  style={{
                    height: '100%',
                    width: '100%',
                    background: getColor(brainTableData.association[0], brainTableData.association[1]),
                  }}
                >
                  {brainTableData.association[0].toFixed(0)}%
                </div>
              </td>
            </tr>
            <tr>
              <th>Visualization</th>
              <td>{brainTableData.visualization[1].toFixed(0)}%</td>
              <td>
                <div
                  className='text-center text-white rounded-pill'
                  style={{
                    height: '100%',
                    width: '100%',
                    background: getColor(brainTableData.visualization[0], brainTableData.visualization[1]),
                  }}
                >
                  {brainTableData.visualization[0].toFixed(0)}%
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <h4 className='mb-3 fw-bolder'>Conclusion</h4>
        <p style={{ marginBottom: '4px', lineHeight: 'normal' }}>
          While{' '}
          <span className='fst-italic underline' style={{ fontFamily: 'sorabold' }}>
            {selectValue.username}
          </span>{' '}
          has shown to have{' '}
          <span style={{ fontFamily: 'sorabold', color: 'var(--button-color)' }}>
            {brainActual >= brainOptimal ? 'good' : brainActual >= 40 ? 'average' : 'weak'}
          </span>{' '}
          cognitive well-being, We recommend training weakened cognitive skills and continuing to practice healthy
          lifestyle habits.We recommend starting a personalized brain training program from{' '}
          <span className='fw-bolder'>NEMA AI</span>
        </p>

        <div className='card-container mt-3'>
          <div className='card-list p-2 mb-0'>
            <div className='clr-box clr-box-green'></div>
            <div className='card-content'>
              {/* <h3>Green</h3> */}
              <p>
                Cognitive <br /> strengths
              </p>
            </div>
          </div>
          <div className='card-list p-2 mb-0'>
            <div className='clr-box clr-box-yellow'></div>
            <div className='card-content'>
              {/* <h3>Yellow</h3> */}
              <p>
                Average <br /> cognitive skills
              </p>
            </div>
          </div>

          <div className='card-list p-2 mb-0'>
            <div className='clr-box clr-box-red'></div>
            <div className='card-content'>
              {/* <h3>Red</h3> */}
              <p>
                Cognitive <br /> weaknesses
              </p>
            </div>
          </div>
        </div>
        <footer>
          <h6>02</h6>
        </footer>
      </div>
    </div>
  )
}

export default Assessment
