import React from 'react'
import { Container } from 'react-bootstrap/'
import Activity from '../dashboard/Activity'
import Header from '../profile/Header'
import UserView from './UserView'

const Insights = () => {
  return (
    <div className='content flex-grow-1 d-flex flex-column'>
      <div className='dashboard-heading'>
        <div class='content-container'>
          <h2>Insights</h2>
          <p>Personal data and compare with others.</p>
        </div>
        <Activity />
      </div>
      <Header />
      <UserView />
    </div>
  )
}

export default Insights
