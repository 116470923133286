import React, { useEffect, useState } from 'react'
import { DragDropContainer, DropTarget } from 'react-drag-drop-container'

const LivingNonLiving = (props) => {
  const [result, setResult] = useState([])

  const [count, setCount] = useState(0)

  useEffect(() => {
    let len = props.evs.options.reduce((total, curr) => {
      if (curr.type === props.evs.find) {
        total += 1
      }
      return total
    }, 0)
    setCount(len)
  }, [props.evs])

  const handleHit = (e) => {
    console.log('hit', e)
    if (result.length >= count) return

    e.containerElem.style.visibility = 'hidden'
    let data = e.dragData
    let img = document.createElement('img')
    img.src = data.element
    img.style.height = '200px'
    img.style.width = '250px'
    let parentContainer = e.target
    parentContainer.appendChild(img)
  }

  const handleDrop = (e) => {
    console.log('drop', e)
    if (result.length >= count) return

    let choice = e.dragData.type
    let img = e.dragData.element
    let isCorrect = props.evs.find === choice

    isCorrect && props.setScore((s) => s + 1)
    setResult([...result, { ques: props.evs.find, ans: img, via: isCorrect ? 'correct' : 'incorrect' }])
  }

  const reset = () => {
    let el = document.getElementsByClassName('drag-element')
    let dropTargets = document.getElementsByClassName('drop-target')
    Object.values(dropTargets).forEach((dropTarget) => {
      dropTarget.innerHTML = ''
    })
    Object.values(el).forEach((e) => {
      e.style.visibility = 'visible'
    })
    setResult([])
    props.setScore(0)
  }

  const check = () => {
    localStorage.setItem('useranswer', JSON.stringify(result))
    props.submit()
  }

  return (
    <div className='d-flex align-items-center mx-5' style={{ minHeight: '70vh' }}>
      <div className='d-flex flex-column gap-4'>
        {props.evs.options.slice(0, props.evs.options.length / 2).map((d, i) => {
          return <DragItem key={i} data={d} handleDrop={handleDrop} />
        })}
      </div>
      <div className='d-flex flex-column flex-wrap align-items-center  flex-grow-1'>
        <h1 className='fs-3 fw-bold'>
          Find <span className='text-capitalize'>{props.evs.find}</span> Things{' '}
          <span className='fs-6 ms-4 fw-normal'>({count - result.length} Remaining)</span>
        </h1>

        <DropTarget targetKey='items' onHit={handleHit}>
          <div
            className='drop-target'
            style={{
              minWidth: '450px',
              minHeight: '450px',
              border: '4px dashed var(--primary-color)',
            }}
          ></div>
        </DropTarget>
        <div className=' my-2'>
          <button type='button' onClick={reset} className='btn common-btn mx-2 py-3 px-5'>
            Reset
          </button>
          <button type='button' onClick={check} className='btn common-btn py-3 px-5' disabled={result.length < count}>
            Submit
          </button>
        </div>
      </div>
      <div className='d-flex flex-column gap-4'>
        {props.evs.options.slice(props.evs.options.length / 2).map((d, i) => {
          return <DragItem key={i} data={d} handleDrop={handleDrop} />
        })}
      </div>
    </div>
  )
}

export default LivingNonLiving

export const DragItem = ({ data, handleDrop }) => {
  return (
    <DragDropContainer targetKey='items' dragData={{ type: data.type, element: data.image }} onDrop={handleDrop}>
      <div className='drag-element'>
        <img height={200} width={250} src={data.image} alt={data.type} />
      </div>
    </DragDropContainer>
  )
}
