import react, { useEffect, useState } from 'react'
import closeIcon from '../assets/images/close_round_icon.svg'
import logo from '../assets/images/logo.svg'
import { Link, NavLink } from 'react-router-dom'
import { Button, Container, Row, Col, Offcanvas, Navbar, Nav } from 'react-bootstrap'
import g10 from '../assets/images/g10.svg'
import group175 from '../assets/images/new_logo.svg'
import './formheader.css'
import { RiMailLine } from 'react-icons/ri'
import { NavDropdown } from 'react-bootstrap'

const options = ['school', 'corporate', 'institution']

const data = [{ name: 'school' }, { name: 'corporate' }, { name: 'institution' }]

const FormHeader = () => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => {
    setShow(true)
  }

  return (
    <>
      <div className='navbar-area'>
        <div className='main-navbar'>
          <Container fluid>
            <Navbar collapseOnSelect expand='xl'>
              <Navbar.Brand href='/'>
                <img src={group175} className='black-logo' alt='image' />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls='responsive-navbar-nav' />
              <Navbar.Collapse id='responsive-navbar-nav'>
                <Nav className='mx-auto'>
                  <div className='nav-item'>
                    <Nav.Link href='https://nemaai.com/'>Home</Nav.Link>
                  </div>
                  <div className='nav-item'>
                    <Nav.Link href='https://nemaai.com/smartscan'>Smart Scan</Nav.Link>
                  </div>
                  <div className='nav-item'>
                    <Nav.Link href='https://nemaai.com/blog'>Science</Nav.Link>
                  </div>

                  <NavDropdown title='Services' id='Services' className='nav-item'>
                    <li className='nav-item'>
                      <Nav.Link href='https://nemaai.com/individual' className='nav-link'>
                        For Special Schools
                      </Nav.Link>
                    </li>

                    <li className='nav-item'>
                      <Nav.Link href='https://nemaai.com/corporate' className='nav-link'>
                        Colleges & Corporates
                      </Nav.Link>
                    </li>
                  </NavDropdown>
                  <div className='nav-item'>
                    <Nav.Link href='https://nemaai.com/contact'>Book Session</Nav.Link>
                  </div>
                  <div className='nav-item'>
                    <NavDropdown title='More' id='More'>
                      <li className='nav-item'>
                        <Nav.Link href='https://nemaai.com/about-us' className='nav-link'>
                          About us
                        </Nav.Link>
                      </li>
                    </NavDropdown>
                  </div>
                </Nav>
              </Navbar.Collapse>
              <div className='d-none d-xl-flex others-options d-flex align-items-center'>
                <div className='option-item'>
                  <div className='call-info'>
                    <i className='ri-mail-line'>
                      <RiMailLine className='text-black' />
                    </i>
                    <a href='mailto:support@nemaai.com'>support@nemaai.com</a>
                  </div>
                </div>
                <div className='option-item'>
                  <NavLink className='default-btn border-0 px-4 py-2' to='/loginmain'>
                    Login
                  </NavLink>
                </div>
                <div className='option-item'>
                  <button className='outline-default-btn bg-transparent px-4 py-2' type='button' onClick={handleShow}>
                    Signup
                  </button>
                </div>
              </div>
            </Navbar>
          </Container>
        </div>
      </div>

      {/* <!-- End Navbar Area --> */}

      {/* <Navbar collapseOnSelect expand='lg' className='bg-body-tertiary'>
        <Navbar.Brand href='/'>
          <figure>
            <img src={logo} alt='' />
          </figure>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='mx-auto'>
            <Nav.Link href='https://nemaai.com/'>Home</Nav.Link>
            <Nav.Link href='https://nemaai.com/contact'>Contact Us</Nav.Link>
          </Nav>
          <Nav>
            <NavLink
              to='/loginmain'
              className='mx-4'
              style={({ isActive }) => {
                return {
                  fontWeight: isActive ? 'bold' : '',
                  color: isActive ? 'var(--primary-color)' : 'var(--secondary-color)',
                }
              }}
            >
              Login
            </NavLink>
            <Button variant='primary' className='common-btn' style={{ padding: '12px 31px' }} type='button' onClick={handleShow}>
              Signup
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Navbar> */}

      <Offcanvas show={show} onHide={handleClose} placement='top' style={{ height: '100%' }}>
        <Offcanvas.Body className='d-flex align-items-center justify-content-center'>
          <Container className='h-100'>
            <div className='d-flex align-items-center justify-content-end'>
              <img
                src={closeIcon}
                alt='close button'
                height={84}
                width={84}
                className='close-btn'
                onClick={handleClose}
              />
            </div>
            <Row>
              <Col className='me-4'>
                <h1 className='display-3 fw-bolder' style={{ color: '#54baaf' }}>
                  Sign up to access your account
                </h1>
              </Col>
              <Col className='ms-4 d-none d-md-block'>
                <img src={g10} alt='g10' width={'100%'} height={400} />
              </Col>
            </Row>
            <Row>
              {options.map((name, i) => (
                <Col key={i} md={6}>
                  <NavLink to={`/role/${name}`} onClick={handleClose}>
                    <div className={`uber-btn my-4 py-4 ${i % 2 === 0 ? 'me-4' : i % 2 === 1 ? 'ms-4' : ''}`}>
                      <h1 className='display-6 fw-bolder text-capitalize'>Sign up as {name}</h1>
                      <div className='overflow-hidden'>
                        <svg viewBox='0 0 24 24' height={36} width={36} fill='currentColor'>
                          <path d='M21.93 12.36A1 1 0 0 0 22 12a1 1 0 0 0-.07-.36c0-.05-.05-.08-.07-.13a1.19 1.19 0 0 0-.09-.15l-5-6a1 1 0 1 0-1.54 1.28L18.86 11H3a1 1 0 0 0 0 2h15.86l-3.63 4.36a1 1 0 0 0 .13 1.41A1 1 0 0 0 16 19a1 1 0 0 0 .77-.36l5-6a1.19 1.19 0 0 0 .09-.15c.02-.05.05-.08.07-.13Z' />
                        </svg>
                      </div>
                    </div>
                  </NavLink>
                </Col>
              ))}
            </Row>
          </Container>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}
export default FormHeader
