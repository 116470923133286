import { useState } from 'react'
import { Col } from 'react-bootstrap'
import ChatBot from 'react-simple-chatbot'
import { ThemeProvider } from 'styled-components'
import { GetCurrentDate } from '../../../CurrDate'

const ChatBoxGame = ({ submit }) => {
  const [result, setResult] = useState([])

  let date = GetCurrentDate()

  const steps = [
    {
      id: '1',
      message: 'Welcome to Nema AI Chat Bot',
      trigger: '2',
    },
    {
      id: '2',
      message: 'What is your name?',
      trigger: '3',
    },
    {
      id: '3',
      user: true,
      validator: (value) => {
        if (!value || value === '') {
          return 'Please enter your name'
        }
        return true
      },
      trigger: '4',
    },
    {
      id: '4',
      message: ({ previousValue }) => {
        setResult((prev) => {
          let update = [
            ...prev,
            {
              ques: 'What is your name?',
              ans: previousValue,
            },
          ]
          return update
        })

        return 'Hi {previousValue}!'
      },
      trigger: '5',
    },
    {
      id: '5',
      message: 'How old are you?',
      trigger: '6',
    },
    {
      id: '6',
      user: true,
      validator: (value) => {
        if (isNaN(value)) {
          return 'Age should be a number'
        } else if (value < 1 || value > 100) {
          return 'Age should be between 1 - 100'
        } else return true
      },
      trigger: '7',
    },
    {
      id: '7',
      message: ({ previousValue }) => {
        setResult((prev) => {
          let update = [
            ...prev,
            {
              ques: 'How old are you?',
              ans: previousValue,
            },
          ]
          return update
        })
        return 'You are {previousValue} years old.'
      },
      trigger: '8',
    },
    {
      id: '8',
      message: 'What is your favourite colour?',
      trigger: '9',
    },
    {
      id: '9',
      user: true,
      validator: (value) => {
        if (!value || value === '') {
          return 'Please enter a colour'
        }
        return true
      },
      trigger: '10',
    },
    {
      id: '10',
      message: ({ previousValue }) => {
        setResult((prev) => {
          let update = [
            ...prev,
            {
              ques: 'What is your favourite colour?',
              ans: previousValue,
            },
          ]
          return update
        })
        return '{previousValue} is your favourite colour.'
      },
      trigger: '11',
    },
    {
      id: '11',
      options: [
        {
          value: '1',
          label: 'End Conversation',
          end: true,
        },
        {
          value: '2',
          label: 'Chat Again',
          trigger: '2',
        },
      ],
    },
  ]

  const handleEnd = () => {
    submit(date, result)
  }

  const optionsStyle = {
    backgroundColor: '#3c726e',
    color: 'white',
    fontSize: '36px',
  }

  const bubbleStyle = {
    fontSize: '36px',
  }

  const inputStyle = {
    fontSize: '36px',
    fontWeight: 'bold',
  }

  const theme = {
    background: '#f5f8fb',
    fontFamily: 'Poppins',
    headerBgColor: '#6BC3BA',
    headerFontColor: '#fff',
    headerFontSize: '48px',
    botBubbleColor: '#3c726e',
    botFontColor: '#fff',
    userBubbleColor: '#fff',
    userFontColor: '#000',
  }

  return (
    <div className='container d-flex justify-content-center align-items-center'>
      <ThemeProvider theme={theme}>
        <Col md={12}>
          <ChatBot
            className='chat-bot'
            steps={steps}
            width={'100%'}
            bubbleOptionStyle={optionsStyle}
            bubbleStyle={bubbleStyle}
            userAvatar={require('../../../assets/images/child2.png')}
            headerTitle={'NEMA AI'}
            placeholder={'Type or Speak to Reply'}
            inputStyle={inputStyle}
            recognitionEnable={true}
            userDelay={500}
            botDelay={800}
            handleEnd={handleEnd}
          />
        </Col>
      </ThemeProvider>
    </div>
  )
}

export default ChatBoxGame
