import { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import * as Apiurl from '../../../Apisurl'
import headersReq from '../../../HeadersJson'
import UserJson from '../../../Userjson'
import refreshbtn from '../../../assets/images/refresh.svg'
import Focus from '../../../includes/Focus'
import Progress from '../Testprogrsscom'
import Festiv from './Festiv'
import FindItems from './FindItems'
import LivingNonLiving from './LivingNonLiving'
import ModesOfTransport from './ModesOfTransport'
import PartsOfBody from './PartsOfBody'
import PartsOfBody2 from './PartsOfBody2'
import PartsOfBody3 from './PartsOfBody3'
import PartsOfTree from './PartsOfTree'
import PartsOfTree2 from './PartsOfTree2'
import Pollution from './Pollution'
import { GetCurrentDate } from '../../../CurrDate'
import PartsOfTree3 from './PartsOfTree3'

const Evs = () => {
  const reqJson = headersReq()
  const [callday, setcallday] = useState(1)
  const [mathsdata, setmathsdata] = useState([])
  const [constantTime, setconstantTime] = useState('')
  const [data1, setdata1] = useState([])
  const [connect, setconnected] = useState(false)
  const [messageHistory, setMessageHistory] = useState([])
  const [score, setScore] = useState(0)
  const location = useLocation()
  const [showError, setShowError] = useState()
  const [timer, setTimer] = useState(30)
  const [page, setPage] = useState(0)
  const [smShow, setsmShow] = useState(false)
  const [level, setlevel] = useState('')
  const [activeleftAnswer, setleftactiveAnswer] = useState('')
  const [activerightAnswer, setrightactiveAnswer] = useState(false)
  let leveldata = location.state.link
  const [englishData, setEnglishData] = useState([])
  let cat = location.state.category
  let initialArray = []
  const [action, setactiondata] = useState([])
  const [theArray, setTheArray] = useState(initialArray)
  const [inputvalue, setinputvalue] = useState('')
  let data11 = UserJson()
  let date = GetCurrentDate()

  useEffect(() => {
    async function levelgame() {
      const response = await fetch(`${Apiurl.Fetchurl + 'level_evs'}`, {
        method: 'POST',
        headers: reqJson,
        body: JSON.stringify({ category: cat }),
      })
      const childdata = await response.json()
      return childdata
    }
    levelgame()
      .then((data) => {
        setEnglishData(data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const submitResult = (redirect) => {
    let answer = JSON.parse(localStorage.getItem('useranswer'))

    let getScore = answer.filter((o, i) => {
      return o.via == 'correct'
    })

    let alldata = {
      ...data11,
      result: JSON.stringify({
        ques: englishData != undefined ? englishData[location.state.link] : '',
        score: getScore.length,
        total: englishData[location.state.link].length,
        ans: answer,
      }),
      date: constantTime,
    }

    const esc = encodeURIComponent

    const query = Object.keys(alldata)
      .map((k) => `${esc(k)}=${esc(alldata[k])}`)
      .join('&')

    async function levelgame() {
      const response = await fetch(`${Apiurl.Fetchurl + 'manual_report_fc?' + query + ''}`, {
        method: 'GET',
        headers: reqJson,
      })
      const childdata = await response.json()
      return childdata
    }
    levelgame()
      .then((data) => {
        navigate(`${redirect}?id=${data.serial_num}`, { state: { ...location.state, date: date } })
        setmathsdata(data)
      })
      .catch((error) => {
        alert(error)
      })
  }

  const submitCustomGame = () => {
    let data = JSON.parse(localStorage.getItem('useranswer'))

    let alldata = {
      ...data11,
      result: JSON.stringify({
        ques: data.map(({ ques }) => ques),
        score: data.reduce((total, curr) => {
          let point = curr.via === 'correct' ? 1 : 0
          return total + point
        }, 0),
        total: data.length,
        ans: data.map(({ ans, via }) => ({ answer: ans, via: via })),
      }),
      date: date,
    }
    const esc = encodeURIComponent

    const query = Object.keys(alldata)
      .map((k) => `${esc(k)}=${esc(alldata[k])}`)
      .join('&')

    async function saveReport() {
      const response = await fetch(`${Apiurl.Fetchurl + 'manual_report_fc?' + query + ''}`, {
        method: 'GET',
        headers: reqJson,
      })
      const childdata = await response.json()
      return childdata
    }

    saveReport()
      .then((data) => {
        navigate(`enresult?id=${data.serial_num}`, { state: { ...location.state, date: date } })
      })
      .catch((error) => {
        alert(error)
      })
  }

  let temp = ['parts_of_tree', 'body_parts', 'mean_of_transport']
  // console.log(aanjsonData);
  let json = temp.includes(cat.trim()) ? [] : englishData.length != 0 ? englishData[leveldata][page] : []
  let length1 = englishData[location.state.link] || 1
  let downloadTimer
  let navigate = useNavigate()
  let catarr = ['adjectives', '2_phase_mcq', 'sentences_mcq']

  if (englishData.length != 0 || temp.includes(cat.trim())) {
    const renderEnglishcat = () => {
      let games
      switch (cat.trim()) {
        case 'festival':
        case 'our_helpers':
        case 'what_we_wear':
          games = (
            <Festiv
              englishData={json}
              datalength={englishData[location.state.link]}
              setPage={setPage}
              submitResult={submitResult}
              setTheArray={setTheArray}
              page={page}
              time={setconstantTime}
              setScore={setScore}
              setdata1={setdata1}
              data1={data1}
            />
          )

          break
        case 'pollution':
        case 'solar_system':
          games = (
            <Pollution
              evs={json}
              datalength={englishData[location.state.link]}
              setPage={setPage}
              submitResult={submitResult}
              setTheArray={setTheArray}
              page={page}
              time={setconstantTime}
              setScore={setScore}
              setdata1={setdata1}
              data1={data1}
            />
          )
          break

        case 'parts_of_tree':
          if (leveldata === 'level1') games = <PartsOfTree />
          if (leveldata === 'level2') games = <PartsOfTree2 submit={submitCustomGame} />
          if (leveldata === 'level3') games = <PartsOfTree3 submit={submitCustomGame} />
          break

        case 'body_parts':
          if (leveldata === 'level1') games = <PartsOfBody submit={submitCustomGame} />
          if (leveldata === 'level2') games = <PartsOfBody2 submit={submitCustomGame} />
          if (leveldata === 'level3') games = <PartsOfBody3 submit={submitCustomGame} />
          break

        case 'mean_of_transport':
          games = <ModesOfTransport submit={submitCustomGame} level={parseInt(leveldata.split('level')[1])} />
          break

        case 'kitchen':
        case 'bathroom':
        case 'living_room':
        case 'bedroom':
        case 'summer':
        case 'winter':
        case 'monsoon':
          games = <FindItems submit={submitCustomGame} evs={englishData[location.state.link]} setScore={setScore} />
          break

        case 'living':
        case 'non_living':
          games = (
            <LivingNonLiving
              evs={englishData[location.state.link]}
              setPage={setPage}
              submit={submitCustomGame}
              setTheArray={setTheArray}
              page={page}
              setScore={setScore}
            />
          )
          break
      }
      return games
    }
    return (
      <>
        <div>
          <Focus />
        </div>
        {renderEnglishcat()}

        <div className='activity-listing'>
          <ul>
            <li className='active'></li>
            <li className='active'>
              Score:
              <b>{catarr.includes(cat) ? length1.length / 2 : length1.length}</b>/<span>{score}</span>
            </li>
          </ul>

          <ul>
            {/* <li>
              <Progress json={englishData[leveldata]} currindex={page} getarray={theArray} />
            </li> */}
            <li className='refresh-btn'>
              <NavLink to='/school/game/level/colorresult'>
                <button>
                  <img src={refreshbtn} alt='refresh' />
                  Start Another Level
                </button>
              </NavLink>
              <button>
                <img src={refreshbtn} alt='refresh' />
                Start Over
              </button>
            </li>
          </ul>
        </div>
      </>
    )
  }
}
export default Evs
