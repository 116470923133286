import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Select from 'react-select'
import * as Apiurl from '../Apisurl'
import Context from '../Context'
import headersReq from '../HeadersJson'
import user3 from '../assets/images/user4.png'
import demoJSON from '../datasets/demo.json'
import Pagination from '../school/Pagination'

const Studentprofile = ({ via = true }) => {
  const location = useLocation()

  const [senddata, setsenddata] = useState([])
  const [mname, setmname] = useState('')
  const [datai, setDatai] = useState()
  const [active, setActive] = useState(0)
  const [logged, setlogged] = useState(1)
  const [loader, setloaderVia] = useState(false)
  const [arrowDisable, setArrowDisable] = useState(true)

  const elementRef = useRef()

  const {
    setbloomData,
    setpieData,
    setemotData,
    setpmData,
    setreportTable,
    selectValue,
    setSelectedValue1,
    moduleCardjson,
    setmoduleCardjson,
    setselectedModule,
  } = useContext(Context)

  const selectData = useContext(Context)

  let isGroup = localStorage.getItem('isGroup')
  let apname = localStorage.getItem('api_name')
  let reqJson = headersReq()

  async function callAllDashBoardFn(res) {
    async function phaseselect() {
      const response = await fetch(`${Apiurl.Fetchurl + apname}`, {
        method: 'POST',
        headers: reqJson,
        body: JSON.stringify({
          module: 1,
          user_id: res.user_id,
          language: localStorage.getItem('getlang') || 'en',
        }),
      })
      const Admin = await response.json()
      return Admin
    }

    if (sessionStorage.getItem('session') === '123456') {
      localStorage.setItem('modulejson', JSON.stringify(demoJSON))
      setmoduleCardjson(demoJSON)
    } else {
      phaseselect()
        .then((data) => {
          localStorage.setItem('modulejson', JSON.stringify(data))
          setmoduleCardjson(data)
        })
        .catch((error) => {
          console.log(error)
        })
    }

    let url = location.pathname.split('/').pop()

    if (url === '') {
      await selectData.Bloomfn(res).then((data) => {
        setbloomData(data)
      })
      await selectData.piefn(res).then((data) => {
        setpieData(data)
      })

      if (sessionStorage.getItem('headsetVia') === '2') {
        await selectData.emotfn(res).then((data) => {
          setemotData(data)
        })
        await selectData.performanceFn(res).then((data) => {
          setpmData(data)
        })
      }
    }
    if (url === 'schoolreport') {
      await selectData.reportTablefn(res).then((data) => {
        setreportTable(data)
      })
    }
    setloaderVia(true)
  }

  useEffect(() => {
    if (sessionStorage.getItem('session') === '123456') {
      let childData = JSON.parse(localStorage.getItem('childData'))
      setDatai(childData)
      callAllDashBoardFn()
      let sendModulleData = {
        user_id: childData[0].user_uid,
        username: childData[0].username,
        modulename1: 'Module 1',
      }
      setSelectedValue1(sendModulleData)
      localStorage.setItem('sendModulleData', JSON.stringify(sendModulleData))
      return
    }

    async function getGroup() {
      const response = await fetch(
        Apiurl.Fetchurl + `make_group?teacher_user_id=${window.sessionStorage.getItem('session')}`,
        {
          method: 'GET',
          headers: reqJson,
        }
      )
      const groupdata = await response.json()
      return groupdata
    }

    async function populateChild() {
      const response = await fetch(`${Apiurl.Fetchurl + 'child'}`, {
        method: 'POST',
        body: JSON.stringify({
          person_uid: window.sessionStorage.getItem('session'),
        }),
        headers: reqJson,
      })
      const childdata = await response.json()
      return childdata
    }

    let api = localStorage.getItem('api_name')

    if (isGroup === 'true') {
      getGroup()
        .then((data) => {
          setDatai(data.group_distribution)
          let onloadModule =
            api == 'main_module_voc' ? 'Vocational' : api == 'main_module_ass' ? 'Assessment' : 'Module 1'
          let childindex = localStorage.getItem('ischildActive') || 0
          let sendModulleData = {
            user_id: data.group_distribution[childindex].group_user_id,
            username: data.group_distribution[childindex].group_name,
            modulename1: onloadModule,
          }
          setselectedModule(onloadModule)

          setSelectedValue1(sendModulleData)
          callAllDashBoardFn(sendModulleData)
          localStorage.setItem('sendModulleData', JSON.stringify(sendModulleData))
        })
        .catch((error) => {
          console.log('error from grouplist', error)
        })
    } else {
      populateChild()
        .then((data) => {
          setDatai(data.Status.children.registred_child)
          let onloadModule =
            api == 'main_module_voc' ? 'Vocational' : api == 'main_module_ass' ? 'Assessment' : 'Module 1'
          let childindex = localStorage.getItem('ischildActive') || 0
          let sendModulleData = {
            user_id: data.Status.children.registred_child[childindex].user_uid,
            username: data.Status.children.registred_child[childindex].username,
            image: data.Status.children.registred_child[childindex].image,
            modulename1: onloadModule,
          }
          setselectedModule(onloadModule)

          setSelectedValue1(sendModulleData)
          callAllDashBoardFn(sendModulleData)
          localStorage.setItem('sendModulleData', JSON.stringify(sendModulleData))
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [])

  const getModuleData = (res, module) => {
    setActive(res)
    setmname(module)
    setselectedModule(module)

    let json = {
      ...selectValue,
      modulename1: module,
    }

    setloaderVia(false)
    callAllDashBoardFn(json)
  }

  const onSelect = (...res) => {
    let api = localStorage.getItem('api_name')
    let json = {
      user_id: res[0].id,
      username: res[0].username,
      image: res[0].image,
      modulename1: api == 'main_module_voc' ? 'Vocational' : api == 'main_module_ass' ? 'Assessment' : 'Module 1',
    }

    setloaderVia(false)
    localStorage.setItem('ischildActive', res[0].index)

    setSelectedValue1(json)
    callAllDashBoardFn(json)

    localStorage.setItem('sendModulleData', JSON.stringify(json))
  }

  let colourStyles = {
    backgroundColor: 'transparent',
    border: 'none',
  }

  let options1
  if (senddata != undefined) {
    if (datai != undefined) {
      options1 = datai.map((o, i) => {
        if (isGroup === 'true') {
          return {
            value: o.group_user_id,
            label: o.group_name,
            username: o.group_name,
            module: 'child',
            id: o.group_user_id,
            index: i,
            school: o.school_name,
            age: o.age,
            class: o.student_class,
            parent: o.parent_name,
          }
        } else {
          return {
            value: o.user_uid,
            label: o.username,
            username: o.username,
            module: 'child',
            id: o.user_uid,
            index: i,
            school: o.school_name,
            age: o.age,
            class: o.class,
            parent: o.parent_name,
            image: o.image,
          }
        }
      })
    }
  }

  let module1 = Object.keys(moduleCardjson)
  const sortedModules = module1.sort((a, b) => {
    const numA = parseInt(a.split(' ')[1])
    const numB = parseInt(b.split(' ')[1])
    return numA - numB
  })

  const handleHorizantalScroll = (element, speed, distance, step) => {
    console.log(elementRef.current)
    let scrollAmount = 0
    const slideTimer = setInterval(() => {
      elementRef.current.scrollLeft += step
      scrollAmount += Math.abs(step)
      if (scrollAmount >= distance) {
        clearInterval(slideTimer)
      }
      if (elementRef.current.scrollLeft === 0) {
        setArrowDisable(true)
      } else {
        setArrowDisable(false)
      }
    }, speed)
  }
  return (
    <>
      {/* <div className='loader-component' style={loader == false ? { display: 'block' } : { display: 'none' }}>
        <div className='graph-loader-container'>
          <svg class='graph-loader' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'>
            <g>
              <path class='graph-loader__element graph-loader__element--bar' d='M 29 75 L 29 67' />
              <path class='graph-loader__element graph-loader__element--bar' d='M 43 75 L 43 55' />
              <path class='graph-loader__element graph-loader__element--bar' d='M 57 75 L 57 38' />
              <path class='graph-loader__element graph-loader__element--bar' d='M 71 75 L 71 25' />
            </g>
            <g>
              <path
                class='graph-loader__element graph-loader__element--pie'
                d='M 50 25 A 5 5 0 1 1 50 75 A 5 5 0 1 1 50 25'
                stroke-dasharray='0 160'
              />
              <path
                class='graph-loader__element graph-loader__element--pie'
                d='M 50 25 A 5 5 0 1 1 50 75 A 5 5 0 1 1 50 25'
                stroke-dasharray='0 160'
              />
              <path
                class='graph-loader__element graph-loader__element--pie'
                d='M 50 25 A 5 5 0 1 1 50 75 A 5 5 0 1 1 50 25'
                stroke-dasharray='0 160'
              />
              <path
                class='graph-loader__element graph-loader__element--pie'
                d='M 50 25 A 5 5 0 1 1 50 75 A 5 5 0 1 1 50 25'
                stroke-dasharray='0 160'
              />
            </g>
          </svg>

          <p>
            Please wait your
            <img src={logo} alt='' style={{ height: '100px', width: '100px', marginLeft: '10px' }} />
            report is being loaded
          </p>
        </div>
      </div> */}
      <div className='studentprofile std-dropdownContainer'>
        <div className='result-container '>
          <p>Showing result for:</p>

          {datai ? (
            <>
              <div className='dropdown-container'>
                <figure className='dropdown-image'>
                  <img src={selectValue.image === 'image' ? user3 : selectValue.image} alt='user4' />

                  <div style={{ minWidth: '150px' }}>
                    <Select
                      options={options1}
                      defaultValue={
                        options1[
                          localStorage.getItem('ischildActive') == null ? 0 : localStorage.getItem('ischildActive')
                        ]
                      }
                      styles={colourStyles}
                      onChange={(e) => onSelect(e)}
                    />
                  </div>
                </figure>
                <div className='module-container1'>
                  <div className=' module-containr' ref={elementRef}>
                    {sortedModules.map((itm, nd) => {
                      if (itm != undefined) {
                        return (
                          <button
                            key={nd}
                            onClick={() => getModuleData(nd, itm)}
                            className={active == nd ? 'active' : ''}
                          >
                            {itm}
                          </button>
                        )
                      }
                    })}
                  </div>
                  {Object?.keys(moduleCardjson)?.length > 3 ? (
                    <Pagination
                      prevClick={() => handleHorizantalScroll(elementRef.current, 10, 100, -10)}
                      nextClick={() => handleHorizantalScroll(elementRef.current, 10, 100, 10)}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </>
          ) : (
            ''
          )}
        </div>
        <div className='weekly-container'>
          <div className='hours-container' style={via != false ? { display: 'block' } : { display: 'none' }}>
            <h6>
              Hours Logged - <span>{logged} /20</span>
            </h6>
          </div>
        </div>
      </div>
    </>
  )
}
export default Studentprofile
