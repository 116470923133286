import React, { useState, useMemo } from 'react'

const PasswordStrengthMeter = ({ password, setIsStrongPassword }) => {
  const [strength, setStrength] = useState(0)

  const calculateStrength = (password) => {
    const regexList = [/[a-z]/, /[0-9]/, /[^A-Za-z0-9]/]
    let strength = 0
    let capitalLetterFound = /[A-Z]/.test(password)

    regexList.forEach((regex) => {
      if (regex.test(password)) {
        strength++
      }
    })

    if (capitalLetterFound) {
      strength++
    }

    return strength
  }

  useMemo(() => {
    const calculatedStrength = calculateStrength(password)
    setStrength(calculatedStrength)
  }, [password])

  const getStrengthText = (strength) => {
    const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(password)

    if (password.length >= 8 && strength >= 3 && hasSpecialCharacter) {
      setIsStrongPassword(true)
      return 'Strong'
    } else if (password.length >= 2 && hasSpecialCharacter) {
      setIsStrongPassword(false)
      return 'Moderate'
    } else {
      setIsStrongPassword(false)
      return 'Weak'
    }
  }

  const getColor = (strength) => {
    switch (getStrengthText(strength)) {
      case 'Weak':
        return 'red'
      case 'Moderate':
        return 'orange'
      case 'Strong':
        return 'green'
      default:
        return 'red'
    }
  }

  return (
    <div>
      <div className='progress mt-2'>
        <div
          className='progress-bar'
          role='progressbar'
          style={{ width: `${Math.min(strength * 25, 100)}%`, backgroundColor: getColor(strength), height: '20px' }}
        ></div>
      </div>
      <p className='mt-2 small' style={{ fontSize: '12px' }}>
        Password Strength: <span style={{ color: getColor(strength) }}>{getStrengthText(strength)}</span>
      </p>
      <ul class='p-0'>
        <li className='small list-item list-inline mb-1'>* Use at least 8 characters</li>
        <li className='small list-item list-inline mb-1'>* Include a mix of uppercase and lowercase letters, numbers, and special characters.</li>
        <li className='small list-item list-inline mb-1'>* Avoid easily guessable phrases or repeating characters.</li>
      </ul>
    </div>
  )
}

export default PasswordStrengthMeter
