import { useEffect, useState } from 'react'
import { Row, Container, Col } from 'react-bootstrap'
import { Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import swal from 'sweetalert'
import UserJson from '../../../Userjson'
import * as Apiurl from '../../../Apisurl'
import headersReq from '../../../HeadersJson'

const Qrcodepay = () => {
  const reqJson = headersReq()
  const [amount, setAmountinput] = useState('')
  let url = new URL(window.location.href)
  let options = decodeURI(url.searchParams.getAll('data')).split('&')
  console.log(options)
  let data = JSON.parse(options[0])
  let prevurl = options[1].split('prevurl=')
  let id = options[2].split('id=')
  let page = options[3].split('page=')[1]

  let navigate = useNavigate()
  // let data = [];
  console.log(id)
  useEffect(() => {
    sessionStorage.setItem('session', id[1])
  }, [])

  // console.log({ ...JSON.parse(userjson), category: "qr_code" }, "sadasd");

  const submit = () => {
    let sendjson = {
      question: JSON.stringify(data),
      user_id: id[1],
      answer: 1,
      nextquestionNum: page,
    }
    const esc = encodeURIComponent
    const query = Object.keys(sendjson)
      .map((k) => `${esc(k)}=${esc(sendjson[k])}`)
      .join('&')
    console.log(query)
    async function levelgame() {
      const response = await fetch(`${Apiurl.Fetchurl + 'response'}`, {
        method: 'POST',
        headers: reqJson,
        body: JSON.stringify(sendjson),
      })
      const childdata = await response.json()
      return childdata
    }
    levelgame()
      .then((data) => {
        // setvocationalData(data);
      })
      .catch((error) => {
        alert(error)
      })
  }
  return (
    <>
      <Container fluid className='excelgame-container'>
        <Row className='colors-main-container justify-content-center '>
          <Col md={6} className='inputsTable'>
            <Table striped bordered hover className='tbody-container1'>
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody className='tbody-container'>
                {data.map((o, i) => {
                  return (
                    <tr>
                      {o.map((ol, index) => {
                        return (
                          <td>
                            <p>{ol.item}</p>
                            <p>{ol.quantity}</p>
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </Table>
            <input
              type='number'
              placeholder='&#8377; 0.00'
              id='amountInput'
              onChange={(e) => {
                setAmountinput(e.target.value)
                if (e.target.value.length > e.target.maxLength) {
                  e.target.value = e.target.value.slice(0, e.target.maxLength)
                }
              }}
              maxlength='4'
            />
            <div style={{ textAlign: 'right' }}>
              {amount != '' ? (
                <button
                  className='common-btn px-3 py-1 mt-2'
                  onClick={() => {
                    swal({
                      title: 'Payment Success',
                      icon: 'success',
                    }).then(() => {
                      // navigate(prevurl[1], {
                      //   state: { ...JSON.parse(userjson), category: "qr_code" },
                      // }); //   alert("in");
                    })
                    submit()
                  }}
                >
                  Pay
                </button>
              ) : (
                ''
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Qrcodepay
