import { Col, Row } from 'react-bootstrap'
import { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Currdate from '../../../CurrDate'
import * as Apiurl from '../../../Apisurl'
import { useSpeechSynthesis } from 'react-speech-kit'
import * as Icon from 'react-bootstrap-icons'
import AudioAnalyser from 'react-audio-analyser-js'
import { SocketConfig } from '../../../SocketConfig'

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'

const PictureComposition = (props) => {
  const location = useLocation()
  const navigate = useNavigate()

  const { speak } = useSpeechSynthesis()
  const { transcript, listening, resetTranscript } = useSpeechRecognition()

  const [parsedata, setparsedata] = useState(false)
  const [inputValue1, setinputValue1] = useState('')
  const [inputValue2, setinputValue2] = useState('')
  const [disabledVia1, setdisabledVia1] = useState(false)
  const [disabledVia, setdisabledVia] = useState(false)
  const [speechTextA, setSpeechTextA] = useState('')
  const [showSuggesstion, setShowSuggestion] = useState(false)
  const inputRef = useRef()

  let singleQuestion = props.englishData
  let allQuestions = props.datalength
  let cat = location.state.category

  let date = Currdate.GetCurrentDate()
  props.time(date)

  useEffect(() => {
    SocketConfig.on('action_data', (data) => {
      let parseddata = data.result
      if (parseddata.length !== 0) {
        setparsedata(true)
        handleSubmit(parseddata.action, true, props.page)
      }
    })
  }, [])

  const startListen = () => {
    captureUserMedia()

    SpeechRecognition.startListening()
    let word = transcript

    inputRef.current.value = word

    setSpeechTextA(word)
  }

  const captureUserMedia = () => {
    navigator.permissions.query({ name: 'microphone' }).then((permissionStatus) => {
      console.log(permissionStatus.state) // granted, denied, prompt
      if (permissionStatus.state != 'granted') {
        SpeechRecognition.stopListening()
        alert('Please allow your microphone')
      } else {
        this.mic_permission = true
      }
      let self = this
      permissionStatus.onchange = function() {
        if (this.state != 'granted') {
          SpeechRecognition.stopListening()
          self.mic_permission = false
        }
        console.log('Permission changed to ' + this.state)
      }
    })
  }

  function handleSubmit(userAnswer, via, idx, btnvia) {
    speak({ text: userAnswer })

    let isCorrectAnswer
    let update

    switch (btnvia) {
      case 1:
        setdisabledVia(true)
        setinputValue1(userAnswer)
        break
      case 2:
        setdisabledVia1(true)
        setinputValue2(userAnswer)
        break
      case 3:
        isCorrectAnswer = true

        setinputValue1('')
        setinputValue2('')
        setdisabledVia(false)
        setdisabledVia1(false)

        props.setTheArray((oldArray) => {
          update = [
            ...oldArray,
            {
              via: isCorrectAnswer ? 'correct' : 'incorrect',
              answer: userAnswer,
            },
          ]
          localStorage.setItem('useranswer', JSON.stringify(update))

          return update
        })

        isCorrectAnswer && props.setScore((prev) => prev + 1)

        if (update.length === allQuestions.length) {
          props.setTheArray([])
          props.submitResult(date)
          // navigate('enresult', { state: { ...location.state, date: date } }, 1000)
          setShowSuggestion(false)
        } else {
          props.setPage((prev) => prev + 1)
          resetTranscript()
          setShowSuggestion(false)
        }
        break
    }
  }

  const disabledcss =
    disabledVia == false
      ? {
          opacity: '0.4',
          pointerEvents: 'none',
        }
      : {
          opacity: '1',
        }

  const disabledcss1 =
    transcript === '' && speechTextA === ''
      ? {
          opacity: '0.4',
          pointerEvents: 'none',
        }
      : {
          opacity: '1',
        }

  if (singleQuestion !== undefined) {
    return (
      <>
        <h2>Choose {cat.replace('_', ' ')}</h2>

        <Row className='english-main-container py-4 question-container'>
          <p className='question-no'>{props.page + 1}</p>
          <Col>
            {!showSuggesstion ? (
              <Row className='english-container'>
                <Col md={6} className='questionImage phrase-image'>
                  <img src={singleQuestion.image} alt='apple' />
                </Col>

                <Col md={3} className='questionImage phrase-image'>
                  <input type='text' value={transcript.toUpperCase()} readOnly ref={inputRef} />
                </Col>
                <Col md={3}>
                  <div className='option-container'>
                    {listening ? (
                      <button
                        type='button'
                        className='common-btn activity m-auto stop-btn'
                        onClick={SpeechRecognition.stopListening}
                      >
                        <div className='pulse-bg'></div>
                        <Icon.Stop />
                      </button>
                    ) : (
                      <button type='button' className='common-btn  m-auto  activity' onClick={() => startListen('A')}>
                        <Icon.Mic />
                      </button>
                    )}
                  </div>
                  <button
                    className='common-btn'
                    style={{
                      fontSize: '16px',
                      padding: '4px',
                      width: '100%',
                      marginBottom: '20px',
                      padding: '10px',
                      marginTop: '30px',
                      ...disabledcss1,
                    }}
                    onClick={() => setShowSuggestion(true)}
                  >
                    Submit
                  </button>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col lg={6} xl={4}>
                  <h4 className='text-black mb-3'>Possible Correct Answers</h4>
                  <ul className='list-unstyled text-black text-start'>
                    {singleQuestion.ans.split('|').map(
                      (possibleAnswer) =>
                        possibleAnswer && (
                          <li
                            className='fs-5 text-black text-capitalize px-2 mb-2'
                            style={{
                              borderLeft: '6px solid var(--green-color)',
                              background: 'var(--green-highlight-color)',
                            }}
                          >
                            {possibleAnswer}
                          </li>
                        )
                    )}
                  </ul>
                </Col>
                <Col lg={3} xl={4} className='d-flex align-items-center justify-content-center'>
                  <h4 className='text-black text-capitalize'>{transcript}</h4>
                </Col>
                <Col lg={3} xl={4} className='d-flex align-items-end'>
                  <button
                    type='button'
                    className='common-btn'
                    style={{
                      padding: '10px',
                      width: '100%',
                      fontSize: '16px',
                    }}
                    onClick={() => handleSubmit(transcript, false, props.page + 1, 3)}
                  >
                    Continue
                  </button>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </>
    )
  }
}

export default PictureComposition
