import * as Apiurl from '../Apisurl'
import headersReq from '../HeadersJson'
import bloomJSON from '../datasets/bloom.json'
import scoreJSON from '../datasets/score_repots.json'
import emotJSON from '../datasets/emot_report.json'
import pmJSON from '../datasets/pm_report.json'

const reqJson = headersReq()

const BloomData = async (res) => {
  if (sessionStorage.getItem('session') === '123456') {
    let data = bloomJSON
    let bloomAnalysis = data?.result?.Assessment
    return [
      parseInt(bloomAnalysis?.Remember),
      parseInt(bloomAnalysis?.Understand),
      parseInt(bloomAnalysis?.Apply),
      parseInt(bloomAnalysis?.Analyze),
      parseInt(bloomAnalysis?.Evaluate),
      parseInt(bloomAnalysis?.Create),
    ]
  }
  async function Bloom() {
    const response = await fetch(`${Apiurl.Fetchurl + 'bloom_tax'}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + ' ' + sessionStorage.getItem('session'),
      },
      body: JSON.stringify(res),
    })
    const data = await response.json()
    return data
  }
  return Bloom()
    .then((data) => {
      let bloomAnalysis = data?.result?.Assessment
      return [
        parseInt(bloomAnalysis?.Remember),
        parseInt(bloomAnalysis?.Understand),
        parseInt(bloomAnalysis?.Apply),
        parseInt(bloomAnalysis?.Analyze),
        parseInt(bloomAnalysis?.Evaluate),
        parseInt(bloomAnalysis?.Create),
      ]
    })
    .catch((error) => {
      console.log(error)
    })
}

const populatePieC = async (res) => {
  if (sessionStorage.getItem('session') === '123456') {
    let data = scoreJSON
    return {
      data: data,
      hourslogged: data?.hours_completed,
    }
  }

  async function pie() {
    const response = await fetch(`${Apiurl.Fetchurl + 'score_reports'}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + ' ' + sessionStorage.getItem('session'),
      },
      body: JSON.stringify(res),
    })
    const data = await response.json()
    return data
  }
  return pie()
    .then((data) => {
      return {
        data: data,
        hourslogged: data?.hours_completed,
      }
    })
    .catch((error) => {
      console.log(error)
    })
}
const emotData = async (res) => {
  if (sessionStorage.getItem('session') === '123456') {
    console.log('called emot')
    let data = emotJSON
    let getData = data.EmotionalAnalysis
    return [getData.Contempt, getData.Happy, getData.Relaxed, getData.Tired]
  }

  async function emotdatafn() {
    const response = await fetch(`${Apiurl.Fetchurl + 'emot_overview'}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + ' ' + sessionStorage.getItem('session'),
      },
      body: JSON.stringify(res),
    })
    const data = await response.json()
    return data
  }

  return emotdatafn()
    .then((data) => {
      let getData = data?.result?.EmotionalAnalysis
      console.log(getData)
      return [getData.Contempt, getData.Happy, getData.Relaxed, getData.Tired]
    })
    .catch((error) => {
      console.log(error)
    })
}

const performManceMatricsOverview = async (res) => {
  if (sessionStorage.getItem('session') === '123456') {
    console.log('called pm')
    let data = pmJSON
    let getData = data.PerformanceMetric
    return [getData.Engagement, getData.Focus, getData.Interest, getData.Stress]
  }

  async function performManceMatrics() {
    const response = await fetch(`${Apiurl.Fetchurl + 'pm_overview'}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + ' ' + sessionStorage.getItem('session'),
      },
      body: JSON.stringify(res),
    })
    const data = await response.json()
    return data
  }

  return performManceMatrics()
    .then((data) => {
      let datad = data
      let getData = datad?.result?.PerformanceMetric
      return [getData.Engagement, getData.Focus, getData.Interest, getData.Stress]
    })
    .catch((error) => {
      console.log(error)
    })
}

const changeUserTable = async (res) => {
  async function levelgame() {
    const response = await fetch(`${Apiurl.Fetchurl + 'reports_table'}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + ' ' + sessionStorage.getItem('session'),
      },
      body: JSON.stringify(res),
    })
    const reportTable = await response.json()
    return reportTable
  }
  return levelgame()
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.log(error)
    })
}

const changePersonalized = async (res) => {
  async function recommendations() {
    const response = await fetch(`${Apiurl.Fetchurl + 'recommendation'}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + ' ' + sessionStorage.getItem('session'),
      },
      body: JSON.stringify(res),
    })
    const data = await response.json()
    return data
  }
  return recommendations()
    .then((data) => {
      return data
    })
    .catch((error) => {
      return error
    })
}
export { BloomData, populatePieC, emotData, performManceMatricsOverview, changeUserTable, changePersonalized }
