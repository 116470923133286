import react from 'react'
import { Row, Container, Col } from 'react-bootstrap'
import { useEffect, useCallback } from 'react'
import { useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import Focus from '../../../includes/Focus'
import Progress from '../Testprogrsscom'
import refreshbtn from '../../../assets/images/refresh.svg'
import socketio from 'socket.io-client'
import StartModal from '../../SelectgameStart'
import * as Apiurl from '../../../Apisurl'
import Userjson from '../../../Userjson'

import { useDrag, DndProvider, DragPreviewImage } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Rank_value from './Rank_order'

import Currency from './Currency'
import headersReq from '../../../HeadersJson'
import { GetCurrentDate } from '../../../CurrDate'

const EnglishGame = () => {
  const reqJson = headersReq()
  const [callday, setcallday] = useState(1)
  const [mathsdata, setmathsdata] = useState([])
  let [constantTime, setconstantTime] = useState('')
  const [data1, setdata1] = useState([])
  const [connect, setconnected] = useState(false)
  const [messageHistory, setMessageHistory] = useState([])
  const [score, setScore] = useState(0)
  const location = useLocation()
  const [showError, setShowError] = useState()
  const [timer, setTimer] = useState(30)
  const [page, setPage] = useState(0)
  const [smShow, setsmShow] = useState(false)
  const [level, setlevel] = useState('')
  const [activeleftAnswer, setleftactiveAnswer] = useState('')
  const [activerightAnswer, setrightactiveAnswer] = useState(false)
  let leveldata = location.state.link
  const [moneyData, setMoneyData] = useState([])
  let cat = location.state.category

  let initialArray = []

  const [action, setactiondata] = useState([])
  const [theArray, setTheArray] = useState(initialArray)
  const [inputvalue, setinputvalue] = useState('')

  const submitResult = () => {
    let answer = JSON.parse(localStorage.getItem('useranswer'))

    let getScore = answer.filter((o, i) => {
      return o.via == 'correct'
    })

    let alldata = {
      ...Userjson(),
      result: JSON.stringify({
        ques:
          moneyData != undefined
            ? moneyData[location.state.link].map((data) => {
                let modifiedData = { ...data }

                if (modifiedData['ans'] !== undefined) {
                  modifiedData['ans'] = modifiedData['ans'].replaceAll(
                    'https://ui-nema-database.s3.ap-south-1.amazonaws.com/',
                    ''
                  )
                }
                if (modifiedData['ques'] !== undefined) {
                  modifiedData['ques'] = modifiedData['ques'].replaceAll(
                    'https://ui-nema-database.s3.ap-south-1.amazonaws.com/',
                    ''
                  )
                }

                return modifiedData
              })
            : '',
        score: getScore.length,
        total: moneyData[location.state.link].length,
        ans: answer,
      }),
      date: constantTime,
    }
    const esc = encodeURIComponent

    const query = Object.keys(alldata)
      .map((k) => `${esc(k)}=${esc(alldata[k])}`)
      .join('&')

    async function levelgame() {
      const response = await fetch(`${Apiurl.Fetchurl + 'manual_report_fc?' + query + ''}`, {
        method: 'GET',
        headers: reqJson,
      })
      const childdata = await response.json()
      return childdata
    }
    levelgame()
      .then((data) => {
        let date = GetCurrentDate()
        navigate(`moneyresult?id=${data.serial_num}`, { state: { ...location.state, date: date } })
        // setmathsdata(data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    async function levelgame() {
      const response = await fetch(`${Apiurl.Fetchurl + 'level_money'}`, {
        method: 'POST',
        headers: reqJson,
        body: JSON.stringify({
          category: cat
            .toLowerCase()
            .split(' ')
            .join('_'),
        }),
      })
      const childdata = await response.json()
      return childdata
    }
    levelgame()
      .then((data) => {
        setMoneyData(data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  let json = moneyData.length != 0 ? moneyData[leveldata][page] : []
  let length1 = moneyData[location.state.link]
  let downloadTimer
  let navigate = useNavigate()
  let catarr = ['adjectives', '2_phase_mcq', 'sentences_mcq']

  if (moneyData.length != 0) {
    const renderMoneyCat = () => {
      let games
      console.log(cat)
      switch (cat) {
        case 'currency_identification':
          games = (
            <Currency
              moneyData={json}
              datalength={moneyData[location.state.link]}
              setPage={setPage}
              submitResult={() => submitResult()}
              setTheArray={setTheArray}
              page={page}
              time={setconstantTime}
              setScore={setScore}
              setdata1={setdata1}
              data1={data1}
            />
          )
          break
        case 'rank_value':
          games = (
            <Rank_value
              moneyData={json}
              datalength={moneyData[location.state.link]}
              setPage={setPage}
              submitResult={() => submitResult()}
              setTheArray={setTheArray}
              page={page}
              time={setconstantTime}
              setScore={setScore}
              setdata1={setdata1}
              data1={data1}
            />
          )
          break
      }
      return games
    }
    return (
      <>
        <div className='d-flex align-item-center justify-content-between my-4' style={{ position: 'relative' }}></div>
        {renderMoneyCat()}

        <div className='activity-listing'>
          <ul>
            <li className='active'></li>
            <li className='active'>
              Score:
              <b>{catarr.includes(cat) ? length1.length / 2 : length1.length}</b>/<span>{score}</span>
            </li>
          </ul>

          <ul>
            <li>
              <Progress json={moneyData[leveldata]} currindex={page} getarray={theArray} />
            </li>
            <li className='refresh-btn'>
              <NavLink to='/school/game/level/colorresult'>
                <button>
                  <img src={refreshbtn} alt='refresh' />
                  Start Another Level
                </button>
              </NavLink>
              <button>
                <img src={refreshbtn} alt='refresh' />
                Start Over
              </button>
            </li>
          </ul>
        </div>
      </>
    )
  }
}
export default EnglishGame
