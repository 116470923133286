import React, { useEffect, useState } from 'react'
import { DragDropContainer, DropTarget } from 'react-drag-drop-container'

const FindItems = (props) => {
  const [result, setResult] = useState([])

  const [count, setCount] = useState(0)

  useEffect(() => {
    let len = props.evs.options.reduce((total, curr) => {
      if (curr.type === props.evs.find) {
        total += 1
      }
      return total
    }, 0)
    setCount(len)
  }, [props.evs])

  const handleHit = (e) => {
    e.containerElem.style.visibility = 'hidden'

    let data = e.dragData.element
    let parentContainer = e.target
    parentContainer.style.background = `url(${data}) no-repeat center`
    parentContainer.style.backgroundSize = 'contain'
  }

  const handleDrop = (e) => {
    console.log('drop', e)
    let idx = e.dropData
    let ans = e.dragData.type
    let img = e.dragData.element

    let isCorrect = ans === props.evs.find ? 'correct' : 'incorrect'
    let updated = [...result]

    updated[idx] = { ques: props.evs.find, ans: img, via: isCorrect }
    setResult(updated)
  }

  const reset = () => {
    let el = document.getElementsByClassName('drag-element')
    let dropTargets = document.getElementsByClassName('drop-target')

    Object.values(dropTargets).forEach((dropTarget) => {
      dropTarget.style.setProperty('background', 'none')
    })

    Object.values(el).forEach((e) => {
      e.style.visibility = 'visible'
    })
    setResult([])

    props.setScore(0)
  }

  const check = () => {
    localStorage.setItem('useranswer', JSON.stringify(result))
    props.submit()
  }
  return (
    <div className='d-flex flex-column align-items-center'>
      <div className='d-flex gap-4 flex-wrap my-5'>
        {props.evs.options.map((d, i) => {
          return <DragItem key={i} data={d} handleDrop={handleDrop} />
        })}
      </div>

      <div className='d-flex flex-column flex-wrap align-items-center gap-4 flex-grow-1 mt-5'>
        <h1 className='fs-3 fw-bold'>
          Find Items In <span className='text-capitalize'>{props.evs.find}</span>
        </h1>

        <div className='d-flex gap-4'>
          {[0, 1, 2].map((d, i) => (
            <DropTarget key={i} targetKey='items' dropData={d} onHit={handleHit}>
              <div
                className='drop-target rounded-circle'
                style={{
                  minWidth: '250px',
                  minHeight: '250px',
                  border: '4px dashed var(--primary-color)',
                }}
              ></div>
            </DropTarget>
          ))}
        </div>

        <div>
          <button type='button' onClick={reset} className='btn common-btn mx-2 py-3 px-5'>
            Reset
          </button>
          <button type='button' onClick={check} disabled={result.length < count} className='btn common-btn py-3 px-5'>
            Submit
          </button>
        </div>
      </div>
    </div>
  )
}

export default FindItems

export const DragItem = ({ data, handleDrop }) => {
  return (
    <DragDropContainer targetKey='items' dragData={{ type: data.type, element: data.image }} onDrop={handleDrop}>
      <div className='drag-element'>
        <img height={200} width={200} src={data.image} alt={data.type} className='rounded-circle object-fit-contain' />
      </div>
    </DragDropContainer>
  )
}
