import react, { useState } from "react";
import Table from "react-bootstrap/Table";
import { MDBIcon } from "mdb-react-ui-kit";
import Meetmodal from "./Meetmodal";
const MeetTable = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Table className="result-table">
        <thead>
          <tr>
            <th>Scheduled for</th>
            <th>Link or Location</th>
            <th>Parent</th>
            <th>Child</th>
            <th>Additional Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className="timing-container">
                <p>
                  <MDBIcon far icon="clock" /> 6:00 PM
                </p>
                <p>
                  <MDBIcon far icon="calendar" /> 1st Aug 2022
                </p>
              </div>
            </td>
            <td>
              <div className="join-container">
                <a href="#">meet.google.com/f...</a>
                <button className="full-btn common-btn" onClick={handleShow}>
                  Join{" "}
                </button>
              </div>
            </td>
            <td>
              <div className="parent-container">
                <img src={require("../assets/images/user3.jpg")} alt="child2" />
                <p>Darrell Steward</p>
              </div>
            </td>
            <td>
              {" "}
              <div className="parent-container">
                <img src={require("../assets/images/user3.jpg")} alt="child2" />
                <p>Darrell Steward</p>
              </div>
            </td>
            <td>
              <p className="text-secondary">For future sessions</p>
            </td>
          </tr>
          <tr>
            <td>
              <div className="timing-container">
                <p>
                  <MDBIcon far icon="clock" /> 6:00 PM
                </p>
                <p>
                  <MDBIcon far icon="calendar" /> 1st Aug 2022
                </p>
              </div>
            </td>
            <td>
              <div className="join-container">
                <a href="#">meet.google.com/f...</a>
                <button className="full-btn common-btn">Join</button>
              </div>
            </td>
            <td>
              <div className="parent-container">
                <img src={require("../assets/images/user3.jpg")} alt="child2" />
                <p>Darrell Steward</p>
              </div>
            </td>
            <td>
              <div className="parent-container">
                <img src={require("../assets/images/user3.jpg")} alt="child2" />
                <p>Darrell Steward</p>
              </div>
            </td>
            <td>
              <p className="text-secondary">For future sessions</p>
            </td>
          </tr>
          <tr>
            <td>
              <div className="timing-container">
                <p>
                  <MDBIcon far icon="clock" /> 6:00 PM
                </p>
                <p>
                  <MDBIcon far icon="calendar" /> 1st Aug 2022
                </p>
              </div>
            </td>
            <td>
              <div className="join-container">
                <p>8502 Preston Rd. Inglewood, Maine 98380</p>
              </div>
            </td>
            <td>
              <div className="parent-container">
                <img src={require("../assets/images/user3.jpg")} alt="child2" />
                <p>Darrell Steward</p>
              </div>
            </td>
            <td>
              <div className="parent-container">
                <img src={require("../assets/images/user3.jpg")} alt="child2" />
                <p>Darrell Steward</p>
              </div>
            </td>
            <td>
              <p className="text-secondary">For future sessions</p>
            </td>
          </tr>
        </tbody>
      </Table>
      <Meetmodal showvia={show} setshowvia={setShow} />
    </>
  );
};

export default MeetTable;
