import React, { useContext, useEffect, useRef, useState } from 'react'
import * as API from '../../Apisurl'
import Context from '../../Context'
import { differenceInSeconds } from 'date-fns'
import { Col, Row } from 'react-bootstrap'
import hero from '../../assets/images/hero.svg'
import memoryspan from '../../assets/images/creative.png'

const generateRandomDigit = () => Math.floor(Math.random() * 10)
const generateRandomLetter = () => String.fromCharCode(Math.floor(Math.random() * 26) + 65)

const MemorySpan = () => {
  const { selectValue } = useContext(Context)
  const [data, setData] = useState([])

  const [userAnswer, setUserAnswer] = useState('')
  const [correctAnswer, setCorrectAnswer] = useState()

  const [showAnswer, setShowAnswer] = useState(false)
  const [showQuestion, setShowQuestion] = useState(false)

  const [isPlaying, setIsPlaying] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)

  const [settings, setSettings] = useState({
    type: 'Digits',
    level: '1',
    no_of_rounds: 4,
    curr_round: 0,
  })
  const [result, setResult] = useState([])
  const [reports, setReports] = useState([])

  const questionRef = useRef()

  const handleChange = (e) => {
    const { name, value } = e.target
    setSettings((old) => ({ ...old, [name]: value }))
  }

  useEffect(() => {
    const getReports = async () => {
      try {
        const response = await fetch(`${API.Fetchurl}games_data?user_id=${selectValue.user_id}`, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('session')}` },
        })
        const data = await response.json()

        const modifiedData = data.ques.filter((d) => d[2] === 'Memory Span').map((d) => JSON.parse(d[6][0]))

        setReports(modifiedData)
      } catch (error) {
        console.log(error)
      }
    }

    getReports()

    return () => {
      clearTimeout(questionRef.current)
    }
  }, [selectValue.user_id])

  const saveReport = async (res) => {
    try {
      await fetch(`${API.Fetchurl}games_data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('session')}`,
        },
        body: JSON.stringify(res),
      })
    } catch (error) {
      console.log(error)
    }
  }

  const startGame = () => {
    if (settings.curr_round === settings.no_of_rounds) {
      let duration = 0,
        avg = 0,
        rate = 0

      setResult((oldResult) => {
        for (let i = 0; i < oldResult.length; i++) {
          duration += oldResult[i].time
          rate += oldResult[i].isCorrect ? 1 : 0
        }

        rate = Math.round((rate / oldResult.length) * 100)
        avg = (duration / oldResult.length).toFixed(1)

        saveReport({
          user_id: selectValue.user_id,
          username: selectValue.username,
          bet: 0,
          time: avg,
          category: 'Memory Span',
          score: rate,
          bet_array: [
            JSON.stringify({
              type: settings.type,
              level: settings.level,
              rate: rate,
              duration: duration,
              avg: avg,
            }),
          ],
        })

        stopGame()
        return oldResult
      })

      return
    }

    setIsPlaying(true)
    setIsGenerating(true)
    setSettings((old) => ({ ...old, curr_round: old.curr_round + 1 }))
    setUserAnswer('')

    let count = 0
    const generateSequence = () => {
      if (count === parseInt(settings.level)) {
        setIsGenerating(false)
        setResult((old) => [...old, { isCorrect: false, time: new Date() }])
        clearInterval(questionRef.current)
        return
      }

      let randomData = settings.type === 'Digits' ? generateRandomDigit() : generateRandomLetter()
      setData((oldData) => [...oldData, randomData])
      count++

      setShowQuestion(true)
      setTimeout(() => {
        setShowQuestion(false)
      }, 1000)
    }

    questionRef.current = setInterval(generateSequence, 1500)
  }

  const stopGame = () => {
    clearInterval(questionRef.current)
    setIsPlaying(false)
    setIsGenerating(false)
    setData([])
    setResult([])
    setShowAnswer(false)
    setShowQuestion(false)
    setUserAnswer('')
    setSettings((old) => ({ ...old, curr_round: 0 }))
  }

  const checkAnswer = (e) => {
    e.preventDefault()
    setShowAnswer(true)

    const isCorrect =
      settings.type === 'Digits' ? parseInt(data.join('')) === parseInt(userAnswer) : data.join('') === userAnswer

    setCorrectAnswer(isCorrect ? 'Correct' : 'InCorrect')

    const updatedData = [...result]
    let diff = differenceInSeconds(new Date(), updatedData[settings.curr_round - 1].time)
    updatedData[settings.curr_round - 1].time = diff
    updatedData[settings.curr_round - 1].isCorrect = isCorrect
    setResult(updatedData)

    setData([])

    setTimeout(() => {
      setShowAnswer(false)
      startGame()
    }, 1000)
  }

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <Row className='h-100'>
        <Col lg={8} xl={9} xxl={9} className='p-0 h-100'>
          <div
            className='d-flex align-items-center justify-content-between bg-white shadow-sm p-4 mb-3'
            style={{ height: '75px' }}
          >
            <div className='d-flex align-items-center gap-3'>
              <img src={memoryspan} alt='mental-maths' height={48} width={48} />
              <h1 className='m-0 fs-2'>Memory Span</h1>
            </div>
            <div className='d-flex align-items-center gap-4'>
              <div>
                <label htmlFor='type'>Type</label>
                <select
                  id='type'
                  name='type'
                  value={settings.type}
                  onChange={handleChange}
                  required
                  disabled={isPlaying}
                  style={{ width: 'fit-content', padding: 0, height: '25px' }}
                  className='ms-2 border border-2 rounded-pill'
                >
                  <option value='Digits'>Digits</option>
                  <option value='Letters'>Letters</option>
                </select>
              </div>
              <div>
                <label htmlFor='level'>Level</label>
                <select
                  id='level'
                  name='level'
                  value={settings.level}
                  onChange={handleChange}
                  required
                  disabled={isPlaying}
                  style={{ width: 'fit-content', padding: 0, height: '25px' }}
                  className='ms-2 border border-2 rounded-pill'
                >
                  {[1, 2, 3, 4, 5].map((lvl) => (
                    <option key={lvl} value={lvl}>
                      {lvl}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className='mx-auto' style={{ width: '450px' }}>
            <div className='d-flex align-items-center justify-content-between'>
              <p className='m-0'>
                Round {settings.curr_round} of {settings.no_of_rounds}
              </p>
              {!isPlaying ? (
                <button className='p-2 px-4 border-0 rounded-3 text-black bg-warning' onClick={startGame}>
                  Start
                </button>
              ) : (
                <button className='p-2 px-4 border-0 rounded-3 text-black bg-warning' onClick={stopGame}>
                  Stop
                </button>
              )}
            </div>
            <div className='dashboard-cards'>
              <div
                className='d-flex flex-wrap align-items-center justify-content-center gap-4 display-1 my-5'
                style={{ height: '200px' }}
              >
                {showQuestion && <span>{data.at(-1)}</span>}
              </div>
              <form
                style={{ visibility: !isPlaying || isGenerating ? 'hidden' : 'visible' }}
                className='d-flex align-items-center justify-content-between gap-3'
                onSubmit={checkAnswer}
              >
                <input
                  required
                  autoFocus
                  type='text'
                  min={0}
                  value={userAnswer}
                  id='answer'
                  name='answer'
                  onChange={(e) => setUserAnswer(e.target.value)}
                  className='border border-2 rounded-3 p-2'
                />
                <button className='p-2 px-4 border-0 rounded-3 text-white bg-black'>Submit</button>
              </form>
              <div className='text-center' style={{ visibility: showAnswer ? 'visible' : 'hidden' }}>
                Correct : {correctAnswer}
              </div>
            </div>
          </div>
        </Col>
        <Col
          lg={4}
          xl={3}
          xxl={3}
          className='h-100 bg-white p-4 d-none d-lg-block'
          style={{ opacity: isPlaying ? 0 : 1, transition: 'opacity 0.2s ease' }}
        >
          <h6>Game Info</h6>
          <table className='table table-borderless table-sm mb-4'>
            <tbody>
              <tr>
                <td>Mode</td>
                <td className='fw-bold'>{settings.type}</td>
              </tr>
              <tr>
                <td>Level</td>
                <td className='fw-bold'>{settings.level}</td>
              </tr>
              <tr>
                <td>Number of rounds</td>
                <td className='fw-bold'>{settings.no_of_rounds}</td>
              </tr>
            </tbody>
          </table>

          <h6>How to play</h6>
          <ul>
            <li>To start the game press start button.</li>
            <li>
              After the items finish appearing enter the items in the correct order in the result box and press enter to
              move on to the next round
            </li>
            <li>You will see if your result was correct or the correct one if it wasn't.</li>
          </ul>

          <h6>Reports</h6>
          {reports.length === 0 && 'No reports yet'}
          <div style={{ overflowY: 'auto', height: '500px' }}>
            <table className='table table-sm table-hover' style={{ fontSize: '10px' }}>
              <tbody className='table-group-divider align-middle '>
                {reports.map((report, index) => (
                  <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>{report.type}</td>
                    <td>{report.level}</td>
                    <td>{report.rate}%</td>
                    <td>{report.avg}</td>
                    <td>{report.duration}s</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default MemorySpan

{
  /* <div className='dashboard-cards p-4 flex-grow-1' style={{ width: '450px' }}>
            <h4>About</h4>
            <p style={{ textAlign: 'justify' }}>
              Memory Span Task is a simple test to measure the short term memory of the person. To put it simple, Memory
              Span Task is a longest list of items a person can remember and recall within a short period of time.
            </p>
            <h4>How To Play</h4>
            <p style={{ textAlign: 'justify' }}>
              To start the game press start button. After the items finish appearing enter the items in the correct
              order in the result box and press enter to move on to the next round
            </p>
          </div> */
}
