import React from "react";

const StartBtn = (props) => {
  return (
    <main>
      <div className="video-controls">
        {!props.permission ? (
          <button
            onClick={props.getCameraPermission}
            className="common-btn p-2 w-100 mt-4"
            type="button"
          >
            Start
          </button>
        ) : (
          <button
            onClick={props.stopBtn}
            className="common-btn p-2 w-100 mt-4"
            type="button"
          >
            Stop
          </button>
        )}
      </div>
    </main>
  );
};
export default StartBtn;
