import React, { useEffect, useState } from 'react'
import { Col, FloatingLabel, Form, Row, Spinner } from 'react-bootstrap'
import CustomDropzone from '../CustomDropzone'
import '../steps.css'
import * as API from '../../../Apisurl'

const StepOne = ({ formRef, validated, data, setData }) => {
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState()

  useEffect(() => {
    // Revoke the data uris to avoid memory leaks
    return () => URL.revokeObjectURL(file)
  }, [file])

  useEffect(() => {
    const getModules = async () => {
      try {
        const response = await fetch(`${API.Fetchurl}main_module_keys`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + sessionStorage.getItem('session'),
          },
          body: JSON.stringify({
            module: 1,
            language: localStorage.getItem('getlang'),
          }),
        })
        if (response.ok) {
          const info = await response.json()
          let num =
            Number(
              Object.keys(info)
                .sort((a, b) => {
                  return b.split(' ')[1] - a.split(' ')[1]
                })[0]
                .split(' ')[1]
            ) + 1

          setData({ ...data, module: `Module ${num}` })
        } else {
          throw new Error('Something went wrong')
        }
      } catch (error) {
        console.log(error.message)
      }
    }
    getModules()
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target
    setData((old) => ({ ...old, [name]: value }))
  }

  const upload = async (e) => {
    if (!file) return

    try {
      setLoading(true)

      const formData = new FormData()
      formData.append('user_id', sessionStorage.getItem('session'))
      formData.append('folder_type', 'module')
      formData.append('file', file)

      const response = await fetch(`${API.Fetchurl}file_upload_1`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('session'),
        },
      })

      const data = await response.json()
      let imageURL = data.file_path[0]
      setData((old) => ({ ...old, image: imageURL }))
      setFile()

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  return (
    <Row>
      <Col>
        <Form className='mt-2' ref={formRef} noValidate validated={validated}>
          <FloatingLabel controlId='module-title' label='Module'>
            <Form.Control
              required
              type='text'
              name='module'
              placeholder='Module'
              value={data.module}
              onChange={handleChange}
              maxLength='30'
              readOnly
            />
            <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
          </FloatingLabel>

          <FloatingLabel controlId='description' label='About' className='mb-3'>
            <Form.Control
              required
              type='text'
              name='text'
              placeholder='About'
              value={data.text}
              onChange={handleChange}
              maxLength='30'
            />
            <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
          </FloatingLabel>
          <div className='d-flex gap-1 mb-4'>
            <CustomDropzone setData={setFile} isMultiple={false} />

            <button
              onClick={upload}
              disabled={!file || loading}
              type='button'
              className='d-flex justify-content-center align-items-center py-1 px-3 border-0 rounded-3 text-white'
              style={{ backgroundColor: 'var(--green-color)' }}
            >
              {loading ? <Spinner animation='border' /> : 'Upload'}
            </button>
          </div>
        </Form>
      </Col>
      <Col>
        <div className='d-flex flex-column gap-2 align-items-center'>
          <h4>Preview</h4>
          <div className='preview-module-card'>
            <div className='text-center'>
              {!file ? <p>uploaded</p> : <p>preview only, click upload to save</p>}
              <img
                src={file ? URL.createObjectURL(file) : data.image}
                alt='module-img'
                onLoad={() => URL.revokeObjectURL(file)}
              />
            </div>
            <div className='preview-info'>
              <h4 style={{ fontSize: '20px' }}>{data.module || 'Title'}</h4>
              <p>{data.text || 'Description About Module'}</p>
            </div>
            <button class='common-btn'>Start</button>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default StepOne
