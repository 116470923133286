import { Button, Card, Col } from 'react-bootstrap'
import { NavLink, useLocation } from 'react-router-dom'
import Speech from '../Speech'

const LevelCard = (props) => {
  let location = useLocation()
  let sendModulleData = JSON.parse(localStorage.getItem('sendModulleData'))

  return (
    <Col md={4}>
      <Card>
        {location.state.attempted[props.link.toLowerCase()] ? (
          <span className='attempttxt bg-primary'>Attempted</span>
        ) : (
          <span className='attempttxt new bg-success'>New</span>
        )}
        <Card.Body>
          <Card.Title>{props.title}</Card.Title>
          <div className='btn-container'>
            <NavLink
              to={props.link.toLowerCase()}
              onClick={() => Speech.Speech(`${sendModulleData.username} choose ${props.link}`)}
              state={{
                ...props.category,
                link:
                  location.state.link == undefined
                    ? props.link.replace(/ /g, '').toLowerCase()
                    : (location.state.link = props.link.replace(/ /g, '').toLowerCase()),
                level:
                  location.state.level == undefined
                    ? props.link.replace(/ /g, '').toLowerCase()
                    : (location.state.level = props.link.replace(/ /g, '').toLowerCase()),
                modulename1: props.category.modulename1,
                typeValue: props.typeValue,
                username: sendModulleData.username,
                userid: sendModulleData.userid,
              }}
              className='w-100'
            >
              <Button className='full-btn common-btn' level={props.link}>
                Start
              </Button>
            </NavLink>
          </div>
        </Card.Body>
      </Card>
    </Col>
  )
}
export default LevelCard
