import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import { IoMdImage } from 'react-icons/io'
import { MdClear } from 'react-icons/md'
import * as API from '../../Apisurl'

const Shop = () => {
  const [show, setShow] = useState(false)
  const [products, setProducts] = useState([])
  const [product, setProduct] = useState({
    title: '',
    description: '',
    stock: '',
    discountPercentage: '',
    brand: '',
    category: '',
    thumbnail: '',
    image: [],
    price: '',
  })
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)
  const [imagesError, setImagesError] = useState('')

  const [validated, setValidated] = useState(false)

  const getProducts = () => {
    fetch(API.Fetchurl + 'get_all_shop')
      .then((res) => res.json())
      .then((data) => setProducts(data.products))
  }

  useEffect(() => {
    getProducts()
  }, [])

  const handleClose = () => {
    setShow(false)
    setProduct({
      title: '',
      description: '',
      stock: '',
      discountPercentage: '',
      brand: '',
      category: '',
      thumbnail: '',
      image: [],
      price: '',
    })
    setFiles([])
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setProduct((oldProduct) => ({ ...oldProduct, [name]: value }))
  }

  const handleSave = async () => {
    let url = product.id ? 'edit_shop' : 'add_shop'
    try {
      await fetch(API.Fetchurl + url, {
        method: 'POST',
        headers: { Authorization: 'Bearer ' + sessionStorage.getItem('session') },
        body: JSON.stringify({ ...product, thumbnail: product.image[0], user_id: sessionStorage.getItem('session') }),
      })

      getProducts()
    } catch (error) {
      console.log(error)
    }
  }

  const handleDelete = async (id) => {
    try {
      await fetch(API.Fetchurl + 'delete_shop', {
        method: 'POST',
        headers: { Authorization: 'Bearer ' + sessionStorage.getItem('session') },
        body: JSON.stringify({ id }),
      })

      getProducts()
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (e.currentTarget.checkValidity() === false || !product?.image.length > 0) {
      e.stopPropagation()
      setImagesError('Images is required')
    } else {
      setImagesError('')
      handleSave()
      handleClose()
    }
    setValidated(true)
  }

  const handleEdit = (product) => {
    setProduct(product)
    setShow(true)
  }

  const upload = async () => {
    if (!files.length) return

    setLoading(true)
    try {
      const formData = new FormData()
      formData.append('user_id', sessionStorage.getItem('session'))
      formData.append('folder_type', 'nema-e-shop')
      files.map((file) => formData.append('file', file))

      const response = await fetch(`${API.Fetchurl}file_upload_1`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('session'),
        },
      })

      const data = await response.json()
      let imageURLS = data.file_path

      setProduct((p) => ({ ...p, image: imageURLS }))
      setFiles([])
      setImagesError('')
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='admin-container content flex-grow-1'>
      <h5>NEMA E-Shop</h5>
      <p>Edit and Manage your products here</p>

      <div className='p-3 ps-0'>
        <div className='d-flex align-items-center justify-content-end'>
          <button className='common-btn px-4 py-2 mb-3' onClick={() => setShow(true)}>
            Add Product
          </button>
        </div>

        <table className='table table-condensed table-hover'>
          <thead>
            <tr>
              <th>#</th>
              <th>Product</th>
              <th>Description</th>
              <th>Category</th>
              <th>Price</th>
              <th></th>
            </tr>
          </thead>
          <tbody className='align-middle'>
            {products.map((product, i) => (
              <tr key={product.id}>
                <th>{i + 1}</th>
                <td className='d-flex gap-4'>
                  <img
                    src={product.thumbnail}
                    alt={product.title}
                    height={75}
                    width={75}
                    style={{ objectFit: 'cover' }}
                  />
                  <div>
                    <p className='text-capitalize'>{product.brand}</p>
                    <h6 className='text-black fw-bold text-lowercase text-capitalize'>{product.title}</h6>
                  </div>
                </td>
                <td className='text-secondary'>{product.description}</td>
                <td className='text-secondary text-capitalize'>{product.category}</td>
                <td>{product.stock}</td>
                <td>₹{product.price}</td>
                <td>
                  <button className='common-btn px-4 py-2 me-2' onClick={() => handleEdit(product)}>
                    Edit
                  </button>
                  <button className='common-btn px-4 py-2' onClick={() => handleDelete(product.id)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Modal size='md' show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{product.id ? 'Edit Product' : 'Add Product'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className='mb-3'>
              <Form.Label className='m-0'>Title</Form.Label>
              <Form.Control type='text' name='title' value={product.title} onChange={handleChange} required />
              <Form.Control.Feedback type='invalid'>Name is required</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label className='m-0'>Description</Form.Label>
              <Form.Control
                as='textarea'
                name='description'
                value={product.description}
                onChange={handleChange}
                rows={3}
                required
                style={{ minHeight: '100px' }}
              />
              <Form.Control.Feedback type='invalid'>Description is required</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label className='m-0'>Category</Form.Label>
              <Form.Control type='text' name='category' value={product.category} onChange={handleChange} required />
              <Form.Control.Feedback type='invalid'>Category is required</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label className='m-0'>Brand</Form.Label>
              <Form.Control type='text' name='brand' value={product.brand} onChange={handleChange} required />
              <Form.Control.Feedback type='invalid'>Brand is required</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Price</Form.Label>
              <Form.Control type='number' name='price' value={product.price} min={0} onChange={handleChange} required />
              <Form.Control.Feedback type='invalid'>Price is required</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Discount</Form.Label>
              <Form.Control
                type='number'
                name='discountPercentage'
                value={product.discountPercentage}
                min={0}
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type='invalid'>Discount is required</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Stock</Form.Label>
              <Form.Control type='number' name='stock' value={product.stock} min={0} onChange={handleChange} required />
              <Form.Control.Feedback type='invalid'>Stock is required</Form.Control.Feedback>
            </Form.Group>

            <div className='mb-3'>
              <label className='mb-2'>Images</label>
              <CustomDropZone setData={setFiles} />
              <div className='d-flex flex-wrap gap-2 mt-3'>
                {files.map((f, id) => (
                  <div key={id} className='position-relative'>
                    <img src={URL.createObjectURL(f)} alt={f.path} height={78} width={78} className='rounded-3' />
                    <button
                      type='button'
                      onClick={() => setFiles((old) => old.filter((_, i) => id !== i))}
                      className='p-0 border-0 bg-black bg-opacity-25 rounded-circle d-flex align-items-center justify-content-center p-1'
                      style={{ position: 'absolute', top: '4px', right: '4px' }}
                    >
                      <MdClear className='text-danger text-white' />
                    </button>
                  </div>
                ))}
                {product.image.map((image, id) => (
                  <div key={id} className='position-relative'>
                    <img
                      src={image}
                      alt={image}
                      height={78}
                      width={78}
                      className='rounded-3'
                      style={{ objectFit: 'cover' }}
                    />
                    <button
                      type='button'
                      onClick={() => setProduct((old) => ({ ...old, image: old.image.filter((_, i) => id !== i) }))}
                      className='p-0 border-0 bg-black bg-opacity-25 rounded-circle d-flex align-items-center justify-content-center p-1'
                      style={{ position: 'absolute', top: '4px', right: '4px' }}
                    >
                      <MdClear className='text-danger text-white' />
                    </button>
                  </div>
                ))}
              </div>

              <div
                className={`${
                  files.length || product?.image?.length ? 'd-flex' : 'd-none'
                } justify-content-end gap-2 mt-2`}
              >
                <button
                  type='button'
                  disabled={loading}
                  className='border rounded-pill bg-danger text-white px-3 py-1'
                  onClick={() => {
                    setFiles([])
                    setProduct((old) => ({ ...old, image: [] }))
                  }}
                >
                  Remove all
                </button>
                <button
                  type='button'
                  disabled={loading}
                  className={`border rounded-pill bg-success ${loading ? 'bg-opacity-50' : ''} text-white px-3 py-1`}
                  onClick={upload}
                >
                  Upload
                </button>
              </div>

              {imagesError && <p className='text-danger'>{imagesError}</p>}
            </div>

            <Button type='submit' className='w-100 common-btn px-4 py-3 text-white'>
              {product.id ? 'Save Changes' : 'Add Product'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Shop

const CustomDropZone = ({ setData }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles?.length) {
        setData(acceptedFiles.map((file) => file))
      }
    },
    [setData]
  )

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
    },
    multiple: true,
    onDrop,
  })

  const baseStyle = {
    minHeight: '100px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    overflow: 'hidden',
    border: '2px dashed #c6c6c6',
    borderRadius: 8,
    outline: 'none',
    transition: 'border .24s ease',
  }

  const acceptStyle = {
    border: '2px dashed #54baaf',
  }

  const rejectStyle = {
    border: '2px dashed #ef4444',
  }

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? acceptStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  )

  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />

      <div className='d-flex flex-column align-items-center'>
        <IoMdImage className='fs-1 mb-3' style={{ color: '#c4c4c4' }} />
        <div>
          <p className='m-0 text-center' style={{ fontSize: '14px' }}>
            <span className='fw-bold'>Upload files</span> or drag and drop
          </p>
          <p className='m-0 text-center text-secondary' style={{ fontSize: '12px' }}>
            JPEG, JPG, PNG accepted
          </p>
        </div>
      </div>
    </div>
  )
}
