import react from 'react'
import { Row, Container, Col } from 'react-bootstrap'
import backbtn from '../../../assets/images/backbtn.svg'
import Focus from '../../../includes/Focus'
import refreshbtn from '../../../assets/images/refresh.svg'
import Draggable, { DraggableCore } from 'react-draggable' // Both at the same time
import { useState, useRef, useEffect, useContext } from 'react'
import StartModal from '../../SelectgameStart'
import { NavLink, useLocation, useParams, useNavigate } from 'react-router-dom'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import * as Currdate from '../../../CurrDate'
import * as Apiurl from '../../../Apisurl'
import { SocketConfig } from '../../../SocketConfig'

import { useSpeechSynthesis } from 'react-speech-kit'

import { Player, ControlBar, BigPlayButton } from 'video-react'
import '../../../assets/css/video-react.css'
// import "node_modules/video-react/dist/video-react.css";
const Directiongame = (props, englishData) => {
  console.log(englishData.video)
  let DraggableCore = Draggable.DraggableCore
  const { speak } = useSpeechSynthesis()
  const [score, setScore] = useState(0)
  const location = useLocation()
  const [isActive, setIsActive] = useState(false)
  let initialArray = []
  const [theArray, setTheArray] = useState(initialArray)
  const [mistakes, setMistakes] = useState(0)
  const [userAnswer, setUserAnswer] = useState('')
  const resetButton = useRef(null)
  const [smShow, setsmShow] = useState(false)
  const [parsedata, setparsedata] = useState(false)
  const [page, setPage] = useState(0)
  const [ansvia, setansvia] = useState(false)

  // const props1 = useContext(UserContext);
  let englishdatavalue = props.englishData
  let datalength = props.datalength
  let date = Currdate.GetCurrentDate()
  props.time(date)
  let datalengthi = datalength
  const [callday, setcallday] = useState(1)
  let navigate = useNavigate()
  useEffect(() => {
    SocketConfig.on('action_data', (data) => {
      console.log('Datta ', data)
      let parseddata = data.result
      if (parseddata.length != 0) {
        setparsedata(true)
        handleSubmit(parseddata.action, true)
      }
      console.log(parseddata)
    })
    //
  }, [])
  // populate level text

  let update
  // checkanswers

  function handleSubmit(e, via) {
    let userAnswer = e

    speak({ text: userAnswer })
    let correctAnswer
    if (
      englishdatavalue.ans.trim() == userAnswer.trim() ||
      englishdatavalue.direction[0].trim() == userAnswer.trim() ||
      englishdatavalue.direction[1].trim() == userAnswer.trim()
    ) {
      setansvia(false)

      props.setPage((prev) => {
        props.setTheArray((oldArray) => {
          const update = [
            ...oldArray,
            {
              via: 'correct',
              answer: userAnswer,
            },
          ]
          localStorage.setItem('useranswer', JSON.stringify(update))
          return update
        })

        if (props.page === datalengthi.length - 1) {
          props.setTheArray([])
          // socketemitdata();
          props.submitResult(date)

          // navigate('enresult', {
          //   state: { ...location.state, date: date },
          // })
        } else {
          props.setScore((prev) => prev + 1)

          return prev + 1
        }
      })
      console.log(props.page)
    } else {
      setansvia(false)

      props.setPage((prev) => {
        props.setTheArray((oldArray) => {
          const update = [
            ...oldArray,
            {
              via: 'incorrect',
              answer: userAnswer,
            },
          ]

          localStorage.setItem('useranswer', JSON.stringify(update))
          return update
        })
        if (props.page === datalengthi.length - 1) {
          SocketConfig.disconnect()
          props.submitResult(date)
          // navigate('enresult', {
          //   state: { ...location.state, date: date },
          // })
        } else {
          return prev + 1
        }
      })
    }
  }

  if (englishdatavalue != undefined) {
    return (
      <>
        <Container fluid>
          <Row className='colors-main-container comparing-game'>
            <h1
              style={{
                textAlign: 'center',
                fontWeight: '600',
                textTransform: 'uppercase',
              }}
            ></h1>
            <Col md={12}>
              <p>Your Problem</p>
              {/* <Focus /> */}
              <Row className='floating-question-container english-main-container question-container'>
                <div className='english-container'>
                  <input type='text' readOnly />
                  <figure className='questionImage'>
                    <Player
                      autoPlay
                      src={englishdatavalue.video}
                      onEnded={function() {
                        setansvia(true)
                      }}
                    >
                      <ControlBar autoHide={false} className='my-class' />
                      <BigPlayButton position='center' />
                    </Player>
                  </figure>
                </div>
                <div
                  className='measure-container'
                  style={
                    ansvia == false
                      ? { pointerEvents: 'none', opacity: '0.4' }
                      : { pointerEvents: 'inherit', opacity: '1' }
                  }
                >
                  {englishdatavalue.mcq.map((o, i) => {
                    return (
                      <div className='measure-item' onClick={() => handleSubmit(o)}>
                        <h5>{o}</h5>
                      </div>
                    )
                  })}
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
        <StartModal
          calldelay={setcallday}
          socket={SocketConfig}
          type={['action', 'focus']}
          date={date}
          letquesLength={datalength.length}
          calldaynum={callday}
        />
      </>
    )
  }
}
export default Directiongame
