import Table from 'react-bootstrap/Table'
import { MDBIcon } from 'mdb-react-ui-kit'
import ReactPaginate from 'react-paginate'
import { useEffect, useState, useContext } from 'react'
import Context from '../../../Context'
import UserJson from '../../../Userjson'
import { Player, ControlBar, BigPlayButton } from 'video-react'
import Loading from '../../../assets/images/loading.svg'
const EnglishResult = () => {
  const props1 = useContext(Context)

  let populatetopT = (o) => {
    console.log(o)
    let topComp = ''
    switch (UserJson().category) {
      case 'few_many':
        topComp = (
          <td>
            <div className='asked-color-box d-flex '>
              <b>{o.occ_img1}</b>

              <b style={{ marginLeft: '8px' }}>{o.occ_img2}</b>
            </div>
            <p style={{ textAlign: 'center', marginTop: '10px' }}>{o.type}</p>
          </td>
        )
        break

      case 'picture_composition':
        topComp = (
          <td>
            <div className='asked-color-box d-flex '>
              <img src={`https://ui-nema-database.s3.ap-south-1.amazonaws.com/${o.image}`} height='80' />
            </div>
          </td>
        )
        break

      case 'adjectives':
      case '2_phase_mcq':
      case 'sentences_mcq':
        topComp = (
          <td>
            <div className='asked-color-box d-flex '>
              <img src={`https://ui-nema-database.s3.ap-south-1.amazonaws.com/${o.question.image}`} height='80' />
              <img
                src={
                  o.answer1 != undefined
                    ? `https://ui-nema-database.s3.ap-south-1.amazonaws.com/${o.answer1.image}`
                    : ''
                }
                style={{ marginLeft: '10px ' }}
                height='80'
              />
            </div>
            <p style={{ textAlign: 'center', marginTop: '10px' }}>{o.type}</p>
          </td>
        )
        break
      case '2_phase':
      case 'sentences':
        topComp = (
          <td>
            <div className='asked-color-box d-flex '>
              <img src={`https://ui-nema-database.s3.ap-south-1.amazonaws.com/${o.images}`} height='80' />
            </div>
          </td>
        )
        break
      case 'preposition':
        topComp = (
          <td>
            <div className='asked-color-box d-flex '>
              <img src={`https://ui-nema-database.s3.ap-south-1.amazonaws.com/${o.images}`} height='80' />
            </div>
          </td>
        )
        break
      case 'action':
        topComp = (
          <td>
            <Player src={o.video}>
              <ControlBar autoHide={false} className='my-class' />
              <BigPlayButton position='center' />
            </Player>
          </td>
        )
        break
      default:
        topComp = (
          <td>
            <div className='asked-color-box'>
              <h6>{o.ques}</h6>
            </div>
          </td>
        )
    }
    return topComp
  }

  let poulateAnswer = (o) => {
    console.log(o, 's')
    let ansjsx
    switch (UserJson().category) {
      case 'meaning_alphabet':
        ansjsx = o.answer.join(',')
        break
      case '3_words':
        ansjsx = o.answer.join(',')
        break
      case 'adjectives':
      case '2_phase_mcq':
        ansjsx = o.answer1 + ',' + o.answer
        break
      default:
        ansjsx = o.answer
        break
    }
    // case "adjectives":
    //   ansjsx = o.answer + "," + o.answer1;
    //   break;
    return ansjsx
  }

  let poulateCorrect = (o) => {
    console.log(o)
    let ansjsx
    switch (UserJson().category) {
      case 'meaning_alphabet':
        ansjsx = o.ans1 + ',' + o.ans2
        break
      case '3_words':
      case 'action':
      case 'few_many':
        ansjsx = o.ans
        break
      case 'adjectives':
      case '2_phase_mcq':
        ansjsx = o.answer1.ans + ',' + o.question.ans
        break

      case 'preposition':
      case 'sentences':
      case '2_phase':
      case 'reading_level1':
        ansjsx = o.ans
        break
      case 'picture_composition':
        ansjsx = o.ans.split('|').join('\n')
        break

      default:
        ansjsx = o.answer
        break
    }

    return ansjsx
  }

  let overview = props1[2].Overview
  let checkPairedArray = ['adjectives', '2_phase_mcq']

  if (overview != undefined && overview.length != 0) {
    let createPairJson = () => {
      const pairedJson = []
      for (let i = 0; i < overview.ques.length; i += 2) {
        pairedJson.push({
          question: overview.ques[i],
          answer1: overview.ques[i + 1],
        })
      }
      return pairedJson
    }
    let isCheckPairedQues = checkPairedArray.includes(UserJson().category)
    let createPairJson1 = isCheckPairedQues ? createPairJson() : overview.ques
    console.log(createPairJson1)
    return (
      <Table className='result-table'>
        <thead>
          <tr>
            <th></th>
            {createPairJson1.map((o, i) => {
              return <th>Q{i + 1}</th>
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Asked</td>

            {createPairJson1.map((o, i) => {
              // console.log(o)
              return populatetopT(o)
            })}
          </tr>
          <tr>
            <td>Your Answer</td>
            {overview.ans.map((o, i) => {
              return (
                <td>
                  <div className='asked-color-box'>
                    <p style={o.via == 'correct' ? { color: '#77C63A' } : { color: 'red' }}>{poulateAnswer(o)}</p>
                  </div>
                </td>
              )
            })}
          </tr>
          <tr>
            <td>Correct Answer</td>
            {createPairJson1.map((o, i) => {
              console.log(o.ques)
              return (
                <td>
                  <div className='asked-color-box'>
                    {poulateCorrect(o)}
                    {/* {isCheckPairedQues
                      ? o.ques.ans + "," + o.ques1.ans
                      : o.ans != undefined
                      ? o.ans
                      : o.ans1 + "," + o.ans2} */}
                  </div>
                </td>
              )
            })}
          </tr>
        </tbody>
      </Table>
    )
  }
}

export default EnglishResult
