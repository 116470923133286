//logout api
const headersReq = () => {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer' + ' ' + sessionStorage.getItem('session'),
  }
}

export default headersReq
