import React, { useEffect, useState } from 'react'
import RealChallenge from '../RealChallenge/RealChallenge'
import * as Currdate from '../../../../../CurrDate'
import { NavLink, useLocation, useParams, useNavigate } from 'react-router-dom'

const TypingMainComponent = (props) => {
  const time = 120
  let date = Currdate.GetCurrentDate()
  let navigate = useNavigate()
  const location = useLocation()
  const [selectedParagraph, setselectedParagraph] = useState(props.paragraph[0].ans)
  const [testInfo, settestInfo] = useState([])
  const [timerStarted, settimerStarted] = useState(false)
  const [timeElapsed, setTimeElapsed] = useState(0)
  const [words, setwords] = useState(0)
  const [characters, setcharacters] = useState(0)
  const [wpm, setwpm] = useState(0)
  const [inputValue, setinputValue] = useState('')

  useEffect(() => {
    const selectedParagraphArray = props.paragraph[0].ans.split('')
    const testInfo = selectedParagraphArray.map((selectedLetter) => {
      return {
        testLetter: selectedLetter,
        status: 'not',
      }
    })
    setselectedParagraph(props.paragraph[0].ans)
    settestInfo(testInfo)
  }, [props.paragraph])

  useEffect(() => {
    if (timerStarted) {
      const timer = setInterval(() => {
        setTimeElapsed((prevTime) => prevTime + 1)
      }, 1000)

      return () => {
        clearInterval(timer)
      }
    }
  }, [timerStarted])

  useEffect(() => {
    const timeSpent = timeElapsed
    const wpm = timeSpent > 0 ? (words / timeSpent) * 60 : 0
    setwpm(parseInt(wpm))
  }, [words, timeElapsed])

  const startTimer = () => {
    settimerStarted(true)
  }

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60)
    const seconds = timeInSeconds % 60

    const formattedMinutes = String(minutes).padStart(2, '0')
    const formattedSeconds = String(seconds).padStart(2, '0')

    return `${formattedMinutes}:${formattedSeconds}`
  }

  const handleUserInput = (inputValue) => {
    if (!timerStarted) startTimer()
    setinputValue(inputValue)
    const characters = inputValue.length
    const words = inputValue.split(' ').length
    const index = characters - 1

    if (index < 0) {
      settestInfo([
        {
          testLetter: testInfo[0].testLetter,
          status: 'not',
        },
        ...testInfo.slice(1),
      ])

      return
    }
    if (index >= selectedParagraph.length) {
      setcharacters(characters)
      setwords(words)

      return
    }
    // Make a copy of test info

    if (!(index === selectedParagraph.length - 1)) testInfo[index + 1].status = 'not'

    // Check for mistake
    const isMistake = inputValue[index] === testInfo[index].testLetter

    // Update the testInfo
    testInfo[index].status = isMistake ? 'correct' : 'incorrect'

    // Update the state
    settestInfo(testInfo)
    setcharacters(characters)
    setwords(words)
  }

  function similarity(s1, s2) {
    var longer = s1
    var shorter = s2
    if (s1.length < s2.length) {
      longer = s2
      shorter = s1
    }
    var longerLength = longer.length
    if (longerLength === 0) {
      return 1.0
    }
    return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)
  }
  //edit distance string check
  function editDistance(s1, s2) {
    s1 = s1.toLowerCase()
    s2 = s2.toLowerCase()
    var costs = new Array()
    for (var i = 0; i <= s1.length; i++) {
      var lastValue = i
      for (var j = 0; j <= s2.length; j++) {
        if (i == 0) costs[j] = j
        else {
          if (j > 0) {
            var newValue = costs[j - 1]
            if (s1.charAt(i - 1) != s2.charAt(j - 1)) newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1
            costs[j - 1] = lastValue
            lastValue = newValue
          }
        }
      }
      if (i > 0) costs[s2.length] = lastValue
    }
    return costs[s2.length]
  }

  function handleSubmit() {
    // console.log(text);
    let similartxt = similarity(selectedParagraph.replace(/\s+/g, ''), inputValue)
    var perc = Math.round(similartxt * 10000) / 100
    props.setPage((prev) => {
      props.setTheArray((oldArray) => {
        const update = [
          ...oldArray,
          {
            via: 'correct',
            percentage: perc,
            inputvalue: inputValue,
          },
        ]
        // inputRef.current.value = "";

        localStorage.setItem('useranswer', JSON.stringify(update))
        return update
      })
      props.submitResult(date)

      // navigate('vocationresult', {
      //   state: { ...location.state, date: date },
      // })
    })
  }
  return (
    <div className='app'>
      <div className='challenge-section-container'>
        <h1 className='challenge-section-header'>Take Your Speed Test Right Here &#x261F;</h1>
        {/* {formatTime(timeElapsed)} */}
        <RealChallenge
          selectedParagraph={selectedParagraph}
          timerStarted={timerStarted}
          timeRemaining={'d'}
          words={words}
          characters={characters}
          wpm={wpm}
          testInfo={testInfo}
          inputValue={handleUserInput}
          handleSubmit={handleSubmit}
          // startAgain={startAgain}
        />
      </div>
    </div>
  )
}

export default TypingMainComponent
