import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import Progress from '../Testprogrsscom'
import refreshbtn from '../../../assets/images/refresh.svg'
import pencil from '../../../assets/images/pencil.svg'
import * as Currdate from '../../../CurrDate'
import Userjson from '../../../Userjson'
import * as Apiurl from '../../../Apisurl'
import headersReq from '../../../HeadersJson'

const TracingGame = () => {
  const reqJson = headersReq()
  const canvasRef = useRef(null)
  const [userAnswer, setUserAnswer] = useState('')
  const [counter, setCounter] = useState(0)
  let navigate = useNavigate()
  const contextRef = useRef(null)
  const [letteridx, setLetterIdx] = useState(0)

  const [isDrawing, setIsDrawing] = useState(false)
  const location = useLocation()
  let initialArray = []
  const [page, setPage] = useState(0)

  const [theArray, setTheArray] = useState(initialArray)
  const [data1, setData] = useState()
  const [data2, setData2] = useState([])
  let dataArrray = []

  let date = Currdate.GetCurrentDate()

  const submitResult = () => {
    let answer = JSON.parse(localStorage.getItem('useranswer'))
    let getScore = answer?.filter((o, i) => {
      return o.via == 'correct'
    })

    let alldata = {
      ...Userjson(),
      result: JSON.stringify({
        ques: data1,
        score: page,
        total: data1.length,
        ans: answer,
      }),
      date: date,
    }
    const esc = encodeURIComponent

    const query = Object.keys(alldata)
      .map((k) => `${esc(k)}=${esc(alldata[k])}`)
      .join('&')

    async function levelgame() {
      const response = await fetch(`${Apiurl.Fetchurl + 'manual_report_fc?' + query + ''}`, {
        method: 'GET',
        headers: reqJson,
      })
      const childdata = await response.json()
      return childdata
    }
    levelgame()
      .then((data) => {
        navigate(`drawresult?id=${data.serial_num}`, { state: { ...location.state, date: date } })
      })
      .catch((error) => {
        alert(error)
      })
  }

  useEffect(() => {
    async function levelgame() {
      const response = await fetch(`${Apiurl.Fetchurl + 'level_drawings'}`, {
        method: 'POST',
        body: JSON.stringify({
          category: location.state.category,
        }),
        headers: reqJson,
      })
      const childdata = await response.json()
      return childdata
    }
    levelgame()
      .then((data) => {
        let getques = data[location.state.link]
        let gtques1 = getques.map((obj, index) => {
          return obj.ques
        })
        dataArrray = gtques1
        setData2(gtques1)
        setData(getques)

        setTimeout(() => {
          getNextQues(gtques1[0])
        }, 500)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  let update = []

  const handleSubmit = () => {
    setCounter(counter + 1)
    let isCompleted = isLineTraceCompleted()

    setPage((prev) => {
      setTheArray((oldArray) => {
        update = [
          ...oldArray,
          {
            via: isCompleted ? 'correct' : 'incorrect',
            answer: data1[prev].ans,
          },
        ]
        localStorage.setItem('useranswer', JSON.stringify(update))
        return update
      })

      if (theArray.length == data2.length - 1) {
        setTheArray([])
        submitResult()
        // navigate(
        //   'drawresult',
        //   {
        //     state: { ...location.state, date: date },
        //   },
        //   1000
        // )
      } else {
        getNextQues(data2[page + 1])
        return page + 1
      }
    })
  }

  const drawTriangle = (canvas, ctx) => {
    // Calculate center coordinates
    const centerX = canvas.width / 2
    const centerY = canvas.height / 2

    // Set triangle vertices
    const x1 = centerX - 125
    const y1 = centerY + 125
    const x2 = centerX + 125
    const y2 = centerY + 125
    const x3 = centerX
    const y3 = centerY - 125

    // Begin drawing
    ctx.beginPath()
    ctx.moveTo(x1, y1)
    ctx.lineTo(x2, y2)
    ctx.lineTo(x3, y3)
    ctx.closePath()

    ctx.strokeStyle = 'rgb(107 195 186)'
    ctx.stroke()
    ctx.strokeStyle = 'rgb(0, 0, 50)'
  }

  const drawSquare = (canvas, ctx) => {
    let x = canvas.width / 5,
      y = x
    let width = x * 3,
      height = y * 3

    ctx.beginPath()
    ctx.strokeStyle = 'rgb(107 195 186)'
    ctx.rect(x, y, width, height)
    ctx.stroke()
    ctx.strokeStyle = 'rgb(0, 0, 50)'
  }

  const drawRect = (canvas, ctx) => {
    let x = canvas.width / 7,
      y = canvas.height / 3
    let width = x * 5,
      height = y

    ctx.beginPath()
    ctx.strokeStyle = 'rgb(107 195 186)'
    ctx.rect(x, y, width, height)
    ctx.stroke()
    ctx.strokeStyle = 'rgb(0, 0, 50)'
  }

  const drawCircle = (canvas, ctx) => {
    let x = canvas.width / 2,
      y = canvas.height / 2,
      radius = canvas.width / 2 - canvas.width / 4

    ctx.beginPath()
    ctx.strokeStyle = 'rgb(107 195 186)'
    ctx.arc(x, y, radius, 0, 2 * Math.PI)
    ctx.stroke()
    ctx.strokeStyle = 'rgb(0, 0, 50)'
  }

  const drawStar = (canvas, ctx) => {
    const centerX = canvas.width / 2
    const centerY = canvas.height / 2

    // Set star properties
    const outerRadius = 150
    const innerRadius = 60
    const numPoints = 5

    // Begin drawing
    ctx.beginPath()

    for (let i = 0; i < numPoints * 2; i++) {
      const radius = i % 2 === 0 ? outerRadius : innerRadius
      const angle = (Math.PI / numPoints) * i

      // Adjusted the angle here
      const x = centerX + radius * Math.cos(angle - Math.PI / 2)
      const y = centerY + radius * Math.sin(angle - Math.PI / 2)

      if (i === 0) {
        ctx.moveTo(x, y)
      } else {
        ctx.lineTo(x, y)
      }
    }

    ctx.closePath()

    ctx.strokeStyle = 'rgb(107 195 186)'
    ctx.stroke()
    ctx.strokeStyle = 'rgb(0, 0, 50)'
  }

  const drawCone = (canvas, ctx) => {
    const centerX = canvas.width / 2
    const centerY = canvas.height / 2

    const baseRadius = 75
    const coneHeight = 150

    ctx.beginPath()
    ctx.arc(centerX, centerY, baseRadius, 0, Math.PI)
    ctx.lineTo(centerX, centerY - coneHeight)
    ctx.closePath()

    ctx.strokeStyle = 'rgb(107 195 186)'
    ctx.stroke()
    ctx.strokeStyle = 'rgb(0, 0, 50)'
  }

  const drawHeart = (canvas, ctx) => {
    const centerX = canvas.width / 2
    const centerY = canvas.height / 2

    ctx.beginPath()

    ctx.moveTo(centerX, centerY)
    ctx.bezierCurveTo(centerX - 200, centerY - 150, centerX - 200, centerY + 100, centerX, centerY + 200)
    ctx.moveTo(centerX, centerY)
    ctx.bezierCurveTo(centerX + 200, centerY - 150, centerX + 200, centerY + 100, centerX, centerY + 200)

    ctx.strokeStyle = 'rgb(107 195 186)'
    ctx.stroke()
    ctx.strokeStyle = 'rgb(0, 0, 50)'
  }

  const getNextQues = (res) => {
    const c = canvasRef.current
    const cx = c.getContext('2d', { willReadFrequently: true })
    contextRef.current = cx

    c.width = 500
    c.height = 500

    cx.lineWidth = 25
    cx.lineCap = 'round'
    cx.strokeStyle = 'rgb(0, 0, 50)'

    cx.font = 'bold 250px arial'
    cx.fillStyle = 'rgb(107 195 186)'
    cx.textBaseline = 'middle'

    let centerx, centery

    if (res === 'Triangle') {
      drawTriangle(c, cx)
    } else if (res === 'Square') {
      drawSquare(c, cx)
    } else if (res === 'Rectangle') {
      drawRect(c, cx)
    } else if (res === 'Circle') {
      drawCircle(c, cx)
    } else if (res === 'Star') {
      drawStar(c, cx)
    } else if (res === 'Cone') {
      drawCone(c, cx)
    } else if (res === 'Heart') {
      drawHeart(c, cx)
    } else {
      centerx = (c.width - cx.measureText(res).width) / 2
      centery = c.height / 2

      cx.fillText(res, centerx, centery)
    }
  }

  // helper for checking if letter is in the background
  const getpixelcolour = (x, y) => {
    var pixels = contextRef.current.getImageData(0, 0, canvasRef.current.width, canvasRef.current.height)
    var index = y * (pixels.width * 4) + x * 4
    return {
      r: pixels.data[index],
      g: pixels.data[index + 1],
      b: pixels.data[index + 2],
      a: pixels.data[index + 3],
    }
  }

  // to get the no.of pixel painted
  const getPixelCount = (r, g, b) => {
    if (contextRef.current) {
      var pixels = contextRef.current.getImageData(0, 0, canvasRef.current.width, canvasRef.current.height)
      var all = pixels.data.length
      var amount = 0
      for (var i = 0; i < all; i += 4) {
        if (pixels.data[i] === r && pixels.data[i + 1] === g && pixels.data[i + 2] === b) {
          amount++
        }
      }
      return amount
    }
  }

  const letterPixelCount = getPixelCount(107, 195, 186)

  const isLineTraceCompleted = () => {
    let setCompLine = false
    if (getPixelCount(0, 0, 50) / letterPixelCount >= 0.9) {
      setCompLine = true
    }
    return setCompLine
  }

  const startDrawing = ({ nativeEvent }) => {
    const { offsetX, offsetY } = nativeEvent

    // check if letter is in the background
    var colour = getpixelcolour(offsetX, offsetY)
    if (colour.a === 0) {
      return
    }

    contextRef.current.beginPath()
    contextRef.current.moveTo(offsetX, offsetY)
    setIsDrawing(true)
  }

  const stopDrawing = () => {
    contextRef.current.closePath()
    isLineTraceCompleted()
    setIsDrawing(false)
  }

  const draw = ({ nativeEvent }) => {
    if (!isDrawing) return
    const { offsetX, offsetY } = nativeEvent
    contextRef.current.lineTo(offsetX, offsetY)
    contextRef.current.stroke()
  }

  if (data1 != undefined) {
    return (
      <div className='flex flex-col items-center'>
        <h2>Tracing-trace the Alphabets</h2>
        <div style={{ textAlign: 'center' }}>
          <canvas
            style={{
              border: '20px solid var(--primary-color)',
            }}
            className='block shadow  can rounded mx-auto my-6 bg-white'
            onTouchStart={startDrawing}
            onMouseDown={startDrawing}
            onMouseUp={stopDrawing}
            onMouseMove={draw}
            ref={canvasRef}
          ></canvas>
        </div>
        <div style={{ textAlign: 'right' }}>
          <button
            className='px-4 py-2.5 bg-blue-800 common-btn p-2 text-white rounded w-32'
            onClick={() => handleSubmit()}
          >
            Next
          </button>
        </div>

        <div className='activity-listing'>
          <ul>
            <li>
              <Progress json={data1} currindex={1} getarray={theArray} />
            </li>
            <li className='refresh-btn'>
              <button>
                <img src={refreshbtn} alt='refresh' />
                Start Another Level
              </button>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}
export default TracingGame
