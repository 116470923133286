import react from 'react'
import { Row, Container, Col } from 'react-bootstrap'
import backbtn from '../../../assets/images/backbtn.svg'
import Focus from '../../../includes/Focus'
import Progress from '../Testprogrsscom'
import refreshbtn from '../../../assets/images/refresh.svg'
import Draggable, { DraggableCore } from 'react-draggable' // Both at the same time
import { useState, useRef, useEffect, useContext } from 'react'
import StartModal from '../../SelectgameStart'
import { NavLink, useLocation, useParams, useNavigate } from 'react-router-dom'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import * as Currdate from '../../../CurrDate'
import * as Apiurl from '../../../Apisurl'
import { useSpeechSynthesis } from 'react-speech-kit'
import { SocketConfig } from '../../../SocketConfig'
import Speech from '../../Speech'

const Calculation = (props) => {
  let DraggableCore = Draggable.DraggableCore
  const { speak } = useSpeechSynthesis()
  const [score, setScore] = useState(0)
  const location = useLocation()
  const [isActive, setIsActive] = useState(false)
  let initialArray = []
  const [theArray, setTheArray] = useState(initialArray)
  const [mistakes, setMistakes] = useState(0)
  const [userAnswer, setUserAnswer] = useState('')
  const resetButton = useRef(null)
  const [smShow, setsmShow] = useState(false)
  const [parsedata, setparsedata] = useState(false)
  const [page, setPage] = useState(0)
  // const props1 = useContext(UserContext);
  let mathsdatavalue = props.mathsdata
  console.log(props.mathsdata)
  let datalength = props.datalength
  let date = Currdate.GetCurrentDate()
  props.time(date)
  let datalengthi = datalength
  const [callday, setcallday] = useState(1)
  let navigate = useNavigate()
  useEffect(() => {
    SocketConfig.on('action_data', (data) => {
      // console.log("Datta ", data);
      let parseddata = data.result
      if (parseddata.length != 0) {
        setparsedata(true)
        handleSubmit(parseddata.action, true)
      }
      // console.log(parseddata);
    })
    //
  }, [])
  let socketVia = parseInt(sessionStorage.getItem('headsetVia'))
  // populate level text
  const levelText = (res) => {
    // console.log(res);
    let populateLeveltxt = ''
    let sign = '>'
    switch (res) {
      case 'level1':
        populateLeveltxt = 'Find Greater'
        sign = '>'
        break
      case 'level2':
        populateLeveltxt = 'Find Lesser'
        sign = '<'
        break
      case 'level3':
        populateLeveltxt = 'Find Equal'
        sign = '=='
        break
    }
    return populateLeveltxt
  }
  let update
  // checkanswers
  let count = 0

  function handleSubmit(e, via) {
    console.log(e)
    let userAnswer = e

    // console.log(userAnswer);
    // speak({ text: userAnswer });
    Speech.Speech(userAnswer)

    let correctAnswer
    let arr = []
    arr.push([mathsdatavalue.direction[0], mathsdatavalue.direction[1], count], userAnswer)

    if (socketVia == 1) {
      if (datalength[props.page].ans1 == userAnswer) {
        props.setPage((prev) => {
          props.setTheArray((oldArray) => {
            update = [
              ...oldArray,
              {
                via: 'correct',
                answer: userAnswer,
              },
            ]
            localStorage.setItem('useranswer', JSON.stringify(update))
            return update
          })
          if (update.length === datalengthi.length) {
            props.setTheArray([])
            // socketemitdata();
            props.submitResult(date)

            // navigate(
            //   'mathresult',
            //   {
            //     state: { ...location.state, date: date },
            //   },
            //   1000
            // )
          } else {
            props.setScore((prev) => prev + 1)
            count++
            return prev + 1
          }
        })
        // console.log(props.page);
      } else {
        props.setPage((prev) => {
          props.setTheArray((oldArray) => {
            update = [
              ...oldArray,
              {
                via: 'incorrect',
                answer: userAnswer,
              },
            ]
            localStorage.setItem('useranswer', JSON.stringify(update))
            return update
          })

          if (update.length === datalengthi.length) {
            props.setTheArray([])
            props.submitResult(date)
            // navigate(
            //   'mathresult',
            //   {
            //     state: { ...location.state, date: date },
            //   },
            //   100
            // )
          } else {
            count++
            return prev + 1
          }
        })
      }
    } else {
      alert(datalength[props.page].ans)
      if (
        datalength[props.page].ans.toUpperCase() == userAnswer.toUpperCase() ||
        datalength[props.page].ans.toUpperCase() == userAnswer.toUpperCase()
      ) {
        props.setPage((prev) => {
          props.setTheArray((oldArray) => {
            update = [
              ...oldArray,
              {
                via: 'correct',
                answer: userAnswer,
              },
            ]
            localStorage.setItem('useranswer', JSON.stringify(update))
            return update
          })
          if (update.length === datalengthi.length) {
            props.setTheArray([])
            // socketemitdata();
            props.submitResult(date)

            // navigate(
            //   'mathresult',
            //   {
            //     state: { ...location.state, date: date },
            //   },
            //   1000
            // )
          } else {
            props.setScore((prev) => prev + 1)
            count++
            return prev + 1
          }
        })
        // console.log(props.page);
      } else {
        props.setPage((prev) => {
          props.setTheArray((oldArray) => {
            update = [
              ...oldArray,
              {
                via: 'incorrect',
                answer: userAnswer,
              },
            ]
            localStorage.setItem('useranswer', JSON.stringify(update))
            return update
          })

          if (update.length === datalengthi.length) {
            props.setTheArray([])
            props.submitResult(date)
            // navigate(
            //   'mathresult',
            //   {
            //     state: { ...location.state, date: date },
            //   },
            //   100
            // )
          } else {
            count++
            return prev + 1
          }
        })
      }
    }
  }

  if (mathsdatavalue != undefined) {
    return (
      <>
        <Container fluid>
          <Row className='colors-main-container comparing-game'>
            <h1
              style={{
                textAlign: 'center',
                fontWeight: '600',
                textTransform: 'uppercase',
              }}
            >
              {levelText(props.level)}
            </h1>
            <Col md={12}>
              <p>Your Problem</p>
              <Focus />
              <div className='addition-container ' style={socketVia == 1 ? { display: 'none' } : { display: 'flex' }}>
                <h5>{mathsdatavalue.no1} </h5>
                <div className='color question-container'>?</div>
                <h5>{mathsdatavalue.no2}</h5>
              </div>
              <div className='addition-container ' style={socketVia == 1 ? { display: 'flex' } : { display: 'none' }}>
                <h5 className='numContainer' onClick={() => handleSubmit(mathsdatavalue.no1, false)}>
                  {mathsdatavalue.no1}
                </h5>
                <div className='color question-container'>?</div>
                <h5 className='numContainer' onClick={() => handleSubmit(mathsdatavalue.no2, false)}>
                  {mathsdatavalue.no2}
                </h5>
              </div>
              <div className='content-container' style={socketVia == 1 ? { display: 'none' } : { display: 'block' }}>
                <p>Select a result to fill in the blank</p>
                <div className='measure-container'>
                  {mathsdatavalue.mcq.map((o, i) => {
                    let d = o.split(' ')
                    return (
                      <div className='measure-item' onClick={() => handleSubmit(o, false)}>
                        <h5>{o}</h5>
                      </div>
                    )
                  })}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <StartModal
          calldelay={setcallday}
          socket={SocketConfig}
          type={['action', 'focus']}
          date={date}
          letquesLength={datalength.length}
          calldaynum={callday}
        />
      </>
    )
  }
}
export default Calculation
