import Table from 'react-bootstrap/Table'
import { MDBIcon } from 'mdb-react-ui-kit'
import ReactPaginate from 'react-paginate'
import Context from '../../../Context'
import { useEffect, useState, useContext } from 'react'

const Colortable = () => {
  const props1 = useContext(Context)
  let overViewData = props1[2]['Overview']
  // console.log(overViewData.ques, "test");
  if (overViewData) {
    return (
      <Table className='result-table'>
        <thead>
          <tr>
            <th></th>
            {overViewData.ques != undefined
              ? overViewData.ques.map((o, i) => {
                  return <th>Q{i + 1}</th>
                })
              : ''}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Asked</td>
            {overViewData.ques
              ? overViewData.ques.map((o, i) => {
                  console.log(o.quest)
                  return (
                    <td>
                      <div className='asked-color-box' style={{ backgroundColor: o.ques.color }}>
                        <p style={{ color: '#fff' }}>{o.ques.colorName}</p>
                      </div>
                    </td>
                  )
                })
              : ''}
          </tr>
          <tr>
            <td>Your Answer</td>
            {overViewData.ans
              ? overViewData.ans.map((o, i) => {
                  return (
                    <td>
                      <div className='asked-color-box colorvia'>
                        {o.via == 'correct' ? <i class='fas fa-check'></i> : <i class='fas fa-times'></i>}
                        <p style={o.via == 'correct' ? { color: '#77C63A' } : { color: 'red' }}>{o.answer}</p>
                      </div>
                    </td>
                  )
                })
              : ''}
          </tr>
          <tr>
            <td>Correct Answer</td>
            {overViewData.ques
              ? overViewData.ques.map((o, i) => {
                  console.log(o.ans.colorName)
                  return (
                    <td>
                      <div className='asked-color-box' style={{ backgroundColor: o.ans.color }}>
                        <p style={{ color: '#fff' }}>{o.ans.colorName}</p>
                      </div>
                    </td>
                  )
                })
              : ''}
          </tr>
        </tbody>
      </Table>
    )
  }
}

export default Colortable
