import { useLocation } from 'react-router-dom'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import nextArrow from '../../assets/images/nextarrow.svg'
import prevArrow from '../../assets/images/prevarrow.svg'
import GamecatCard from './GameCatCard'
import { Row } from 'react-bootstrap'

const GameCatSlider = () => {
  let data = JSON.parse(localStorage.getItem('modulejson'))
  let use_Loc = useLocation()

  var settings = {
    dots: false,
    infinite: false,
    initialslide: 0,
    speed: 500,
    autoplay: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: (
      <figure className='arrow-item prevArrow'>
        <img src={nextArrow} alt='nextArrow' />
      </figure>
    ),
    prevArrow: (
      <figure className='arrow-item prevArrow'>
        <img src={prevArrow} alt='prevarrow' />
      </figure>
    ),
  }

  let catdata = ''
  let modName =
    localStorage
      .getItem('modulename')
      .toUpperCase()
      .charAt(0)
      .toUpperCase() +
    localStorage
      .getItem('modulename')
      .toUpperCase()
      .slice(1)
      .toLowerCase()

  return (
    <>
      <h3>Please select a Category to proceed</h3>
      <Row className='option-container'>
        <Slider {...settings} className='gameCatContainer'>
          {data != undefined && data != ''
            ? data[modName].map((obj, index) => {
                if (index != 0) {
                  if (obj.module.toUpperCase() == use_Loc.state.modulename.toUpperCase()) {
                    catdata = obj.categories.map((obj, index) => {
                      return (
                        <GamecatCard
                          key={index}
                          title={obj.name}
                          images={obj.image}
                          content={obj.text}
                          link={obj.name_title}
                          percentage={obj.attempted_percentage || 0}
                        />
                      )
                    })
                  }
                }

                return catdata
              })
            : ''}
        </Slider>
      </Row>
    </>
  )
}
export default GameCatSlider
