import { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'

import Button from 'react-bootstrap/Button'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Form from 'react-bootstrap/Form'
import 'react-dropzone-uploader/dist/styles.css'
import { useNavigate, useParams } from 'react-router-dom'
import * as Apiurl from '../Apisurl'
import userImg from '../assets/images/user5.svg'
import Formstyle from './cssModule/Formstyle.module.css'
import logo from '../assets/images/logo.svg'
import Select from 'react-select'
import jwtDecode from 'jwt-decode'
import sendMail from './sendMail'
import PasswordStrengthMeter from "../login/PasswordStrengthMeter.jsx"

const AdminForm = () => {
  const { type: role } = useParams()
  const navigate = useNavigate()
  const [isStrongPassword, setIsStrongPassword] = useState(false)

  const [hrList, setHrList] = useState()
  const [companyList, setCompanyList] = useState()
  const [services, setServices] = useState([])
  const [service, setService] = useState([])

  const typingTimeoutRef = useRef()

  const [uservalues, setInputValue] = useState({
    username: '',
    email_id: '',
    password: '',
    phonenumber: '',
    school_name: '',
    speciality: '',
    duration: '1',
    max_students: '1',
    secret_key: '',
    module_start: '1',
    module_end: '5',
    person_type: role,
  })

  const [childData, setChildData] = useState({
    username: '',
    class: '',
    school_name: '',
    special_need: '',
    parent_name: 'NA',
    person_type: 'child',
    dob: '',
    gender: '',
  })

  const [validated, setValidated] = useState(false)
  const [loading, setLoading] = useState(false)
  const [err, seterr] = useState('')
  const [err1, seterr1] = useState('')
  const formElement = useRef()

  const handleChange = (e) => {
    const { name, value } = e.target
    setInputValue({ ...uservalues, [name]: value })
  }

  const handleServiceChange = (e) => {
    seterr1('')
    setService(e)
  }

  const teacherValidate = (event) => {
    event.preventDefault()
    const isFormInvalid = formElement.current.checkValidity() === false
    const isServiceEmpty = !service.length > 0
    const isInvalidAccessKey = uservalues.secret_key !== '$$858948994039039049&&&&'

    if (isFormInvalid || isServiceEmpty || isInvalidAccessKey) {
      event.stopPropagation()
      isServiceEmpty && seterr1('Please enter required field')
      isInvalidAccessKey && seterr('Access key invalid')
    } else {
      if(!isStrongPassword){
        alert('Please use a stronger password')
        return
      }
      // processSignup().catch((error) => {
      //   console.error(error)
      // })

      signup()
    }
    setValidated(true)
  }

  async function signup() {
    setLoading(true)
    try {
      const response = await fetch(Apiurl.Fetchurl + 'signup', {
        method: 'POST',
        body: JSON.stringify(uservalues),
      })
      if (!response.ok) {
        const error = await response.json()
        throw new Error(error?.error || 'Something Went Wrong....')
      }
      const data = await response.json()
      window.sessionStorage.setItem('session', data.session)
      window.sessionStorage.setItem('max_students', data.max_students)
      sendMail({ email: uservalues.email_id, username: uservalues.username, password: uservalues.password })
      navigate('/loginmain')
      // return data
    } catch (error) {
      seterr(error.message)
    } finally {
      setLoading(false)
    }
  }

  // const initiatePayment = async () => {
  //   try {
  //     const response = await fetch(Apiurl.Fetchurl + 'order', {
  //       method: 'POST',
  //       body: JSON.stringify({
  //         service_id: service.map((s) => parseInt(s.value)),
  //         month: parseInt(uservalues.duration),
  //         person_type: role,
  //       }),
  //     })
  //     const data = await response.json()
  //     openPaymentGateway(data.Success)
  //   } catch (error) {
  //     console.error(error)
  //     throw new Error('Error in initiatePayment')
  //   }
  // }

  // const openPaymentGateway = (paymentData) => {
  //   const paymentOptions = {
  //     key: process.env.REACT_APP_RAZORPAY,
  //     amount: paymentData.amount,
  //     currency: paymentData.currency,
  //     name: 'NEMA AI',
  //     description: 'Test Transaction',
  //     image: logo,
  //     order_id: paymentData.id,

  //     handler: async (response) => {
  //       try {
  //         const verificationResponse = await fetch(Apiurl.Fetchurl + 'verify', {
  //           method: 'POST',
  //           body: JSON.stringify({
  //             ...response,
  //             service_id: service.map((s) => parseInt(s.value)),
  //             month: parseInt(uservalues.duration),
  //             person_type: role,
  //           }),
  //         })

  //         if (!verificationResponse.ok) throw new Error('Something went wrong')

  //         const verificationData = await verificationResponse.json()
  //         sessionStorage.setItem('isPaymentSuccessful', 'true')
  //         sessionStorage.setItem('responseFromOrder', JSON.stringify(response))
  //         sessionStorage.setItem('paymentResponse', JSON.stringify(verificationData))

  //         const { user_id } = await signup()
  //         if (!user_id) throw new Error('User Creation failed')

  //         await verifyPayment({
  //           service_id: JSON.stringify(service.map((s) => parseInt(s.value))),
  //           month: parseInt(uservalues.duration),
  //           person_type: role,
  //           ...response,
  //           payment_id: verificationData.payment_id,
  //           method: JSON.stringify(verificationData.method),
  //           user_id,
  //         })

  //         sessionStorage.removeItem('isPaymentSuccessful')
  //         sessionStorage.removeItem('responseFromOrder')
  //         sessionStorage.removeItem('paymentResponse')

  //         navigate('/loginmain')
  //       } catch (error) {
  //         console.error(error)
  //       }
  //     },
  //     theme: { color: '#3399cc' },
  //   }
  //   const razorpayInstance = new window.Razorpay(paymentOptions)
  //   razorpayInstance.open()
  // }

  // const verifyPayment = async (paymentDetails) => {
  //   try {
  //     const res = await fetch(Apiurl.Fetchurl + 'verify?' + new URLSearchParams(paymentDetails).toString())
  //     if (!res.ok) throw new Error('Something went wrong')
  //     // const data = await res.json()
  //     // return data
  //   } catch (error) {
  //     console.error(error)
  //     throw new Error('Error in verifyPayment')
  //   }
  // }

  // const processSignup = async () => {
  //   if (err || err1) return

  //   const isPaymentSuccessful = sessionStorage.getItem('isPaymentSuccessful') === 'true'
  //   const paymentResponse = JSON.parse(sessionStorage.getItem('paymentResponse'))
  //   const responseFromOrder = JSON.parse(sessionStorage.getItem('responseFromOrder'))

  //   if (isPaymentSuccessful) {
  //     const { user_id } = await signup()
  //     if (!user_id) throw new Error('User Creation failed')

  //     await verifyPayment({
  //       ...responseFromOrder,
  //       service_id: JSON.stringify(service.map((s) => parseInt(s.value))),
  //       month: parseInt(uservalues.duration),
  //       person_type: role,
  //       payment_id: paymentResponse.payment_id,
  //       method: JSON.stringify(paymentResponse.method),
  //       user_id,
  //     })
  //     sessionStorage.removeItem('isPaymentSuccessful')
  //     sessionStorage.removeItem('responseFromOrder')
  //     sessionStorage.removeItem('paymentResponse')

  //     navigate('/loginmain')
  //   } else {
  //     try {
  //       await initiatePayment()
  //     } catch (error) {
  //       console.error(error)
  //       throw new Error('Error in processSignup')
  //     }
  //   }
  // }

  const checkAvailability = useCallback(async () => {
    if (!uservalues.username) return
    try {
      const response = await fetch(Apiurl.Fetchurl + 'verify_user', {
        method: 'POST',
        body: JSON.stringify({ username: uservalues.username }),
      })

      if (!response.ok) throw new Error('username unqiueness check failed')

      const data = await response.json()
      if (data.Status === 400) return seterr(data.message)
      else return seterr()
    } catch (error) {
      console.error('Error:', error)
    }
  }, [uservalues.username])

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }

    typingTimeoutRef.current = setTimeout(checkAvailability, 1000)

    return () => {
      clearTimeout(typingTimeoutRef.current)
    }
  }, [uservalues.username, checkAvailability])

  useEffect(() => {
    const getHRList = async () => {
      if (role !== 'student' || !uservalues.school_name.trim()) {
        setHrList([])
        return
      }
      try {
        const response = await fetch(`${Apiurl.Fetchurl}hr_list?company_name=${uservalues.school_name.trim()}&person_type=${role}`)
        const data = await response.json()
        setHrList(data.hr_data)
      } catch (error) {
        console.log(error)
      }
    }

    getHRList()
  }, [uservalues.school_name, role])

  useEffect(() => {
    const getCompanyList = async () => {
      if (role !== 'student') return
      try {
        const response = await fetch(`${Apiurl.Fetchurl}company_list?person_type=${role}`)
        const data = await response.json()
        setCompanyList(data.company_list)
      } catch (error) {
        console.log(error)
      }
    }

    getCompanyList()
  }, [role])

  useEffect(() => {
    const getServices = async () => {
      if (!role) return
      try {
        const response = await fetch(`${Apiurl.Fetchurl}service_id_plan?person_type=${role}`)
        const data = await response.json()
        const modifiedData = Object.entries(data.output).map(([id, name]) => ({
          label: name,
          value: id,
          isFixed: id === '10',
        }))
        setServices(modifiedData)
        if (modifiedData.length > 0) {
          setService(modifiedData.filter((d) => d.isFixed))
        }
      } catch (error) {
        console.error(error)
      }
    }

    getServices()
  }, [role])

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: 'transparent',
      border: 0,
      borderRadius: 0,
      borderBottom: err1 ? '2px solid #dc3545' : service?.length > 0 || state.isFocused ? '2px solid var(--primary-color)' : '2px solid #ebebeb',
      boxShadow: 'none',
      '&:hover': {
        borderBottom: err1 ? '2px solid #dc3545' : '2px solid var(--primary-color)',
      },
    }),
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isSelected || isFocused ? 'var(--primary-color)' : null,
      color: isSelected || isFocused ? 'white' : 'black',
    }),
    valueContainer: (base) => ({ ...base, padding: 0 }),
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: 'none' } : base
    },
  }

  return (
    <>
      <Row>
        {err && <p className='text-danger'>{err}</p>}
        <Col md={8}>
          <Form className={Formstyle.formContainer} noValidate validated={validated} ref={formElement}>
            <Row>
              <Col md={6}>
                <div className={Formstyle.profileContainer}>
                  <figure>
                    <img src={userImg} alt='userimage' />
                  </figure>
                  <FloatingLabel controlId='floatingInput' label='Username' className={Formstyle.labelinput}>
                    <Form.Control
                      type='text'
                      placeholder='xcvvx'
                      maxLength='40'
                      required
                      name='username'
                      value={uservalues.username}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                  </FloatingLabel>
                </div>
              </Col>
              <Col md={6}>
                <FloatingLabel controlId='floatingInput' label='Password' className='mb-3'>
                  <Form.Control
                    type='password'
                    value={uservalues.password}
                    onChange={handleChange}
                    name='password'
                    placeholder='asdasd'
                    maxLength='40'
                    required
                  />
                 <PasswordStrengthMeter password={uservalues.password} setIsStrongPassword={setIsStrongPassword} />
                  <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>
            {/* <Row>
              <Col md={6}>
                <label>Services</label>
                <Select
                  placeholder='Add Services'
                  onChange={handleServiceChange}
                  options={services}
                  value={service}
                  isMulti
                  isClearable={false}
                  isSearchable={false}
                  styles={customStyles}
                />
                {err1 && <p style={{ marginTop: '0.25rem', fontSize: '.875em', color: '#dc3545' }}>{err1}</p>}
              </Col>
              <Col md={6}>
                <FloatingLabel controlId='Subscription' label='Subscription (Months)' className={Formstyle.labelinput}>
                  <Form.Control
                    type='number'
                    max={12}
                    min={1}
                    placeholder='1'
                    value={uservalues.duration}
                    onChange={handleChange}
                    name='duration'
                    required
                  />
                  <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row> */}

            <Row>
              <Col md={6}>
                <FloatingLabel controlId='floatingInput' label='Email' className='mb-3'>
                  <Form.Control
                    type='email'
                    value={uservalues.email_id}
                    onChange={handleChange}
                    name='email_id'
                    placeholder='name@example.com'
                    maxLength='40'
                    required
                  />
                  <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6}>
                <div className={Formstyle.mobContainer}>
                  <FloatingLabel controlId='floatingInput' label='Phone Number' required className={Formstyle.labelinput}>
                    <Form.Control
                      type='number'
                      maxLength='40'
                      placeholder=''
                      value={uservalues.phonenumber}
                      onChange={handleChange}
                      name='phonenumber'
                      required
                    />
                    <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                  </FloatingLabel>
                </div>
              </Col>
            </Row>

            {role === 'employee' || role === 'hr' ? (
              <Row>
                <Col md={6}>
                  <Form.Group className='mb-3'>
                    <Form.Label htmlFor='gender'>Gender</Form.Label>
                    <br />
                    <Form.Check
                      required
                      type='radio'
                      id='Male'
                      label='Male'
                      name='gender'
                      value='male'
                      checked={childData.gender === 'male'}
                      onChange={(e) => setChildData({ ...childData, gender: e.target.value })}
                      inline
                    />
                    <Form.Check
                      required
                      type='radio'
                      id='Female'
                      label='Female'
                      name='gender'
                      value='female'
                      checked={childData.gender === 'female'}
                      onChange={(e) => setChildData({ ...childData, gender: e.target.value })}
                      inline
                    />
                    <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <FloatingLabel controlId='dob' label='Date of Birth' className='mb-3'>
                    <Form.Control
                      required
                      type='date'
                      name='dob'
                      value={childData.dob
                        ?.split('-')
                        .reverse()
                        .join('-')}
                      onChange={(e) =>
                        setChildData({
                          ...childData,
                          dob: e.target.value
                            ?.split('-')
                            .reverse()
                            .join('-'),
                        })
                      }
                    />
                    <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>
            ) : null}

            <Row>
              <Col>
                <FloatingLabel controlId='floatingInput' label={role === 'teacher' || role === 'parent' ? 'School' : 'Company'} className='mb-3'>
                  {role === 'employee' ? (
                    <Form.Select required value={uservalues.school_name} onChange={handleChange} name='school_name'>
                      <option value=''>select company</option>
                      {companyList?.map((name, i) => (
                        <option key={i} value={name}>
                          {name}
                        </option>
                      ))}
                    </Form.Select>
                  ) : (
                    <Form.Control
                      type='text'
                      placeholder='asdsa'
                      name='school_name'
                      value={uservalues.school_name}
                      onChange={handleChange}
                      maxLength='40'
                      required
                    />
                  )}
                  <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col>
                {role === 'teacher' ? (
                  <FloatingLabel controlId='floatingSelect' label='Speciality'>
                    <Form.Select required value={uservalues.speciality} onChange={handleChange} name='speciality'>
                      <option value=''>Select</option>
                      <option value='educator'>Special educator</option>
                      <option value='therapis'>Speech therapist</option>
                      <option value='assessor'>Technical assessor</option>
                      <option value='other'>Other</option>
                    </Form.Select>
                    <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                  </FloatingLabel>
                ) : (
                  <FloatingLabel controlId='floatingSelect' label='Department'>
                    <Form.Control
                      type='text'
                      placeholder='xcvvx'
                      maxLength='40'
                      required
                      name='speciality'
                      value={uservalues.speciality}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                  </FloatingLabel>
                )}
              </Col>
              {role === 'employee' ? (
                <Col>
                  <FloatingLabel controlId='floatingInput' label='HR'>
                    <Form.Select
                      required
                      aria-label='Floating label select example'
                      value={childData.class}
                      onChange={(e) => setChildData({ ...childData, class: e.target.value })}
                      name='class'
                    >
                      <option value=''>select hr</option>
                      {hrList?.map((name, i) => (
                        <option key={i} value={name}>
                          {name}
                        </option>
                      ))}
                    </Form.Select>

                    <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              ) : null}
            </Row>

            <Row>
              <Col>
                <FloatingLabel controlId='floatingInput' label='Access Key' className={Formstyle.labelinput}>
                  <Form.Control
                    required={role === 'admin' || role === 'hr'}
                    type='text'
                    placeholder='******'
                    name='secret_key'
                    value={uservalues.secret_key}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>

            {uservalues.secret_key === '$$858948994039039049&&&&' ? (
              <Row>
                <Col md={6}>
                  <FloatingLabel controlId='Subscription' label='Subscription (Months)' className={Formstyle.labelinput}>
                    <Form.Control
                      type='number'
                      max={12}
                      min={1}
                      placeholder='1'
                      value={uservalues.duration}
                      onChange={handleChange}
                      name='duration'
                      required
                    />
                    <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel controlId='max_students  ' label='No of Students' className={Formstyle.labelinput}>
                    <Form.Control
                      type='number'
                      max={100}
                      min={1}
                      placeholder='1'
                      value={uservalues.max_students}
                      onChange={handleChange}
                      name='max_students'
                      required
                    />
                    <Form.Control.Feedback type='invalid'>Please enter required field</Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>
            ) : null}

            <Button onClick={teacherValidate} variant='primary' className='common-btn full-btn' disabled={loading} type='button'>
              {loading ? (
                <div className='d-flex align-items-center justify-content-center gap-2'>
                  <Spinner animation='border' />
                  Submitting
                </div>
              ) : (
                'Submit'
              )}
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default AdminForm
