import { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

const Roleitem = () => {
  const navigate = useNavigate()

  const [role, setRole] = useState('teacher')

  const redirectPage = () => {
    localStorage.setItem('role', role)
    navigate('/role/' + role, { state: 1 })
  }

  return (
    <form class='login-form-container   parent-container '>
      <h3>Create an Account</h3>
      <p>Please choose a role</p>
      <div class='select-role-container'>
        <figure
          className={role == 'teacher' ? 'select-role-item active' : 'select-role-item '}
          onClick={() => setRole('teacher')}
          data-attr='teacher'
        >
          <svg xmlns='http://www.w3.org/2000/svg' width='88' height='78' fill='none'>
            <path
              d='M4.315 39.276A11.75 11.75 0 0 1 16.061 27.53a11.75 11.75 0 0 1 11.746 11.746 11.748 11.748 0 0 1-11.745 11.746 11.77 11.77 0 0 1-8.299-3.447 11.765 11.765 0 0 1-3.447-8.299zm27.253 27.851A16.027 16.027 0 0 0 6.86 57.899 16.03 16.03 0 0 0 0 71.118v4.588A2.29 2.29 0 0 0 2.294 78h27.529a2.294 2.294 0 0 0 2.294-2.294v-2.111l15.508-18.536H41.66L31.567 67.127zm55.61-14.361a2.29 2.29 0 0 1-2.294 2.294H47.626l16.059-19.18v.001a2.295 2.295 0 0 0-3.487-2.937L41.662 55.058H28.999a21.504 21.504 0 0 0-3.625-2.385 16.32 16.32 0 0 0 2.902-24.25 16.32 16.32 0 0 0-12.216-5.482A15.796 15.796 0 0 0 9.177 24.5V4.588H6.883a2.294 2.294 0 1 1 0-4.588h78a2.294 2.294 0 1 1 0 4.588h-2.295V50.47h2.295c.608 0 1.191.242 1.622.672a2.29 2.29 0 0 1 .672 1.622v.001zm-18.354-27.53a2.29 2.29 0 0 0-2.294-2.294H29.824a2.294 2.294 0 1 0 0 4.588H66.53a2.29 2.29 0 0 0 2.294-2.294zm0-9.177a2.29 2.29 0 0 0-2.294-2.294H39a2.295 2.295 0 1 0 0 4.588h27.53a2.29 2.29 0 0 0 2.294-2.294z'
              fill='url(#a)'
            />
            <defs>
              <radialGradient
                id='a'
                cx='0'
                cy='0'
                r='1'
                gradientUnits='userSpaceOnUse'
                gradientTransform='rotate(33.326 -23.29 12.535) scale(96.3376 94.6377)'
              >
                <stop stop-color='#97d5cd' />
                <stop stop-color='#54baaf' />
              </radialGradient>
            </defs>
          </svg>

          <figcaption>Teacher</figcaption>
        </figure>
        <figure
          className={role == 'parent' ? 'select-role-item active' : 'select-role-item '}
          onClick={() => setRole('parent')}
        >
          <svg xmlns='http://www.w3.org/2000/svg' width='36' height='75' fill='none'>
            <path
              d='M29.938 7.691c0 4.247-3.443 7.69-7.691 7.69A7.69 7.69 0 1 1 22.248 0a7.691 7.691 0 0 1 7.691 7.691z'
              fill='url(#a)'
            />
            <path
              d='M30.145 17.477H14.351c-.784 0-1.522.188-2.181.513 3.135 1.132 5.385 4.13 5.385 7.65a8.09 8.09 0 0 1-1.534 4.738c2.991.4 5.306 2.962 5.306 6.059v13.079a6.134 6.134 0 0 1-4.5 5.904v16.788c0 .551-.086 1.082-.242 1.582A3.78 3.78 0 0 0 19.354 75h5.785a3.81 3.81 0 0 0 3.807-3.807V45.186h1.199a4.94 4.94 0 0 0 4.937-4.937l-.001-17.836a4.934 4.934 0 0 0-4.937-4.936z'
              fill='url(#b)'
            />
            <path
              d='M18.826 49.515v-13.08a3.62 3.62 0 0 0-3.621-3.619H3.621A3.62 3.62 0 0 0 0 36.435v13.08a3.62 3.62 0 0 0 3.62 3.621h.879v19.071A2.793 2.793 0 0 0 7.291 75h4.243a2.792 2.792 0 0 0 2.792-2.793V53.136h.881c.117 0 .227-.023.342-.034 1.835-.175 3.278-1.704 3.278-3.587z'
              fill='url(#c)'
            />
            <path
              d='M6.261 30.318c.9.607 1.985.963 3.152.963s2.252-.355 3.152-.963a5.642 5.642 0 0 0-2.541-10.284L9.414 20a5.64 5.64 0 0 0-5.641 5.64 5.64 5.64 0 0 0 2.488 4.678z'
              fill='url(#d)'
            />
            <defs>
              <radialGradient
                id='a'
                cx='0'
                cy='0'
                r='1'
                gradientUnits='userSpaceOnUse'
                gradientTransform='rotate(36.311 3.08 24.483) scale(17.6255 17.9978)'
              >
                <stop offset='.085' stop-color='#97d5cd' />
                <stop offset='1' stop-color='#54baaf' />
              </radialGradient>
              <radialGradient
                id='b'
                cx='0'
                cy='0'
                r='1'
                gradientUnits='userSpaceOnUse'
                gradientTransform='matrix(21.15708 39.03259 -34.99348 18.96774 12.973 28.433)'
              >
                <stop offset='.085' stop-color='#97d5cd' />
                <stop offset='1' stop-color='#54baaf' />
              </radialGradient>
              <radialGradient
                id='c'
                cx='0'
                cy='0'
                r='1'
                gradientUnits='userSpaceOnUse'
                gradientTransform='matrix(17.38344 28.62404 -27.1763 16.50423 .66 40.851)'
              >
                <stop offset='.085' stop-color='#97d5cd' />
                <stop offset='1' stop-color='#54baaf' />
              </radialGradient>
              <radialGradient
                id='d'
                cx='0'
                cy='0'
                r='1'
                gradientUnits='userSpaceOnUse'
                gradientTransform='matrix(10.41503 7.65444 -7.8164 10.63542 4.168 22.149)'
              >
                <stop offset='.085' stop-color='#97d5cd' />
                <stop offset='1' stop-color='#54baaf' />
              </radialGradient>
            </defs>
          </svg>

          <figcaption>Parent</figcaption>
        </figure>
      </div>

      <button class='common-btn full-btn' type='button' onClick={redirectPage}>
        Continue
      </button>
      <div className='Login-button'>
        <p>
          Already Have an Account ? <NavLink to={'/loginmain'}>Login</NavLink>
        </p>
      </div>
    </form>
  )
}
export default Roleitem
