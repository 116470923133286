import { useContext, useEffect, useState } from 'react'
import { Button, Col, Collapse, Container, FloatingLabel, Form, Row } from 'react-bootstrap'
import { BsFilterRight } from 'react-icons/bs'
import * as API from '../Apisurl'
import Context from '../Context'
import headersReq from '../HeadersJson'
import Activity from '../dashboard/Activity'
import Header from '../profile/Header'
import Aggregate from './Aggreate'
import Cummulative from './Cummulative'
import Insights from './Insights'
import SubjectWise from './SubjectWise'

const SubjectInsights = () => {
  let reqJson = headersReq()
  const { selectValue } = useContext(Context)

  let role = localStorage.getItem('role')
  let initialValues = { category: 'all', speciality: 'all', gender: 'all', date: 'all', minAge: 2, maxAge: 99 }

  const [filter, setFilter] = useState(initialValues)
  const [categories, setCategories] = useState([])
  const [specialities, setSpecialities] = useState([])
  const [show, setShow] = useState(false)
  const [cummulativeData, setCummulativeData] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getSpecialities = async () => {
      try {
        const response = await fetch(`${API.Fetchurl}get_all_speciality`, {
          headers: reqJson,
        })
        const data = await response.json()
        setSpecialities(data)
      } catch (error) {
        console.log(error)
      }
    }
    const getCategories = async () => {
      try {
        const response = await fetch(`${API.Fetchurl}get_all_category`, {
          headers: reqJson,
        })
        const data = await response.json()
        setCategories(data)
      } catch (error) {
        console.log(error)
      }
    }

    getCategories()
    getSpecialities()
  }, [])

  useEffect(() => {
    const getProfileData = async () => {
      if (!selectValue.user_id) return

      setLoading(true)
      try {
        const response = await fetch(`${API.Fetchurl}profile_screen_aggregate`, {
          method: 'POST',
          headers: reqJson,
          body: JSON.stringify({
            isGroup: localStorage.getItem('isGroup') || false,
            user_id: selectValue.user_id,
            ...filter,
          }),
        })

        if (!response.ok) throw new Error('Something went Wrong.....')

        const { user } = await response.json()
        setCummulativeData(user)

        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
    getProfileData()
  }, [selectValue.user_id, filter.category, filter.speciality, filter.gender, filter.minAge, filter.maxAge])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFilter((old) => ({ ...old, [name]: value }))
  }

  const reset = () => setFilter(initialValues)

  return (
    <div className='content flex-grow-1 text-black'>
      <Container fluid>
        <div className='d-print-none'>
          <div className='dashboard-heading'>
            <div className='content-container'>
              <h2>Report</h2>
              <p>Here’s a quick overview for your reports</p>
            </div>
            <Activity />
          </div>
          <Header />
        </div>

        <Container fluid='lg' className='mt-2'>
          {/* Filters */}
          <div className='d-print-none bg-white position-sticky top-0 d-flex flex-column' style={{ zIndex: 9 }}>
            <button
              onClick={() => setShow((p) => !p)}
              className=' fs-5 fw-bold rounded-pill px-3 py-1 border-0 align-self-end'
              style={{ background: 'var(--green-highlight-color)', color: 'var(--green-color)' }}
            >
              Filter <BsFilterRight style={{ fontSize: '28px', color: 'var(--green-color)', strokeWidth: '1' }} />
            </button>
            <Collapse in={show}>
              <div>
                <Row className='p-4'>
                  <Col md={6} lg={4} xl={3}>
                    <FloatingLabel controlId='floatingSelect' label='Category'>
                      <Form.Select required value={filter.category} onChange={handleChange} name='category'>
                        <option value='all'>Any</option>
                        {categories.map((c, i) => {
                          return (
                            <option key={i} value={c}>
                              {c}
                            </option>
                          )
                        })}
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col md={6} lg={4} xl={3}>
                    <FloatingLabel controlId='floatingSelect' label='Speciality'>
                      <Form.Select required value={filter.speciality} onChange={handleChange} name='speciality'>
                        <option value='all'>Any</option>
                        {specialities.map((s, i) => {
                          return (
                            <option key={i} value={s}>
                              {s}
                            </option>
                          )
                        })}
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col md={6} lg={4} xl={3}>
                    <FloatingLabel controlId='Date' label='Date'>
                      <Form.Select required value={filter.date} onChange={handleChange} name='date'>
                        <option value='all'>Any</option>
                        <option value='this_week'>This Week</option>
                        <option value='this_month'>This Month</option>
                        <option value='this_year'>This Year</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col md={6} lg={4} xl={3}>
                    <FloatingLabel controlId='floatingSelect' label='Gender'>
                      <Form.Select required value={filter.gender} onChange={handleChange} name='gender'>
                        <option value='all'>Any</option>
                        <option value='male'>Male</option>
                        <option value='female'>Female</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col md={6} lg={4} xl={3}>
                    <FloatingLabel label='Min Age'>
                      <Form.Control
                        type='number'
                        min={2}
                        max={99}
                        onChange={handleChange}
                        name='minAge'
                        value={filter.minAge}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col md={6} lg={4} xl={3}>
                    <FloatingLabel label='Max Age'>
                      <Form.Control
                        type='number'
                        min={2}
                        max={99}
                        onChange={handleChange}
                        name='maxAge'
                        value={filter.maxAge}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col md={6} lg={4} xl={3}>
                    <Button className='common-btn w-100 py-3' onClick={reset}>
                      Reset
                    </Button>
                  </Col>
                </Row>
              </div>
            </Collapse>
          </div>

          <div>
            <h4
              className='dashboard-cards text-center'
              style={{ color: 'var(--green-color)', backgroundColor: 'var(--green-highlight-color)' }}
            >
              Subject Wise Findings
            </h4>

            {role !== 'hr' && <Aggregate />}
            <SubjectWise />
          </div>

          <div>
            <div className='page-break' />
            <Cummulative filter={filter} fetching={loading} data={cummulativeData} />
          </div>

          {role !== 'hr' && role !== 'teacher' && role !== 'parent' ? (
            <div>
              <div className='page-break' />
              <Insights filter={filter} />
            </div>
          ) : null}
        </Container>
      </Container>
    </div>
  )
}

export default SubjectInsights

export const colorsLight = ['#b5d8a6', '#f8c995', '#de8ed2', '#91d3d9']
export const colorsDefault = ['#6AB04C', '#F0932B', '#BC1CA4', '#22A6B3']
export const colorsDark = ['#558d3d', '#c07622', '#961683', '#1b858f']
