import react from "react";
import { Row, Container, Col } from "react-bootstrap";
import backbtn from "../../../assets/images/backbtn.svg";
import Focus from "../../../includes/Focus";
import Progress from "../Testprogrsscom";
import refreshbtn from "../../../assets/images/refresh.svg";
import Draggable, { DraggableCore } from "react-draggable"; // Both at the same time
import { useState, useRef, useEffect, useContext } from "react";
import ReactTypingSpeedTest from "@skpts/react-typing-speed-test";

import StartModal from "../../SelectgameStart";
import { NavLink, useLocation, useParams, useNavigate } from "react-router-dom";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import * as Currdate from "../../../CurrDate";
import * as Apiurl from "../../../Apisurl";
import { SocketConfig } from "../../../SocketConfig";

import { useSpeechSynthesis } from "react-speech-kit";
import leftarrow from "../../../assets/images/left.svg";
import rightarrow from "../../../assets/images/right.svg";
import Form from "react-bootstrap/Form";
import WordSpeed from "./typing/App/App";
// const Wordspeed = (props) => {
//   const [startTest, setstartTest] = useState(true);

//   let headingname = useParams();
//   const { speak } = useSpeechSynthesis();
//   // let date = (UserJson().date = GetCurrentDate());
//   let DraggableCore = Draggable.DraggableCore;
//   const [score, setScore] = useState(0);
//   const location = useLocation();
//   const [isActive, setIsActive] = useState(false);
//   let initialArray = [];
//   const [parsedata, setparsedata] = useState(false);
//   const [error, setError] = useState();
//   const [mistakes, setMistakes] = useState(0);
//   const [userAnswer, setUserAnswer] = useState("");
//   const resetButton = useRef(null);
//   const [smShow, setsmShow] = useState(false);
//   const [img1, setimg1] = useState();
//   const [per, setper] = useState(0);
//   const [wpm, setwpm] = useState(0);

//   const [duration, setduration] = useState(40);
//   const [smoothScrolling, setSmoothScrolling] = useState(true);

//   const [img2, setimg2] = useState();
//   let inputRef = useRef();

//   let vocationalData = props.vocationalData;
//   //   console.log(vocationalData);
//   let datalength = props.datalength;
//   let date = Currdate.GetCurrentDate();
//   console.log(props.submitResult);
//   props.time(date);
//   let datalengthi = datalength;
//   const [callday, setcallday] = useState(1);
//   let navigate = useNavigate();

//   function editDistance(s1, s2) {
//     s1 = s1.toLowerCase();
//     s2 = s2.toLowerCase();

//     var costs = new Array();
//     for (var i = 0; i <= s1.length; i++) {
//       var lastValue = i;
//       for (var j = 0; j <= s2.length; j++) {
//         if (i == 0) costs[j] = j;
//         else {
//           if (j > 0) {
//             var newValue = costs[j - 1];
//             if (s1.charAt(i - 1) != s2.charAt(j - 1))
//               newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
//             costs[j - 1] = lastValue;
//             lastValue = newValue;
//           }
//         }
//       }
//       if (i > 0) costs[s2.length] = lastValue;
//     }
//     return costs[s2.length];
//   }
//   let text = "";
//   function handleSubmit(e, via) {
//     let textNode = [];
//     let nodelength = document.querySelectorAll(".css-130j4hh");
//     //let nodelength = document.querySelectorAll(".css-1bnzk2j-Box");

//     console.log(nodelength, "ss");

//     //let errorNode = document.querySelectorAll(".css-m38i3m");

//     // if (nodelength.length != 0) {
//     //   // alert("in");
//     //   textNode = nodelength;
//     //   console.log(nodelength);
//     // } else {
//     //   textNode = errorNode;

//     // }

//     let val = nodelength.forEach((o, i) => {
//       // let space = document.querySelectorAll(".css-gvtkf1-Box");
//       // console.log(space[i].textContent);
//       console.log(o.textContent == "");
//       text += "" + o.textContent;
//     });
//     // alert(text);
//     localStorage.setItem("useranswer1", text);

//     // console.log(text);
//     let similartxt = similarity(
//       datalength[props.page].ans.replace(/\s+/g, ""),
//       text
//     );
//     var perc = Math.round(similartxt * 10000) / 100;
//     props.setPage((prev) => {
//       props.setTheArray((oldArray) => {
//         const update = [
//           ...oldArray,
//           {
//             via: "correct",
//             percentage: perc,
//             inputvalue: inputRef.current.value,
//           },
//         ];
//         inputRef.current.value = "";

//         localStorage.setItem("useranswer", JSON.stringify(update));
//         return update;
//       });

//       if (props.page === datalengthi.length - 1) {
//         props.setTheArray([]);
//         // socketemitdata();
//         props.submitResult(date);

//         navigate("vocationresult", {
//           state: { ...location.state, date: date },
//         });
//       } else {
//         props.setScore((prev) => prev + 1);

//         return prev + 1;
//       }
//     });
//     setstartTest(true);
//     setduration(60);
//   }
//   useEffect(() => {
//     if (smoothScrolling) {
//       document.documentElement.style.scrollBehavior = "smooth";
//     } else {
//       document.documentElement.style.scrollBehavior = "auto";
//     }

//     return () => {
//       document.documentElement.style.scrollBehavior = "smooth";
//     };
//   }, [smoothScrolling]);

//   function similarity(s1, s2) {
//     var longer = s1;
//     var shorter = s2;
//     if (s1.length < s2.length) {
//       longer = s2;
//       shorter = s1;
//     }
//     var longerLength = longer.length;
//     if (longerLength === 0) {
//       return 1.0;
//     }
//     return (
//       (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)
//     );
//   }
//   //edit distance string check
//   function editDistance(s1, s2) {
//     s1 = s1.toLowerCase();
//     s2 = s2.toLowerCase();
//     var costs = new Array();
//     for (var i = 0; i <= s1.length; i++) {
//       var lastValue = i;
//       for (var j = 0; j <= s2.length; j++) {
//         if (i == 0) costs[j] = j;
//         else {
//           if (j > 0) {
//             var newValue = costs[j - 1];
//             if (s1.charAt(i - 1) != s2.charAt(j - 1))
//               newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
//             costs[j - 1] = lastValue;
//             lastValue = newValue;
//           }
//         }
//       }
//       if (i > 0) costs[s2.length] = lastValue;
//     }
//     return costs[s2.length];
//   }
//   const preventCopyPaste = (e: ClipboardEvent<HTMLInputElement>) => {
//     e.preventDefault();
//     setError("Copying and pasting is not allowed!");
//   };
//   const errorRemover = () => {
//     let similartxt = similarity(datalength[props.page].ans, text);
//     // console.log(similartxt);
//     var perc = Math.round(similartxt * 10000) / 100;
//     setper(perc);
//     // console.log();
//     setError("");
//   };
//   return (
//     <>
//       <Container fluid>
//         <h2>Type the following text</h2>
//         <Row className="colors-main-container typing-game  objgame ">
//           <Col md={6}>
//             <div className="preview-txt-container-word">
//               {datalength[props.page].ans}
//             </div>
//           </Col>
//           <Col md={6}>
//             <div className="txt-container" ref={inputRef}>
//               <ReactTypingSpeedTest
//                 color="light"
//                 testContent={datalength[props.page].ans}
//                 onFinish={(e) => {
//                   //   setstartTest(false);
//                   setwpm(e.wpm);
//                   localStorage.setItem("speed", e.wpm);
//                   errorRemover();
//                   // alert(per);
//                   handleSubmit();
//                   // console.log(e);
//                 }}
//                 duration={200000}
//                 showResult={false}
//                 startTest={startTest}
//               ></ReactTypingSpeedTest>

//               {/* <span className="matching-per">{per}%</span> */}
//             </div>
//           </Col>
//           <p className="mt-2" style={{ color: "red", textAlign: "right" }}>
//             {error}
//           </p>
//         </Row>

//         {/* <button onClick={() => setstartTest(true)}>Start Test</button> */}

//         <div className="text-right" style={{ textAlign: "right" }}>
//           {/* <button className="common-btn px-3 py-2" onClick={handleSubmit}>
//             Submit
//           </button> */}
//         </div>
//       </Container>
//       <StartModal
//         calldelay={setcallday}
//         socket={SocketConfig}
//         type={["action", "focus"]}
//         date={date}
//         letquesLength={datalength.length}
//         calldaynum={callday}
//       />
//     </>
//   );
// };
// export default Wordspeed;
// const Wordspeed = (props) => {
//   const [startTest, setstartTest] = useState(true);
//   let instate1 = {
//     text: "",
//     inputValue: "",
//     lastLetter: "",
//     words: [],
//     completedWords: [],
//     completed: false,
//     startTime: undefined,
//     timeElapsed: 0,
//     wpm: 0,
//     started: false,
//     progress: 0,
//   };
//   const [instate, setinstate] = useState(instate1);

//   let headingname = useParams();
//   const { speak } = useSpeechSynthesis();
//   // let date = (UserJson().date = GetCurrentDate());
//   let DraggableCore = Draggable.DraggableCore;
//   const [score, setScore] = useState(0);
//   const location = useLocation();
//   const [isActive, setIsActive] = useState(false);
//   let initialArray = [];
//   const [parsedata, setparsedata] = useState(false);
//   const [error, setError] = useState();
//   const [mistakes, setMistakes] = useState(0);
//   const [userAnswer, setUserAnswer] = useState("");
//   const resetButton = useRef(null);
//   const [smShow, setsmShow] = useState(false);
//   const [img1, setimg1] = useState();
//   const [per, setper] = useState(0);
//   const [wpm, setwpm] = useState(0);

//   const [duration, setduration] = useState(40);
//   const [smoothScrolling, setSmoothScrolling] = useState(true);

//   const [img2, setimg2] = useState();
//   let inputRef = useRef();

//   let vocationalData = props.vocationalData;
//   //   console.log(vocationalData);
//   let datalength = props.datalength;
//   let date = Currdate.GetCurrentDate();
//   console.log(props.submitResult);
//   props.time(date);
//   let datalengthi = datalength;
//   const [callday, setcallday] = useState(1);
//   let navigate = useNavigate();

//   let text = "";
//   function handleSubmit(e, via) {
//     let textNode = [];
//     let nodelength = document.querySelectorAll(".css-130j4hh");
//     //let nodelength = document.querySelectorAll(".css-1bnzk2j-Box");

//     //let errorNode = document.querySelectorAll(".css-m38i3m");

//     // if (nodelength.length != 0) {
//     //   // alert("in");
//     //   textNode = nodelength;
//     //   console.log(nodelength);
//     // } else {
//     //   textNode = errorNode;

//     // }

//     let val = nodelength.forEach((o, i) => {
//       // let space = document.querySelectorAll(".css-gvtkf1-Box");
//       // console.log(space[i].textContent);
//       console.log(o.textContent == "");
//       text += "" + o.textContent;
//     });
//     // alert(text);
//     localStorage.setItem("useranswer1", text);

//     // console.log(text);
//     let similartxt = similarity(
//       datalength[props.page].ans.replace(/\s+/g, ""),
//       text
//     );
//     var perc = Math.round(similartxt * 10000) / 100;
//     props.setPage((prev) => {
//       props.setTheArray((oldArray) => {
//         const update = [
//           ...oldArray,
//           {
//             via: "correct",
//             percentage: perc,
//             inputvalue: inputRef.current.value,
//           },
//         ];
//         inputRef.current.value = "";

//         localStorage.setItem("useranswer", JSON.stringify(update));
//         return update;
//       });

//       if (props.page === datalengthi.length - 1) {
//         props.setTheArray([]);
//         // socketemitdata();
//         props.submitResult(date);

//         navigate("vocationresult", {
//           state: { ...location.state, date: date },
//         });
//       } else {
//         props.setScore((prev) => prev + 1);

//         return prev + 1;
//       }
//     });
//     setstartTest(true);
//     setduration(60);
//   }

//   const handleChange = (e) => {
//     const { words, completedWords } = instate;
//     const inputValue = e.target.value;
//     const lastLetter = inputValue[inputValue.length - 1];

//     const currentWord = words[0];
//     console.log(currentWord, "currentWord");

//     // if space or '.', check the word
//     if (lastLetter === " " || lastLetter === ".") {
//       // check to see if it matches to the currentWord
//       // trim because it has the space
//       if (inputValue.trim() === currentWord) {
//         // remove the word from the wordsArray
//         // cleanUp the input
//         const newWords = [...words.slice(1)];
//         console.log(newWords, "newWords");
//         console.log(newWords.length, "newWords.length");
//         const newCompletedWords = [...completedWords, currentWord];
//         console.log(newCompletedWords, "newCompletedWords");
//         console.log("----------------");

//         // Get the total progress by checking how much words are left
//         const progress =
//           (newCompletedWords.length /
//             (newWords.length + newCompletedWords.length)) *
//           100;
//         setinstate.inputValue = inputValue;
//         // this.setState({
//         //   words: newWords,
//         //   completedWords: newCompletedWords,
//         //   inputValue: "",
//         //   completed: newWords.length === 0,
//         //   progress: progress,
//         // });
//       }
//     } else {
//       // this.setState({
//       //   inputValue: inputValue,
//       //   lastLetter: lastLetter,
//       // });
//       // console.log(this.state.inputValue, "this.state.inputValue");
//       // console.log(this.state.lastLetter, "this.state.lastLetter");
//       // console.log("================================");
//     }

//     calculateWPM();
//   };

//   const calculateWPM = () => {
//     const { startTime, completedWords } = instate;
//     const now = Date.now();
//     const diff = (now - startTime) / 1000 / 60; // 1000 ms / 60 s
//     console.log(now, "now");
//     console.log(startTime, "startTime");
//     console.log(diff, "diff");
//     console.log("**************");

//     // every word is considered to have 5 letters
//     // so here we are getting all the letters in the words and divide them by 5
//     // "my" shouldn't be counted as same as "deinstitutionalization"
//     const wordsTyped = Math.ceil(
//       completedWords.reduce((acc, word) => (acc += word.length), 0) / 5
//     );
//     console.log(completedWords, "completedWords");
//     console.log(wordsTyped, "wordsTyped");
//     console.log("+=+=+=+=+=+=");

//     // calculating the wpm
//     const wpm = Math.ceil(wordsTyped / diff);

//     // this.setState({
//     //   wpm: wpm,
//     //   timeElapsed: diff,
//     // });
//   };
//   const preventCopyPaste = (e: ClipboardEvent<HTMLInputElement>) => {
//     e.preventDefault();
//     setError("Copying and pasting is not allowed!");
//   };
//   const errorRemover = () => {
//     let similartxt = similarity(datalength[props.page].ans, text);
//     // console.log(similartxt);
//     var perc = Math.round(similartxt * 10000) / 100;
//     setper(perc);
//     // console.log();
//     setError("");
//   };
//   return (
//     <>
//       <Container fluid>
//         <h2>Type the following text</h2>
//         <Row className="colors-main-container typing-game  objgame ">
//           <Col md={6}>
//             <div className="preview-txt-container-word">
//               {datalength[props.page].ans}
//             </div>
//           </Col>
//           <Col md={6}>
//             <div className="txt-container" ref={inputRef}>
//               <input
//                 type="text"
//                 onChange={handleChange}
//                 value={instate.inputValue}
//                 placeholder="asdsadsad sadsad"
//                 // autoFocus={started ? 'true' : 'false'}
//                 autoFocus={true}
//               />

//               {/* <span className="matching-per">{per}%</span> */}
//             </div>
//           </Col>
//           <p class Name="mt-2" style={{ color: "red", textAlign: "right" }}>
//             {error}
//           </p>
//         </Row>

//         {/* <button onClick={() => setstartTest(true)}>Start Test</button> */}

//         <div className="text-right" style={{ textAlign: "right" }}>
//           {/* <button className="common-btn px-3 py-2" onClick={handleSubmit}>
//             Submit
//           </button> */}
//         </div>
//       </Container>
//       <StartModal
//         calldelay={setcallday}
//         socket={SocketConfig}
//         type={["action", "focus"]}
//         date={date}
//         letquesLength={datalength.length}
//         calldaynum={callday}
//       />
//     </>
//   );
// };
// export default Wordspeed;
// import "./styles.css";

const TypingSpeedJsx = (props) => {
  let datalength = props.datalength;
  const { setTheArray, submitResult, setPage } = props;
  return (
    <Row className="colors-main-container   objgame ">
      <WordSpeed
        paragraph={datalength}
        setTheArray={setTheArray}
        setPage={setPage}
        submitResult={submitResult}
      />
    </Row>
  );
};

export default TypingSpeedJsx;
