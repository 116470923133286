import { useContext } from 'react'
import HeaderPdf from './pdfparts/header'
import Context from '../../Context'

const LearningStyle = ({ data }) => {
  const { selectValue } = useContext(Context)

  const progressData = [
    {
      titleA: 'Kinesthetic',
      titleB: 'Learners',
      percentage: data.interactive[0],
      bgColor: '#1851fe',
      borderColor: 'rgb(13 110 253 / 30%)',
      trend: `${Math.round(
        data.interactive[2]
      )}% percent people learn better by hands on activities. Kinesthetic learners thrive in hands-on activities, excelling through movement and touch.`,
    },
    {
      titleA: 'Auditory',
      titleB: 'Learners',
      percentage: data.audio[0],
      bgColor: '#00bdc3',
      borderColor: 'rgb(3 189 195 / 30%)',
      trend: `${Math.round(
        data.audio[2]
      )}% learn better by audio Auditory learners excel in environments emphasizing listening, speaking, and verbal instruction.`,
    },
    {
      titleA: 'Visual',
      titleB: 'Learners',
      percentage: data.photos[0],
      bgColor: '#f5457a',
      borderColor: 'rgb(245 70 122 / 30%)',
      trend: `${Math.round(
        data.photos[2]
      )}% learn best with visuals only. Visual learners thrive in environments utilizing visual aids, diagrams, and images.`,
    },
    {
      titleA: 'Audio-visual',
      titleB: 'Learners',
      percentage: data.videos[0],
      bgColor: '#eca549',
      borderColor: 'rgb(235 165 73/ 30%)',
      trend: `${Math.round(
        data.videos[2]
      )}% learn with a mix of audio and visuals. Audio-visual learners excel with information presented through auditory and visual methods.`,
    },
  ]

  const sortedData = Object.entries({ ...data })
    .map(([key, val]) => {
      let newKeys = {
        interactive: 'interactive',
        videos: 'video',
        audio: 'auditory',
        photos: 'visual cues',
      }

      return { name: newKeys[key], perc: Math.round(val[2]) }
    })
    .sort((a, b) => b.perc - a.perc)

  const preferenceData = Object.entries({ ...data })
    .map(([key, val]) => {
      let newKeys = {
        interactive: 'Kinesthetic',
        videos: 'audio-visuals',
        audio: 'audio',
        photos: 'visual cues',
      }

      return { name: newKeys[key], perc: Math.round(val[0]) }
    })
    .sort((a, b) => b.perc - a.perc)

  return (
    <div className='main-container page8-container'>
      <HeaderPdf />

      <div className='pdf-content-container'>
        <div className='heading-seprator'>
          <h1>
            <span>LEARNING PROFILE</span>
          </h1>
          <div className='report-content'>
            <p>
              A learning style profile captures an individual's preferred methods of acquiring and processing
              information, including visual, auditory, and kinesthetic modalities. It identifies the most effective ways
              an individual absorbs and retains knowledge.
            </p>
            <div className='listing-container'>
              <h2>General Trends</h2>
              <p>
                Combining interactive or video-based content is generally more effective for learning than relying
                solely on simple audio or visuals.
              </p>
              <p>
                <span className='text-capitalize'>{sortedData[0].name} </span>content has demonstrated a preference at{' '}
                {sortedData[0].perc}%, surpassing {sortedData[1].name} learning at {sortedData[1].perc}%,{' '}
                {sortedData[2].name} at {sortedData[2].perc}%, and sole reliance on {sortedData[3].name} at{' '}
                {sortedData[3].perc}%.
              </p>
              <h2 className='bold_text'>For {selectValue.username},</h2>
              <ul className='progress_list'>
                {progressData
                  .sort((a, b) => b.percentage - a.percentage)
                  .map((item, index) => (
                    <li key={index} className='bg-white shadow p-2 pt-0'>
                      <div
                        className='mx-2 text-white text-center py-2'
                        style={{
                          background: item.bgColor,
                          borderBottomLeftRadius: '24px',
                          borderBottomRightRadius: '24px',
                        }}
                      >
                        <span>
                          {item.titleA} <br /> {item.titleB}
                        </span>
                      </div>

                      <div
                        className='mx-auto my-3 rounded-circle d-flex align-items-center justify-content-center fs-6 text-white'
                        style={{
                          height: '75px',
                          width: '75px',
                          fontFamily: 'sorabold',
                          background: item.bgColor,
                          border: '12px solid white',
                          outline: `8px solid ${item.borderColor}`,
                        }}
                      >
                        {Math.round(item.percentage)}%
                      </div>

                      <span style={{ color: item.bgColor }}>General Trends:</span>
                      <br />
                      <p style={{ color: item.bgColor }}>{item.trend}</p>
                    </li>
                  ))}
              </ul>
              <p style={{ textAlign: 'justify' }}>
                {selectValue.gender === 'male' ? 'His' : selectValue.gender === 'female' ? 'Her' : ' Their'} learning
                profile leans heavily towards {preferenceData[0].name}, The next preference is for{' '}
                {preferenceData[1].name}, finding efficiency in {preferenceData[2].name}, and considering{' '}
                {preferenceData[3].name} as the last option. Relying solely on {preferenceData[3].name} can lead to
                feeling lost or zoned out, negatively impacting learning and cognitive utilization. <br /> Applying this
                awareness in education or career can help individuals acknowledge and enhance their cognition,
                ultimately benefiting mental health and reducing mental stress when processing information for optimal
                functioning and excellence.
              </p>
            </div>
          </div>
        </div>
        <footer>
          <h6>07</h6>
        </footer>
      </div>
    </div>
  )
}
export default LearningStyle
