import nemaLogo from '../img/nemalogo1Asset2.png'

const HeaderPdf = () => {
  return (
    <header className='pdf-header'>
      <img src={nemaLogo} alt='' className='white-logo-svg' />
      <a href='https://www.nemaai.com' target='_blank' rel='noreferrer' className='pdfbtn'>
        Book A Scan Today
      </a>
    </header>
  )
}
export default HeaderPdf
