import Table from 'react-bootstrap/Table'
import { MDBIcon } from 'mdb-react-ui-kit'
import ReactPaginate from 'react-paginate'
import UserJson from '../../../Userjson'
import { useOutletContext } from 'react-router-dom'
import react, { useRef } from 'react'
import Pagination from '../../Pagination'
import { useEffect, useState, useContext } from 'react'
import * as Apiurl from '../../../Apisurl'
import Context from '../../../Context'

const Colortable = () => {
  const [overview1, setOverview] = useState()
  const props1 = useContext(Context)
  console.log(props1)
  let tableref = useRef()
  let user = UserJson()
  let tlengthdata = tableref.current
  console.log(tableref.current)
  console.log(tlengthdata)
  console.log(props1)

  let category = UserJson().category

  //   const retunSwitchTable = () => {
  //     switch () {

  //   }
  // }
  let overview = props1[2].Overview

  if (overview != undefined && overview.length != 0) {
    return (
      <>
        <Table className='result-table' ref={tableref}>
          <thead>
            <tr>
              <th></th>
              {overview.ques.map((o, i) => {
                return <th>Q{i + 1}</th>
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Asked</td>

              {category != 'rank_value'
                ? overview.ques.map((o, i) => {
                    console.log(o)
                    return (
                      <td>
                        <div className='asked-color-box'>
                          {o.mcq.map((im, i) => {
                            return <img src={im} height='80px' />
                          })}
                        </div>
                      </td>
                    )
                  })
                : overview.ques.map((o, i) => {
                    return (
                      <td>
                        <div className='asked-color-box d-flex '>
                          {o.ques.split(',').map((im, i) => {
                            return (
                              <img src={`https://ui-nema-database.s3.ap-south-1.amazonaws.com/${im}`} height='80' />
                            )
                          })}
                        </div>
                      </td>
                    )
                  })}
            </tr>
            <tr>
              <td>Your Answer</td>
              {category != 'rank_value'
                ? overview.ans.map((o, i) => {
                    return (
                      <td>
                        <div className='asked-color-box'>
                          <p
                            style={
                              o.via == 'correct'
                                ? { border: '2px solid green', color: 'green' }
                                : { border: '2px solid red', color: 'red' }
                            }
                          >
                            {o.answer}
                          </p>
                        </div>
                      </td>
                    )
                  })
                : overview.ans.map((o, i) => {
                    return (
                      <td>
                        <div
                          className='asked-color-box d-flex '
                          style={
                            o.via == 'correct'
                              ? { border: '2px solid green', color: 'green' }
                              : { border: '2px solid red', color: 'red' }
                          }
                        >
                          {o.answer.map((im, i) => {
                            return <img src={im} height='80' />
                          })}
                        </div>
                      </td>
                    )
                  })}
            </tr>
            <tr>
              <td>Correct Answer</td>
              {category != 'rank_value'
                ? overview.ques.map((o, i) => {
                    return (
                      <td>
                        <div className='asked-color-box'>
                          <p>{o.ans}</p>
                        </div>
                      </td>
                    )
                  })
                : overview.ques.map((o, i) => {
                    return (
                      <td>
                        <div
                          className='asked-color-box d-flex '
                          // style={
                          //   o.via == "correct"
                          //     ? { border: "2px solid red" }
                          //     : { border: "2px solid green" }
                          // }
                        >
                          {o.ans.split(',').map((im, i) => {
                            return (
                              <img src={`https://ui-nema-database.s3.ap-south-1.amazonaws.com/${im}`} height='80' />
                            )
                          })}
                        </div>
                      </td>
                    )
                  })}
            </tr>
          </tbody>
        </Table>
      </>
    )
  }
}

export default Colortable
