import react from "react";
import userImage from "../assets/images/user3.svg";
import notification from "../assets/images/notification.svg";
import downarrow from "../assets/images/downarrow.svg";
import batt from "../assets/images/batt.svg";
import Activity from "../dashboard/Activity";
import Switch from "../ToggleSpeach";
import { SocketConfig } from "../SocketConfig";

const HomeHeader = (props) => {
  return (
    <>
      <div className="dashboard-heading">
        <p className="ham-menu">
          <i class="fas fa-bars"></i>
        </p>
        <div className="content-container">
          <h2>School </h2>
          <p>Explore assignments and see how you’s performed</p>
        </div>
        <div className="activity-container">
          {sessionStorage.getItem("headsetVia") == 2 ? (
            <figure className="profile-container profile-item m-0">
              <div className="nav-item dropdown">
                <a className="nav-link toggle-container">
                  <figure className="profile-image ecg-container">
                    <figcaption>EEG Insight</figcaption>
                    <img
                      src={batt}
                      className="downarrow"
                      style={
                        SocketConfig.connected == true
                          ? { background: "#21c167" }
                          : { background: "#c4c4c4" }
                      }
                      alt="downarrow"
                    />
                    <p className="text">33</p>
                  </figure>
                </a>
              </div>
            </figure>
          ) : (
            ""
          )}
          {/* <Switch /> */}
          <Activity />
        </div>
      </div>
    </>
  );
};
export default HomeHeader;
