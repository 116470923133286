import React, { useContext, useEffect, useRef, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import * as API from '../Apisurl'
import Context from '../Context'
import Activity from '../dashboard/Activity'
import Header from '../profile/Header'
import { BsCheckCircleFill } from 'react-icons/bs'
import Select from 'react-select'
import { isArray } from 'lodash'

const Sessions = () => {
  const { selectValue } = useContext(Context)
  const [progressData, setProgressData] = useState()
  const location = useLocation()
  const data = location.state?.data

  const flashcards = data.map((d) => d.text)
  const games = data.map((d) => d.games).flat(2)

  useEffect(() => {
    const getStatus = async () => {
      if (!selectValue.user_id) return

      try {
        const response = await fetch(`${API.Fetchurl}4weeks_status?user_id=${selectValue.user_id}`, {
          headers: { Authorization: 'Bearer ' + sessionStorage.getItem('session') },
        })

        if (!response.ok) throw new Error('Something went wrong...')
        const data = await response.json()
        setProgressData(data)
      } catch (error) {
        console.log(error)
      }
    }

    getStatus()
  }, [selectValue.user_id])

  return (
    <div className='content flex-grow-1'>
      <Container fluid>
        <div className='dashboard-heading'>
          <div class='content-container'>
            <h2>Weekly Plan</h2>
            <p>A comprehensive 4 week plan to improve your abilities</p>
          </div>
          <Activity />
        </div>
        <Header />

        <div className='my-4'>
          <Row>
            {flashcards.map((f, i) => (
              <Flashcards key={i} f={f} sessionNum={i + 1} progressData={progressData} />
            ))}

            {games.length > 0 && <Games games={games} />}
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Sessions

const Flashcards = ({ f, sessionNum, progressData }) => {
  const { selectValue } = useContext(Context)
  const { week } = useParams()

  const [file, setFile] = useState(null)
  const [progress, setProgress] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const fileRef = useRef()

  const handleFile = (e) => setFile(e.target.files[0])
  const handleSelect = (e) => {
    setProgress(e)
    updateStatus({ progress: e.value, upload_file: 'none' })
  }

  const upload = async () => {
    if (!file) return

    try {
      setError(null)
      const formData = new FormData()
      formData.append('user_id', selectValue.user_id)
      formData.append('folder_type', 'corp_flashcard')
      formData.append('file', file)

      const response = await fetch(`${API.Fetchurl}file_upload_1`, {
        method: 'POST',
        body: formData,
        headers: { Authorization: 'Bearer ' + sessionStorage.getItem('session') },
      })

      const data = await response.json()

      if (!isArray(data.file_path)) throw new Error(data.file_path)

      return data.file_path[0]
    } catch (err) {
      console.log(err)
      setError(err.message)
    }
  }

  const updateStatus = async (body) => {
    try {
      const response = await fetch(`${API.Fetchurl}4weeks_status`, {
        method: 'POST',
        headers: { Authorization: 'Bearer ' + sessionStorage.getItem('session') },
        body: JSON.stringify({
          user_id: selectValue.user_id,
          week: week,
          session: sessionNum,
          ...body,
        }),
      })

      if (!response.ok) throw new Error('Something went wrong...')

      const data = await response.json()
    } catch (error) {
      console.log(error)
    }
  }

  const handleUpload = async () => {
    setLoading(true)
    try {
      const uploadedFile = await upload()
      setFile(null)
      fileRef.current.value = null

      const body = {
        progress: progress,
        upload_file: uploadedFile,
      }

      const res = await updateStatus(body)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Col md={6} xl={4}>
      <h3 className='text-center text-uppercase'>Session {sessionNum}</h3>
      <div className='dashboard-cards d-flex flex-column justify-content-between p-4 h-100 overflow-visible'>
        <div>
          <h2 className='text-capitalize text-black mb-3'>{f[0].replace(':', '')} </h2>

          <ol>
            {f[1]
              .split('\n')
              .filter((d) => d !== '')
              .map((d, i) => (
                <li key={i} className='m-0 mt-2'>
                  {d}
                </li>
              ))}
          </ol>
          <div className='d-flex align-items-center gap-2 border-top py-3'>
            <label
              htmlFor={`file${sessionNum}`}
              className='rounded-pill w-50 h-100 p-1 ps-3 overflow-hidden'
              style={{ border: file ? '2px solid var(--green-color)' : '2px dashed #c4c4c4' }}
            >
              {file ? <p className='m-0 text-black opacity-75'>{file.name}</p> : 'Click to add file'}
            </label>
            <input className='d-none' type='file' id={`file${sessionNum}`} ref={fileRef} onChange={handleFile} />
            <button
              onClick={handleUpload}
              disabled={loading || !file}
              className={`rounded-pill py-1 px-3 border-0 bg-success text-white ${
                loading ? 'bg-opacity-50' : 'bg-opacity-100'
              }`}
            >
              {loading ? 'Uploading...' : 'Upload'}
            </button>
          </div>
          {error && <p className='text-danger'>{error}</p>}
        </div>

        <div className='d-flex justify-content-end'>
          <Select
            isSearchable={false}
            value={
              progress || (progressData && progressData[week][sessionNum] === 'inprogress' ? options[0] : options[1])
            }
            onChange={handleSelect}
            options={options}
            styles={styles}
          />
        </div>
      </div>
    </Col>
  )
}

const Games = ({ games }) => {
  return (
    <Col md={6} xl={4}>
      <h3 className='text-center text-uppercase'>Games</h3>
      <div className='dashboard-cards d-flex flex-column justify-content-between p-4 h-100 overflow-visible'>
        <div>
          <h2 className='text-capitalize text-black mb-3'>Games to play to improve your cognitive ability</h2>
          <ul>
            {games.map((game) => (
              <li className='fs-5'>
                <NavLink to={`/games/${game}`}>{game}</NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Col>
  )
}

const options = [
  { label: 'In Progress', value: 'inprogress', color: '#ffe5a0', textColor: 'darkorange' },
  { label: 'Completed', value: 'completed', color: 'lightgreen', textColor: 'green' },
]

const styles = {
  control: (base) => ({
    ...base,
    boxShadow: 'none',
    border: 0,
    minWidth: '120px',
  }),

  singleValue: (base, state) => ({
    ...base,
    color: state.data.textColor,
    background: state.data.color,
    padding: '2px 8px',
    borderRadius: '8px',
  }),

  valueContainer: (base) => ({
    ...base,
    padding: 0,
    margin: 0,
    textAlign: 'center',
    fontSize: '18px',
  }),
  input: (base) => ({
    ...base,
    padding: 0,
    margin: 0,
  }),
  // indicatorsContainer: (base, state) => ({
  //   ...base,
  //   display: 'none',
  // }),
}
