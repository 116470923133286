import react from 'react'
import { useState, useContext } from 'react'
import logo from '../assets/images/logo.svg'
import head from '../assets/images/head.svg'

import { Link, NavLink, useNavigate } from 'react-router-dom'
import Context from '../Context'
import socketio from 'socket.io-client'
import * as NavObj from './NavObj'
// import socketUrl
import * as Apiurl from '../Apisurl'
import { motion } from 'framer-motion'
// import Timer from "./Timer";
import { ProSidebar, SidebarHeader, Menu, MenuItem, SubMenu } from 'react-pro-sidebar'
import 'react-pro-sidebar/dist/css/styles.css'
import { RxDoubleArrowLeft, RxDoubleArrowRight, RxTriangleUp, RxTriangleDown } from 'react-icons/rx'

const Sidebar = () => {
  let setSidebarAttr = window.innerWidth
  let naviagte = useNavigate()
  const [isopen, setOpen] = useState(setSidebarAttr >= 600 ? true : false)
  const [subMenu, setSubMenu] = useState(false)
  const context = useContext(Context)
  const { timeLeft } = context
  const openSideNav = () => {
    setOpen(!isopen)
  }

  const showReuiredBar = () => {
    if (sessionStorage.getItem('session') === '123456') {
      return NavObj.demo
    }

    let isDemo = localStorage.getItem('isDemo') === 'true'
    let role = localStorage.getItem('role')

    let sidebarObj = []
    if (sessionStorage.getItem('session') == 'be9d991a-e5d5-4142-81ba-c79d1fabdf07') {
      sidebarObj = NavObj.specialAccessArr
    } else {
      switch (true) {
        case role === 'parent':
          sidebarObj = isDemo ? NavObj.demoAccess : NavObj.parentAccessArr
          break
        case role === 'teacher':
          sidebarObj = isDemo
            ? NavObj.navObj.filter(
                (d) => ['overview', 'school', 'report', 'switch', 'EEG Report'].includes(d.title.toLowerCase()) && d
              )
            : NavObj.navObj
          break
        case role === 'hr':
          sidebarObj = isDemo
            ? NavObj.hr.filter((d) => ['/profile', '/activities', '/subject'].includes(d.link.toLowerCase()) && d)
            : NavObj.hr
          break
        case role === 'employee':
          sidebarObj = isDemo
            ? NavObj.emp.filter((d) => ['/profile', '/activities', '/subject'].includes(d.link.toLowerCase()) && d)
            : NavObj.emp
          break
        case role === 'student':
          sidebarObj = NavObj.student
          break
        case role === 'college':
          sidebarObj = NavObj.college
          break
        case role === 'admin':
          sidebarObj = NavObj.corp
          break
      }
    }
    return sidebarObj
  }
  let t
  const time = () => {
    t = new Date()
    t.setSeconds(t.getSeconds() + timeLeft)
  }
  timeLeft !== undefined && time()
  return (
    <>
      <div className='sidenav d-print-none'>
        {setSidebarAttr >= 600 ? (
          ''
        ) : (
          <figure className='openMobMenu' onClick={() => setOpen(true)}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              class='bi bi-list'
              viewBox='0 0 16 16'
            >
              <path
                fill-rule='evenodd'
                d='M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z'
              />
            </svg>
          </figure>
        )}

        <motion.div
          className='sidebar'
          animate={
            isopen
              ? setSidebarAttr >= 600
                ? { width: 'fit-content' }
                : { width: '100%', top: 0 }
              : setSidebarAttr >= 600
              ? { width: '86px' }
              : { width: '100%', top: '-100vh' }
          }
          style={setSidebarAttr >= 600 ? { position: 'sticky', top: 0, left: 0 } : { position: 'fixed' }}
        >
          <div className='overflow-auto'>
            <div className='sidebar-header'>
              <a href='#' class='sidebar-logo'>
                {isopen ? <img src={logo} alt /> : <img src={head} alt style={{ height: '30px', width: '30px' }} />}
              </a>
              {/* <div
                className="hame-menu"
                style={!isopen ? { display: "none" } : { display: "block" }}
                onClick={openSideNav}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-list"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                  />
                </svg>
              </div> */}
            </div>

            <div className='sidebar-body h-auto'>
              <nav class='nav-sidebar'>
                <li class='nav-item  ham-option active' onClick={openSideNav}>
                  <a
                    className='nav-link'
                    style={
                      ({ textAlign: 'center', lineHeight: '28px' },
                      !isopen ? { justifyContent: 'center', padding: '0' } : { padding: '0 10px' })
                    }
                  >
                    <figure>{isopen ? <RxDoubleArrowLeft /> : <RxDoubleArrowRight />}</figure>
                  </a>
                </li>
                {showReuiredBar().map((item, index) => {
                  return !item.isGroup ? (
                    <li key={index} className='nav-item active'>
                      <NavLink
                        activeClassName='active'
                        to={item.link}
                        onClick={() => setOpen(setSidebarAttr >= 600 ? true : false)}
                        style={isopen ? { width: 'inherit' } : { width: '60px' }}
                        state={{ pageid: 2 }}
                        className='nav-link'
                      >
                        <figure>{item.image}</figure>
                        <span style={isopen ? { display: 'block' } : { display: 'none' }}>{item.title}</span>
                      </NavLink>
                    </li>
                  ) : (
                    <>
                      <li class='nav-item active'>
                        <button
                          className='nav-link bg-transparent border-0'
                          style={isopen ? { width: 'inherit' } : { width: '60px' }}
                          onClick={() => setSubMenu((p) => !p)}
                        >
                          <span style={isopen ? { display: 'block' } : { display: 'none' }}>{item.title}</span>
                          <span className='fs-4 p-0 m-0'>{subMenu ? <RxTriangleUp /> : <RxTriangleDown />}</span>
                        </button>
                      </li>

                      {subMenu && (
                        <ul className='p-0'>
                          {item.sublinks.map((sl, i) => (
                            <li key={i} className='nav-item active'>
                              <NavLink
                                activeClassName='active'
                                to={sl.link}
                                onClick={() => setOpen(setSidebarAttr >= 600 ? true : false)}
                                style={isopen ? { width: 'inherit' } : { width: '60px' }}
                                state={{ pageid: 2 }}
                                className='nav-link '
                              >
                                <figure>{sl.image}</figure>
                                <span style={isopen ? { display: 'block' } : { display: 'none' }}>{sl.title}</span>
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      )}
                    </>
                  )
                })}
              </nav>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  )
}

export default Sidebar
