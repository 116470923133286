import Container from 'react-bootstrap/Container'
import { useParams } from 'react-router-dom'
import FormHeader from '../includes/FormHeader'
import ParentsForm from '../parents/Parents'
import CollegeForm from './CollegeForm'
import RoleHeader from './RoleFormHeading'
import TeacherForm from './TeacherForm'
import EmployeeForm from './EmployeeForm'
import HRForm from './HRForm'
import StudentForm from './StudentForm'
import AdminForm from './AdminForm'

const FormComponent = () => {
  const { type: role } = useParams()

  const renderForm = () => {
    switch (role) {
      case 'student':
        return <StudentForm />
      case 'college':
        return <CollegeForm />
      case 'employee':
        return <EmployeeForm />
      case 'hr':
        return <HRForm />
      case 'parent':
        return <ParentsForm />
      case 'teacher':
        return <TeacherForm />
      case 'admin':
        return <AdminForm />
      default:
        return
    }
  }

  return (
    <>
      <FormHeader />

      <Container>
        <RoleHeader role={role} />
        {renderForm()}
      </Container>
    </>
  )
}

export default FormComponent
