import react, { useEffect, useState } from 'react'
import { Row, Col, Table } from 'react-bootstrap'
import { htmlToJson } from './Htmltojson'
import * as Currdate from '../../../CurrDate'
import { NavLink, useLocation, useParams, useNavigate } from 'react-router-dom'
import { Modal, Button } from 'react-bootstrap'
import { Gateway, GatewayDest, GatewayProvider } from 'react-gateway'
import { updateLocale } from 'moment'
import QRCode from 'react-qr-code'
import UserJson from '../../../Userjson'
import * as Apiurl from '../../../Apisurl'
import { useStopwatch, useTimer } from 'react-timer-hook'
import headersReq from '../../../HeadersJson'

const QRCodejsx = (props) => {
  const reqJson = headersReq()
  const [fields, setFields] = useState([''])
  const [err, seterr] = useState([''])
  const [inputArr, setinputArr] = useState([])
  const [isShow, invokeModal] = useState(false)
  let time
  useEffect(() => {
    time = new Date()
    time.setSeconds(time.getSeconds() + 100)
  })

  const { seconds, minutes, hours, days, isRunning, start, pause, resume, restart } = useTimer({
    time,
    onExpire: () => getStateData(),
  })
  let currIndexData = props.vocationalData
  let datalengthi = props.datalength.length
  let date = Currdate.GetCurrentDate()
  let navigate = useNavigate()
  const location = useLocation()
  console.log(location)
  let answerArray = []

  const setQueTable1 = (Via) => {
    let mapDATA = []
    let data = document.querySelector('.tbody-container')
    let dataheader = document.querySelector('.tbody-container1')
    let tableInputData = Array.from(data.children).map((html, index) => {
      mapDATA = Array.from(html.children).map((html1, index1) => {
        console.log(html1.children[0])
        html1.children[0].value = ''
      })
    })
    return tableInputData
  }
  let update

  function handleSubmit(e, via) {
    let mapDATA = []
    const time = new Date()
    time.setSeconds(time.getSeconds() + 60)
    let data = document.querySelector('.tbody-container')
    let dataheader = document.querySelector('.tbody-container1')
    let tableInputData = Array.from(data.children).map((html, index) => {
      mapDATA = Array.from(html.children).map((html1, index1) => {
        return {
          [dataheader.children[0].children[0].children[index1].textContent]: html1.children[0].value,
        }
      })
      answerArray.push(mapDATA)
      return mapDATA
    })
    invokeModal({
      modalvia: true,
      answerArray: tableInputData,
      prevurl: location.pathname,
    })
    let sendjson = {
      question: [],
      answer: 0,
      user_id: sessionStorage.getItem('session'),
      nextquestionNum: props.page,
    }
    async function levelgame() {
      const response = await fetch(`${Apiurl.Fetchurl + 'qr_code'}`, {
        method: 'POST',
        headers: reqJson,
        body: JSON.stringify(sendjson),
      })
      const childdata = await response.json()
      return childdata
    }
    levelgame()
      .then((data) => {})
      .catch((error) => {
        alert(error)
      })
    if (tableInputData.length == 0) {
      seterr('please fill atleast one answer')
    } else {
      console.log(seconds, 'shsshsh')
      restart(time)

      // if (!isRunning) {

      // }
    }
  }

  function getStateData() {
    let mapDATA = []
    let data = document.querySelector('.tbody-container')
    let dataheader = document.querySelector('.tbody-container1')
    let tableInputData = Array.from(data.children).map((html, index) => {
      mapDATA = Array.from(html.children).map((html1, index1) => {
        return {
          [dataheader.children[0].children[0].children[index1].textContent]: html1.children[0].value,
        }
      })
      answerArray.push(mapDATA)
      return mapDATA
    })
    async function levelgame1() {
      const response = await fetch(`${Apiurl.Fetchurl + 'response?nextquestionNum=' + props.page}`, {
        method: 'GET',
        headers: reqJson,
      })
      const childdata = await response.json()
      return childdata
    }
    levelgame1()
      .then((data) => {
        console.log(data, 'tttt')
        invokeModal({
          modalvia: false,
          // answerArray: tableInputData,
          // prevurl: location.pathname,
        })

        props.setTheArray((oldArray) => {
          update = [
            ...oldArray,
            {
              via: 'correct',
              useranswers: tableInputData,
              answers: 1,
            },
          ]
          // console.log(update);

          localStorage.setItem('useranswer', JSON.stringify(update))
          return update
        })
        // props.setTheArray(tableInputData);

        props.setPage(data.nextquestionNum)
        setQueTable1()
        if (props.page == datalengthi - 1) {
          // setTimeout(() => {
          props.submitResult(date)

          props.setTheArray([])

          // navigate('vocationresult', {
          //   state: { ...location.state, date: date },
          // })
          // }, 1000)
        }
      })
      .catch((error) => {
        alert(error)
      })
  }
  let encodeUri =
    'https://nema.live/qrCodePay?data=' +
    encodeURIComponent(
      JSON.stringify(isShow.answerArray) +
        '&prevurl=' +
        isShow.prevurl +
        '&id=' +
        sessionStorage.getItem('session') +
        '&page=' +
        props.page
    )

  if (currIndexData != undefined) {
    return (
      <Row className='excelgame-container'>
        <Modal show={isShow.modalvia}>
          <Modal.Header closeButton onClick={() => invokeModal(false)}>
            <Modal.Title>Scan QR Code</Modal.Title>
            {isRunning ? (
              <div className='profile-container profile-item timer-popup'>
                <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
              </div>
            ) : (
              ''
            )}
          </Modal.Header>
          <Modal.Body>
            <div
              style={{
                textAlign: 'center',
                border: '10px solid var(--primary-color)',
              }}
            >
              <QRCode value={`${encodeUri}`} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='danger' onClick={() => invokeModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <h6>World Problems</h6>
        <Col md={6}>
          <div className='content-container'>
            <p>{currIndexData.ques}</p>
          </div>
        </Col>
        <Col md={6} className='inputsTable'>
          <Table striped bordered hover className='tbody-container1'>
            <thead>
              <tr>
                {Object.keys(currIndexData.ans).map((item, index) => {
                  return <th>{item}</th>
                })}
              </tr>
            </thead>
            <tbody className='tbody-container'>
              {currIndexData.ans['item'].map((o, i) => {
                return (
                  <tr>
                    {Object.keys(currIndexData.ans).map((item, index) => {
                      return (
                        <td>
                          <input
                            type='text'
                            placeHolder={'Type' + ' ' + item}
                            className={item + i}
                            maxlength={item == 'quantity' ? '10' : '50'}
                          />
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </Table>

          {err}
          <div style={{ textAlign: 'right' }}>
            <button className='common-btn px-3 py-1 ' onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </Col>
      </Row>
    )
  }
}

// export default Excelgame;

export default QRCodejsx
