import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, FloatingLabel, Form, Row } from 'react-bootstrap'
import { Chart } from 'react-chartjs-2'
import Slider from 'react-slick'
import * as Apiurl from '../Apisurl'
import Context from '../Context'
import headersReq from '../HeadersJson'
import '../profile/profile.css'

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
)

const Cummulative = () => {
  const reqJson = headersReq()
  const context = useContext(Context)
  let { selectValue } = context
  let initialValues = {
    date: 'all',
    category: 'all',
    speciality: 'all',
  }

  const [categories, setCategories] = useState([])
  const [specialities, setSpecialities] = useState([])

  const [allData, setAllData] = useState()

  const [filter, setFilter] = useState(initialValues)

  useEffect(() => {
    const getProfileData = async () => {
      try {
        const response = await fetch(`${Apiurl.Fetchurl}profile_screen_aggregate_user`, {
          method: 'POST',
          headers: reqJson,
          body: JSON.stringify({
            isGroup: localStorage.getItem('isGroup') || false,
            user_id: selectValue.user_id,
            ...filter,
          }),
        })

        const data = await response.json()
        setAllData({
          Learning_Style: data.Learning_Style,
          Brain_Strength: data.Brain_Strength,
          Emotional_Analysis: data.Emotional_Analysis,
          Performance_Metric: data.Performance_Metric,
        })
      } catch (error) {
        console.log(error)
      }
    }
    getProfileData()
  }, [selectValue.user_id, filter])

  useEffect(() => {
    const getSpecialities = async () => {
      try {
        const response = await fetch(`${Apiurl.Fetchurl}get_all_speciality`, {
          method: 'GET',
          headers: reqJson,
        })
        const data = await response.json()
        setSpecialities(data)
      } catch (error) {
        console.log(error)
      }
    }
    const getCategories = async () => {
      try {
        const response = await fetch(`${Apiurl.Fetchurl}get_all_category`, {
          method: 'GET',
          headers: reqJson,
        })
        const data = await response.json()
        setCategories(data)
      } catch (error) {
        console.log(error)
      }
    }
    getSpecialities()
    getCategories()
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFilter((old) => ({
      ...old,
      [name]: value,
    }))
  }

  const reset = () => {
    setFilter(initialValues)
  }

  return (
    <>
      <Row className='align-items-end'>
        {/* ----------------- Filter tab ------------------------------ */}
        <Col lg={5} xl={4}>
          <div className='dashboard-cards d-flex gap-4'>
            <div>
              <img src={require('../assets/images/user4.png')} height='75px' width='75px' alt='child' />
            </div>

            <div className='d-flex justify-content-between gap-1 w-100 my-auto'>
              <div>
                <p className='m-0 text-black fs-6'>
                  <span className='fw-bold'>Name</span> : {selectValue.username}
                </p>
                <p className='m-0 text-black fs-6'>
                  <span className='fw-bold'>School</span> :{selectValue.school}
                </p>
              </div>
              <div>
                <p className='m-0 text-black fs-6'>
                  <span className='fw-bold'>Class</span> : {selectValue.class}
                </p>
                <p className='m-0 text-black fs-6'>
                  <span className='fw-bold'>Age</span> : {selectValue.age}
                </p>
              </div>
              <div>
                <p className='m-0 text-black fs-6'>
                  <span className='fw-bold'>Speciality</span> : {selectValue.speciality}
                </p>
              </div>
            </div>
          </div>
        </Col>

        <Col lg={7} xl={8}>
          <div className='dashboard-cards'>
            <Row>
              <Col>
                <FloatingLabel controlId='Category' label='Category'>
                  <Form.Select required value={filter.category} onChange={handleChange} name='category'>
                    <option value='all'>Any</option>
                    {categories.map((c, i) => {
                      return (
                        <option key={i} value={c}>
                          {c}
                        </option>
                      )
                    })}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId='floatingSelect' label='Speciality'>
                  <Form.Select required value={filter.speciality} onChange={handleChange} name='speciality'>
                    <option value='all'>Any</option>
                    {specialities.map((s, i) => {
                      return (
                        <option key={i} value={s}>
                          {s}
                        </option>
                      )
                    })}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId='Date' label='Date'>
                  <Form.Select required value={filter.date} onChange={handleChange} name='date'>
                    <option value='all'>Any</option>
                    <option value='this_week'>This Week</option>
                    <option value='this_month'>This Month</option>
                    <option value='this_year'>This Year</option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col>
                <Button className='common-btn w-100 h-75' onClick={reset}>
                  Reset
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      {/* ------------------- charts ------------------------ */}

      <Slider {...settings}>
        {allData &&
          Object?.entries(allData)?.map(([key, data], idx) => {
            return (
              <div className='dashboard-cards' key={idx}>
                <div class='performance-heading mb-4'>
                  <h4 className='text-black'>{key.split('_').join(' ')}</h4>
                  {/* <div className='d-flex gap-2'>
                      {Object.keys(data).map((names, i) => {
                        return (
                          <h4
                            key={i}
                            className='text-end text-capitalize'
                            style={{ color: colorsUser[i], fontSize: '12px' }}
                          >
                            {names}
                          </h4>
                        )
                      })}
                    </div> */}
                </div>

                <Row>
                  {Object?.entries(data)?.map(([label, value], i) => {
                    const chartData = getChartData(i, label, value)

                    return (
                      <Col key={i} md={12 / (Object.keys(data).length / 2)} className='p-2'>
                        <Chart type='bar' data={chartData} options={options} height={100} />
                      </Col>
                    )
                  })}
                </Row>
              </div>
            )
          })}
      </Slider>
    </>
  )
}

export default Cummulative

export const colorsUser = ['#6AB04C', '#F0932B', '#BC1CA4', '#22A6B3', '#ACB4EA', '#A67C00']
export const colorsOthers = [
  'rgba(106, 176, 76,0.7)',
  'rgba(240, 147, 43,0.7)',
  'rgba(188, 28, 164,0.7)',
  'rgba(27, 133, 143,0.7)',
  'rgba(172, 180, 234,0.7)',
  'rgba(166, 124, 0,0.7)',
]

export const options = {
  responsive: true,

  scales: {
    x: {
      title: {
        display: true,
        text: 'Sessions',
      },
      grid: {
        display: false,
      },
    },
    y: {
      min: 0,
      max: 100,
      ticks: {
        stepSize: 20,
      },
    },
  },
  plugins: {
    tooltip: {
      displayColors: false,
    },
    legend: {
      align: 'end',
    },
  },
}

export const getChartData = (i, label, data) => {
  let labels = [...Array(data.length).keys()].map((i) => i + 1)
  return {
    labels: labels,
    datasets: [
      { type: 'line', label: label, data: data, borderColor: colorsOthers[i], backgroundColor: colorsOthers[i] },
      {
        type: 'bar',
        label: label,
        data: data,
        backgroundColor: colorsUser[i],
        maxBarThickness: 50,
        borderRadius: 8,
      },
    ],
  }
}

const settings = {
  arrows: false,
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  speed: 500,
  autoplaySpeed: 4000,
  cssEase: 'linear',
}
