import react, { useContext, useState } from 'react'
import Button from 'react-bootstrap/Button'
import { Col, Row } from 'react-bootstrap'
import Card from 'react-bootstrap/Card'
import { NavLink, useLocation } from 'react-router-dom'
import { useSpeechSynthesis } from 'react-speech-kit'
import Speech from '../Speech'
import Context from '../../Context'

const GamecatCard = (props) => {
  let location = useLocation()
  let sendModulleData = JSON.parse(localStorage.getItem('sendModulleData'))

  const { selectValue } = useContext(Context)

  return (
    <Col style={{ padding: '0px 0px 0px 20px' }}>
      <Card>
        <div
          className='position-absolute d-flex justify-content-center align-items-center rounded-circle'
          style={{
            width: '50px',
            height: '50px',
            right: '15px',
            top: '15px',
            background: `conic-gradient(var(--primary-color) ${(props.percentage / 100) * 360}deg, #e9e9e9 0deg)`,
          }}
        >
          <div
            className='d-flex justify-content-center align-items-center bg-white text-black rounded-circle shadow'
            style={{ width: '35px', height: '35px', fontSize: '12px', fontWeight: 800 }}
          >
            {Math.round(props.percentage)}%
          </div>
        </div>
        <Card.Img variant='top' src={props.images} />
        <Card.Body>
          <Card.Title>{props.title} </Card.Title>
          <Card.Text>{props.content}</Card.Text>
          <NavLink
            to={props.link.toLowerCase()}
            onClick={() => Speech.Speech(`${sendModulleData.username} choose ${props.title}`)}
            state={{
              ...location.state,
              title:
                location.state.title == undefined
                  ? props.title.toLowerCase()
                  : (location.state.title = props.title.toLowerCase()),
              username: selectValue.username,
              userid: selectValue.userid,
              category: props.link,
              category2: props.link,
              modulename2: (location.state.modulename2 = props.link),
            }}
          >
            <Button className='common-btn w-100'>Start</Button>
          </NavLink>
        </Card.Body>
      </Card>
    </Col>
  )
}
export default GamecatCard
