import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import ArrowLeft from '../../assets/images/arrow_left_icon.svg'
import ArrowRight from '../../assets/images/right_arrow_icon.svg'
import * as API from '../../Apisurl'
import Context from '../../Context'
import { useTimer } from 'react-timer-hook'

const LeftRight = () => {
  const { selectValue } = useContext(Context)

  const [isPlaying, setIsPlaying] = useState(false)
  const [score, setScore] = useState(0)

  const [keyPressed, setKeyPressed] = useState('')
  const [randomKey, setRandomKey] = useState('')
  const [show, setShow] = useState(true)

  const timeout = useRef()

  const { minutes, seconds, restart, pause } = useTimer({
    autoStart: false,
    expiryTimestamp: new Date(),
    onExpire: () => stopGame(),
  })

  const choices = useMemo(() => ['arrowleft', 'arrowright'], [])

  // useCallback is use because every re-render all fns are recreated
  // so old reference of handleKeyPress is lost
  // there removeEventListener can't work
  const handleKeyPress = useCallback(
    (e, btnPressed) => {
      setKeyPressed(e.key?.toLowerCase() || btnPressed)

      // After 0.5s of key press hide and reset keyPressed state
      setTimeout(() => {
        setShow(false)
        setKeyPressed('')

        // After 0.7s of hiding, show new random key
        timeout.current = setTimeout(() => {
          let randomIdx = Math.round(Math.random())
          setRandomKey(choices[randomIdx])
          setShow(true)
        }, 700) // end of inner timer
      }, 500) // end of outer timer
    },
    [choices]
  )

  const saveReport = async (res) => {
    try {
      await fetch(`${API.Fetchurl}games_data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('session')}`,
        },
        body: JSON.stringify(res),
      })
    } catch (error) {
      console.log(error)
    }
  }

  const startGame = useCallback(() => {
    setScore(0)

    document.addEventListener('keyup', handleKeyPress)
    let randomIdx = Math.round(Math.random())
    setRandomKey(choices[randomIdx])
    setShow(true)

    setIsPlaying(true)
    let time = new Date()
    restart(time.setMinutes(time.getMinutes() + 1))
  }, [choices, handleKeyPress, restart])

  const stopGame = useCallback(() => {
    document.removeEventListener('keyup', handleKeyPress)
    setRandomKey('')
    setKeyPressed('')
    setShow(false)

    setIsPlaying(false)
    pause()
    saveReport({
      user_id: selectValue.user_id,
      username: selectValue.username,
      bet: 0,
      time: '1',
      category: 'Match Keys Game',
      score: score,
      bet_array: [],
    })
  }, [handleKeyPress, pause, score, selectValue.user_id, selectValue.username])

  useEffect(() => {
    if (keyPressed !== '' && randomKey !== '' && keyPressed === randomKey) {
      setScore((s) => s + 1)
    }
  }, [randomKey, keyPressed])

  useEffect(() => {
    return () => document.removeEventListener('keyup', handleKeyPress)
  }, [handleKeyPress])

  return (
    <div className='flex-grow-1 d-flex align-items-center justify-content-center'>
      <div className='dashboard-cards rounded-3 p-4 d-flex flex-column' style={{ minHeight: '500px', width: '450px' }}>
        <h4 className='text-end m-0 mb-1'>
          <span className='text-secondary'>Score : </span>
          <span>{score}</span>
        </h4>
        {isPlaying && (
          <h4 className='text-end m-0 mb-2'>
            <span className='text-secondary'>Time : </span>
            <span>
              {minutes} : {seconds}
            </span>
          </h4>
        )}

        <div
          className={`flex-grow-1 d-flex gap-2 justify-content-center align-items-center rounded-3 py-2 px-3 ${
            keyPressed === '' || randomKey === ''
              ? 'bg-white'
              : keyPressed === randomKey
              ? 'bg-success text-white'
              : 'bg-danger text-white'
          }`}
        >
          {isPlaying ? (
            <img
              className={`${show ? 'd-block' : 'd-none'}`}
              src={`${randomKey === 'arrowleft' ? ArrowLeft : randomKey === 'arrowright' ? ArrowRight : ''}`}
              width='256'
              height='256'
              alt='keyboard-key'
            />
          ) : (
            <h2 className='text-capitalize m-0'>
              Press start to play, <br /> Press the displayed key to score points
            </h2>
          )}
        </div>

        {!isPlaying ? (
          <button type='button' onClick={startGame} className='game-btn bg-warning text-black'>
            Start
          </button>
        ) : (
          <>
            <div className='d-flex flex-wrap'>
              {choices.map((key, i) => (
                <button
                  key={i}
                  type='button'
                  onClick={(e) => handleKeyPress(e, key)}
                  className={`border bg-transparent w-${(100 / choices.length).toString()}`}
                >
                  <img
                    src={`${key === 'arrowleft' ? ArrowLeft : key === 'arrowright' ? ArrowRight : ''}`}
                    width='64'
                    height='64'
                    alt='keyboard-key'
                  />
                </button>
              ))}
            </div>
            <button type='button' onClick={stopGame} className='game-btn'>
              Stop
            </button>
          </>
        )}
      </div>
    </div>
  )
}

export default LeftRight
