import React, { useContext } from 'react'
import { Col } from 'react-bootstrap'
import { NavLink, useLocation } from 'react-router-dom'
import Speech from './Speech'
import Context from '../Context'
const Categorycard = (props) => {
  const { selectValue } = useContext(Context)
  let location = useLocation()

  let username
  let userid
  if (location.state != null) {
    username = selectValue.username
    userid = selectValue.user_id
  }
  return (
    <Col md={4}>
      <NavLink
        to={props.link.toLowerCase()}
        onClick={() => Speech.Speech(`${selectValue.username} choose ${props.title}`)}
        state={{
          username: username,
          userid: userid,
          category: props.title,
          category2: props.link,
          modulename: location.state.modulename,
          modulename3: location.state.modulename3,
          modulename2: location.state.modulename2,
          modulename1: location.state.modulename1,
        }}
      >
        <div className='position-relative'>
          <figure className='search-item'>
            <span
              className={`attempttxt ${props.attempted ? 'bg-primary' : 'new bg-success'}`}
              style={{
                display: location.state.modulename2 === 'flashcard' ? 'block' : 'none',
                position: 'absolute',
                top: '-5%',
                right: '95%',
                fontSize: '13px',
                padding: '3px 10px',
                borderRadius: '30px',
                color: '#fff',
                fontWeight: 800,
              }}
            >
              {props.attempted ? 'Attempted' : 'New'}
            </span>

            <div
              className='d-flex align-items-center justify-content-center rounded-circle'
              style={{
                width: '80px',
                height: '80px',
                background: `conic-gradient(var(--primary-color) ${(props.percentage / 100) * 360}deg, #c9c9c9 0deg)`,
              }}
            >
              <img src={props.images} alt='student' className='shadow' />
            </div>
            <figcaption>
              <h6>{props.title}</h6>
              <p className='m-0'>{Math.round(props.percentage)}%</p>
            </figcaption>
          </figure>
        </div>
      </NavLink>
    </Col>
  )
}
export default Categorycard
