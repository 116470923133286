import React from 'react'
import ReactDOM from 'react-dom/client'
import './style.min.css'

import App from './App'

import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/index.css'
import { BrowserRouter } from 'react-router-dom'
import { render } from '@testing-library/react'
import Selectedlang from './Selectedlang'

// import 'mdbreact/dist/css/mdb.css';

import Button from 'react-bootstrap/Button'

import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <App />
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
