import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { Col, Row } from 'react-bootstrap'
import '../profile/profile.css'

const Cummulative = ({ fetching, data }) => {
  const [allData, setAllData] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    if (!fetching) {
      setAllData({
        Learning_Style: data.Learning_Style,
        Brain_Strength: data.Brain_Strength,
        Emotional_Analysis: data.Emotional_Analysis,
        Performance_Metric: data.Performance_Metric,
      })
      setLoading(false)
    }
  }, [fetching])

  let role = localStorage.getItem('role')

  return (
    <div>
      <h4
        className='dashboard-cards text-center'
        style={{
          color: 'var(--green-color)',
          backgroundColor: 'var(--green-highlight-color)',
        }}
      >
        Improvements Across Sessions
      </h4>
      <p className='fs-5' style={{ textAlign: 'justify' }}>
        Through these findings, you may uncover that your{' '}
        {role === 'parent' || role === 'teacher' ? 'child' : 'individual'} may excel in Math, instead of Arts because of
        his higher brain strength in Math. His thinking power gets activated the moment he sees an algebra question
        instead of a political issue.
      </p>
      <p className='fs-5' style={{ textAlign: 'justify' }}>
        This analysis will give you a complete picture about how the learning style of your{' '}
        {role === 'parent' || role === 'teacher' ? 'child' : 'individual'} gets impacted and how you can improve the
        same. For example, across the sessions we make some recommendations and based on that the
        {role === 'parent' || role === 'teacher' ? 'child' : 'individual'} starts learning via interactive content,
        which can improve his concentration and the stress level will decrease. These performance improvements are
        tracked on a monthly basis for each subject through our month-on-month sessions.
      </p>
      <p className='fs-5 fw-bold' style={{ textAlign: 'justify' }}>
        Instructions: You can click on the legends to show/hide curve
      </p>

      {/* ------------------- charts ------------------------ */}

      <Row>
        {!loading &&
          Object.entries(allData)?.map(([key, data], idx) => {
            const series = Object.entries(data).map(([name, values]) => ({
              name: name.charAt(0).toUpperCase() + name.slice(1),
              data: values.map((v) => Math.round(v)),
            }))

            const options = {
              chart: {
                toolbar: {
                  autoSelected: 'pan',
                  show: false,
                },
                zoom: {
                  enabled: false,
                },
                pan: {
                  enabled: true,
                  type: 'x',
                },
              },
              tooltip: {
                x: {
                  formatter: (value, extra) => 'Session ' + value,
                },
              },
              colors: colorsUser,
              dataLabels: {
                enabled: true,
              },
              stroke: {
                curve: 'smooth',
              },
              markers: {
                size: 2,
              },
              xaxis: {
                range: Math.min(5, series[0].data.length - 1),
                title: {
                  text: 'Sessions',
                },
              },
              yaxis: {
                tickAmount: 4,
                min: 0,
                max: 100,
              },
              legend: {
                position: 'top',
                horizontalAlign: 'right',
              },
            }

            return (
              <Col key={idx} xl={6}>
                {idx % 2 ? <div className='page-break' /> : null}
                <div className='dashboard-cards'>
                  <div class='performance-heading mb-4'>
                    <h4 className='text-black'>{key.split('_').join(' ')}</h4>
                  </div>

                  <Chart options={options} series={series} type='line' height={350} />
                </div>
              </Col>
            )
          })}
      </Row>
    </div>
  )
}

export default Cummulative

const colorsUser = ['#6AB04C', '#F0932B', '#BC1CA4', '#22A6B3', '#ACB4EA', '#A67C00']
