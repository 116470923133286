import React, { useContext, useState } from 'react'
import { Button, Col, FloatingLabel, Form, Row } from 'react-bootstrap'
import Dropzone from 'react-dropzone-uploader'
import * as API from '../../../Apisurl'
import Context from '../../../Context'
import * as Currdate from '../../../CurrDate'

const CanvaGPT = ({ submit }) => {
  const { selectValue } = useContext(Context)
  const [formData, setFormData] = useState({
    game: '',
    topic: '',
    file: '',
  })
  let date = Currdate.GetCurrentDate()

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((old) => ({ ...old, [name]: value }))
  }

  const handleChangeStatus = ({ file, xhr }, status, data) => {
    if (status === 'removed') {
      setFormData((old) => ({ ...old, file: '' }))
    }
    if (status === 'done') {
      let result = JSON.parse(xhr.response)
      setFormData((old) => ({ ...old, file: result.file_path[0] }))
    }
  }

  const getUploadParams = ({ meta, file }) => {
    const body = new FormData()

    body.append('user_id', selectValue.user_id)
    body.append('folder_type', formData.game)
    body.append('file', file)

    return {
      body,
      url: `${API.Fetchurl + 'file_upload_1'}`,
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('session'),
      },
      method: 'POST',
    }
  }

  const handleSubmit = () => {
    submit(date, [{ ...formData }])
  }
  return (
    <div className='mx-auto w-50 dashboard-cards p-4'>
      <Form>
        <Row>
          <Col md={6}>
            <FloatingLabel controlId='game' label='Game'>
              <Form.Control
                required
                type='text'
                placeholder='game'
                maxLength='20'
                name='game'
                onChange={handleChange}
                value={formData.game}
              />
              <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
            </FloatingLabel>
          </Col>
          <Col md={6}>
            <FloatingLabel controlId='topic' label='Topic'>
              <Form.Control
                required
                type='text'
                placeholder='topic'
                maxLength='20'
                name='topic'
                onChange={handleChange}
                value={formData.topic}
              />
              <Form.Control.Feedback type='invalid'>Please enter required field.</Form.Control.Feedback>
            </FloatingLabel>
          </Col>
          <Col>
            <label>Upload CSV</label>
            <Dropzone
              inputContent={'Drag and drop files here or click to browse.'}
              getUploadParams={getUploadParams}
              name='file'
              maxFiles={1}
              onChangeStatus={handleChangeStatus}
            />
          </Col>
        </Row>
        <Button type='button' onClick={handleSubmit} className='common-btn mt-2'>
          Submit
        </Button>
      </Form>
    </div>
  )
}

export default CanvaGPT
