import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import UserJson from "../Userjson";
import { GetCurrentDate } from "../CurrDate";
import { useSpeechSynthesis } from "react-speech-kit";
import * as Apiurl from "../Apisurl";
const StartModal = (props) => {

  const { speak } = useSpeechSynthesis();

  const [show, setShow] = useState(true);
  const [play, setplay] = useState(false);
  // alert(props.letquesLength);
  let userjson = {
    result: "begin",
    buffer: props.calldaynum,
    type: props.type,
    ques_length: props.letquesLength,
    ...UserJson(props.date),
  };

  console.log(userjson);
  const [showtimer, setshowtimer] = useState(false);
  const [runTimer, setrunTimer] = useState(false);
  const handleClose = () => {

    setrunTimer(true);
    setShow(false);
    setplay(true);
    setshowtimer(true);
      // Apiurl.Socketurl.connect("http://18.224.83.210:8080");


    props.socket.emit("common", userjson);
  };

  const handleShow = () => setShow(true);
  const handleChange = (e) => {
    props.calldelay(e.target.value);
  };

  return (
    <>
      <Modal
        show={sessionStorage.getItem("headsetVia") != 1 ?  show:""}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
      >
      
        <Modal.Header>
          <Modal.Title>Select timer to Start</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Select
            aria-label="Default select example"
            onChange={handleChange}
          >
            <option value="1">1 sec</option>
            <option value="2">2 sec</option>
            <option value="3">3 sec</option>
            <option value="4">4 sec</option>

            <option value="5">5 sec</option>
            <option value="6">6 sec</option>
            <option value="7">7 sec</option>
            <option value="8">8 sec</option>
            <option value="9">9 sec</option>

            <option value="10">10 sec</option>
          </Form.Select>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="w-100 common-btn"
            onClick={() => handleClose(speak({ text: "start" }))}
          >
            Start
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showtimer}
        className="timerModal"
        backdrop="static"
        centered
        keyboard={false}
      >
        <CountdownCircleTimer
          isPlaying={play}
          duration={parseInt(props.calldaynum)}
          colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
          colorsTime={[7, 5, 2, 0]}
          onComplete={() => {
            setshowtimer(false);
          }}
        >
          {({ remainingTime }) => "Starting In" + "   " + remainingTime}
        </CountdownCircleTimer>
      </Modal>
    </>
  );
};

export default StartModal;
