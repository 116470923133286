import Form from "react-bootstrap/Form";
import { useContext, useRef, useState } from "react";
import Context from "./Context";
function Switch() {
  let toggle = useRef();
  const [isOn, setIsOn] = useState(false);

  return (
    <Form>
      <Form.Check
        type="switch"
        ref={toggle}
        checked={isOn}
        onClick={() =>
          setIsOn((prevState) => localStorage.setItem("isOn", !prevState))
        }
        id="custom-switch"
        label="Speech"
      />
    </Form>
  );
}

export default Switch;
