import React, { useContext, useRef } from 'react'
import { Tabs, Tab, Table } from 'react-bootstrap'
import Context from '../../Context'
import Userjson from '../../Userjson'

const QuizResult = () => {
  const { overview, score, total } = useContext(Context)
  const tableRef = useRef()
  const userData = Userjson()

  const info = {
    preprimary: ['primary1', 'primary2'],
    primary1: ['secondary', 'primary2'],
    primary2: ['secondary', 'prevocation2'],
    secondary: ['prevocation1', 'prevocation2'],
    prevocation1: ['vocational training', 'prevocation2'],
    prevocation2: ['vocational training', 'vocational training'],
  }

  const getHeadLine = () => {
    if (score < 80) {
      return `You are being demoted to ${info[userData.category][1]}`
    }

    return `You are being promted to ${info[userData.category][0]}`
  }

  const getAutisumTable = () => {
    return (
      <Table className='result-table quizes_report_table text-center'>
        <thead>
          <tr>
            <th>Total {'<'} 70</th>
            <th>
              Total {'>='} 70 and Total {'<='} 106
            </th>
            <th>
              Total {'>='} 107 && Total {'<='} 153
            </th>
            <th>Total {'>'} 153</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='fs-6 fw-normal text-black'>No Autism</td>
            <td>Mild Autism</td>
            <td>Moderate Autism</td>
            <td>Severe Autism</td>
          </tr>
        </tbody>
      </Table>
    )
  }

  const renderTopHeading = () => {
    if (userData.category.toUpperCase() === 'ISSA') {
      return getAutisumTable()
    } else {
      return <h4>{getHeadLine()}</h4>
    }
  }

  if (overview) {
    switch (userData.category.toLowerCase()) {
      case 'adhd':
      case 'wenderadhd':
      case 'addh':
      case 'psc':
        return (
          <Tabs defaultActiveKey='summary' className='mb-3'>
            <Tab eventKey='summary' title='Summary'>
              <Table className='result-table quizes_report_table'>
                <tbody>
                  <tr>
                    <td>{overview.isADHD ? 'You have ADHD' : 'You do not have ADHD'}</td>
                    <td>
                      <div
                        className='rounded-pill px-3 m-auto'
                        style={{ border: '1px solid #54baaf', width: 'fit-content' }}
                      >
                        <span style={{ color: '#54baaf' }}> {score}</span>/<span>{total}</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Tab>
            <Tab eventKey='reports' title='Reports'>
              <div className='report-container mb-3'>
                <h3></h3>
                <p>
                  Score:
                  <b> {score}</b>/<span>{total}</span>
                </p>
              </div>
              <Table className='result-table quizes_report_table' ref={tableRef}>
                <thead>
                  <tr>
                    <th>Question</th>
                    <th>Answer</th>
                  </tr>
                </thead>
                <tbody>
                  {overview.ques.ques.map((question, index) => (
                    <tr key={index}>
                      <td>
                        <p>
                          {index + 1}. {question}
                        </p>
                      </td>
                      <td>
                        <p className='m-0 text-center'>{overview.ques.ans[index]['ans']}</p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Tab>
          </Tabs>
        )
      default:
        return (
          <Tabs defaultActiveKey='summary' className='mb-3'>
            <Tab eventKey='summary' title='Summary'>
              {renderTopHeading()}
              <Table className='result-table quizes_report_table'>
                <thead>
                  <tr>
                    <th>Topic</th>
                    <th>Score</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(overview.ques.summary).map(([k, v]) =>
                    k === 'score' || k === 'total' || k === 'percentage' ? (
                      ''
                    ) : (
                      <tr>
                        <td>{k}</td>
                        <td>
                          <div
                            className='rounded-pill px-3 m-auto'
                            style={{ border: '1px solid #54baaf', width: 'fit-content' }}
                          >
                            <span style={{ color: '#54baaf' }}> {v.score}</span>/<span>{v.total}</span>
                          </div>
                        </td>
                      </tr>
                    )
                  )}
                  <tr>
                    <td>Total</td>
                    <td>
                      <div
                        className='rounded-pill px-3 m-auto'
                        style={{ border: '1px solid #54baaf', width: 'fit-content' }}
                      >
                        <span style={{ color: '#54baaf' }}> {overview.ques.summary.score}</span>/
                        <span>{overview.ques.summary.total}</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Tab>
            <Tab eventKey='reports' title='Reports'>
              <div className='report-container mb-3'>
                <h3></h3>
                <p>
                  Score:
                  <b> {score}</b>/<span>{total}</span>
                </p>
              </div>
              <Table className='result-table quizes_report_table' ref={tableRef}>
                <thead>
                  <tr>
                    <th>Question</th>
                    <th>Answer</th>
                    <th>Additional Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {overview.ques.ques.map((question, index) => (
                    <tr key={index}>
                      <td>
                        <p>
                          {index + 1}. {question}
                        </p>
                      </td>
                      <td>
                        <p className='m-0 text-center'>{overview.ques.ans[index]['ans']}</p>
                      </td>
                      <td>
                        <p>{overview.ques.ans[index]['notes'] && overview.ques.ans[index]['notes']}</p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Tab>
          </Tabs>
        )
    }
  }
}
export default QuizResult
