import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Col, Collapse, Form, Row } from 'react-bootstrap'
import { FiChevronDown } from 'react-icons/fi'
import * as API from '../Apisurl'

const TeamView = () => {
  const [teamList, setTeamList] = useState()

  const initialValues = {
    category: 'all',
    speciality: 'all',
    gender: 'all',
    minAge: 2,
    maxAge: 99,
  }
  const [filter, setFilter] = useState(initialValues)
  const [categories, setCategories] = useState()

  const isGroup = localStorage.getItem('isGroup')

  useEffect(() => {
    const getinfo = async () => {
      try {
        const response = await fetch(
          `${API.Fetchurl}day_analysis?isGroup=${isGroup}&category=${filter.category}&speciality=${filter.speciality}&gender=${filter.gender}&minAge=${filter.minAge}&maxAge=${filter.maxAge}`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${sessionStorage.getItem('session')}`,
            },
          }
        )
        if (response.ok) {
          const data = await response.json()

          setTeamList(data)
        } else {
          throw new Error('Something Went Wrong...')
        }
      } catch (error) {
        console.log(error)
      }
    }
    getinfo()
  }, [])

  useEffect(() => {
    const getCategories = async () => {
      try {
        const response = await fetch(`${API.Fetchurl}get_all_category`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem('session')}`,
          },
        })
        const data = await response.json()
        setCategories(data)
      } catch (error) {
        console.log(error)
      }
    }

    getCategories()
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFilter((old) => ({
      ...old,
      [name]: value,
    }))
  }

  const reset = () => {
    setFilter(initialValues)
  }

  const save = async () => {
    try {
      const response = await fetch(`${API.Fetchurl}day_analysis`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('session')}`,
        },
        body: JSON.stringify({
          productivity_shuffled_dict: teamList,
          isGroup: false,
        }),
      })

      if (response.ok) {
        const data = await response.json()
        setTeamList(data)
      } else {
        throw new Error('Something Went Wrong...')
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  const dragEnd = (result) => {
    const { source, destination } = result

    if (!destination) return

    if (source.droppableId === destination.droppableId) return

    if (source.droppableId === 'unattempted' || destination.droppableId === 'unattempted') return

    let s_id = source.droppableId,
      d_id = destination.droppableId

    let s_idx = source.index

    let s_list = teamList[s_id],
      d_list = teamList[d_id]

    let [item_to_move] = s_list.splice(s_idx, 1)

    d_list.push(item_to_move)

    d_list.sort((a, b) => {
      return b[1] - a[1]
    })

    setTeamList({ ...teamList, [d_id]: d_list, [s_id]: s_list })
  }

  return (
    <div className='my-4'>
      <Form className='dashboard-cards p-4'>
        <Row>
          <Col>
            <Form.Group controlId='Category'>
              <Form.Label>Category</Form.Label>
              <Form.Select required value={filter.category} onChange={handleChange} name='category'>
                <option value='all'>Any</option>
                {categories?.map((c, i) => {
                  return (
                    <option key={i} value={c}>
                      {c}
                    </option>
                  )
                })}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='Speciality'>
              <Form.Label>Speciality</Form.Label>
              <Form.Select required value={filter.speciality} onChange={handleChange} name='speciality'>
                <option value='all'>Any</option>
                <option value='educator'>Special educator</option>
                <option value='therapis'>Speech therapist</option>
                <option value='assessor'>Technical assessor</option>
                <option value='other'>Other</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='Gender'>
              <Form.Label>Gender</Form.Label>
              <Form.Select required value={filter.gender} onChange={handleChange} name='gender'>
                <option value='all'>Any</option>
                <option value='male'>Male</option>
                <option value='female'>Female</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='Min Age'>
              <Form.Label>Min Age</Form.Label>
              <Form.Control
                type='number'
                min={2}
                max={99}
                onChange={handleChange}
                name='minAge'
                value={filter.minAge}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='Max Age'>
              <Form.Label>Max Age</Form.Label>
              <Form.Control
                type='number'
                min={2}
                max={99}
                onChange={handleChange}
                name='maxAge'
                value={filter.maxAge}
              />
            </Form.Group>
          </Col>
          <Col>
            <button type='button' className='common-btn h-50 w-100 mb-1' onClick={reset}>
              Reset
            </button>

            <button type='button' className='common-btn h-50 w-100' onClick={save}>
              Save
            </button>
          </Col>
        </Row>
      </Form>
      <Row className='text-black'>
        <DragDropContext onDragEnd={dragEnd}>
          {teamList &&
            Object.entries(teamList).map(([col_name, usersList]) => {
              return <Column title={col_name} users={usersList} />
            })}
        </DragDropContext>
      </Row>
    </div>
  )
}

export default TeamView

export const Column = ({ title, users }) => {
  const [avg, setAvg] = useState()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (users.length === 0) return setAvg(0)
    let total = users.reduce((sum, curr) => {
      return sum + curr[1]
    }, 0)
    let res = (total / users.length).toFixed(1)
    setAvg(res)
  }, [users.length, users])

  return (
    <Col
      md={6}
      xl={4}
      xxl={3}
      style={{ order: title === 'afternoon' ? 2 : title === 'evening' ? 3 : title === 'morning' ? 1 : 4 }}
    >
      <div className='dashboard-cards p-4'>
        <div className='d-flex justify-content-between align-items-center border-bottom pb-2 mb-3'>
          <h5 className='text-capitalize'>{title}</h5>
          <h5 className='d-flex align-items-center gap-2'>
            {users?.length > 5 && (
              <span
                onClick={() => setOpen((prev) => !prev)}
                style={{
                  cursor: 'pointer',
                  transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
              >
                <FiChevronDown />
              </span>
            )}
            <span
              className={`rounded-pill px-4 py-1 text-white ${
                avg <= 20 ? 'bg-danger' : avg <= 60 ? 'bg-warning' : 'bg-success'
              }`}
            >
              {avg}
            </span>
          </h5>
        </div>

        <Droppable droppableId={title}>
          {(provided, snapshot) => (
            <div className='d-flex flex-column gap-2' ref={provided.innerRef} {...provided.droppableProps}>
              {users.length ? (
                <>
                  {users.slice(0, 5).map((u, i) => {
                    return <UserItem key={u[0]} user={u} index={i} />
                  })}
                  {users.length > 5 && (
                    <Collapse in={open}>
                      <div>
                        <div className='d-flex flex-column gap-2'>
                          {users.slice(5).map((u, i) => {
                            return <UserItem key={u[0]} user={u} index={i} />
                          })}
                        </div>
                      </div>
                    </Collapse>
                  )}
                </>
              ) : (
                !snapshot.isDraggingOver && <div className='text-secondary'> No employees in this list </div>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </Col>
  )
}

export const UserItem = ({ user, index }) => {
  return (
    <Draggable draggableId={user[0]} index={index}>
      {(provided, snapshot) => (
        <div
          className={`d-flex justify-content-between align-items-center p-1 rounded-pill bg-white border border-2 ${
            user[1] <= 20 ? 'border-danger' : user[1] <= 60 ? 'border-warning' : 'border-success'
          } ${snapshot.isDragging ? 'shadow' : ''}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <span className='mx-2 w-75' style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {user[0]}
          </span>
          <span
            className={`d-flex justify-content-center align-items-center rounded-pill px-3 py-1 text-white ${
              user[1] <= 20 ? 'bg-danger' : user[1] <= 60 ? 'bg-warning' : 'bg-success'
            }`}
          >
            {user[1].toFixed(1)}
          </span>
        </div>
      )}
    </Draggable>
  )
}
