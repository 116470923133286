import { useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import Draggable from 'react-draggable' // Both at the same time
import { useLocation, useNavigate } from 'react-router-dom'
import * as Apiurl from '../../../Apisurl'
import * as Currdate from '../../../CurrDate'
import UserJson from '../../../Userjson'
import refreshbtn from '../../../assets/images/refresh.svg'
import MainData from '../../Maindata'
import Progress from '../Testprogrsscom'
import Editor from './Editor'
import Excelgame from './Excelgame'
import Qrcode from './Qrcode'
import Typing from './Typing'
import Wordspeed from './Wordspeed'
import SocialMedia from './SocialMedia'
import headersReq from '../../../HeadersJson'
import CanvaGPT from './CanvaGPT'
import ChatBoxGame from './ChatBoxGame'
import EyeTracking from './EyeTracking'
import Shop from './Shop'

const VocationalGame = () => {
  const reqJson = headersReq()
  const location = useLocation()
  const navigate = useNavigate()

  let DraggableCore = Draggable.DraggableCore
  const [score, setScore] = useState(0)
  const [callday, setcallday] = useState(1)
  const [isActive, setIsActive] = useState(false)
  const [vocationalData, setVocationalData] = useState([])
  let [constantTime, setconstantTime] = useState('')
  const [data1, setdata1] = useState([])

  let initialArray = []
  const [theArray, setTheArray] = useState(initialArray)
  const [page, setPage] = useState(0)

  let date = Currdate.GetCurrentDate()
  let cat = location.state.category

  let datei = {
    date: date,
  }

  useEffect(() => {
    async function levelgame() {
      const response = await fetch(`${Apiurl.Fetchurl + 'level_type'}`, {
        method: 'POST',
        headers: reqJson,
        body: JSON.stringify({
          category: cat == 'qr-code' ? 'qr_code' : cat,
        }),
      })
      const childdata = await response.json()
      return childdata
    }
    levelgame()
      .then((data) => {
        setVocationalData(data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  let userData = UserJson()
  let answer

  const submitResult = (...datei) => {
    answer = JSON.parse(localStorage.getItem('useranswer'))
    let getScore

    if (Array.isArray(answer)) {
      getScore = answer.filter((o, i) => {
        return o.via == 'correct'
      })
    }

    let alldata = {
      ...userData,
      result: JSON.stringify({
        ques: vocationalData.length != 0 ? vocationalData[location.state.link] : '',
        score: Array.isArray(answer) ? getScore.length : 0,
        total: vocationalData.length != 0 ? vocationalData[location.state.link].length : '',
        ans: answer,
      }),
      date: datei,
    }

    const esc = encodeURIComponent
    const query = Object.keys(alldata)
      .map((k) => `${esc(k)}=${esc(alldata[k])}`)
      .join('&')

    async function levelgame() {
      const response = await fetch(`${Apiurl.Fetchurl + 'manual_report_fc?' + query + ''}`, {
        method: 'GET',
        headers: reqJson,
      })
      const childdata = await response.json()
      return childdata
    }

    levelgame()
      .then((data) => {
        navigate(`vocationalresult?id=${data.serial_num}`, { state: { ...location.state, date: date } })
      })
      .catch((error) => {
        alert(error)
      })
  }

  const submitCustomGame = (datei, data) => {
    let alldata = {
      ...userData,
      result: JSON.stringify({
        ques: data,
        score: 1,
        total: 1,
        ans: [],
      }),
      date: datei,
    }
    const esc = encodeURIComponent
    const query = Object.keys(alldata)
      .map((k) => `${esc(k)}=${esc(alldata[k])}`)
      .join('&')

    async function levelgame() {
      const response = await fetch(`${Apiurl.Fetchurl + 'manual_report_fc?' + query}`, {
        method: 'GET',
        headers: reqJson,
      })
      const childdata = await response.json()
      return childdata
    }
    levelgame()
      .then((data) => {
        navigate(`vocationalresult?id=${data.serial_num}`, { state: { ...location.state, date: date } })
      })
      .catch((error) => {
        alert(error)
      })
  }

  if (
    vocationalData.length != 0 ||
    userData.category == 'content_writing' ||
    userData.category == 'social_media' ||
    userData.category === 'aidesign' ||
    userData.category === 'nemabot' ||
    userData.category === 'ecommerce_training'
  ) {
    const renderGame = () => {
      let comp

      switch (cat.toLowerCase()) {
        case 'excel_typing':
          comp = (
            <Excelgame
              datalength={vocationalData[location.state.link]}
              vocationalData={vocationalData[location.state.link][page]}
              setPage={setPage}
              time={setconstantTime}
              submitResult={submitResult}
              setTheArray={setTheArray}
              theArray={theArray}
              level={location.state.link}
              page={page}
              setdata1={setdata1}
              data1={data1}
              setScore={setScore}
            />
          )
          break
        case 'sentence_type':
          comp = (
            <Typing
              datalength={vocationalData[location.state.link]}
              vocationalData={vocationalData[location.state.link][page]}
              setPage={setPage}
              time={setconstantTime}
              submitResult={submitResult}
              setTheArray={setTheArray}
              level={location.state.link}
              page={page}
              setdata1={setdata1}
              data1={data1}
              setScore={setScore}
            />
          )
          break
        case 'content_writing':
          comp = (
            <Editor
              // datalength={vocationalData[location.state.link]}
              // vocationalData={vocationalData[location.state.link][page]}
              setPage={setPage}
              time={setconstantTime}
              submitResult={submitResult}
              setTheArray={setTheArray}
              level={location.state.link}
              page={page}
              setdata1={setdata1}
              data1={data1}
              setScore={setScore}
            />
          )
          break
        case 'qr_code':
          comp = (
            <Qrcode
              datalength={vocationalData[location.state.level]}
              vocationalData={vocationalData[location.state.level][page]}
              setPage={setPage}
              time={setconstantTime}
              submitResult={submitResult}
              setTheArray={setTheArray}
              level={location.state.level}
              page={page}
              setdata1={setdata1}
              data1={data1}
              setScore={setScore}
            />
          )
          break
        case 'social_media':
          comp = <SocialMedia submit={submitCustomGame} />
          break

        case 'aidesign':
          comp = <CanvaGPT submit={submitCustomGame} />
          break

        case 'nemabot':
          comp = <ChatBoxGame submit={submitCustomGame} />
          break

        case 'ecommerce_training':
          comp = <Shop submit={submitCustomGame} />
          break

        default:
          comp = (
            <Wordspeed
              datalength={vocationalData[location.state.link]}
              vocationalData={vocationalData[location.state.link][page]}
              setPage={setPage}
              time={setconstantTime}
              submitResult={submitResult}
              setTheArray={setTheArray}
              level={location.state.link}
              page={page}
              setdata1={setdata1}
              data1={data1}
              setScore={setScore}
            />
          )
      }
      return comp
    }
    return (
      <>
        <Container fluid>
          <Row className='colors-main-container '>{renderGame()}</Row>
          <div className='activity-listing'>
            <ul>
              <li>
                <Progress json={vocationalData[location.state.link]} currindex={page} getarray={theArray} />
              </li>
              <li className='refresh-btn'>
                <button>
                  <img src={refreshbtn} alt='refresh' />
                  Start Another Level
                </button>
                {/* <button>
                  <img src={refreshbtn} alt="refresh" />
                  Start Over
                </button> */}
              </li>
            </ul>
          </div>
        </Container>
      </>
    )
  }
}
export default VocationalGame
