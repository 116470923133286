import 'chartjs-plugin-datalabels'
import { useContext, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import Context from '../Context'

const Pm = (props) => {
  let selectData = useContext(Context)

  const [gradient, setgradient] = useState(1)

  if (sessionStorage.getItem('headsetVia') == '2') {
    if (selectData.pm) {
      return (
        <Bar
          style={{ marginTop: '30px' }}
          data={{
            labels: ['Attention', 'Focus', 'Interest', 'Stress'],
            datasets: [
              {
                barPercentage: 1,
                barThickness: 80,
                maxBarThickness: 60,
                borderRadius: 8,
                maintainAspectRatio: false,
                data: selectData.pm,
                backgroundColor: (context) => {
                  const ctx = context.chart.ctx
                  const gradient = ctx.createLinearGradient(0, 0, 0, 200)
                  gradient.addColorStop(0, '#eaf6f6')
                  gradient.addColorStop(1, '#e0f3f1')
                  return gradient
                },
              },
            ],
          }}
          height={props.height != undefined ? props.height : '60px'}
          options={{
            plugins: {
              legend: {
                display: false,
                labels: {
                  color: 'rgb(255, 99, 132)',
                },
                backgroundColor: gradient,
                title: {
                  display: false,
                  text: 'Custom Chart Title',
                },
              },
            },
            scales: {
              x: {
                grid: {
                  display: false,
                },
              },
              y: {
                type: 'linear',
                display: false,
                position: 'left',
                grid: {
                  display: false,
                },
              },
            },
          }}
        />
      )
    }
  }
}
export default Pm
