const Speech = (txt) => {
  let speech = new SpeechSynthesisUtterance()
  console.log(speech)

  speech.lang = 'en'
  speech.volume = 1
  speech.rate = 1
  speech.pitch = 1
  speech.text = txt

  let voices = window.speechSynthesis.getVoices()
  // console.log(voices)
  speech.voice = voices[1]

  window.speechSynthesis.speak(speech)
}

export default { Speech }
