import Table from "react-bootstrap/Table";
import { MDBIcon } from "mdb-react-ui-kit";
import ReactPaginate from "react-paginate";
import { useEffect, useState, useContext } from "react";
import Context from "../../../Context";
import UserJson from "../../../Userjson";
import { Player, ControlBar, BigPlayButton } from "video-react";
const DrawResult = () => {
  const props1 = useContext(Context)
    
        ;
    console.log(props1);


  let overview = props1[2].Overview;
  if (overview != undefined && overview.length != 0) {
    // console.log(eresult);
    return (
      <Table className="result-table">
        <thead>
          <tr>
            <th></th>
            {overview.ques.map((o, i) => {
              return <th>Q{i + 1}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Asked</td>

            {overview.ans.map((o, i) => {
              return <td>{o.answer}</td>;
            })}
          </tr>
          <tr>
            <td>Your Answer</td>
            {overview.ans.map((o, i) => {
              return (
                <td>
                  <div className="asked-color-box">
                    <p
                      style={
                        o.via == "correct"
                          ? { color: "#77C63A" }
                          : { color: "red" }
                      }
                    >
                      {UserJson().category == "meaning_alphabet"
                        ? o.answer.join(",")
                        : o.answer}
                    </p>
                  </div>
                </td>
              );
            })}
          </tr>
          <tr>
            <td>Correct Answer</td>
            {overview.ques.map((o, i) => {
              return (
                <td>
                  <div className="asked-color-box">
                    {o.ans != undefined ? o.ans : o.ans1 + "," + o.ans2}
                  </div>
                </td>
              );
            })}
          </tr>
        </tbody>
      </Table>
    );
  }
};

export default DrawResult;
